import React from 'react';
import { Button } from '@shopify/polaris';
import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';

const BridgeButton = ({ url, children, ...rest }) => {
  const app = useAppBridge();

  return (
    <Button
      {...rest}
      url={url}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        const redirect = Redirect.create(app);
        if (rest.pathType === 'app') {
          redirect.dispatch(Redirect.Action.APP, url);
        } else {
          redirect.dispatch(Redirect.Action.REMOTE, {
            url,
            newContext: rest.external === undefined || rest.external,
          });
        }
      }}
    >
      {children}
    </Button>
  );
};

export default BridgeButton;
