import React from 'react';
import PageItem from './PageItem';

function PageItems({ pages = [] }) {
  return (
    <>
      {pages
        .sort((a, b) => (b.created_at > a.created_at ? 1 : -1))
        .map((item) => (
          <PageItem {...item} key={item.uuid} />
        ))}
    </>
  );
}
export default PageItems;
