import { Button, BlockStack } from '@shopify/polaris';
import CustomToggleSettings from 'components/CustomToggleSettings';
import Subtitle from 'components/Subtitle';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IntegrationEvents from '../../common/IntegrationEvents';
import ConnectKlaviyoModal from './ConnectKlaviyoModal';
import KlaviyoAccount from './KlaviyoAccount';
import NotificationModal from 'pages/Settings/YotpoSmsPage/components/NotificationModal';
import IntegrationSkeleton from 'pages/Settings/common/IntegrationSkeleton';
import useSyncKlaviyoStatus from 'hooks/useSyncKlaviyoStatus';
import {
  useDisconnectKlaviyoMutation,
  useGetKlaviyoConfigQuery,
  useSendKlaviyoTestNotificationMutation,
  useUpdateKlaviyoConfigMutation,
} from '../KlaviyoIntegrationAPI';
import useIntegrationCallbackURL from 'hooks/useIntegrationCallbackURL';
import RushPage from 'components/custom/RushPage';
import RushSaveBar from 'components/custom/RushSaveBar';
import { isEqual } from 'lodash';

function KlaviyoIntegrationPage() {
  const storeUUID = useSelector((state) => state.shop?.storeUUID);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [quiet, setQuiet] = useState({});
  const [selectedNotificationEvent, setSelectedNotificationEvent] = useState({});
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const { isActive, toggleActive } = useSyncKlaviyoStatus(storeUUID);

  const integrationInstallLink = useIntegrationCallbackURL({
    storeUUID,
    integrationURL: '/auth/klaviyo/install',
  });
  const {
    data = {},
    isFetching: isLoading,
    isSuccess,
  } = useGetKlaviyoConfigQuery(undefined, {
    skip: !storeUUID,
  });
  const klaviyoConfig = data?.data || {};
  const meta = data?.meta || {};

  const [updateKlaviyo, { isLoading: isUpdating }] = useUpdateKlaviyoConfigMutation();
  const [deleteklaviyo, { isLoading: isDeleting }] = useDisconnectKlaviyoMutation();
  const [sendTestNotification, { isLoading: isSendingTestNotification }] = useSendKlaviyoTestNotificationMutation();

  useEffect(() => {
    if (isSuccess && !isLoading && klaviyoConfig) {
      setEvents(klaviyoConfig.events);
      setQuiet(klaviyoConfig.quiet);
    }
  }, [isLoading, isSuccess, klaviyoConfig]);

  const [t] = useTranslation();

  const handleDiscard = () => {
    setEvents(klaviyoConfig.events);
    setQuiet(klaviyoConfig.quiet);
  };

  const handleSave = () => {
    updateKlaviyo({
      ...klaviyoConfig,
      is_active: isActive,
      events,
      quiet,
    });
  };

  const handleConnect = () => {
    window.open(`${integrationInstallLink}`, '_blank');
  };

  const toggleConnectModal = () => {
    setShowConnectModal(!showConnectModal);
  };

  const handleAddEvent = (newEvent) => {
    setEvents([newEvent, ...events]);
  };

  const handleDeleteEvent = (event_index) => {
    const evts = events.filter((_, index) => index !== event_index);
    setEvents(evts);
  };

  const toggleNotificationModal = () => {
    setShowNotificationModal(!showNotificationModal);
  };

  const handleEventNotification = (nevent) => {
    setSelectedNotificationEvent(nevent);
    toggleNotificationModal();
  };

  const handleToggleEventActivation = (evt) => {
    const updatedEvent = { ...evt, is_active: !evt.is_active };
    const updatedEvents = events.map((event) => event.uuid === updatedEvent.uuid ? updatedEvent : event);
    setEvents(updatedEvents);
  };

  const handleNotificationSend = ({ mode, language, value }) => {
    const modeParams = mode === 'phone' ? { phone: value } : { email: value };
    sendTestNotification({
      ...modeParams,
      locale: language,
      trigger: selectedNotificationEvent
        ? {
          uuid: selectedNotificationEvent.trigger_uuid,
          params: selectedNotificationEvent.params,
        }
        : undefined,
    });
  };

  const isDirtyState = useMemo(() => {
    const toSave = { quiet, events, isActive };
    const existing = { quiet: klaviyoConfig.quiet, events: klaviyoConfig.events, isActive: klaviyoConfig.is_active };
    return !isEqual(toSave, existing);
  }, [quiet, events, isActive, klaviyoConfig]);

  const handleDelete = () => {
    deleteklaviyo();
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {isDirtyState && !isLoading && (
        <RushSaveBar
          message={t('carrier-maskings.unsaved-changes')}
          saveAction={{
            content: t('carrier-maskings.save'),
            onAction: handleSave,
            loading: isLoading || isUpdating,
          }}
          discardAction={
            isLoading || isUpdating
              ? undefined
              : {
                onAction: handleDiscard,
                content: t('common.discard'),
              }
          }
        />
      )}
      {isLoading ? (
        <IntegrationSkeleton title={t('settings.klaviyo.title')} />
      ) : (
        <RushPage
          backAction={{ url: `/s/${storeUUID}/notifications` }}
          title={t('settings.klaviyo.title')}
          primaryAction={
            isActive ? (
              <Button
                plain
                onClick={() => {
                  setSelectedNotificationEvent('');
                  toggleNotificationModal();
                }}
                loading={isSendingTestNotification}
              >
                {t('common.send_test_notification')}
              </Button>
            ) : undefined
          }
          subtitle={
            <Subtitle
              actions={[
                {
                  text: t('common.how_to_get_started'),
                  source: 'https://support.rush.app/notifications/klaviyo/integrate.html',
                },
              ]}
            />
          }
        >
          <BlockStack gap={'400'}>
            {!Object.keys(klaviyoConfig)?.length && (
              <CustomToggleSettings
                content={t('notifications.klaviyo_account.active_title')}
                buttonLabel={t('reconnect.primary.action')}
                onToggle={handleConnect}
              />
            )}
            {Object.keys(klaviyoConfig)?.length ? (
              <>
                <CustomToggleSettings
                  buttonLabel={
                    isActive
                      ? t('common.inactivate')
                      : t('settings_page.addon_card.button.label_activate')
                  }
                  content={t('notifications.klaviyo_account.active_title')}
                  isActive={isActive}
                  onToggle={toggleActive}
                />
                <div className={!isActive ? 'disabled-feature-page' : ''}>
                  <BlockStack gap={'500'}>
                    <KlaviyoAccount
                      onRemove={handleDelete}
                      isLoading={isDeleting}
                    />
                    <IntegrationEvents
                      description={t('notifications.klaviyo.add_event.description')}
                      triggers={meta?.triggers}
                      events={events}
                      onAddEvent={handleAddEvent}
                      onDeleteEvent={handleDeleteEvent}
                      onSendEvent={handleEventNotification}
                      onToggleEventActivation={handleToggleEventActivation}
                    />
                  </BlockStack>
                </div>
              </>
            ) : (
              ''
            )}
          </BlockStack>
        </RushPage>
      )}
      {showConnectModal && (
        <ConnectKlaviyoModal isModalOpen={true} onClose={toggleConnectModal} />
      )}
      <NotificationModal
        open={showNotificationModal}
        onClose={toggleNotificationModal}
        onSend={handleNotificationSend}
      />
    </div>
  );
}

export default KlaviyoIntegrationPage;
