import { storeApi } from '../../redux/store/storeApi';

export const ShipmentDetailsApis = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getShipmentDetailsNew: builder.query({
      query: (shipmentId) => {
        return {
          url: `/shipments3/${shipmentId}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipment-details-new'],
    }),

    updateTrackingDetailsNew: builder.mutation({
      query: ({ shipmentId, ...data }) => {
        return {
          url: `/shipments3/${shipmentId}/tracking-details`,
          method: 'PUT',
          body: { ...data },
        };
      },
      invalidatesTags: ['shipment-details-new', 'top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    reTrackShipmentNew: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment/retrack`,
          method: 'PUT',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    deleteShipmentNew: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment`,
          method: 'DELETE',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      invalidatesTags: ['top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    markAsDeliveredNew: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment/mark-as-delivered`,
          method: 'PUT',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      // invalidatesTags: ['shipments', 'top-messages'],
      invalidatesTags: ['top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetShipmentDetailsNewQuery,
  useUpdateTrackingDetailsNewMutation,
  useReTrackShipmentNewMutation,
  useDeleteShipmentNewMutation,
  useMarkAsDeliveredNewMutation,
} = ShipmentDetailsApis;
