import { Box, InlineStack, Text, BlockStack } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function DeliveryTimes({ statistics }) {
  const [t] = useTranslation();
  const {
    stat_days_order_created_to_fulfillment,
    stat_days_fulfillment_to_in_transit,
    stat_days_order_created_to_delivery,
    stat_days_fulfillment_created_to_delivery,
    stat_days_in_transit_to_delivery,
  } = statistics;

  const showCard =
    stat_days_order_created_to_fulfillment ||
    stat_days_fulfillment_to_in_transit ||
    stat_days_order_created_to_delivery ||
    stat_days_fulfillment_created_to_delivery ||
    stat_days_in_transit_to_delivery;

  return !showCard ? (
    ''
  ) : (
    <Box padding="400">
      <BlockStack gap="400">
        <Text variant="headingMd">{t('shipment_details.delivery_time')}</Text>
        <BlockStack gap="100">
          {stat_days_order_created_to_delivery ? (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.order_to_delivery')}</Text>
              <Text>{stat_days_order_created_to_delivery}</Text>
            </InlineStack>
          ) : (
            ''
          )}
          {stat_days_order_created_to_fulfillment ? (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.order_to_fulfillment')}</Text>
              <Text>{stat_days_order_created_to_fulfillment}</Text>
            </InlineStack>
          ) : (
            ''
          )}
          {stat_days_fulfillment_created_to_delivery ? (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.fulfillment_to_delivery')}</Text>
              <Text>{stat_days_fulfillment_created_to_delivery}</Text>
            </InlineStack>
          ) : (
            ''
          )}
          {stat_days_fulfillment_to_in_transit ? (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.fulfillment_to_in_transit')}</Text>
              <Text>{stat_days_fulfillment_to_in_transit}</Text>
            </InlineStack>
          ) : (
            ''
          )}
          {stat_days_in_transit_to_delivery ? (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.in_transit_to_delivery')}</Text>
              <Text>{stat_days_in_transit_to_delivery}</Text>
            </InlineStack>
          ) : (
            ''
          )}
        </BlockStack>
      </BlockStack >
    </Box >
  );
}
export default DeliveryTimes;
