import { API_URL } from 'config';
import { storeApi } from 'redux/store/storeApi';

export const KlaviyoSmsPageAPI = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getKlaviyoConfig: builder.query({
      query: () => {
        return {
          url: `/channels/klaviyo`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response,
      providesTags: ['klaviyo-sms'],
    }),
    addKlaviyoEvent: builder.mutation({
      query: ({ data, storeUUID }) => {
        return {
          baseUrl: `${API_URL}/notifications/${storeUUID}`,
          url: `/channels/klaviyo`,
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    sendKlaviyoTestNotification: builder.mutation({
      query: (data) => {
        return {
          url: `/channels/klaviyo/test`,
          body: { ...data },
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    updateKlaviyoConfig: builder.mutation({
      query: (data) => {
        return {
          url: `/channels/klaviyo`,
          method: 'PUT',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['klaviyo-sms', 'settings'],
    }),
    disconnectKlaviyo: builder.mutation({
      query: () => {
        return {
          url: `/channels/klaviyo`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['klaviyo-sms', 'settings'],

      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetKlaviyoConfigQuery,
  useAddKlaviyoEventMutation,
  useUpdateKlaviyoConfigMutation,
  useDisconnectKlaviyoMutation,
  useSendKlaviyoTestNotificationMutation,
} = KlaviyoSmsPageAPI;
