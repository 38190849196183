import {
  Box,
  InlineStack,
  Text,
  Tooltip,
  BlockStack,
} from '@shopify/polaris';
import { groupBy, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils';
import RenderBadge from './RenderBadge';
import { LOCAL_STORAGE } from 'Constants';

function TrackedCheckpoints({
  shipments = [],
  statuses,
  hiddenCarrierDetails = [],
}) {
  const [t] = useTranslation();

  const checkpoints = shipments.reduce((prev, next, index) => {
    const storagekey = `${LOCAL_STORAGE.SHIPMENT_DETAILS_CARRIER_HIDDEN}_${index + 1
      }`;
    const isHidden = hiddenCarrierDetails.includes(storagekey);

    const shipmentCheckpoints =
      shipments?.length > 1 && isHidden
        ? []
        : next?.checkpoints?.map((point) => {
          return {
            ...point,
            carrierIcon: next?.carrier?.logo_svg,
          };
        });
    prev = [...prev, ...shipmentCheckpoints];
    return prev;
  }, []);
  const timeline = checkpoints?.map((t) => {
    return {
      ...t,
      day: moment.utc(t.timestamp).format('L'),
    };
  });
  const groups = groupBy(timeline, 'day');
  return isEmpty(checkpoints) ? (
    ''
  ) : (
    <Box padding="400" width="100%">
      <BlockStack gap={'500'}>
        <InlineStack align="space-between">
          <Text variant="headingMd">
            {t('shipment_details.tracked_checkpoints')}
          </Text>
          {/* <Button plain>{t('shipment_details.report_issue')}</Button> */}
        </InlineStack>
        <BlockStack gap={'400'}>
          {Object.keys(groups).map((g) => {
            const group = groups[g];

            return group.length > 0 ? (
              <BlockStack gap={'400'}>
                <Box paddingBlockStart={'100'}>
                  <Tooltip
                    content={moment.utc(group[0].timestamp).format('ll')}
                  >
                    <Text variant="headingSm" as="span">
                      <div className={'usage'}>
                        {formatDate(group[0].timestamp)}
                      </div>
                    </Text>
                  </Tooltip>
                </Box>

                <BlockStack gap="400" width="100%">
                  {orderBy(group, 'timestamp', 'desc')?.map(
                    (timeline = {}, index) => {
                      const {
                        location = {},
                        status_uuid,
                        carrierIcon,
                      } = timeline;
                      return (
                        <InlineStack
                          key={timeline.status_uuid + index}
                          wrap={false}
                          align={'space-between'}
                          blockAlign="start"
                        >
                          <Box maxWidth="calc(100% - 70px)">
                            <InlineStack wrap={false} gap="200" align="start">
                              {shipments?.length > 1 && (
                                <img src={carrierIcon} width={'25px'} />
                              )}
                              <BlockStack gap={'100'}>
                                <Text>{timeline.message}</Text>
                                {Object.keys(location).length ? (
                                  <Text color="subdued">
                                    {location.address_summary}
                                  </Text>
                                ) : (
                                  ''
                                )}
                                <Box>
                                  <InlineStack gap="200">
                                    <RenderBadge
                                      status_uuid={status_uuid}
                                      statuses={statuses}
                                    />
                                    {/* <RenderBadge
                                      status_uuid={status_uuid}
                                      sub_status_uuid={sub_status_uuid}
                                      statuses={statuses}
                                    /> */}
                                  </InlineStack>
                                </Box>
                              </BlockStack>
                            </InlineStack>
                          </Box>
                          <Text as="p" color="subdued">
                            {moment.utc(timeline.timestamp).format('LT')}
                          </Text>
                        </InlineStack>
                      );
                    }
                  )}
                </BlockStack>
              </BlockStack>
            ) : (
              ''
            );
          })}
        </BlockStack>
      </BlockStack>
    </Box>
  );
}

export default TrackedCheckpoints;
