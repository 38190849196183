import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@shopify/polaris';
import { UserPage } from '@rush-deliveries/storybooks-shopify';
import { isEmailValid } from 'Utils/utils';
import { useNavigate } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import VisitorHOC from '../VisitorHOC';

function Recover({
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  isLoading,
  forgotPassword,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  const handleEmailChange = useCallback((value) => {
    setShowEmailError(false);
    setEmail(value);
  }, []);

  const handleSubmit = () => {
    setShowEmailError(false);

    let hasErrors = false;

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    forgotPassword(email);
  };

  const handleBackToSignIn = () => {
    navigate(VISITOR_URLS.LOGIN);
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={isLoading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('password_recovery_screen.title')}
          subtitle={t('password_recovery_screen.sub_title')}
          inputFields={[
            {
              placeholder: t('signin_screen.email_placeholder'),
              error: showEmailError
                ? t('change_email_screen.modal.invalid_email')
                : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
          ]}
          button={t('password_recovery_screen.continue_btn')}
          footer1={
            <Text variant="bodyMd" as="span">
              <Link onClick={handleBackToSignIn}>
                {t('password_recovery_screen.sign_in_link')}
              </Link>
            </Text>
          }
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
export default VisitorHOC(Recover);
