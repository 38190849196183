import React, { useMemo, useState } from 'react';
import {
  Text,
  Link,
  Button,
  Popover,
  ActionList,
  ButtonGroup,
  Badge,
  Icon,
  Box,
  BlockStack,
  InlineStack,
  InlineGrid,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import {
  MenuHorizontalIcon,
  ExternalSmallIcon,
} from '@shopify/polaris-icons';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import withActionsHOC from './common/withActionsHOC';
import './styles.scss';

const PageItem = (props) => {
  const {
    title,
    created_at,
    link = '',
    type,
    actions,
    customizePage,
    publishPage,
    isPublishing,
    preview_link,
  } = props;

  const { t } = useTranslation();
  const [popoverActive, setPopoverActive] = useState(false);

  const pageUrl = type === 'os2' ? link : preview_link;

  const secondaryActions = [
    { content: t('tracking_page_new.customize'), onAction: customizePage },
    {
      content: t('tracking_page_new.publish'),
      onAction: publishPage,
      loading: isPublishing,
    },
  ];

  const shopifyOpenStoreLabel = t('tracking_page_new.shopify_open_store_label');
  const shopifyModernLabel = t('tracking_page_new.modern_type');
  const shopifySourceCodeLabel = t('tracking_page_new.source_code_type');
  const shopifCustomCodeLabel = t('tracking_page_new.custom_type');

  const morePopover = (
    <Popover
      active={popoverActive}
      onClose={() => setPopoverActive(false)}
      activator={
        <Button
          icon={MenuHorizontalIcon}
          onClick={() => setPopoverActive(!popoverActive)}
        />
      }
      preferredPosition={'below'}
      preferredAlignment={'center'}
    >
      <Popover.Pane>
        <ActionList actionRole="menuitem" items={actions} />
      </Popover.Pane>
    </Popover>
  );

  const getBadgeLabel = () => {
    switch (type) {
      case 'os2':
        return shopifyOpenStoreLabel;
      case 'modern':
        return shopifyModernLabel;
      case 'source':
      case 'traditional':
        return shopifySourceCodeLabel;
      case 'api':
        return shopifCustomCodeLabel;
    }
  };

  const badgetype = useMemo(() => {
    if (type === 'os2') {
      return 'success';
    } else if (type === 'modern') {
      return 'info';
    } else if (type === 'api') {
      return 'warning';
    } else {
      return undefined;
    }
  }, [type]);

  return (
    <Box
      padding={'500'}
      paddingBlockStart={'0'}
      borderBlockEndWidth="0165"
      borderColor="border-brand"
    >
      <div className="page_item">
        <InlineGrid
          columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
          gap={'300'}
        >
          <Box width="100%">
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Link url={pageUrl} monochrome external removeUnderline>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    {title}
                  </Text>
                </Link>
                <Badge tone={badgetype}>{getBadgeLabel()}</Badge>
              </InlineStack>

              <InlineStack gap="100">
                <Text color="subdued">
                  {t(
                    'shipment_detail.section.order_details.label.created_at_date'
                  )}
                  :
                </Text>
                <Text color="subdued">{moment(created_at).format('LLL')}</Text>
              </InlineStack>
            </BlockStack>
            {pageUrl ? (
              <div className="page-link-container">
                <Link target="_blank" onClick={() => window.open(pageUrl)}>
                  {pageUrl}
                </Link>
                <Icon source={ExternalSmallIcon} tone="interactive" />
              </div>
            ) : (
              ''
            )}
          </Box>
          <InlineStack align="end">
            <ButtonGroup noWrap>
              {morePopover}
              {secondaryActions.map(({ content, onAction, loading }) => (
                <Button key={uuid()} onClick={onAction} loading={loading}>
                  {content}
                </Button>
              ))}
            </ButtonGroup>
          </InlineStack>
        </InlineGrid>
      </div>
    </Box>
  );
};

export default withActionsHOC(PageItem);
