import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Filters,
  OptionList,
  Text,
  BlockStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useLazyGetProductsQuery } from 'redux/store/commonStoreApis';
import './styles.scss';
import RushModal from 'components/custom/RushModal';

function ProductsPicker({
  selectedProducts = [],
  onAdd,
  onCancel,
  queryString = '',
}) {
  const [t] = useTranslation();

  const [getOptions, optionsResponse] = useLazyGetProductsQuery();
  const { data: optionList = {}, isFetching, isSuccess } = optionsResponse;

  const [selected, setSelected] = useState([]); // will save array of objects
  const [list, setList] = useState([]);
  const [query, setQuery] = useState(queryString);

  useEffect(() => {
    if (isSuccess && optionList) {
      setList(optionList?.products || []);
    }
  }, [optionList, isSuccess]);

  useEffect(() => {
    getOptions('');
  }, []);

  const handleAdd = () => {
    onAdd([...selectedProducts, ...selected]);
  };

  const debouncesGetOptions = useCallback(
    debounce((searchVal) => {
      getOptions(searchVal);
    }, 500),
    []
  );

  useEffect(() => {
    try {
      debouncesGetOptions(query);
      // eslint-disable-next-line no-empty
    } catch { }
  }, [query]);

  const onSelectedChange = (newSelected) => {
    const prevSel = selected.map((p) => p.id);
    const currList = list.map((p) => p.id);
    const absentSelIds = prevSel.filter((id) => !currList.includes(id));
    const absentSel = selected.filter(({ id }) => absentSelIds.includes(id));
    const selectedProds = list.filter((p) => newSelected.includes(p.id));
    setSelected([...selectedProds, ...absentSel]);
  };

  const listOptions = useMemo(() => {
    const selectedIds = selectedProducts.map((p) => Number(p.id));
    return list.filter(({ id }) => !selectedIds.includes(Number(id)));
  }, [selectedProducts, list]);

  return (
    <RushModal
      title={t('common.pick-products')}
      open={true}
      onClose={onCancel}
      primaryAction={{
        content: t('common.add'),
        onAction: handleAdd,
        disabled: !selected.length,
      }}
      secondaryActions={[
        {
          content: t('common.cancel'),
          onAction: onCancel,
        },
      ]}
      footer={
        <Text
          variant="bodyMd"
          as="span"
          color="subdued"
        >{`${selected.length} selected`}</Text>
      }
    >
      <Filters
        focused={true}
        queryValue={query}
        filters={[]}
        onQueryChange={setQuery}
        onQueryClear={() => {
          setQuery('');
        }}
        onClearAll={() => {
          setQuery('');
        }}
        queryPlaceholder={t('common.filter-products-by-name')}
        loading={isFetching}
      />

      <div className="products-picker">
        <OptionList
          onChange={onSelectedChange}
          options={listOptions.map((p) => {
            return {
              value: p.id,
              label: (
                <BlockStack gap={'100'}>
                  <Text variant="bodyMd" as="span">
                    {p.title}
                  </Text>
                  <Text variant="bodyMd" as="span" color="subdued">
                    {p.id}
                  </Text>
                </BlockStack>
              ),
              media: <Avatar shape="square" source={p.image} />,
            };
          })}
          selected={selected.map((s) => s.id)}
          allowMultiple
        />
      </div>
    </RushModal>
  );
}

export default ProductsPicker;
