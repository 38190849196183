import React, { useEffect } from 'react';
import { Spinner,Modal } from '@shopify/polaris';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RushModal from 'components/custom/RushModal';
export default function EmailPreviewModal({
  emailTemplatePreview,
  emailPreviewObject,
  setEmailPreviewObject,
  getEmailTemplatePreview,
  resetEmailTemplatePreview,
}) {
  const [t] = useTranslation();
  useEffect(() => {
    if (_.isNull(emailTemplatePreview) && !_.isNull(emailPreviewObject)) {
      getEmailTemplatePreview(emailPreviewObject.slug);
    }
  }, [emailTemplatePreview, getEmailTemplatePreview, emailPreviewObject]);

  return (
    <RushModal
      open={!_.isNull(emailPreviewObject)}
      onClose={() => {
        setEmailPreviewObject(null);
        resetEmailTemplatePreview();
      }}
      title={t('emails.preview_modal.title')}
      large
    >
      <Modal.Section>
        {!_.isNull(emailTemplatePreview) && (
          <div dangerouslySetInnerHTML={{ __html: emailTemplatePreview }} />
        )}
        {_.isNull(emailTemplatePreview) && (
          <div className="LoadingPage-Wrapper">
            <div className="LoadingPage-Spinner">
              <Spinner accessibilityLabel="Loading" size="large" color="teal" />
            </div>
          </div>
        )}
      </Modal.Section>
    </RushModal>
  );
}
