import { Box, InlineStack, Text, BlockStack } from '@shopify/polaris';
import { getHumanDate } from 'pages/Shipments3/components/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ShipmentInformation({ statistics = {} }) {
  const [t] = useTranslation();

  const { last_synced_at, client_waiting_time } = statistics;
  const lastCheck = getHumanDate(last_synced_at, false);
  return !(last_synced_at && client_waiting_time) ? (
    ''
  ) : (
    <Box padding="400">
      <BlockStack gap="400">
        <Text variant="headingMd">
          {t('shipment_details.shipment_information')}
        </Text>
        <BlockStack gap="100">
          {/* <InlineStack align="space-between">
        <Text> {t('shipment_details.eta')}</Text>
        <Text>Mar 1,2022</Text>
      </InlineStack>
      <InlineStack align="space-between">
        <Text> {t('shipment_details.status')}</Text>
        <Text>Delivered</Text>
      </InlineStack>
      <InlineStack align="space-between">
        <Text> {t('shipment_details.quota_mark')}</Text>
        <Text>free</Text>
      </InlineStack> */}
          {client_waiting_time && (
            <InlineStack align="space-between">
              <Text> {t('shipment_details.client_waiting')}</Text>
              <Text>{client_waiting_time}</Text>
            </InlineStack>
          )}
          {!!last_synced_at && (
            <InlineStack align="space-between">
              <Text> {t('shipment_details.last_carrier_check')}</Text>
              <Text variation={lastCheck.variation}>{lastCheck?.text}</Text>
            </InlineStack>
          )}
        </BlockStack>
      </BlockStack >
    </Box >
  );
}

export default ShipmentInformation;
