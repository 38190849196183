import React from 'react';
import {
  Card,
  Checkbox,
  TextField,
  Text,
  SkeletonBodyText,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink/TranslateLink';

const AnalyticsCard = ({ proxyConfig, onProxyUpdate, isLoading }) => {
  const { t } = useTranslation();
  const {
    facebook_pixel,
    google_analytics,
    google_analytics4,
    google_conversion_id,
    google_tag_manager,
    is_tracking_analytics = false,
  } = proxyConfig;
  const toggleEnableTracking = () => {
    onProxyUpdate('is_tracking_analytics', !is_tracking_analytics);
  };

  const trackingFields = [
    {
      label: t('tracking_page_new.analytics.google_tag_manager'),
      value: google_tag_manager,
      footer: t('tracking_page_new.analytics.google_tag_manager_footer'),
      onChange: (value) => onProxyUpdate('google_tag_manager', value),
    },
    {
      label: t('tracking_page_new.analytics.google_analytics'),
      footer: t('tracking_page_new.analytics.google_analytics_footer'),
      value: google_analytics,
      onChange: (value) => onProxyUpdate('google_analytics', value),
    },
    {
      label: t('tracking_page_new.analytics.google_universal_analytics'),
      footer: t('settings.tracking_page.tracking.card.ga.input.helper_text'),
      value: google_analytics4,
      onChange: (value) => onProxyUpdate('google_analytics4', value),
    },
    {
      label: t('tracking_page_new.analytics.google_remarketing_code'),
      footer: t(
        'settings.tracking_page.tracking.card.ga_remarketing_code.input.helper_text'
      ),
      value: google_conversion_id,
      onChange: (value) => onProxyUpdate('google_conversion_id', value),
    },
    {
      label: t('settings.tracking_page.tracking.card.fb.title'),
      footer: t('settings.tracking_page.tracking.card.fb.input.helper_text'),
      value: facebook_pixel,
      onChange: (value) => onProxyUpdate('facebook_pixel', value),
    },
  ];

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="500">
        <BlockStack gap="200">
          <Text as="h4" variant="headingMd">
            {t('tracking_page_new.analytics.title')}
          </Text>
          <Text as="span" color="subdued">
            {t('tracking_page_new.analytics.description')}
          </Text>
        </BlockStack>
        {isLoading ? (
          <Box>
            <SkeletonBodyText />
          </Box>
        ) : (
          <>
            <Checkbox
              label={t('tracking_page_new.analytics.enable_tracking')}
              checked={is_tracking_analytics}
              onChange={toggleEnableTracking}
            />

            {is_tracking_analytics && (
              <BlockStack gap={'500'}>
                {trackingFields.map((field) => (
                  <BlockStack key={field.label} gap="100">
                    <TextField
                      autoComplete={'off'}
                      label={field.label}
                      value={field.value?.toString()}
                      onChange={field.onChange}
                      multiline
                    />
                    <Text color="subdued">
                      <TranslateLink text={field.footer}></TranslateLink>
                    </Text>
                  </BlockStack>
                ))}
              </BlockStack>
            )}
          </>
        )}
      </BlockStack>
    </Card>
  );
};

export default AnalyticsCard;
