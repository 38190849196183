import React from 'react';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  Box,
  BlockStack,
  InlineStack,
  Divider,
  SkeletonThumbnail,
} from '@shopify/polaris';
function IntegrationSkeleton({ title }) {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < 5; i++) {
      skeletons.push(
        <React.Fragment key={i}>
          <Box padding="500">
            <div className="skeleton-tbl">
              <InlineStack align="space-between" blockAlign="center">
                <Box width="20%">
                  <SkeletonBodyText lines={1} />
                </Box>

                <Box width="30%">
                  <InlineStack align="space-between" blockAlign="center">
                    <Box width="20%">
                      <SkeletonDisplayText size="small" />
                    </Box>
                    <Box width="40%">
                      <SkeletonDisplayText size="small" />
                    </Box>
                    <Box width="20%">
                      <SkeletonDisplayText size="small" />
                    </Box>
                  </InlineStack>
                </Box>
              </InlineStack>
            </div>
          </Box>
        </React.Fragment>
      );
    }
    return skeletons;
  };

  return (
    <SkeletonPage title={title}>
      <BlockStack gap="500">
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <InlineStack align="space-between" blockAlign="center">
              <Box width="30%">
                <SkeletonBodyText lines={1} />
              </Box>
              <Box width="10%">
                <SkeletonDisplayText />
              </Box>
            </InlineStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <InlineStack align="space-between" blockAlign="center">
              <Box width="20%">
                <SkeletonBodyText lines={1} />
              </Box>
              <Box width="10%">
                <SkeletonBodyText lines={1} />
              </Box>
            </InlineStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding={'500'}>
            <InlineStack align="space-between" blockAlign="center">
              <Box width="50%">
                <SkeletonBodyText lines={2} />
              </Box>
              <Box width="10%">
                <SkeletonDisplayText />
              </Box>
            </InlineStack>
          </Box>
          <Divider />
          {renderSkeletons()}
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <SkeletonBodyText lines={2} />
          </Box>

          <Divider />

          <Box padding="500">
            <BlockStack gap={'200'}>
              <InlineStack gap={'300'} blockAlign="center">
                <SkeletonThumbnail size="extraSmall" />
                <SkeletonBodyText lines={1} />
              </InlineStack>

              <SkeletonBodyText lines={1} />
            </BlockStack>
          </Box>
        </Card>
        <br />
      </BlockStack>
    </SkeletonPage>
  );
}
export default IntegrationSkeleton;
