import { ContextualSaveBar } from '@shopify/polaris';
import { ContextualSaveBar as BridgeContextualSaveBar } from '@shopify/app-bridge-react';

import React from 'react';
import useIsEmbededApp from 'hooks/useIsEmbededApp';

function RushSaveBar(props) {
  const isEmbededApp = useIsEmbededApp();

  const { children, ..._pageProps } = props;
  return isEmbededApp ? (
    <BridgeContextualSaveBar
      {..._pageProps}
      fullWidth
      leaveConfirmationDisable
      visible
    >
      {children}
    </BridgeContextualSaveBar>
  ) : (
    <ContextualSaveBar {..._pageProps}>{children}</ContextualSaveBar>
  );
}

export default RushSaveBar;
