import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockStack, InlineStack, Text } from '@shopify/polaris';
import { UserPage } from '@rush-deliveries/storybooks-shopify';
import { isEmailValid } from 'Utils/utils';
import TranslateLink from 'components/TranslateLink';
import VisitorHOC from '../VisitorHOC';

function RegisterByInvite({
  visitorLanguages,
  changeVisitorLanguage,
  selectedVisitorLanguage,
}) {
  const [t] = useTranslation();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [firstnameError, setFirstnameError] = useState(null);
  const [lastnameError, setLastnameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const handleFirstnameChange = useCallback((value) => {
    setFirstnameError(false);
    setFirstname(value);
  }, []);

  const handleLastnameChange = useCallback((value) => {
    setLastnameError(false);
    setLastname(value);
  }, []);

  const handleEmailChange = useCallback((value) => {
    setEmailError(false);
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPasswordError(false);
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setFirstnameError(null);
    setLastnameError(null);
    setEmailError(null);
    setPasswordError(null);

    let hasErrors = false;

    if (firstname.length === 0) {
      setFirstnameError('First name is required');
      hasErrors = true;
    }

    if (lastname.length === 0) {
      setLastnameError('Last name is required');
      hasErrors = true;
    }

    if (!isEmailValid(email)) {
      setEmailError(t('change_email_screen.modal.invalid_email'));
      hasErrors = true;
    }

    if (password.length < 6) {
      setPasswordError('Password cannot be less than 6 characters');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={false}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={"You've been invited to join"}
          message={
            <InlineStack align={'center'} blockAlign={'center'}>
              <img
                src={'https://assets.rush.app/languages/svg/034-china.svg'}
                alt={'logo'}
                width={50}
              />
              <BlockStack gap={'100'} blockAlign={'leading'}>
                <Text as="h1" variant="headingMd">
                  {'Primal Harvest'}
                </Text>
                <Text variant="bodyMd" as="span" color={'subdued'}>
                  {'primalharvest.com'}
                </Text>
              </BlockStack>
            </InlineStack>
          }
          inputFields={[
            {
              placeholder: '* Your work email@gmail.com',
              error: emailError,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
            {
              placeholder: t('registration_screen.firstname_placeholder'),
              error: firstnameError,
              value: firstname,
              onChange: handleFirstnameChange,
              type: 'text',
            },
            {
              placeholder: t('registration_screen.lastname_placeholder'),
              error: lastnameError,
              value: lastname,
              onChange: handleLastnameChange,
              type: 'text',
            },
            {
              placeholder: t('registration_screen.password_placeholder'),
              error: passwordError,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          button={'Join Primal Harvest'}
          footer1={
            <TranslateLink text={t('registration_screen.footer_message1')} />
          }
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
export default VisitorHOC(RegisterByInvite);
