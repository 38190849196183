import React from 'react';
import {
  Card,
  Checkbox,
  Text,
  Button,
  BlockStack,
  Box,
  InlineStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink/TranslateLink';

function OSSettingsCard({ onStatusClick, isSelected, onChange }) {
  const [t] = useTranslation();

  const ossTitle = t('tracking_page_new.oss_title');
  const ossDescription = t('tracking_page_new.oss_description');
  const enableOssLabel = t('tracking_page_new.enable_oss_label');
  const ossHelpText = t('tracking_page_new.oss_help_text');
  const themeStatusLabel = t('tracking_page_new.themes_status');

  return (
    <Card roundedAbove="sm">
      <BlockStack gap={'500'}>
        <BlockStack gap={'200'}>
          <InlineStack align="space-between" blockAlign="center">
            <Text variant="headingMd" as="span">
              {ossTitle}
            </Text>
            <Button plain onClick={onStatusClick}>
              {themeStatusLabel}
            </Button>
          </InlineStack>
          <Text color="subdued">
            <TranslateLink text={ossDescription}></TranslateLink>
          </Text>
        </BlockStack>
        <Box>
          <Checkbox
            label={enableOssLabel}
            checked={isSelected}
            onChange={() => onChange(!isSelected)}
          />
        </Box>
        <Box>
          <Text color="subdued">{ossHelpText}</Text>
        </Box>
      </BlockStack>
    </Card>
  );
}

export default OSSettingsCard;
