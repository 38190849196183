import React, { useCallback, useMemo, useState, Suspense } from 'react';
import { Frame } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useI18n } from './i18n';
import {
  baseActions,
  IsErrorToast,
  ShowToast,
  ToastMessage,
  ToastDismissCallBack,
} from 'redux/store/baseSlice';
import RushToast from 'components/custom/RushToast';
import { PolarisProvider } from 'components/Providers/PolarisProvider';
import { AppBridgeProvider } from 'components/Providers/AppBridgeProvider';
import { QueryProvider } from 'components/Providers/QueryProvider';
import RushLoading from 'components/custom/RushLoader';
import LoadingPage from 'components/LoadingPage';
import { getEmbeddedNavMenu } from 'components/LeftNavigation/navUtils';
import useCheckPermission from 'hooks/useCheckPermission';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import { StoreUUID } from 'redux/store/shopSlice';
import AppRoutes from 'routes';
import useGetAndSetUser from 'hooks/useGetAndSetUser';
import useCheckQuestionaire from 'hooks/useCheckQuestionaire';
import useStorageStoreId from 'hooks/useStorageStoreId';
import { useGetFeaturesQuery } from 'redux/store/commonStoreApis';
import {
  TOP_BAR_EXCLUDED_URLS,
  LEFT_NAVIGATION_EXCLUDED_URLS,
} from 'Constants';
import SudoBanner from 'components/SudoBanner';
import { NavigationMenu } from '@shopify/app-bridge-react';
import useEmbededAppRedirect from 'hooks/useEmbededAppRedirect';
import { AUTH_URLS, USER_URLS } from 'config/urls';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import { isEmbededAppExperience } from 'Utils/utils';
import useGetAndSetToken from 'hooks/useGetAndSetToken';
import ScrollToTop from 'components/ScrollToTop';
import FreshChat from 'components/FreshChat/FreshChat';

// Lazy load components
const TopBarMarkup = React.lazy(() => import('./components/TopBarMarkup'));
const LeftNavigation = React.lazy(() => import('./components/LeftNavigation'));


const authPaths = [AUTH_URLS.SHOPIFY_AUTH, AUTH_URLS.RUSH_AUTH];
const approvalRequiredPaths = [
  AUTH_URLS.RUSH_APPROVAL,
  AUTH_URLS.RUSH_EXIT_IFRAME,
];
const splashPaths = [AUTH_URLS.RUSH_SPLASH];

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const accessToken = useSelector(AccessToken);
  const storeUUID = useSelector(StoreUUID);
  const showToast = useSelector(ShowToast);
  const toastMessage = useSelector(ToastMessage);
  const toastDismissCallBack = useSelector(ToastDismissCallBack);
  const isErrorToast = useSelector(IsErrorToast);

  const [showMobileNav, setShowMobileNav] = useState(false);

  const toggleMobileNav = useCallback(
    () => setShowMobileNav((active) => !active),
    []
  );

  const isVerifiedApp = useMemo(
    () => window.__SHOPIFY_DEV_IS_VERIFIED_APP || accessToken,
    [accessToken]
  );

  const isTokenLoading = useGetAndSetToken();
  const isEmbededApp = useIsEmbededApp();
  const tempEmbeddedExperience = isEmbededAppExperience(storeUUID);
  const isVerifyingEmbededApp = isEmbededApp && !isVerifiedApp;

  useI18n({
    skip:
      (isVerifyingEmbededApp &&
        !approvalRequiredPaths.includes(location.pathname)) ||
      authPaths.includes(location.pathname),
  });
  useStorageStoreId({ skip: isVerifyingEmbededApp || isEmbededApp });
  useEmbededAppRedirect();
  const { isLoading: isUserLoading, user = {} } = useGetAndSetUser({
    skip:
      !accessToken ||
      authPaths.includes(location.pathname) ||
      isVerifyingEmbededApp,
  });

  const { isLoading: isCheckingPermissions } = useCheckPermission({
    skip:
      isEmbededApp ||
      (isEmbededApp && !tempEmbeddedExperience) ||
      isUserLoading ||
      authPaths.includes(location.pathname) ||
      !storeUUID ||
      !accessToken,
  });
  const { isLoading: isCheckingQuestionnaire } = useCheckQuestionaire({
    skip:
      (isEmbededApp && !tempEmbeddedExperience) ||
      isUserLoading ||
      authPaths.includes(location.pathname) ||
      isVerifyingEmbededApp ||
      !accessToken,
  });
  useGetFeaturesQuery(undefined, {
    skip:
      (isEmbededApp && !tempEmbeddedExperience) ||
      !storeUUID ||
      !accessToken ||
      authPaths.includes(location.pathname) ||
      isVerifyingEmbededApp,
  });

  const showLoadingPage = useMemo(() => {
    return (
      (isTokenLoading ||
        isCheckingPermissions ||
        isCheckingQuestionnaire ||
        isVerifyingEmbededApp ||
        isUserLoading) &&
      !approvalRequiredPaths.includes(location.pathname)
    );
  }, [
    isCheckingPermissions,
    isCheckingQuestionnaire,
    isVerifyingEmbededApp,
    isUserLoading,
    location.pathname,
    isTokenLoading,
  ]);

  const excludeLeftBar =
    isEmbededApp ||
    (isEmbededApp && tempEmbeddedExperience) ||
    !accessToken ||
    isCheckingQuestionnaire ||
    LEFT_NAVIGATION_EXCLUDED_URLS.some((url) =>
      location.pathname.endsWith(url)
    );

  const excludeTopBar =
    isEmbededApp ||
    (isEmbededApp && tempEmbeddedExperience) ||
    !accessToken ||
    isCheckingQuestionnaire ||
    TOP_BAR_EXCLUDED_URLS.some((url) => location.pathname.endsWith(url));

  const toastMarkup = useMemo(() => {
    if (showToast && toastMessage) {
      return (
        <RushToast
          content={toastMessage}
          duration={4000}
          onDismiss={() => {
            if (toastDismissCallBack) {
              toastDismissCallBack();
            }
            dispatch(baseActions.dismissToast());
          }}
          error={isErrorToast}
        />
      );
    }
  }, [showToast, toastMessage, dispatch, isErrorToast]);

  const renderEmpededNavBar = () => {
    const nonQPaths = [
      ...authPaths,
      ...approvalRequiredPaths,
      ...splashPaths,
      USER_URLS.QUESTIONNAIRE,
    ];
    return !isVerifyingEmbededApp &&
      tempEmbeddedExperience &&
      !showLoadingPage &&
      !nonQPaths.includes(location.pathname) ? (
      <NavigationMenu
        navigationLinks={getEmbeddedNavMenu({
          storeUUID,
        })}
        matcher={(link, location) => link.destination === location.pathname}
      ></NavigationMenu>
    ) : (
      ''
    );
  };

  // Redirect logic
  // useEffect(() => {
  //   if (location.pathname === AUTH_URLS.SHOPIFY_AUTH) {
  //     window.location.href = 'https://api.rush.app/auth/shopify/connect';
  //   }
  // }, [location.pathname]);

  return isEmbededApp ? (
    <AppBridgeProvider>
      <PolarisProvider>
        <QueryProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Frame
              topBar={
                !excludeTopBar &&
                !showLoadingPage && (
                  <TopBarMarkup toggleMobileNav={toggleMobileNav} />
                )
              }
              navigation={
                !excludeLeftBar && !showLoadingPage && <LeftNavigation />
              }
              showMobileNavigation={showMobileNav}
              onNavigationDismiss={toggleMobileNav}
            >
              {!showLoadingPage ? <></> : null}

              <div className="main">
                <div
                  className="speed"
                  style={{
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                    width: `calc(100vw - 2px)`,
                    height: `calc(100vh - 2px)`,
                    pointerEvents: 'none',
                    zIndex: 1,
                    background: 'transparent',
                    color: 'transparent',
                    fontSize: '50px',
                  }}
                >
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Dolorum sit fugiat alias qui odit natus repudiandae excepturi
                  dignissimos ut voluptatem. Repellat optio esse commodi dolor
                  et? Vero esse iste ex. Itaque error atque fuga nihil soluta
                  suscipit, temporibus iste pariatur! Earum non voluptatibus ut
                  iste rerum modi corporis quae voluptas veritatis amet officiis
                  iure repudiandae dolorum a, ipsum nam omnis error quaerat
                  impedit hic unde dolor corrupti! Aliquam odio quidem ut
                  dolorum consequuntur, ducimus enim facere? Deserunt
                  accusantium dolorum culpa in suscipit. Sunt doloribus impedit
                  vero explicabo deserunt atque eos totam numquam distinctio,
                  necessitatibus vel, ducimus molestiae. Quo ea nostrum,
                  pariatur tenetur autem maxime voluptatibus, distinctio quis
                  dolorum suscipit magni repellendus impedit atque corporis
                  voluptas, odio deleniti tempora dolor assumenda excepturi
                  fugit! Modi, voluptatibus cumque? Accusantium, labore? Vitae
                  voluptatibus numquam ducimus unde expedita. At amet ex ipsam a
                  culpa delectus, in quis voluptas, adipisci temporibus dicta
                  fugit incidunt similique maiores. Architecto aut eos, facere
                  officia fugiat repudiandae reprehenderit numquam eaque optio,
                  tenetur quaerat voluptate eum, iusto libero perspiciatis illo
                  culpa alias nihil! Eius unde molestiae rem nostrum veritatis
                  laborum voluptatem quisquam deserunt tempora neque doloremque
                  sint, in incidunt vel fugit ipsum eveniet. Quos tempora
                  architecto dolore asperiores dolores quae commodi?
                </div>

                {showLoadingPage && !authPaths.includes(location.pathname) && (
                  <>
                    <LoadingPage />
                    <RushLoading />
                  </>
                )}
                {!showLoadingPage &&
                  !approvalRequiredPaths.includes(location.pathname) &&
                  !splashPaths.includes(location.pathname) &&
                  !authPaths.includes(location.pathname) && (
                    <SudoBanner user={user} />
                  )}
                {!isTokenLoading && !isCheckingQuestionnaire && (
                  <>
                    <ScrollToTop />
                    <AppRoutes isLoading={showLoadingPage} />
                  </>
                )}
              </div>
              {toastMarkup}
            </Frame>
          </Suspense>
          {renderEmpededNavBar()}
          <Suspense fallback={<div>Loading...</div>}>
            <FreshChat
              shop={user}
              host={'https://wchat.freshchat.com'}
              token={'da9f8811-8413-4fee-b039-f45eaad735f1'}
              externalId={'rush_' + user?.storeUUID}
              restoreId={user?.freshchat_id}
              widgetUuid={'b8ffa543-0536-4979-9514-c4df4dc02d64'}
            />
          </Suspense>
        </QueryProvider>
      </PolarisProvider>
    </AppBridgeProvider>
  ) : (
    <PolarisProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Frame
          topBar={
            !excludeTopBar &&
            !showLoadingPage && (
              <TopBarMarkup toggleMobileNav={toggleMobileNav} />
            )
          }
          navigation={!excludeLeftBar && !showLoadingPage && <LeftNavigation />}
          showMobileNavigation={showMobileNav}
          onNavigationDismiss={toggleMobileNav}
        >
          {!showLoadingPage ? <></> : null}

          <div className="main">
            <div
              className="speed"
              style={{
                position: 'fixed',
                top: '1px',
                left: '1px',
                width: `calc(100vw - 2px)`,
                height: `calc(100vh - 2px)`,
                pointerEvents: 'none',
                zIndex: 1,
                background: 'transparent',
                color: 'transparent',
                fontSize: '50px',
              }}
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam
              dolores labore sit cumque dolorem ea voluptates perferendis
              aliquid tenetur repellendus, odio ducimus excepturi est quo
              explicabo modi consectetur reprehenderit magni beatae asperiores
              nobis? Dolores esse nostrum commodi reiciendis eos et nobis vitae,
              eius vel exercitationem illo! In aperiam quis quod odio vitae!
              Aspernatur, asperiores obcaecati dicta dolor itaque, odio
              molestiae impedit officiis eaque officia optio voluptas at omnis,
              laudantium nisi voluptatibus velit explicabo atque rem saepe?
              Ratione veniam vero placeat non sapiente, iste ipsam libero nam
              optio blanditiis, facilis dolore, ullam autem! Nisi unde maiores
              provident aliquid distinctio ducimus pariatur? At perferendis quia
              consequatur provident odit labore dolore ipsam ratione sunt
              sapiente quam consequuntur aut, aliquid fugit ducimus, vel
              reprehenderit corporis quidem soluta voluptatum ab atque nemo iure
              dolorum? Possimus dignissimos minima neque, ea labore assumenda
              eveniet sint, vitae, aliquid autem esse numquam tempora? Facilis,
              esse, delectus iusto tempore dolorum illum laboriosam voluptates
              veritatis vel dolor et harum adipisci sequi incidunt ducimus
              maxime omnis reiciendis. Molestias delectus distinctio aliquid
              maiores explicabo animi aspernatur facilis, fugit nobis amet
              aperiam totam illum vel repudiandae! Molestias veniam hic porro!
              Enim omnis aliquid porro expedita tempora totam dolores magnam
              obcaecati! At earum veritatis porro.
            </div>

            {showLoadingPage && !authPaths.includes(location.pathname) && (
              <>
                <LoadingPage />
                <RushLoading />
              </>
            )}
            {!showLoadingPage &&
              !approvalRequiredPaths.includes(location.pathname) &&
              !splashPaths.includes(location.pathname) &&
              !authPaths.includes(location.pathname) && (
                <SudoBanner user={user} />
              )}
            {!isTokenLoading && !isCheckingQuestionnaire && (
              <>
                <ScrollToTop />
                <AppRoutes isLoading={showLoadingPage} />
              </>
            )}
          </div>
          {toastMarkup}
        </Frame>
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <FreshChat
          shop={user}
          host={'https://wchat.freshchat.com'}
          token={'da9f8811-8413-4fee-b039-f45eaad735f1'}
          externalId={`rush_${user?.storeUUID}`}
          restoreId={user?.freshchat_id}
          widgetUuid={'b8ffa543-0536-4979-9514-c4df4dc02d64'}
        />
      </Suspense>
    </PolarisProvider>
  );
}

export default App;
