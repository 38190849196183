import React from 'react';
import { Card, EmptyState } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function MaksingsEmptyState({ onAdd }) {
  const [t] = useTranslation();

  return (
    <Card roundedAbove="sm">
      <EmptyState
        heading={t('carrier-maskings.no-carriers-found')}
        action={{ content: t('carrier-maskings.add'), onAction: onAdd }}
        image="https://assets.rush.app/app/carrier-masking/carrier-masking-empty-state.png?s"
      ></EmptyState>
    </Card>
  );
}

export default MaksingsEmptyState;
