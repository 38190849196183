import { Modal, Text } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function DeleteYotpoConfigModal(props) {
  const { isOpen, onClose, onDelete } = props;

  const handleRemove = () => {
    onDelete();
  };
  const [t] = useTranslation();
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={t('notifications.yotpo_sms.remove_config.modal.title')}
        primaryAction={{
          content: t('common.remove'),
          onAction: handleRemove,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: t('common.cancel'),
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <Text>
            {t('notifications.yotpo_sms.remove_event.config.description')}
          </Text>
        </Modal.Section>
      </Modal>
    </>
  );
}

export default DeleteYotpoConfigModal;
