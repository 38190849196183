import React, { useState, useCallback, useEffect } from 'react';
import { Autocomplete, Icon } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

function CarriersAutoComplete({ listOptions, selected = [], onSelect, error }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(listOptions);

  useEffect(() => {
    if (!isEmpty(selected)) {
      const matchedOption = listOptions.find((option) => {
        return option.value.match(selected);
      });
      setInputValue(matchedOption?.label);
    }
  }, [selected, listOptions]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === '') {
        setOptions(listOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = listOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [listOptions]
  );

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      onSelect(selected);
      setInputValue(selectedValue[0]);
    },
    [options]
  );

  const handleClearButtonClick = () => {
    setInputValue('');
    onSelect([]);
  };

  const textField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      clearButton
      onClearButtonClick={handleClearButtonClick}
      value={inputValue}
      prefix={<Icon source={SearchIcon} color="inkLighter" />}
      type="search"
      label={t(`shipments.settings_modal.carrier_search_input_label`)}
      placeholder={t(`shipments.settings_modal.carrier_search_default_text`)}
      error={error ? t(`shipments.settings_modal.carrier_invalid_value`) : ''}
    />
  );

  return (
    <Autocomplete
      options={options}
      selected={selected}
      onSelect={updateSelection}
      textField={textField}
      listTitle={t(`shipments.settings_modal.carrier_search_input_label`)}
      emptyState={t(`shipments.settings_modal.carrier_search_default_text`)}
    />
  );
}

export default CarriersAutoComplete;
