import React, { useEffect, useMemo, useState } from 'react';
import {
  usePublishPageMutation,
  useRemoveOS2PageMutation,
  useRemoveProxyPageMutation,
  useDuplicatePageMutation,
} from 'pages/TrackingPageNew/newTrackingPageApis';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { STORE_URLS } from 'config/urls';
import { Modal, Text } from '@shopify/polaris';
import { baseActions } from 'redux/store/baseSlice';
import PageTitleModal from './PageTitleModal';
import RushLoading from 'components/custom/RushLoader';
import RushModal from 'components/custom/RushModal';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';
import { useRemoveCustomPageMutation } from 'pages/TrackingPageNew/newTrackingPageApis';
const withActionsHOC = (Component) => (props) => {
  const { t } = useTranslation();

  const {
    title,
    type,
    link,
    uuid,
    edit_page_url,
    editor_url,
    isDefaultPageCard,
    preview_link,
  } = props;
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const { data: user } = useGetUserDetailsQuery();

  const pageUrl = link;

  const navigate = useNavigate();
  const [publishPage, { isLoading: isPublishing, isSuccess: isPublished }] =
    usePublishPageMutation();
  const [removeOS2Page, { isLoading: isRemovingOS2, isSuccess: isRemovedOS2 }] =
    useRemoveOS2PageMutation();

  const [
    removeCustomPage,
    { isLoading: isRemovingCustom, isSuccess: isRemovedCustom },
  ] = useRemoveCustomPageMutation();

  const [
    duplicatePage,
    { isLoading: isDuplicatingPage, isSuccess: isPageDuplicated },
  ] = useDuplicatePageMutation();
  const [
    removeproxyPage,
    { isLoading: isRemovingProxy, isSuccess: isRemovedProxy },
  ] = useRemoveProxyPageMutation();

  const isRemovingPage = useMemo(() => {
    return isRemovingOS2 || isRemovingProxy || isRemovingCustom;
  }, [isRemovingOS2, isRemovingProxy, isRemovingCustom]);

  const isPageRemoved = useMemo(() => {
    return isRemovedOS2 || isRemovedProxy || isRemovedCustom;
  }, [isRemovedOS2, isRemovedProxy, isRemovedCustom]);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  useEffect(() => {
    if (!isRemovingPage && isPageRemoved) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_deleted_toast'),
          type: 'success',
        })
      );
      setIsConfirmationOpen(false);
    }
  }, [isRemovingPage, isPageRemoved]);

  useEffect(() => {
    if (!isDuplicatingPage && isPageDuplicated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.duplicate_toast'),
          type: 'success',
        })
      );
      setShowDuplicateModal(false);
    }
  }, [isDuplicatingPage, isPageDuplicated]);

  useEffect(() => {
    if (!isPublishing && isPublished) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.publish_message', {
            page_title: title,
          }),
          type: 'success',
        })
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isPublishing, isPublished]);

  const handleRemoveClick = () => {
    setIsConfirmationOpen(true);
  };

  const handleCustomize = () => {
    switch (type) {
      case 'os2':
        window.open(editor_url);
        break;
      case 'modern':
        navigate(
          `/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGE_MODERN}/${uuid}`
        );
        break;
      case 'source':
      case 'traditional':
        navigate(
          `/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGE_SOURCE_CODE}/${uuid}`
        );
        break;
      case 'api':
        navigate(
          `/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGES}?custom_tp_uuid=${uuid}`
        );
        break;
    }
  };

  const handleEdit = () => {
    if (type == 'os2') {
      window.open(edit_page_url);
    }
  };

  const handleEditOS2Code = () => {
    navigate(`/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGES}/code/${uuid}`);
  };

  const handlePreview = () => {
    if (type == 'os2') {
      window.open(pageUrl);
    } else if (type === 'api') {
      window.open(pageUrl);
    } else {
      window.open(preview_link);
    }
  };

  const handleRemove = () => {
    if (type == 'os2') {
      removeOS2Page({ id: uuid });
    } else if (type === 'api') {
      removeCustomPage(uuid);
    } else {
      removeproxyPage({ id: uuid });
    }
  };

  const handlePublish = () => {
    publishPage({ id: uuid, type });
  };

  const onDuplicate = (title) => {
    duplicatePage({ id: uuid, title });
  };

  const handleDuplicate = () => {
    setShowDuplicateModal(true);
  };

  let popoverActions = [
    { content: t('tracking_page_new.preview'), onAction: handlePreview },
    { content: t('tracking_page_new.duplicate'), onAction: handleDuplicate },
  ];

  if (!isDefaultPageCard) {
    popoverActions.push({
      content: t('tracking_page_new.remove'),
      onAction: handleRemoveClick,
    });
  }

  if (type === 'os2') {
    popoverActions.splice(1, 0, {
      content: t('tracking_page_new.pageEdit'),
      onAction: handleEdit,
    });
    if (user.sudo) {
      popoverActions.splice(2, 0, {
        content: t('Edit code'),
        onAction: handleEditOS2Code,
      });
    }
  }

  return (
    <>
      {isPublishing || isRemovingOS2 || isRemovingCustom || isRemovingProxy ? (
        <RushLoading />
      ) : (
        ''
      )}
      <Component
        {...props}
        publishPage={handlePublish}
        customizePage={handleCustomize}
        isPublishing={isPublishing}
        isPublished={isPublished}
        actions={popoverActions}
      />
      <RushModal
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title={t('tracking_page_new.confirmation.remove_title')}
        primaryAction={{
          content: t('common.remove'),
          destructive: true,
          loading: isRemovingPage,
          onAction: () => {
            handleRemove();
          },
        }}
        secondaryActions={[
          {
            content: t('common.cancel'),
            onAction: () => setIsConfirmationOpen(false),
          },
        ]}
      >
        <Modal.Section>
          <Text as="span">
            {t('tracking_page_new.confirmation.remove_description')}
          </Text>
        </Modal.Section>
      </RushModal>
      {showDuplicateModal && (
        <PageTitleModal
          onClose={() => setShowDuplicateModal(false)}
          onOk={onDuplicate}
          isLoading={isDuplicatingPage}
        />
      )}
    </>
  );
};

export default withActionsHOC;
