import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@shopify/polaris';
import { UserPage } from '@rush-deliveries/storybooks-shopify';
import { isEmailValid } from 'Utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import { useUpdateEmailMutation } from 'pages/Visitor/visitorAPIs';
import VisitorHOC from '../VisitorHOC';

function ChangeEmail({
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  isLoading,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [
    updateEmail,
    { isLoading: isUpdatingEmail, isSuccess: isEmailUpdated },
  ] = useUpdateEmailMutation();
  const token = params.get('token');
  let oldEmail = params.get('email');

  if (!token || !oldEmail) {
    navigate(VISITOR_URLS.LOGIN);
  } else {
    oldEmail = oldEmail.replaceAll(' ', '+');
  }

  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  useEffect(() => {
    if (!isUpdatingEmail && isEmailUpdated) {
      navigate(
        `${VISITOR_URLS.EMAIL_VALIDATION}?token=${token}&email=${email}`
      );
    }
  }, [isUpdatingEmail, isEmailUpdated]);

  const handleEmailChange = useCallback((value) => {
    setShowEmailError(false);
    setEmail(value);
  }, []);

  const handleSubmit = () => {
    setShowEmailError(false);

    let hasErrors = false;

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    updateEmail({ token, email });
  };

  const handleBackToSignUp = () => {
    navigate(VISITOR_URLS.REGISTER);
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={isLoading || isUpdatingEmail}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('change_email_screen.title')}
          subtitle={
            <TranslateLink
              text={t('change_email_screen.sub_title', {
                email: oldEmail,
              })}
            />
          }
          inputFields={[
            {
              placeholder: t('signin_screen.email_placeholder'),
              error: showEmailError
                ? t('change_email_screen.modal.invalid_email')
                : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
          ]}
          button={t(
            'email_validation_screen.continue_to_email_confirmation_btn'
          )}
          footer1={
            <Text variant="bodyMd" as="span">
              <Link onClick={handleBackToSignUp}>
                {t('email_validation_screen.sign_up_again')}
              </Link>
            </Text>
          }
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
export default VisitorHOC(ChangeEmail);
