import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  InlineStack,
  Select,
  Spinner,
  BlockStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useUninstallOS2ThemeMutation } from 'pages/TrackingPageNew/newTrackingPageApis';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import RushModal from 'components/custom/RushModal';
function RemoveModal({ onClose, selectedThemeId, themes, isPublishedTheme }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [withPages, setWithPages] = useState(false);
  const [uninstallTemplate, uninstallResponse] = useUninstallOS2ThemeMutation();
  const { isSuccess: uninstallSuccess, isLoading: isRemoving } =
    uninstallResponse;

  useEffect(() => {
    if (uninstallSuccess) {
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.os2_tracking_page.wizard.removed_toast'),
          type: 'success',
        })
      );
      onClose();
    }
  }, [uninstallSuccess]);

  const handleRemove = () => {
    uninstallTemplate({ id: selectedThemeId, with_pages: Number(withPages) });
  };

  const getLabel = (theme) => {
    return theme.is_published ? `${theme.name} (Published)` : theme.name;
  };

  const renderDropdown = () => {
    let themeOptions = themes.filter(({ id }) => id === selectedThemeId);
    const options = themeOptions.map((theme) => ({
      label: getLabel(theme),
      value: theme.id,
    }));
    return (
      <InlineStack>
        <Select
          label={t('settings.os2_tracking_page.wizard.selected_theme')}
          options={options}
          disabled
          value={selectedThemeId}
        />
      </InlineStack>
    );
  };

  return (
    <RushModal
      title={
        isRemoving
          ? t(
            'settings.os2_tracking_page.wizard.step3.uninstallation_inprogress'
          )
          : t('settings.os2_tracking_page.wizard.step3.uninstallation_title')
      }
      open={true}
      onClose={onClose}
    >
      <Card roundedAbove="sm">
        {isRemoving ? (
          <InlineStack align="center">
            <Spinner size="large" />
          </InlineStack>
        ) : (
          <BlockStack gap={'500'}>
            {renderDropdown()}
            {isPublishedTheme && (
              <Checkbox
                label={t('settings.os2_tracking_page.wizard.remove_pages')}
                checked={withPages}
                onChange={setWithPages}
              />
            )}
          </BlockStack>
        )}

        {isRemoving ? (
          ''
        ) : (
          <InlineStack align="end" gap={'200'}>
            <Button onClick={onClose}>{t('common.close')}</Button>

            <Button
              primary
              destructive={true}
              outline={true}
              onClick={handleRemove}
            >
              {'Remove'}
            </Button>
          </InlineStack>
        )}
      </Card>
    </RushModal>
  );
}
export default RemoveModal;
