import React, { useMemo, useState, useCallback, useEffect } from 'react';
import CustomActionList from 'CustomActionList';
import { useTranslation } from 'react-i18next';
import {
  useDeleteShipmentNewMutation,
  useMarkAsDeliveredNewMutation,
  useReTrackShipmentNewMutation,
} from '../ShipmentDetailsApis';
import {
  RefreshIcon,
  CheckIcon,
  DeleteIcon,
  RedoIcon,
  NotificationIcon,
} from '@shopify/polaris-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { SHIPMENT_ACTION_DELIVERED, SHIPMENT_ACTION_DELETE } from 'Constants';
import RushLoading from 'components/custom/RushLoader';
import ActionModal from './ActionModal';
import { STORE_URLS } from 'config/urls';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import { useMuteNotificationsMutation } from 'pages/Shipments3/shipmentsApi';
import { useReProcessShipments3Mutation } from 'pages/Shipments3/shipmentsApi';
function Actions({
  isDetailLoaded,
  actions,
  isDetailLoading,
  refetchShipmentDetails,
  refetchWithDelay,
  trackingNumber,
  detail,
}) {
  const [t] = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showActionModal, setShowActionModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);

  const [
    muteNotifications,
    { isLoading: isMuting, isSuccess: isMuted, data: muteMessage },
  ] = useMuteNotificationsMutation();
  const [reTrackShipment, { isLoading: isReTracking, isSuccess: isRetracked }] =
    useReTrackShipmentNewMutation();

  const [
    reProcessShipment,
    { isLoading: isReProcessing, isSuccess: isReprocessed },
  ] = useReProcessShipments3Mutation();

  const [deleteShipment, { isLoading: isDeleting, isSuccess: isDeleted }] =
    useDeleteShipmentNewMutation();
  const [
    markShipmentAsDelivered,
    { isLoading: isMarkingDelivered, isSuccess: isMarkedDelivered },
  ] = useMarkAsDeliveredNewMutation();

  const toggleActionModal = useCallback(
    () => setShowActionModal(!showActionModal),
    [showActionModal]
  );

  useEffect(() => {
    if (!isMuting && isMuted) {
      dispatch(
        baseActions.setToastMessage({
          message: muteMessage?.message,
          type: 'success',
        })
      );
      refetchWithDelay();
    }
  }, [isMuting, isMuted]);

  useEffect(() => {
    if (!isDeleting && isDeleted) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipments.delete.toster_message'),
          type: 'success',
        })
      );
      navigate(`/s/${params.uuid}${STORE_URLS.SHIPMENTS}`);
    }
  }, [isDeleting, isDeleted]);

  useEffect(() => {
    if (!isMarkingDelivered && isMarkedDelivered) {
      refetchWithDelay();
    }
  }, [isMarkingDelivered, isMarkedDelivered]);

  useEffect(() => {
    if (!isReTracking && isRetracked) {
      refetchWithDelay();
    }
  }, [isReTracking, isRetracked]);

  useEffect(() => {
    if (!isReProcessing && isReprocessed) {
      refetchWithDelay();
    }
  }, [isReProcessing, isReprocessed]);

  const handleRetrack = () => {
    reTrackShipment([params.shipmentId]);
  };
  const handleReprocess = () => {
    reProcessShipment([params.shipmentId]);
  };
  const handleMarkAsDelivered = () => {
    setModalAction(SHIPMENT_ACTION_DELIVERED);
    toggleActionModal();
  };
  const handleDelete = () => {
    setModalAction(SHIPMENT_ACTION_DELETE);
    toggleActionModal();
  };

  const handleModalAction = () => {
    if (modalAction === SHIPMENT_ACTION_DELIVERED) {
      markShipmentAsDelivered([params.shipmentId]);
    } else if (modalAction === SHIPMENT_ACTION_DELETE) {
      deleteShipment([params.shipmentId]);
    }
    toggleActionModal();
  };

  const handleMuteNotifications = (selection, mute) => {
    muteNotifications(
      selection.map((s) => ({
        shipment_uuid: s,
        is_muted: mute,
      }))
    );
  };

  const secondaryActions = useMemo(() => {
    if (isDetailLoaded && actions.length && !isDetailLoading) {
      let actionsList = [];
      if (detail.quota_status !== 'over_quota') {
        if (detail.is_muted) {
          actionsList.push({
            content: t('shipments.unmute_notifications.bulk_button'),
            accessibilityLabel: t('shipments.unmute_notifications.bulk_button'),
            icon: NotificationIcon,
            onAction: () => handleMuteNotifications([params.shipmentId], false),
          });
        } else {
          actionsList.push({
            content: t('shipments.mute_notifications.bulk_button'),
            accessibilityLabel: t('shipments.mute_notifications.bulk_button'),
            icon: NotificationIcon,
            onAction: () => handleMuteNotifications([params.shipmentId], true),
          });
        }
      }

      if (
        actions?.includes('can_refresh') &&
        detail.quota_status !== 'over_quota'
      ) {
        actionsList.push({
          content: t('shipment_detail.actions.refresh'),
          accessibilityLabel: t('shipment_detail.actions.refresh'),
          icon: RefreshIcon,
          onAction: refetchShipmentDetails,
        });
      }
      if (
        actions?.includes('can_retrack') &&
        detail.quota_status !== 'over_quota'
      ) {
        actionsList.push({
          content: t('shipment_detail.actions.retrack'),
          accessibilityLabel: t('shipment_detail.actions.retrack'),
          icon: RedoIcon,
          onAction: handleRetrack,
        });
      }
      if (actions?.includes('can_reprocess')) {
        actionsList.push({
          content: t(
            'shipment_settings_new.fulfillment.reprocess_modal_button'
          ),
          accessibilityLabel: t(
            'shipment_settings_new.fulfillment.reprocess_modal_button'
          ),
          icon: RedoIcon,
          onAction: handleReprocess,
        });
      }
      if (
        actions?.includes('can_mark_as_delivered') &&
        detail.quota_status !== 'over_quota'
      ) {
        actionsList.push({
          content: t('shipment_detail.actions.mark_as_delivered'),
          accessibilityLabel: t('shipment_detail.actions.mark_as_delivered'),
          icon: CheckIcon,
          onAction: handleMarkAsDelivered,
        });
      }
      if (actions?.includes('can_delete')) {
        actionsList.push({
          content: t('shipment_detail.actions.delete'),
          accessibilityLabel: t('shipment_detail.actions.delete'),
          icon: DeleteIcon,
          onAction: handleDelete,
        });
      }
      return actionsList;
    }
    return [];
  }, [isDetailLoaded, actions, isDetailLoading, detail]);

  return (
    <>
      {isMarkingDelivered || isDeleting || isReTracking || isMuting ? (
        <RushLoading />
      ) : (
        ''
      )}

      <CustomActionList actions={secondaryActions} outline={true} />
      {showActionModal && (
        <ActionModal
          modalAction={modalAction}
          onClose={toggleActionModal}
          shipmentTrackNumber={trackingNumber}
          onAction={handleModalAction}
        />
      )}
    </>
  );
}
export default Actions;
