import React, { useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  EmptyState,
  IndexTable,
  Text,
  InlineStack,
  BlockStack,
  Box,
} from '@shopify/polaris';
import AddEditMessage from './AddEditMessage';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, DuplicateIcon } from '@shopify/polaris-icons';
import { sortBy } from 'lodash';
import '../styles.scss';
import { useGetCountriesQuery } from 'redux/store/commonStoreApis';

function MessagesCard({ messages = [], onChange, error }) {
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [isDuplicateCase, setIsDuplicateCase] = useState(false);
  const [editMessageIndex, setEditMessageIndex] = useState(-1);

  const { data: countriesList = [] } = useGetCountriesQuery();

  const getHours = (str = '') => {
    try {
      const [M, wStr] = str.includes('M') ? str.split('M') : [0, str];
      const [w, dstr] = wStr.includes('w') ? wStr.split('w') : [0, wStr];
      const [d, hStr] = dstr.includes('d') ? dstr.split('d') : [0, dstr];
      const [h, mStr] = hStr.includes('h') ? hStr.split('h') : [0, hStr];
      const [m] = mStr.includes('m') ? mStr.split('m') : [0];

      const monthHours = Number(M) * 730;
      const weekHours = Number(w) * 168;
      const dayHours = Number(d) * 24;
      const hours = Number(h);
      const minHours = Number(m) * 0.0167;
      return monthHours + weekHours + dayHours + hours + minHours;
    } catch {
      return 0;
    }
  };

  const sortedMessages = useMemo(() => {
    const m = messages.map((m) => {
      return {
        ...m,
        _h: getHours(m.time_offset_label),
      };
    });
    // eslint-disable-next-line no-unused-vars
    return sortBy(m, '_h').map(({ _h, ...m }) => m);
  }, [messages]);

  const toggleModal = () => {
    setShowModal((show) => !show);
  };

  const onAddMessage = () => {
    setEditMessageIndex(-1);
    setIsDuplicateCase(false);
    toggleModal();
  };

  const onEditMessage = (index) => {
    setEditMessageIndex(index);
    setIsDuplicateCase(false);
    toggleModal();
  };

  const onDuplicateMessage = (index) => {
    setEditMessageIndex(index);
    setIsDuplicateCase(true);
    toggleModal();
  };

  const updateMessage = (msgIndex, message) => {
    let updatedMsgs = [...sortedMessages];
    updatedMsgs.splice(msgIndex, 1, message);
    onChange(updatedMsgs);
  };

  const duplicateMessage = (msgIndex, msg) => {
    let updatedMsgs = [...sortedMessages];
    // eslint-disable-next-line no-unused-vars
    const { uuid, ...duplicateMsg } = msg;
    updatedMsgs.splice(msgIndex + 1, 0, duplicateMsg);
    onChange(updatedMsgs);
  };

  const addMessage = (msg) => {
    let updatedMsgs = [...sortedMessages];
    updatedMsgs.push(msg);
    onChange(updatedMsgs);
  };

  const deleteMessage = (msgIndex) => {
    let updatedMsgs = [...sortedMessages];
    updatedMsgs.splice(msgIndex, 1);
    onChange(updatedMsgs);
  };

  const onSaveMessage = (message) => {
    if (isDuplicateCase) {
      duplicateMessage(editMessageIndex, message);
    } else if (editMessageIndex === -1) {
      addMessage(message);
    } else {
      updateMessage(editMessageIndex, message);
    }
    setEditMessageIndex(-1);

    toggleModal();
  };

  const resourceName = {
    singular: t('pre-shipment.timeline-message'),
    plural: t('pre-shipment.timeline-messages'),
  };

  const emptyStatemarkup = () => {
    return (
      <EmptyState
        heading={
          <Text variant="bodyMd" as="span" color="subdued">
            {t('pre-shipment.no-timeline-message-added')}
          </Text>
        }
        footerContent={error}
      />
    );
  };

  const ClickAbleCell = (values) => {
    return (
      <div
        className="clickable-container-div"
        onClick={() => {
          onEditMessage(values.index);
        }}
        title={values.showTitle ? values.title || values.children : ''}
      >
        {values.children}
      </div>
    );
  };

  const rowMarkup = () => {
    return sortedMessages.map((timelineMessage, index) => {
      const {
        message,
        time_offset_label,
        uuid = index,
        is_locations,
        location_city,
        location_country_iso3,
        location_state,
      } = timelineMessage;
      const country = countriesList.find(
        (c) => c.code_iso3 === location_country_iso3
      )?.label;
      const location = [location_city, location_state, country]
        .filter((l) => l)
        .join(', ');
      return (
        <IndexTable.Row id={uuid} key={uuid} position={index}>
          <IndexTable.Cell className="message-cell">
            <ClickAbleCell index={index} showTitle>
              {message}
            </ClickAbleCell>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <ClickAbleCell index={index}>{time_offset_label}</ClickAbleCell>
          </IndexTable.Cell>
          <IndexTable.Cell className="buttons-container">
            <ClickAbleCell index={index} showTitle title={location}>
              {is_locations ? location : ''}
            </ClickAbleCell>
            <div className="action-buttons">
              <ButtonGroup variant='segmented'>
                <Button
                  accessibilityLabel={t('common.duplicate')}
                  onClick={() => onDuplicateMessage(index)}
                  size="small"
                  icon={DuplicateIcon}
                ></Button>{' '}
                <Button
                  size="small"
                  accessibilityLabel={t('common.delete')}
                  onClick={() => deleteMessage(index)}
                  icon={DeleteIcon}
                ></Button>
              </ButtonGroup>
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  };

  return (
    <>
      <Card roundedAbove="sm" padding={0}>
        <BlockStack gap={'500'}>
          <Box padding={'500'} paddingBlockEnd={'0'}>
            <BlockStack gap={'200'}>
              <InlineStack gap={'500'} align="space-between">
                <Text variant="headingMd" as="h2">
                  {t('pre-shipment.messages-title')}
                </Text>
                <Button plain onClick={onAddMessage}>
                  {t('common.add')}
                </Button>
              </InlineStack>
              <Text variant="bodyMd" as="span" color="subdued">
                {t('pre-shipment.message-description')}
              </Text>
            </BlockStack>
          </Box>
          <div className="messages-table-container">
            <IndexTable
              emptyState={emptyStatemarkup()}
              resourceName={resourceName}
              selectable={false}
              itemCount={messages.length}
              headings={[
                { title: t('pre-shipment.messages') },
                { title: t('pre-shipment.timeline') },
                { title: t('pre-shipment.location') },
              ]}
            >
              {rowMarkup()}
            </IndexTable>
          </div>
        </BlockStack>
      </Card>
      {showModal && (
        <AddEditMessage
          message={
            editMessageIndex === -1 ? null : sortedMessages[editMessageIndex]
          }
          onSave={onSaveMessage}
          onClose={toggleModal}
        />
      )}
    </>
  );
}

export default MessagesCard;
