import { AccessToken } from 'pages/Visitor/visitorSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const { API_URL } = require('config');

function useCheckIntegration({ url, skip }) {
  const accessToken = useSelector(AccessToken);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(!skip);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}${url}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();
        setData(jsonData);
        setSuccess(true);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (!skip) {
      fetchData();
    }
  }, [url, accessToken, skip]);

  const handleReset = () => {
    setData(null);
    setError(null);
    setSuccess(false);
    setLoading(skip ? false : true);
  };

  return {
    data,
    error,
    loading,
    success: skip ? false : success,
    reset: handleReset,
  };
}

export default useCheckIntegration;
