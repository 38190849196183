import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: undefined,
  emailToRecoverPassword: '',
  registerPassword: '',
};

const visitorSlice = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setEmailToRecoverPassword(state, action) {
      state.emailToRecoverPassword = action.payload;
    },
    setRegisterPassword(state, action) {
      state.registerPassword = action.payload;
    },
  },
});

// Actions
export const visitorActions = visitorSlice.actions;

// Selectors
export const AccessToken = (state) => state.visitor.accessToken;

// Reducer
const visitor = visitorSlice.reducer;
export default visitor;
