import React, { useState, useCallback, useEffect } from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import SmallTopMessage from 'components/SmallTopMessage';
import { useNavigate } from 'react-router-dom';
import SaveBar from '../KlaviyoPage/SaveBar';
import OmnisendDetails from './OmnisendDetails';
import OmnisendActions from './OmnisendActions';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import OmnisendPageCustomization from './OmnisendPageCustomization';
import isNull from 'lodash/isNull';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import SettingsToaster from '../SettingsToaster';
import { STORE_URLS } from 'config/urls';
import Subtitle from 'components/Subtitle';
import i18next from 'i18next';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';

export default function OmnisendPage({
  getOmnisend,
  saveOmnisend,
  loading,
  omnisendConfig,
  omnisendMeta,
  resetOmnisend,
  omnisendConfigSaved,
  shop,
  featureRequest,
  resetFeatureRequest,
  loadingFeatureId,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [localOmnisendObj, setLocalOmnisendObj] = useState({});

  const [apiKey, setApiKey] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);

  const [selectedTrigger, setSelectedTrigger] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (omnisendConfigSaved) {
      getOmnisend();
      resetOmnisend();
    }
  }, [omnisendConfigSaved, resetOmnisend, getOmnisend]);

  useEffect(() => {
    resetOmnisend();
    getOmnisend();
  }, [getOmnisend, resetOmnisend, i18next.language]);

  useEffect(() => {
    if (featureRequest) {
      setIsUpdated(false);
      getOmnisend();
      resetFeatureRequest();
      setApiKeyError(false);
    }
  }, [getOmnisend, resetFeatureRequest, featureRequest]);

  useEffect(() => {
    if (omnisendConfig?.hasOwnProperty('is_active') && !loading && !isUpdated) {
      setLocalOmnisendObj({
        config: omnisendConfig,
        meta: omnisendMeta,
        isUpdated: false,
      });
    }
  }, [omnisendMeta, omnisendConfig, loading, isUpdated]);

  useEffect(() => {
    if (!isEmpty(localOmnisendObj.config) && localOmnisendObj.config.triggers) {
      const { config } = localOmnisendObj;
      const temp = config.triggers
        .map((trigger) => (trigger.is_active ? trigger.trigger_slug : null))
        .filter((item) => !isNull(item));
      setApiKey(config.api_key);
      setSelectedLanguage(config.language);
      setSelectedTrigger(temp);
    }
  }, [omnisendConfig, localOmnisendObj]);

  const updateOmnisendPayload = () => {
    setApiKeyError(null);
    setIsUpdated(true);
  };

  const discardChanges = useCallback(() => {
    setIsUpdated(false);
    setApiKeyError(false);

    setLocalOmnisendObj({
      config: omnisendConfig,
      meta: omnisendMeta,
      isUpdated: false,
    });
  }, [omnisendMeta, omnisendConfig]);

  const redirectBackToPrevious = useCallback(() => {
    navigate(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
  }, [history, shop.storeUUID]);

  const redirectBack = useCallback(() => {
    resetOmnisend();

    if (omnisendConfigSaved) {
      return;
    }

    redirectBackToPrevious();
  }, [resetOmnisend, redirectBackToPrevious, omnisendConfigSaved]);

  const submit = () => {
    if (
      !apiKey ||
      isEmpty(trim(apiKey)) ||
      apiKey.search(/^[A-Z0-9]{24}-[A-Z0-9]{50}$/i)
    ) {
      setApiKeyError(t('omnisend.modal.public_api_key_error_text'));
      return;
    }
    const tempTriggers = omnisendConfig.triggers.map((trigger) => {
      if (selectedTrigger.includes(trigger.trigger_slug)) {
        return { ...trigger, is_active: true };
      } else {
        return { ...trigger, is_active: false };
      }
    });
    saveOmnisend({
      api_key: trim(apiKey),
      triggers: tempTriggers,
      language: selectedLanguage,
    });
    setIsUpdated(false);
  };

  if (!localOmnisendObj.hasOwnProperty('config')) {
    return <LoadingLayoutPage />;
  }

  return (
    <>
      <SmallTopMessage />
      <RushPage
        backAction={{ onAction: redirectBack }}
        title={t('omnisend.modal.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('settings.shipbob_pages.subtitle.read_more'),
                source:
                  'https://support.rush.app/integrating-omnisend-and-rush',
              },
            ]}
          />
        }
      >
        <Layout>
          <SaveBar
            isSaveBarEnabled={isUpdated}
            submitData={submit}
            discardAction={discardChanges}
          />
          {omnisendConfigSaved && <RushLoading />}
          {(loading || loadingFeatureId > 0) && <RushLoading />}

          <OmnisendPageCustomization
            omnisendConfig={omnisendConfig}
            localOmnisendObj={localOmnisendObj}
            setLocalOmnisendObj={setLocalOmnisendObj}
            resetOmnisend={resetOmnisend}
          />

          {localOmnisendObj &&
            localOmnisendObj.hasOwnProperty('config') &&
            localOmnisendObj.config.is_active && (
              <>
                <Layout.AnnotatedSection
                  title={t('settings.omnisend.section.title')}
                  description={t(
                    'settings.omnisend.section.configure.description'
                  )}
                >
                  <OmnisendDetails
                    omnisendConfig={omnisendConfig}
                    omnisendMeta={omnisendMeta}
                    localOmnisendObj={localOmnisendObj}
                    apiKey={apiKey}
                    setApiKey={setApiKey}
                    apiKeyError={apiKeyError}
                    setSelectedLanguage={setSelectedLanguage}
                    setSelectedTrigger={setSelectedTrigger}
                    selectedLanguage={selectedLanguage}
                    selectedTrigger={selectedTrigger}
                    updateOmnisendPayload={updateOmnisendPayload}
                  />
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title={t('settings.omnisend.actions.title')}
                >
                  <OmnisendActions
                    omnisendConfig={omnisendConfig}
                    apiKey={apiKey}
                    setApiKeyError={setApiKeyError}
                    selectedLanguage={selectedLanguage}
                    selectedTrigger={selectedTrigger}
                  />
                </Layout.AnnotatedSection>
              </>
            )}
        </Layout>
        <UnsavedChangesModal />
      </RushPage>
      <SettingsToaster />
    </>
  );
}
