import sortBy from 'lodash/sortBy';
import {
  DATE_RANGE_VALUE_LAST_30_DAYS,
  DATE_RANGE_VALUE_LAST_90_DAYS,
  DATE_RANGE_VALUE_LAST_7_DAYS,
  SHIPMENTS_PER_PAGE_RECORDS,
  DATE_RANGE_VALUE_LAST_MONTH,
  DATE_RANGE_VALUE_LAST_120_DAYS,
  DATE_RANGE_VALUE_THIS_YEAR,
  DATE_RANGE_VALUE_LAST_365_DAYS,
  DATE_RANGE_VALUE_CUSTOM,
  DB_DATE_FORMAT_STRING,
  DATE_RANGE_VALUE_YESTERDAY,
  DATE_RANGE_VALUE_TODAY,
  DATE_RANGE_VALUE_LAST_60_DAYS,
} from '../../../Constants';
import moment from 'moment';

export const MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE = 0;
export const MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE = 35;

export const MIN_TRANSIT_DAYS = 0;
export const MAX_TRANSIT_DAYS = 60;

export const getSearchCategories = (searchString) => {
  const trimmedSearchString = searchString.trim();

  if (/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(trimmedSearchString)) {
    return 'shipment_uuid';
  } else if (/^\S+@\S+\.\S+$/.test(trimmedSearchString)) {
    return 'customer_email';
  } else if (/\s/.test(trimmedSearchString)) {
    return 'customer_name';
  } else if (trimmedSearchString.length <= 6) {
    return 'order_number';
  } else {
    return 'tracking_number';
  }
};
export const DEFAULT_SHIPMENT_SORT_OPTION = ['order_number desc'];

export const getShipmentSortOptions = (t) => {
  return [
    {
      label: t('shipments.sort.fulfilment_date'),
      value: 'created_at asc',
      directionLabel: t('shipments.sort.oldest_to_newest'),
    },
    {
      label: t('shipments.sort.fulfilment_date'),
      value: 'created_at desc',
      directionLabel: t('shipments.sort.newest_to_oldest'),
    },
    {
      label: t('shipments.sort.date_synced'),
      value: 'last_synced_at asc',
      directionLabel: t('shipments.sort.oldest_to_newest'),
    },
    {
      label: t('shipments.sort.date_synced'),
      value: 'last_synced_at desc',
      directionLabel: t('shipments.sort.newest_to_oldest'),
    },
    {
      label: t('shipments.sort.order_number'),
      value: 'order_number asc',
      directionLabel: t('shipments.sort.oldest_to_newest'),
    },
    {
      label: t('shipments.sort.order_number'),
      value: 'order_number desc',
      directionLabel: t('shipments.sort.newest_to_oldest'),
    },
    {
      label: t('shipments.sort.customer_name'),
      value: 'first_name asc',
      directionLabel: 'A-Z',
    },
    {
      label: t('shipments.sort.customer_name'),
      value: 'first_name desc',
      directionLabel: 'Z-A',
    },
    {
      label: t('shipments.sort.total_price'),
      value: 'money_total_price asc',
      directionLabel: t('shipments.sort.low_to_high'),
    },
    {
      label: t('shipments.sort.total_price'),
      value: 'money_total_price desc',
      directionLabel: t('shipments.sort.high_to_low'),
    },
    {
      label: t('shipments.sort.last_carrier_update'),
      value: 'stat_last_checkpoint_at asc',
      directionLabel: t('shipments.sort.oldest_to_newest'),
    },
    {
      label: t('shipments.sort.last_carrier_update'),
      value: 'stat_last_checkpoint_at desc',
      directionLabel: t('shipments.sort.newest_to_oldest'),
    },
  ];
};

export const getOrdersCreatedInOptions = (t) => {
  return [
    {
      label: t('shipments.filter.date_range.last_7_days'),
      value: DATE_RANGE_VALUE_LAST_7_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_30_days'),
      value: DATE_RANGE_VALUE_LAST_30_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_month'),
      value: DATE_RANGE_VALUE_LAST_MONTH,
    },
    {
      label: t('shipments.filter.date_range.last_90_days'),
      value: DATE_RANGE_VALUE_LAST_90_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_120_days'),
      value: DATE_RANGE_VALUE_LAST_120_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_60_days'),
      value: DATE_RANGE_VALUE_LAST_60_DAYS,
    },
    {
      label: t('shipments.filter.date_range.this_year'),
      value: DATE_RANGE_VALUE_THIS_YEAR,
    },
    {
      label: t('shipments.filter.date_range.last_365_days'),
      value: DATE_RANGE_VALUE_LAST_365_DAYS,
    },
    {
      label: t('shipments.filter.date_range.custom'),
      value: DATE_RANGE_VALUE_CUSTOM,
      disabled: true,
    },
  ];
};

export const getColumns = (t) => {
  return [
    {
      title: t('shipments.column.order'),
      value: 'order',
      selected: true,
      disabled: true,
    },
    { title: t('shipments.column.status'), value: 'status', selected: true },
    {
      title: t('shipments.column.sub_status'),
      value: 'subStatus',
      selected: true,
    },
    { title: t('shipments.column.eta'), value: 'eta', selected: true },
    {
      title: t('shipments.column.first_mile_carrier'),
      value: 'firstMileCarrier',
      selected: true,
    },
    {
      title: t('shipments.column.first_mile_tracking_number'),
      value: 'firstMileTrackingNumber',
      selected: true,
    },
    {
      title: t('shipments.column.last_mile_carrier'),
      value: 'lastMileCarrier',
      selected: true,
    },
    {
      title: t('shipments.column.last_mile_tracking_number'),
      value: 'lastMileTrackingNumber',
      selected: true,
    },
    {
      title: t('shipments.column.carrier_last_update'),
      value: 'carrierLastUpdate',
      selected: true,
    },
    {
      title: t('shipments.column.destination_country'),
      value: 'destinationCountry',
      selected: true,
    },
    {
      title: t('shipments.column.destination_city'),
      value: 'destinationCity',
      selected: true,
    },
    {
      title: t('shipments.column.customer_name'),
      value: 'customerName',
      selected: true,
    },
    {
      title: t('shipments.column.customer_email'),
      value: 'customerEmail',
      selected: true,
    },
    {
      title: t('shipments.column.customer_phone'),
      value: 'customerPhone',
      selected: true,
    },
    {
      title: t('shipments.column.order_date'),
      value: 'orderDate',
      selected: true,
    },
    {
      title: t('shipments.column.shipment_date'),
      value: 'shipmentDate',
      selected: true,
    },
    {
      title: t('shipments.column.in_transit_date'),
      value: 'inTransitDate',
      selected: true,
    },
    {
      title: t('shipments.column.delivered_date'),
      value: 'deliveredDate',
      selected: true,
    },
    {
      title: t('shipments.column.order_price'),
      value: 'orderPrice',
      selected: true,
    },
    {
      title: t('shipments.column.last_carrier_check'),
      value: 'lastCarrierCheck',
      selected: true,
    },
  ];
};

export const COLUMN_MAPPINGS = {
  order: 'order_label',
  status: 'shipment_status_uuid',
  subStatus: 'shipment_sub_status_uuid',
  firstMileCarrier: 'first_mile_carrier_uuid',
  firstMileTrackingNumber: 'first_mile_tracking_number',
  eta: 'shipment_estimated_delivery_date',
  lastMileCarrier: 'last_mile_carrier_uuid',
  lastMileTrackingNumber: 'last_mile_tracking_number',
  carrierLastUpdate: 'shipment_last_checkpoint_at',
  destinationCountry: 'destination_country',
  destinationCity: 'destination_city',
  customerName: 'customer_name',
  customerEmail: 'customer_email',
  customerPhone: 'customer_phone',
  orderDate: 'order_created_at',
  shipmentDate: 'shipment_created_at',
  inTransitDate: 'shipment_in_transit_at',
  deliveredDate: 'shipment_delivered_at',
  orderPrice: 'order_price_label',
  lastCarrierCheck: 'last_sync_at',
};

export const getDatesFromValue = (value) => {
  let result = {
    start: moment().format(DB_DATE_FORMAT_STRING),
    end: moment().format(DB_DATE_FORMAT_STRING),
  };
  switch (value) {
    case DATE_RANGE_VALUE_TODAY:
      result.start = moment().format(DB_DATE_FORMAT_STRING);
      result.end = moment().format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_YESTERDAY:
      result.start = moment().subtract(1, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_7_DAYS:
      result.start = moment().subtract(7, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_30_DAYS:
      result.start = moment().subtract(30, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_MONTH:
      result.start = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_90_DAYS:
      result.start = moment().subtract(90, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_120_DAYS:
      result.start = moment().subtract(120, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_60_DAYS:
      result.start = moment().subtract(60, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_THIS_YEAR:
      result.start = moment().startOf('year').format(DB_DATE_FORMAT_STRING);
      break;
    case DATE_RANGE_VALUE_LAST_365_DAYS:
      result.start = moment().subtract(365, 'd').format(DB_DATE_FORMAT_STRING);
      break;
    default:
      result.start = moment().subtract(60, 'd').format(DB_DATE_FORMAT_STRING);
      break;
  }
  return result;
};

export const transformFlagFilters = (flagFilters) => {
  return flagFilters.reduce((prev, next) => {
    prev[next] = true;
    return prev;
  }, {});
};

export const getQueryObject = (
  page,
  filters,
  shipmentStatuses,
  columns,
  isForParams = false
) => {
  const selectedSort = (filters.sort || DEFAULT_SHIPMENT_SORT_OPTION)[0]?.split(
    ' '
  );
  const object = {
    fields: isForParams
      ? undefined
      : columns
          .filter(({ selected }) => selected)
          .map(({ value }) => COLUMN_MAPPINGS[value])
          .join(','),
    per_page: SHIPMENTS_PER_PAGE_RECORDS,
    page,
    sort: selectedSort[1] === 'desc' ? `-${selectedSort[0]}` : selectedSort[0],
    search: filters.search !== '' ? filters.search : undefined,
    search_category:
      filters.search !== '' ? filters.search_category : undefined,
    statuses_uuid: filters.statuses?.length
      ? filters.statuses
          ?.map((id) => getStatusUuidFromId(shipmentStatuses, id))
          .join(',')
      : undefined,
    sub_statuses_uuid: filters.subStatuses?.length
      ? filters.subStatuses
          ?.map((id) => getSubStatusUuidFromId(shipmentStatuses, id))
          .join(',')
      : undefined,
    country_uuid: filters.selectedDeliveryDestinations?.length
      ? filters.selectedDeliveryDestinations?.join(',')
      : undefined,
    firstmile_carriers_uuid: filters.firstMileCarriers?.length
      ? filters.firstMileCarriers?.join(',')
      : undefined,
    lastmile_carriers_uuid: filters.lastMileCarriers?.length
      ? filters.lastMileCarriers?.join(',')
      : undefined,
    days_without_carrier_update_min:
      filters.daysPassedWithoutCarrierUpdate[0] >
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE
        ? filters.daysPassedWithoutCarrierUpdate[0]
        : undefined,
    days_without_carrier_update_max:
      filters.daysPassedWithoutCarrierUpdate[1] <
      MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE
        ? filters.daysPassedWithoutCarrierUpdate[1]
        : undefined,
    days_in_transit_min:
      filters.transitDaysBetween[0] > MIN_TRANSIT_DAYS
        ? filters.transitDaysBetween[0]
        : undefined,
    days_in_transit_max:
      filters.transitDaysBetween[1] < MAX_TRANSIT_DAYS
        ? filters.transitDaysBetween[1]
        : undefined,
    orders_created_in: isForParams ? filters.ordersCreatedIn : undefined,
    orders_created_from: filters.ordersCreatedInFrom,
    orders_created_to: filters.ordersCreatedInTo,
    ...transformFlagFilters(filters.selectedFlagOptions || []),
  };

  Object.keys(object).forEach((key) => {
    if (object[key] === undefined) {
      delete object[key];
    }
  });

  return object;
};

const getSubStatusUuidFromId = (shipmentStatuses, id) => {
  let subStatus = null;
  for (const status of shipmentStatuses) {
    subStatus = status.substatuses?.find((sub) => sub.id === id);
    if (subStatus) {
      break;
    }
  }
  return subStatus.uuid;
};

const getStatusUuidFromId = (shipmentStatuses, id) => {
  const status = shipmentStatuses.find((status) => status.id === id);
  return status?.uuid;
};

export const getStatusIdFromUuid = (shipmentStatuses, uuid) => {
  const status = shipmentStatuses.find((status) => status.uuid === uuid);
  return status?.id;
};

export const getCountryNameFromUUID = (countriesList, uuid) => {
  const status = countriesList.find((c) => c.value === uuid);
  return status?.label;
};

export const getCarrierNameFromUuid = (shipmentCarriers, uuid) => {
  const carrier = shipmentCarriers.find((carrier) => carrier.uuid === uuid);
  return carrier?.label;
};

export const getSubStatusLabelFromId = (shipmentStatuses, id) => {
  let subStatus = null;
  for (const status of shipmentStatuses) {
    subStatus = status.substatuses?.find((sub) => sub.id === id);
    if (subStatus) {
      break;
    }
  }
  return subStatus.label;
};

export const getStatusLabelFromId = (shipmentStatuses, id) => {
  const status = shipmentStatuses.find((st) => st.id === id);
  return status?.label;
};

export const getTextForFilterTag = (value, t, from = null, to = null) => {
  switch (value) {
    case DATE_RANGE_VALUE_TODAY:
      return t('shipments.filter.date_range.today');
    case DATE_RANGE_VALUE_YESTERDAY:
      return t('shipments.filter.date_range.yesterday');
    case DATE_RANGE_VALUE_LAST_7_DAYS:
      return t('shipments.filter.date_range.last_7_days');
    case DATE_RANGE_VALUE_LAST_30_DAYS:
      return t('shipments.filter.date_range.last_30_days');
    case DATE_RANGE_VALUE_LAST_MONTH:
      return t('shipments.filter.date_range.last_month');
    case DATE_RANGE_VALUE_LAST_90_DAYS:
      return t('shipments.filter.date_range.last_90_days');
    case DATE_RANGE_VALUE_LAST_120_DAYS:
      return t('shipments.filter.date_range.last_120_days');
    case DATE_RANGE_VALUE_LAST_60_DAYS:
      return t('shipments.filter.date_range.last_60_days');
    case DATE_RANGE_VALUE_THIS_YEAR:
      return t('shipments.filter.date_range.this_year');
    case DATE_RANGE_VALUE_LAST_365_DAYS:
      return t('shipments.filter.date_range.last_365_days');
    case DATE_RANGE_VALUE_CUSTOM:
      return `${from} - ${to}`;
    default:
      return t('shipments.filter.date_range.last_120_days');
  }
};

export const formatDate = (date) => {
  if (!date) {
    return '';
  }

  const momentObject = moment(date);
  let text = momentObject.format('MMM D');
  if (momentObject.year() !== moment().year()) {
    text += `, ${momentObject.year()}`;
  }
  return text;
};

export const getQueryString = (page, filters, shipmentStatuses, columns) => {
  const queryObject = getQueryObject(
    page,
    filters,
    shipmentStatuses,
    columns,
    true
  );
  return Object.entries(queryObject)
    .map(([key, value]) => {
      if (key === 'search') {
        return `${key}=${encodeURIComponent(value)}`;
      }
      return `${key}=${value}`;
    })
    .join('&');
};

export const getCarrierChoices = (shipmentCarriers = [], notAssignedLabel) => {
  const choices = sortBy([...shipmentCarriers], 'label').map((carrier) => ({
    label: carrier.label,
    value: carrier.uuid,
  }));

  choices.unshift({ value: 'none', label: notAssignedLabel });
  return choices;
};

export const getHumanDate = (date, isFinal) => {
  if (!date) {
    return '';
  }

  const mom = moment.utc(date);
  const minutes = moment().diff(mom, 'minutes');

  let text;

  if (minutes < 1) {
    text = 'Under a minute';
  } else if (minutes < 60) {
    text = `${minutes}mins ago`;
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    text = `${hours}h, ${minutes - hours * 60}mins ago`;
  } else {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes - days * 1440) / 60);
    if (hours > 0) {
      text = `${days}d, ${hours}h ago`;
    } else {
      text = `${days}d ago`;
    }
  }

  return {
    text,
    variation: isFinal ? 'subdued' : minutes <= 2880 ? 'positive' : 'critical',
  };
};

export const getShipmentUuidFromIndex = (shipments, index) =>
  shipments[index]?.uuid;

export const getStatusIdFromSubStatusUuid = (shipmentStatuses, uuid) => {
  let subStatus;
  const status = shipmentStatuses.find((status) => {
    subStatus = status.substatuses?.find(
      (subStatus) => subStatus.uuid === uuid
    );
    return !!subStatus;
  });

  return { status: status.id, subStatus: subStatus.id };
};

export const getDefaultFilters = () => {
  return {
    search_category: {},
    search: '',
    sort: DEFAULT_SHIPMENT_SORT_OPTION,
    statuses: [],
    subStatuses: [],
    selectedFlagOptions: [],
    selectedDeliveryDestinations: [],
    firstMileCarriers: [],
    lastMileCarriers: [],
    daysPassedWithoutCarrierUpdate: [
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
    ],
    transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
    ordersCreatedIn: DATE_RANGE_VALUE_LAST_60_DAYS,
    ordersCreatedInFrom: undefined,
    ordersCreatedInTo: undefined,
    page: 1,
  };
};

export const getFlagFilterOptions = (t) => {
  return [
    {
      label: t('shipments.flag_filter.only_domestic'),
      value: 'is_domestic',
    },
    {
      label: t('shipments.flag_filter.only_international'),
      value: 'is_international',
    },
    {
      label: t('shipments.flag_filter.only_returned'),
      value: 'is_returned',
    },
    {
      label: t('shipments.flag_filter.expired'),
      value: 'is_expired',
    },
    {
      label: t('shipments.flag_filter.notifications_muted'),
      value: 'is_muted',
    },
    {
      label: t('shipments.flag_filter.stalled'),
      value: 'is_stalled',
    },
    {
      label: t('shipments.flag_filter.problematic'),
      value: 'is_problematic',
    },
    {
      label: `${t('shipment_details.filter_over_quota')} ⚠️`,
      value: 'over_quota',
    },
  ];
};
