import { storeApi } from '../../redux/store/storeApi';

export const smartEDDApis = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getEDDConfig: builder.query({
      query: () => {
        return {
          url: `/features/edd/config`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || undefined,
      providesTags: ['smartEDD'],
    }),
    getEDDStats: builder.query({
      query: () => {
        return {
          url: `/features/edd/store-stats`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || undefined,
    }),
    setEDDConfig: builder.mutation({
      query: (data) => {
        return {
          url: `/features/edd/config`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      invalidatesTags: ['smartEDD', 'settings'],
      transformResponse: (response = {}) => response?.data || response,
    }),

    deleteEDDConfig: builder.mutation({
      query: (uuid) => {
        return {
          url: `/features/edd/config/${uuid}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['smartEDD'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetEDDConfigQuery,
  useSetEDDConfigMutation,
  useDeleteEDDConfigMutation,
  useGetEDDStatsQuery,
} = smartEDDApis;
