import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormLayout,
  Select,
  ChoiceList,
  Modal,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import RushModal from 'components/custom/RushModal';
export default function TopMenuLinkModal({
  item,
  onClose,
  onAddLink,
  metaLinks = [],
}) {
  const OPTION_ICON_LABEL = 'icon';
  const OPTION_TEXT_LABEL = 'label';

  const [t] = useTranslation();
  const [itemType, setItemType] = useState('icon');
  const [icon, setIcon] = useState(metaLinks[0]?.link || '');
  const [label, setLabel] = useState('');
  const [link, setLink] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (item) {
      const type = Object.keys(item).includes('label') ? 'label' : 'icon';
      setItemType(type);
      if (type === 'icon') {
        setIcon(item.icon);
      } else {
        setLabel(item.label);
      }
      setLink(item?.link);
      setErrorMsg(null);
    }
  }, [item]);

  const submit = () => {
    if (!link) {
      setErrorMsg(
        t('settings.tracking_page.header_links_modal.label_invalid_text')
      );
      return;
    }

    let itemToAdd = {
      [itemType]: itemType === OPTION_ICON_LABEL ? icon : label,
      link,
    };
    onAddLink(itemToAdd);

    onClose();
  };

  return (
    <RushModal
      open={true}
      onClose={onClose}
      title={t('settings.tracking_page.header_links_modal.title')}
      primaryAction={{
        content: t('settings.tracking_page.header_links_modal.save_btn_label'),
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: t(
            'settings.tracking_page.header_links_modal.cancel_btn_label'
          ),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <div className="">
          <FormLayout>
            <div className="small-gap-list">
              <ChoiceList
                title={t(
                  'settings.tracking_page.header_links_modal.options_title'
                )}
                choices={[
                  {
                    label: t(
                      'settings.tracking_page.header_links_modal.icon_option'
                    ),
                    value: OPTION_ICON_LABEL,
                  },
                  {
                    label: t(
                      'settings.tracking_page.header_links_modal.text_option'
                    ),
                    value: OPTION_TEXT_LABEL,
                  },
                ]}
                selected={[itemType]}
                onChange={([value]) => {
                  setItemType(value);
                }}
              />
            </div>
            {itemType === OPTION_ICON_LABEL && (
              <div>
                <Select
                  label={t(
                    'settings.tracking_page.header_links_modal.content.icon_label'
                  )}
                  options={metaLinks.map((type) => ({
                    label: type.name,
                    value: type.link,
                  }))}
                  onChange={(value) => {
                    setIcon(value);
                  }}
                  value={icon}
                  id="select-top-menu-links-icon"
                />
              </div>
            )}
            {itemType === OPTION_TEXT_LABEL && (
              <TextField
                autoComplete={'off'}
                value={label}
                label={t(
                  'settings.tracking_page.header_links_modal.content.text_label'
                )}
                onChange={(value) => setLabel(value)}
                error={errorMsg}
                placeholder={t(
                  'settings.tracking_page.header_links_modal.content.label_input_text'
                )}
                id={`input-top-menu-links-modal-label`}
              />
            )}
            <TextField
              autoComplete={'off'}
              label={t(
                'settings.tracking_page.header_links_modal.content.link_label'
              )}
              onChange={(value) => setLink(value)}
              value={link}
              error={errorMsg}
              placeholder={t(
                'settings.tracking_page.header_links_modal.content.link_input_text'
              )}
              id={`input-top-menu-links-modal-link`}
            />
          </FormLayout>
        </div>
      </Modal.Section>
    </RushModal>
  );
}
