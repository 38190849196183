import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@shopify/polaris';
import { UserPage } from '@rush-deliveries/storybooks-shopify';
import { useNavigate } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import VisitorHOC from '../VisitorHOC';
import { useSelector } from 'react-redux';

function RecoverSuccess({
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  isLoading,
  forgotPassword,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const emailToRecoverPassword = useSelector(
    ({ visitor }) => visitor.emailToRecoverPassword
  );

  const handleResendInstructions = () => {
    forgotPassword(emailToRecoverPassword);
  };

  const handleSubmit = () => {
    navigate(VISITOR_URLS.LOGIN);
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={isLoading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('Instructions sent')}
          subtitle={
            <TranslateLink
              text={t('password_recovery_successs_screen.sub_title', {
                email: emailToRecoverPassword,
              })}
            />
          }
          button={t('password_recovery_successs_screen.back_to_sign_in_btn')}
          footer1={
            <Text variant="bodyMd" as="span">
              <Link onClick={handleResendInstructions}>
                {t(
                  'password_recovery_successs_screen.resent_instructions_again_btn'
                )}
              </Link>
            </Text>
          }
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
export default VisitorHOC(RecoverSuccess);
