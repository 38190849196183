import React, { useEffect, useState } from 'react';
import { CalloutCard } from '@shopify/polaris';
import ApproveImg from '../images/approve.svg';
import { useTranslation } from 'react-i18next';
import LoadingPage from 'components/LoadingPage';
import RushPage from 'components/custom/RushPage';
import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';

function RushApprove() {
  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);
  const app = useAppBridge();

  const params = new URLSearchParams(window.location.href);
  const redirectUri = params.get('redirectUri');

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1200);
  });

  const handleApprove = () => {
    const redirect = Redirect.create(app);
    redirect.dispatch(Redirect.Action.REMOTE, decodeURIComponent(redirectUri));
  };

  return (
    <>
      {!visible ? <LoadingPage /> : ''}
      <RushPage fullWidth>
        <div className={`permissions-card ${!visible ? 'hidden' : ''}`}>
          <CalloutCard
            title={t('approve.title')}
            illustration={ApproveImg}
            primaryAction={{
              content: t('approve.primary.action'),
              onAction: handleApprove,
            }}
          >
            <p>{t('approve.description')}</p>
          </CalloutCard>
        </div>
      </RushPage>
    </>
  );
}

export default RushApprove;
