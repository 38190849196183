import React from 'react';
import { findStatus } from '../utils';
import { getShipmentBadge } from 'Utils/BadgeUtils';
import { Badge } from '@shopify/polaris';

function RenderBadge({ statuses, status_uuid, sub_status_uuid }) {
  const statusInfo = findStatus(statuses, status_uuid);
  const subStatusInfo = statusInfo?.sub_statuses?.find(
    (subs) => subs.uuid === sub_status_uuid
  );
  let slug = sub_status_uuid ? subStatusInfo?.slug : statusInfo?.slug;
  let title = sub_status_uuid ? subStatusInfo?.title : statusInfo?.title;
  const badgeProps = getShipmentBadge(slug);

  return title ? <Badge {...badgeProps}>{title}</Badge> : '';
}

export default RenderBadge;
