import { API_URL } from 'config';
import { storeApi } from 'redux/store/storeApi';

export const YotpoSmsPageAPI = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getYotpoConfig: builder.query({
      query: () => {
        return {
          url: `/channels/yotpo`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response,
      providesTags: ['yotpo-sms'],
    }),
    addYotpoEvent: builder.mutation({
      query: ({ data, storeUUID }) => {
        return {
          baseUrl: `${API_URL}/notifications/${storeUUID}`,
          url: `/channels/yotpo`,
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    sendTestNotification: builder.mutation({
      query: (data) => {
        return {
          url: `/channels/yotpo/test`,
          body: { ...data },
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    updateYotpoConfig: builder.mutation({
      query: (data) => {
        return {
          url: `/channels/yotpo`,
          method: 'PUT',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['yotpo-sms', 'settings'],
    }),
    disconnectYotpo: builder.mutation({
      query: () => {
        return {
          url: `/channels/yotpo`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['yotpo-sms', 'settings'],

      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetYotpoConfigQuery,
  useAddYotpoEventMutation,
  useUpdateYotpoConfigMutation,
  useDisconnectYotpoMutation,
  useSendTestNotificationMutation,
} = YotpoSmsPageAPI;
