import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import freshChatPlaceholder from 'images/placeholderFreshchat.png';

const FreshChat = ({ ...rest }) => {
  const dispatch = useDispatch();
  const [widgetLoaded, setWidgetLoaded] = useState(false);

  const loadFreshChatWidget = () => {
    setTimeout(() => {
      // Introducing a delay to load the script
      const script = document.createElement('script');
      script.src = 'https://hulk.freshchat.com/js/widget.js';
      script.async = true;
      script.onload = () => {
        setWidgetLoaded(true);
      };
      document.body.appendChild(script);
    }, 3000); // Delay set to 3 seconds
  };

  const processFreshChat = useCallback(
    (shopFreshChatId, restoreId) => {
      setTimeout(() => {
        // Introducing a delay in processing
        if (shopFreshChatId === '' || shopFreshChatId === null) {
          dispatch({
            type: 'SET_FRESHCHAT_ID',
            payload: {
              freshchat_id: restoreId,
            },
          });
        }
      }, 2000); // Delay set to 2 seconds
    },
    [dispatch]
  );

  const initFreshChat = () => {
    setTimeout(() => {
      // Introducing a delay in initialization
      if (!rest.host) {
        rest.host = 'https://wchat.freshchat.com';
      }

      window.fcWidget.init({
        ...rest,
      });

      const { shop } = rest;

      const fcChatData = {
        firstName: shop.first_name,
        email: shop.email,
        meta: {
          app_name: window.appName,
          store_name: shop.id,
          store_url: shop.store_url,
        },
      };

      setTimeout(() => {
        // Introducing a delay in user actions
        window.fcWidget.user.isExists().then((data) => {
          if (!data.data) {
            window.fcWidget.user.create(fcChatData, (resp) => {
              if (resp.status === 200) {
                processFreshChat(shop.freshchat_id, resp.data.restoreId);
              }
            });
          } else if (!shop.freshchat_id) {
            window.fcWidget.user.get().then((result) => {
              if (result.status === 200) {
                processFreshChat(shop.freshchat_id, result.data.restoreId);
              }
            });
          } else {
            window.fcWidget.user.update(fcChatData);
          }
        });
      }, 1500); // Delay set to 1.5 seconds
    }, 2000); // Delay set to 2 seconds
  };

  useEffect(() => {
    loadFreshChatWidget();
  }, []);

  useEffect(() => {
    if (widgetLoaded) {
      initFreshChat();
    }
  }, [widgetLoaded]);

  return <img src={freshChatPlaceholder} alt="FreshChat" />;
};

export default FreshChat;
