import React, { useState, useCallback } from 'react';
import {
  InlineStack,
  Text,
  Box,
  BlockStack,
  Modal,
  Link,
  TextField,
  Spinner,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function ConnectKlaviyoModal({ isModalOpen, onClose }) {
  const [t] = useTranslation();

  const [publicApiKey, setPublicApiKey] = useState('');
  const [privateApiKey, setPrivateApiKey] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);

  const handlePublicApiKeyChange = useCallback(
    (newValue) => setPublicApiKey(newValue),
    []
  );
  const handlePrivateApiKeyChange = useCallback(
    (newValue) => setPrivateApiKey(newValue),
    []
  );

  const handleConnect = () => {
    setIsConnecting(true);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={onClose}
      title={
        isConnecting
          ? t('settings.kalaviyo.setting_title_connecting')
          : t('settings.kalaviyo.setting_title_connect_to_kalaviyo_account')
      }
      primaryAction={
        !isConnecting && {
          content: t('common.connect'),
          onAction: handleConnect,
        }
      }
    >
      <Modal.Section>
        <BlockStack
          gap={'300'}
          distribution="center"
          alignment="center"
          fullWidth
        >
          {isConnecting ? (
            <>
              <BlockStack align="center" inlineAlign="center">
                <Spinner accessibilityLabel="Connecting" size="large" />
              </BlockStack>
            </>
          ) : (
            <>
              <InlineStack gap="200">
                <Text>
                  {t('settings.klaviyo.fillout_credential_description')}
                </Text>
                <Link>{t('settings.klaviyo.article_find_description')}</Link>
              </InlineStack>
              <Box width="50%">
                <TextField
                  label={t('notifications.credentials_public_api_key')}
                  value={publicApiKey}
                  onChange={handlePublicApiKeyChange}
                  autoComplete="off"
                />
              </Box>
              <Box width="50%">
                <TextField
                  label={t('notifications.credentials_private_api_key')}
                  value={privateApiKey}
                  onChange={handlePrivateApiKeyChange}
                  autoComplete="off"
                />
              </Box>
            </>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
}

export default ConnectKlaviyoModal;
