import React, { useEffect, useState, useCallback } from 'react';

import {
  Card,
  ChoiceList,
  BlockStack,
  RadioButton,
  Text,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

export default function OrderUpdateSection({
  orderStatusMeta,
  orderPageConfigObj,
  setOrderPageConfigObj,
}) {
  const [t] = useTranslation();
  const [sectionVisibilityData, setSectionVisibilityData] = useState(false);
  const [getShipmentUpdatesButton, setGetShipmentUpdatesButton] = useState([]);

  useEffect(() => {
    setSectionVisibilityData(
      orderPageConfigObj.configuration.shipment_updates_section.visible
    );
    setGetShipmentUpdatesButton(
      orderPageConfigObj.configuration.shipment_updates_section.updates_button
    );
  }, [orderPageConfigObj]);

  const handleSelectSectionVisibilityChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        shipment_updates_section: {
          ...orderPageConfigObj.configuration.shipment_updates_section,
          visible: !sectionVisibilityData,
        },
      },
      isUpdated: true,
    });
    setSectionVisibilityData((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, sectionVisibilityData]);

  const handleSelectTrackOrderChange = useCallback(
    (value) => {
      setOrderPageConfigObj({
        configuration: {
          ...orderPageConfigObj.configuration,
          shipment_updates_section: {
            ...orderPageConfigObj.configuration.shipment_updates_section,
            updates_button: value[0],
          },
        },
        isUpdated: true,
      });
      setGetShipmentUpdatesButton(value);
    },
    [orderPageConfigObj, setOrderPageConfigObj]
  );

  return (
    <Card roundedAbove="sm">
      <Text variant="headingMd" as="h2">
        {t('settings.thankyou_page.order_update_section_card.title')}
      </Text>
      <BlockStack gap="300">
        <Text variant="bodyMd" as="span" color="subdued">
          {t('settings.thankyou_page.order_update_section_card.description')}
        </Text>
        <img
          style={{ width: '100%', height: 'auto' }}
          src="https://assets.rush.app/shopifyapp/shopify-order-status-page-feature/shipment_updates_section.png"
          alt=""
        />

        <BlockStack gap="100">
          <Text variant="bodyMd" as="span">
            {t(
              'settings.thankyou_page.order_update_section_card.section_visibility.title'
            )}
          </Text>
          <BlockStack>
            <RadioButton
              label={t(
                'settings.thankyou_page.order_update_section_card.shown_option'
              )}
              checked={sectionVisibilityData}
              name="shipment_updates_section_visible"
              onChange={handleSelectSectionVisibilityChange}
              value={true}
            />
            <RadioButton
              label={t(
                'settings.thankyou_page.order_update_section_card.hidden_option'
              )}
              name="shipment_updates_section_visible"
              checked={!sectionVisibilityData}
              onChange={handleSelectSectionVisibilityChange}
              value={false}
            />
          </BlockStack>
        </BlockStack>
        <div className="small-gap-list">
          <ChoiceList
            title={t(
              'settings.thankyou_page.order_update_section_card.shipment_update_button.title'
            )}
            choices={orderStatusMeta.shipment_updates_section.updates_button.map(
              (type) => ({
                label: type.label,
                value: type.slug,
              })
            )}
            selected={getShipmentUpdatesButton}
            onChange={handleSelectTrackOrderChange}
          />
        </div>
      </BlockStack>
    </Card>
  );
}
