import React from 'react';
import { Card } from '@shopify/polaris';
import TableSkeleton from './TableSkeleton';
import TabsSkeleton from './TabsSkeleton';

function TabsTableSkeleton() {
  return (
    <Card roundedAbove="sm" padding={0}>
      <TabsSkeleton />
      <TableSkeleton />
    </Card>
  );
}

export default TabsTableSkeleton;
