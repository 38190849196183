import { isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';
import { storeApi } from './storeApi';
import { baseActions } from './baseSlice';

export const rtkMiddleware = (api) => (next) => (action) => {
  try {
    if (isRejectedWithValue(action)) {
      const error = action?.payload?.data?.errors;
      const skipError = [
        'getAccessToken',
        'applyPromoCode',
        'updateChargeLimit',
      ];
      if (!skipError.includes(action?.meta?.arg?.endpointName)) {
        api.dispatch(
          baseActions.setToastMessage({
            message:
              error?.display?.description ||
              error?.display?.title ||
              error?.message ||
              'Something went wrong!',
            type: 'error',
          })
        );
      }
    } else {
      /**
       * reset caches API's data on store change and on Lang update
       */

      if (
        action?.type === 'shop/setStoreUUID' ||
        action?.meta?.arg?.endpointName === 'updateUserLocale'
      ) {
        api.dispatch(storeApi.util.resetApiState());
        if (action?.type === 'shop/setStoreUUID') {
          api.dispatch(baseActions.setSkipPermissions(false));
        }
      }

      const successToastEndpoints = [
        'updateUserDetails',
        'resendCode',
        'verifyCodeUser',
        'postPromoCharges',
        'forgotPassword',
        'resendVerificationCode',
        'verifyCode',
        'updateEmail',
        'addTrackingPage',
        'uninstallTemplate',
        'cloneTemplate',
        'updateFullfilmentNotification',
        'sendTestNotification',
        'exportShipments3',
        'sendKlaviyoTestNotification',
        'reTrackShipmentNew',
        'deleteShipmentNew',
        'markAsDeliveredNew',
        'reProcessShipments3',
      ];
      if (
        isFulfilled(action) &&
        successToastEndpoints.includes(action.meta?.arg?.endpointName) &&
        action?.payload?.message
      ) {
        api.dispatch(
          baseActions.setToastMessage({
            message: action?.payload?.message,
            type: 'success',
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
  return next(action);
};
