export const VISITOR_URLS = {
  LOGIN: '/v/login',
  REGISTER: '/v/register',
  REGISTER_BY_INVITE: '/v/register-by-invite',
  RECOVER: '/v/recover',
  RECOVER_SUCCESS: '/v/recover-success',
  RECOVER_PASSWORD: '/v/recover-password',
  EMAIL_VALIDATION: '/v/email-validation',
  CHANGE_EMAIL: '/v/change-email',
};
export const PERMISSION_URLS = {
  APPROVE: '/permissions/approve',
  RECONNECT: '/permissions/reconnect',
};
export const STORE_URLS = {
  SMART_EDD: '/addons/smart-estimated-delivery-date',
  ONBOARDING: '/onboarding',
  DASHBOARD: '/dashboard',
  SHIPMENTS: '/shipments',
  SHIPMENTS3: '/shipments3',
  SHIPMENTS_BLACKLISTING: '/shipments/blacklisting',
  SHIPMENTS_PRE_SHIPMENT_TIMELINE: '/shipments/pre-shipment-timeline',
  SHIPMENTS_CONFIGURATIONS: '/shipments/configuration',
  TRACKING_PAGES: '/tracking-pages',
  TRACKING_PAGE: '/tracking-page',
  TRACKING_PAGE_MODERN: '/tracking-page/modern',
  TRACKING_PAGE_SOURCE_CODE: '/tracking-page/source-code',
  BILLING: '/billing',
  CARRIER_MASKING: '/shipments/carrier-masking',

  NOTIFICATIONS: '/notifications',
  EMAIL_NOTIFICATION: '/notifications/transactional-emails-legacy',
  EMAIL_NOTIFICATION_RULE: '/notifications/transactional-emails-legacy-rule',
  KLAVIYO: '/notifications/klaviyo-legacy',
  KLAVIYO_INTEGRATION: '/notifications/klaviyo',
  YOTPO_SMS: '/notifications/yotpo-sms-and-email',
  OMNISEND: '/notifications/omnisend-legacy',
  FULLFILMENT_EVENTS: '/notifications/shopify-fulfilment-events',
  NOTIFICATIONS_ACTIVITY_FEED: '/notifications/activity-feed',
  CONVERSIONS: '/conversions',
  ADDONS: '/addons',
  PRODUCT_REVIEWS: '/addons/product-reviews',
  THANK_YOU: '/addons/thankyou',
  SMS_NOTIFICATION: '/addons/smsNotification',
  SMS_NOTIFICATION_RULE: '/addons/SMSNotificationRule',
  UPSELLS: '/addons/upsells',
  MERCHANT_NOTE: '/addons/merchant-note',
  SHIP_BOB_CONFIG: '/addons/shipbob-config',
  PERKS: '/perks',
};

export const USER_URLS = {
  DETAILS: '/u/details',
  QUESTIONNAIRE: '/u/questionnaire',
  ADD_STORE: '/u/add-store',
  STORES_LIST: '/s',
  MEMBERS_AND_PERMISSIONS: '/u/members-and-permissions',
};

export const AUTH_URLS = {
  SHOPIFY_AUTH: '/shopify-login-auth',
  RUSH_AUTH: '/rush-auth',
  RUSH_EXIT_IFRAME: '/rush-exitiframe',
  RUSH_APPROVAL: '/rush-approval',
  RUSH_SPLASH: '/rush-splash',
  SHOPIFY_EXTENSIONS: '/shopify/extensions/admin-link',
};
