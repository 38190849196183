import React from 'react';
import { Navigate } from 'react-router-dom';
import Custom from 'components/CustomRedirect';

function StoreRoute({ storeUUID, children, ...rest }) {
  if (storeUUID && rest?.location?.pathname?.includes('_current')) {
    return (
      <Custom
        to={rest?.location?.pathname?.replace('_current', storeUUID)}
        {...rest}
      />
    );
  }
  return storeUUID ? children : <Navigate to="/s" />;
}

export default StoreRoute;
