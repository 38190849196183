import {
  Card,
  InlineStack,
  Text,
  BlockStack,
  Button,
  Box,
  Divider,
} from '@shopify/polaris';
import React from 'react';
import '../styles.scss';
import RushLogo from 'images/tryrush-icon.svg';
import ArrowRightMinor from 'images/ArrowRightMinor.svg';
import { useTranslation } from 'react-i18next';

function Step1(props) {
  const { icon, name, onAddStore } = props;
  const [t] = useTranslation();

  return (
    <Card padding={0}>
      <Box width="600px" height="400px" padding={800}>
        <BlockStack gap={800} align="center" inlineAlign="center">
          <Text variant="heading2xl" as="h2" alignment="center">
            {t('install_modal.step2.title', { integration_name: name })}
          </Text>
          <InlineStack align="center" gap={600}>
            <img src={RushLogo} alt={'logo'} width={'40'} height={'40'} />
            <img src={ArrowRightMinor} />
            <img src={icon} alt={'logo'} width={'40'} height={'40'} />
          </InlineStack>
          <Box width="70%">
            <InlineStack align="center">
              <Text> {t('install_modal.step3.description')}</Text>
            </InlineStack>
          </Box>
        </BlockStack>
      </Box>
      <Divider />
      <Box padding="400">
        <InlineStack gap="200" align="end" wi>
          <Button primary onClick={onAddStore}>
            {t('common.add_store')}
          </Button>
        </InlineStack>
      </Box>
    </Card>
  );
}

export default Step1;
