import React, { useCallback, useMemo } from 'react';
import {
  ChoiceList,
  Card,
  Icon,
  Text,
  BlockStack,
  SkeletonDisplayText,
  SkeletonBodyText,
} from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';
import { useGetCarrierUsageQuery } from 'pages/Dashboard/DashboardApi';
import { DB_DATE_FORMAT_STRING } from 'Constants';
import moment from 'moment';
import TranslateLink from 'components/TranslateLink/TranslateLink';
import orderBy from 'lodash/orderBy';

function OverrideCarriersCard({
  currCarrierRule = {},
  handleChoiceChange,
  formValidations = {},
}) {
  const [t] = useTranslation();
  const carriersResponse = useAllCarriersQuery();

  const { data: allCarriers = [], isLoading: isCarriersLoading } =
    carriersResponse;

  const { data: usageCarriers = [], isLoading: isUsageCarriersLoading } =
    useGetCarrierUsageQuery({
      from: moment().subtract(30, 'days').format(DB_DATE_FORMAT_STRING),
      to: moment().format(DB_DATE_FORMAT_STRING),
    });

  const carrierOptions = useMemo(() => {
    if (!isCarriersLoading && allCarriers) {
      return allCarriers.map((c) => ({
        value: c.uuid,
        label: c.title,
      }));
    }
  }, [isCarriersLoading, allCarriers]);

  const updateSelection = useCallback(
    (selected) => {
      handleChoiceChange('target_carriers_uuid', selected);
    },
    [handleChoiceChange]
  );

  const selectedOptions = useMemo(() => {
    return currCarrierRule.target_carriers_uuid || [];
  }, [currCarrierRule.target_carriers_uuid]);

  const renderChildren = (isSelected) => {
    if (isSelected) {
      return isCarriersLoading || !currCarrierRule ? (
        <SkeletonDisplayText />
      ) : (
        <BlockStack>
          <br />
          <AutoCompleteWithTags
            limitItems={false}
            error={
              !formValidations.override_carrier && !selectedOptions?.length
            }
            errorMessage={t('carrier-maskings.override-carriers-required')}
            allowMultiple={true}
            optionList={carrierOptions || []}
            selected={selectedOptions}
            onChange={updateSelection}
            emptyState={t('shipments.settings_modal.location_default_text')}
            label={''}
            id="carrier-maskings"
            prefix={<Icon source={SearchIcon} color="inkLighter" />}
            placeholder="Search"
          />
          {isUsageCarriersLoading ? (
            <SkeletonBodyText />
          ) : usageCarriers.length ? (
            <Text variant="bodyMd" as="span" tone="subdued">
              <TranslateLink
                text={t('carrier-maskings.override-carrier-helptext', {
                  carriers: orderBy(usageCarriers, 'title')
                    ?.map((carrier) => carrier.title)
                    .join(', '),
                })}
              />
            </Text>
          ) : (
            ''
          )}
        </BlockStack>
      );
    }
  };

  return (
    <Card roundedAbove="sm">
      <BlockStack gap={'500'}>
        <Text variant="headingMd" as="h2">
          {t('carrier-maskings.override-carriers')}
        </Text>
        <div className="small-gap-list">
          <ChoiceList
            choices={[
              { label: t('carrier-maskings.all-carriers'), value: 'all' },
              {
                label: t('carrier-maskings.specific-carriers'),
                value: 'specific',
                renderChildren,
              },
            ]}
            selected={
              currCarrierRule.target_carriers_all === true
                ? ['all']
                : ['specific']
            }
            onChange={([value]) => {
              handleChoiceChange('target_carriers_all', value === 'all');
            }}
          />
        </div>
      </BlockStack>
    </Card>
  );
}

OverrideCarriersCard.propTypes = {
  currCarrierRule: PropTypes.object,
  handleChoiceChange: PropTypes.func,
};

export default OverrideCarriersCard;
