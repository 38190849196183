import React, { useState, useCallback } from 'react';
import {  TextField, FormLayout, Text,Modal } from '@shopify/polaris';
import './style.scss';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import RushModal from 'components/custom/RushModal';
export default function FAQModal({ questionAns = {}, onClose, onChange }) {
  const [t] = useTranslation();

  const [questionTextError, setQuestionTextError] = useState(null);
  const [questionText, setQuestionText] = useState(questionAns.question);
  const handleQuestionTextChange = useCallback((value) => {
    setQuestionTextError(null);
    setQuestionText(value);
  }, []);

  const [answerTextError, setAnswerTextError] = useState(null);
  const [answerText, setAnswerText] = useState(questionAns.answer);
  const handleAnswerTextChange = useCallback((value) => {
    setAnswerTextError(null);
    setAnswerText(value);
  }, []);

  const submit = () => {
    if (_.isNull(questionText) || _.isEmpty(_.trim(questionText))) {
      setQuestionTextError(
        t('settings.tracking_page.faq_modal.question_invalid_text')
      );
      return;
    }
    if (_.isNull(answerText) || _.isEmpty(_.trim(answerText))) {
      setAnswerTextError(
        t('settings.tracking_page.faq_modal.answer_invalid_text')
      );
      return;
    }
    onChange({ question: questionText, answer: answerText });
  };

  return (
    <RushModal
      open={true}
      onClose={onClose}
      title={t('settings.tracking_page.faq_modal.title')}
      primaryAction={{
        content: t('settings.tracking_page.faq_modal.save_btn_label'),
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: t('settings.tracking_page.faq_modal.cancel_btn_label'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <div className="FAQ-Row">
          <FormLayout>
            <TextField
              autoComplete={'off'}
              value={questionText}
              label={t(
                'settings.tracking_page.faq_modal.content.question_label'
              )}
              onChange={handleQuestionTextChange}
              error={questionTextError}
              placeholder={t(
                'settings.tracking_page.faq_modal.content.question_input_text'
              )}
              id={`input-faq-modal-question`}
            />

            <TextField
              autoComplete={'off'}
              label={t('settings.tracking_page.faq_modal.content.answer_label')}
              onChange={handleAnswerTextChange}
              value={answerText}
              error={answerTextError}
              placeholder={t(
                'settings.tracking_page.faq_modal.content.answer_input_text'
              )}
              multiline={4}
              id={`input-faq-modal-answer`}
            />
          </FormLayout>
        </div>
        <div className="FAQ-Row">
          <Text variant="bodyMd" as="span" color="subdued">
            <TranslateLink
              text={t('settings.tracking_page.faq_modal.content.helper_text')}
            />
          </Text>
        </div>
      </Modal.Section>
    </RushModal>
  );
}
