import { storeApi } from '../../redux/store/storeApi';

export const billingAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getChargeLimit: builder.query({
      query: () => {
        return {
          url: `/charge_limit?include_forecasted_usage=1`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    applyPromoCode: builder.query({
      query: (promoCode) => {
        return {
          url: `/promo/${encodeURIComponent(promoCode)}/applicable`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    postPromoCharges: builder.mutation({
      query: (promoCode) => {
        return {
          url: `/charges/promo/${promoCode}`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateChargeLimit: builder.mutation({
      query: (amount) => {
        return {
          url: `/charges/charge_limit/${amount}`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['top-messages'],
    }),
    getPlans: builder.query({
      query: () => {
        return {
          url: `/subscription_plans_with_groups`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    subscripPlan: builder.mutation({
      query: (planId) => {
        return {
          url: `charges/plan/${planId}`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['top-messages'],
    }),
  }),
});

export const {
  useLazyApplyPromoCodeQuery,
  useGetChargeLimitQuery,
  usePostPromoChargesMutation,
  useUpdateChargeLimitMutation,
  useGetPlansQuery,
  useSubscripPlanMutation,
} = billingAPIs;
