import {
  Box,
  Button,
  InlineStack,
  Icon,
  Link,
  Text,
  Tooltip,
  BlockStack,
} from '@shopify/polaris';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RenderBadge from './RenderBadge';
import { isEmpty } from 'lodash';
import UpdateCarrierModal from 'pages/Shipments3/components/UpdateCarrierModal';
import {
  ExternalSmallIcon,
  ViewIcon,
  HideIcon,
} from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
import TranslateLink from 'components/TranslateLink';
import { LOCAL_STORAGE } from 'Constants';

function Carriers({
  shipments = [],
  statuses,
  refetchWithDelay,
  toggleHiddenCarrier,
  hiddenCarrierDetails = [],
}) {
  const [t] = useTranslation();
  const [showCarrierModal, setShowCarrierModal] = useState(false);
  const [shipmentToUpdate, setShipmentToUpdate] = useState();

  const renderMileCarrier = (index) => {
    if (shipments.length === 1) {
      return '';
    } else if (index === 0) {
      return (
        <Text variant="bodySm" tone="subdued">
          {'First mile carrier'}
        </Text>
      );
    } else if (index === shipments.length - 1) {
      return (
        <ExternalLink
          url="https://support.rush.app/last-mile-carrier"
          target={'_blank'}
        >
          <Text variant="bodySm" tone="subdued">
            {'Last mile carrier'}
          </Text>
        </ExternalLink>
      );
    } else {
      return (
        <Text variant="bodySm" tone="subdued">
          {'Next mile carrier'}
        </Text>
      );
    }
  };

  const hasAnyCarrierInfo = (shipments = []) => {
    return shipments.some((shipment) => !isEmpty(shipment.carrier));
  };

  const renderEmptyState = () => {
    return (
      <BlockStack>
        <Box padding="400">
          <TranslateLink text={t('shipment_details.no_carrier_message')} />
        </Box>
        <Box padding="400">
          <InlineStack align="end">
            <Button
              onClick={() => {
                setShipmentToUpdate(shipments?.[0]);
                setShowCarrierModal(true);
              }}
              variant="primary"
            >
              {t('shipment_detail.change_carrier.carrier_search_input_label')}
            </Button>
          </InlineStack>
        </Box>
      </BlockStack>
    );
  };

  const renderEditButton = (shipment, index) => {
    const editButton = (
      <Button
        plain
        onClick={() => {
          setShipmentToUpdate(shipment);
          setShowCarrierModal(true);
        }}
      >
        {t('common.edit')}
      </Button>
    );
    let mileCarrier = 'next mile carrier';
    if (index === 0) {
      mileCarrier = 'first mile carrier';
    } else if (index === shipments.length - 1) {
      mileCarrier = 'last mile carrier';
    }
    const storageKey = `${LOCAL_STORAGE.SHIPMENT_DETAILS_CARRIER_HIDDEN}_${index + 1
      }`;
    const isHidden = hiddenCarrierDetails.includes(storageKey);
    const showHide = isHidden ? 'Show' : 'Hide';
    const icon = isHidden ? HideIcon : ViewIcon;

    const tooltipContent = `${showHide} ${mileCarrier}`;
    const showHideIcon = shipments?.length > 1 && (
      <Tooltip content={tooltipContent}>
        <i
          style={{ cursor: 'pointer' }}
          onClick={() => toggleHiddenCarrier(storageKey, !isHidden)}
        >
          <Icon source={icon} color={isHidden ? 'subdued' : 'interactive'} />
        </i>
      </Tooltip>
    );

    return (
      <InlineStack gap="100" blockAlign="center">
        {showHideIcon}
        {editButton}
      </InlineStack>
    );
  };

  return (
    <>
      {!hasAnyCarrierInfo(shipments) ? (
        renderEmptyState()
      ) : shipments.length ? (
        <>
          <Box padding="400">
            <BlockStack gap="400">
              {shipments.map((shipment = {}, index) => {
                const {
                  carrier = {},
                  uuid,
                  status_uuid,
                  sub_status_uuid,
                } = shipment;
                return isEmpty(carrier) ? null : (
                  <InlineStack align="space-between" key={uuid}>
                    <Box maxWidth="calc(100% - 50px)">
                      <InlineStack
                        gap="200"
                        align="start"
                        blockAlign="center"
                      >
                        <img
                          width="35px"
                          src={carrier.logo_svg}
                          alt={carrier.title}
                        />
                        <BlockStack gap="100">
                          <InlineStack gap="200">
                            <Text>{carrier.title}</Text>
                            {shipment.tracking_link ? (
                              <InlineStack>
                                <Link
                                  target="_blank"
                                  url={shipment.tracking_link}
                                >
                                  {shipment.tracking_number}
                                </Link>
                                <Icon
                                  source={ExternalSmallIcon}
                                  color={'interactive'}
                                />
                              </InlineStack>
                            ) : (
                              <Text>{shipment.tracking_number}</Text>
                            )}
                            <RenderBadge
                              status_uuid={status_uuid}
                              statuses={statuses}
                            />
                            <RenderBadge
                              status_uuid={status_uuid}
                              sub_status_uuid={sub_status_uuid}
                              statuses={statuses}
                            />
                          </InlineStack>
                          {renderMileCarrier(index)}
                        </BlockStack>
                      </InlineStack>
                    </Box>
                    {renderEditButton(shipment, index)}
                  </InlineStack>
                );
              })}
            </BlockStack>
          </Box>
        </>
      ) : null}
      {showCarrierModal && (
        <UpdateCarrierModal
          initializer={'shipment_detail'}
          shipmentTrackNumber={shipmentToUpdate?.tracking_number}
          shipmentCarrier={shipmentToUpdate?.carrier?.slug}
          shipmentIds={[shipmentToUpdate.uuid]}
          onClose={({ isUpdated } = {}) => {
            setShowCarrierModal(false);
            if (isUpdated) {
              refetchWithDelay();
            }
          }}
        />
      )}
    </>
  );
}

export default Carriers;
