import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newModernPageStyle: 'light',
  newModernPageTitle: '',
  newSourceCodePageTitle: '',
};

const trackingPageSlice = createSlice({
  name: 'trackingPage',
  initialState,
  reducers: {
    setNewModernPageStyle(state, action) {
      state.newModernPageStyle = action.payload;
    },
    setNewModernPageTitle(state, action) {
      state.newModernPageTitle = action.payload;
    },
    setSourceCodePageTitle(state, action) {
      state.newSourceCodePageTitle = action.payload;
    },
  },
});

// Actions
export const trackingPageActions = trackingPageSlice.actions;

// Selectors
export const NewModernPageStyle = (state) =>
  state.trackingPage.newModernPageStyle;
export const NewModernPageTitle = (state) =>
  state.trackingPage.newModernPageTitle;
export const NewSourceCodePageTitle = (state) =>
  state.trackingPage.newSourceCodePageTitle;

// Reducer
const tpSlice = trackingPageSlice.reducer;
export default tpSlice;
