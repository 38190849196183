import React, { useEffect, useState } from 'react';
import { Card } from '@shopify/polaris';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetPreShipmentTimelinesQuery } from '../preShipmentTimelineApis';
import PreShipmentDataTable from './PreShipmentDataTable';
import SmallTopMessage from 'components/SmallTopMessage';
import { useTranslation } from 'react-i18next';
import Subtitle from 'components/Subtitle';
import UpgradePlanBanner from '../../../components/UpgradePlanBanner';
import FeaturePageWrapper from 'components/FeaturePageWrapper';
import { FEATURES_SLUG } from 'Constants';
import './styles.scss';
import WithoutChecksTableSkeleton from 'components/Skeletons/WithoutChecksTableSkeleton';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';
import { STORE_URLS } from 'config/urls';
import useIsEmbededApp from 'hooks/useIsEmbededApp';

function PreShipmentTimeline({ isFeatureAvailable }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const isEmbededApp = useIsEmbededApp();
  const { data, isLoading, isFetching } = useGetPreShipmentTimelinesQuery();
  const [preShipmentTimelines, setPreShipmentTimelines] = useState([]);

  useEffect(() => {
    data && setPreShipmentTimelines(data);
  }, [data]);

  const handleAddPreShipmentTimeline = () => {
    navigate(location.pathname + '/new');
  };
  return (
    <>
      <SmallTopMessage />
      <RushPage
        fullWidth
        title={t('pre-shipment.page-title')}
        primaryAction={{
          content: t('pre-shipment.page-action'),
          onAction: handleAddPreShipmentTimeline,
          disabled: !isFeatureAvailable,
        }}
        backAction={
          isEmbededApp
            ? {
              url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
            }
            : undefined
        }
        subtitle={
          <Subtitle
            text={t('pre-shipment.sub-title')}
            actions={[
              {
                text: t('common.get-started'),
                source: t('pre-shipment.get-started-link'),
              },
              {
                text: t('common.watch-video'),
                source: t('pre-shipment.for-dropshippers-link'),
              },
            ]}
          />
        }
      >
        {isLoading ? (
          <Card roundedAbove="sm" padding={0}>
            <WithoutChecksTableSkeleton />
          </Card>
        ) : (
          <>
            {' '}
            {!isFeatureAvailable ? (
              <>
                <UpgradePlanBanner />
                <br />
              </>
            ) : (
              ''
            )}
            <Card roundedAbove="sm" padding={0}>
              <div
                className={`preShipmentTimelines-main ${!isFeatureAvailable ? 'disabled-table' : ''
                  }`}
              >
                <PreShipmentDataTable
                  preShipmentTimelines={preShipmentTimelines}
                  setPreShipmentTimelines={setPreShipmentTimelines}
                  isDisabled={!isFeatureAvailable}
                />
              </div>
              {preShipmentTimelines?.length ? <br /> : ''}
            </Card>
          </>
        )}
      </RushPage>
      {isFetching ? <RushLoading /> : ''}
    </>
  );
}

export default FeaturePageWrapper(
  PreShipmentTimeline,
  FEATURES_SLUG.PRE_SHIPMENT
);
