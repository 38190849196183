import React from 'react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
// import LogoComplete from 'images/logo-complete.svg';
import { Link } from 'react-router-dom';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import '@shopify/polaris/build/esm/styles.css';

function AdapterLink({ url, external, ...rest }) {
  if (!external) {
    return <Link to={url} {...rest} />;
  }

  const { children, ...linkProps } = rest;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" {...linkProps}>
      {' '}
      {children}
    </a>
  );
}
// const theme = {
//   logo: {
//     width: 60,
//     topBarSource: LogoComplete,
//     url: '/s',
//     accessibilityLabel: 'Rush',
//   },
// };

export function BridgePolarisProvider({ children }) {
  const locale = new URLSearchParams(location.search).get('locale');
  if (locale && locale !== null) {
    const lowercaseLocale = locale?.split('-')[0];
    ClientStorage.set(LOCAL_STORAGE.LANGUAGE, lowercaseLocale);
  }
  return (
    <AppProvider i18n={translations} linkComponent={AdapterLink} theme={'light'}>
      {children}
    </AppProvider>
  );
}

export function PolarisProvider({ children }) {
  const isEmbededApp = useIsEmbededApp();
  return isEmbededApp ? (
    <BridgePolarisProvider>{children}</BridgePolarisProvider>
  ) : (
    <AppProvider i18n={translations} linkComponent={AdapterLink} theme={'light'}>
      {children}
    </AppProvider>
  );
}
