import orderBy from 'lodash/orderBy';
import { baseApi } from './baseApi';

const commonBaseAPIs = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStoreStatistics: builder.query({
      query: (storeUUID) => {
        return {
          url: `/stores/${storeUUID}?include=statistics`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getJobStatus: builder.query({
      query: (jobId) => {
        return {
          url: `/stores/public/job-status/${jobId}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getJobStatusByLink: builder.query({
      query: (link) => {
        return {
          baseUrl: link,
          url: ``,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    allCarriers: builder.query({
      query: () => {
        return {
          url: `/carriers`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getHealth: builder.query({
      query: () => {
        return {
          url: `/status/health`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getUserLanguages: builder.query({
      query: () => {
        return {
          url: `/products/rush-fe-app/languages`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return orderBy(response?.data, 'label_local');
        } else {
          return [];
        }
      },
    }),
    getLanguageList: builder.query({
      query: () => {
        return {
          url: `/products/tracking-page/languages`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return orderBy(response?.data, 'label_local');
        } else {
          return [];
        }
      },
    }),
    getUserDetails: builder.query({
      query: () => {
        return {
          url: `/user/me`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['userinfo'],
    }),
    getOrganizations: builder.query({
      query: () => {
        return {
          url: `/user/me/organizations`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getChatIdentifier: builder.query({
      query: () => {
        return {
          url: `/user/me/chat-identifier`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getChannels: builder.query({
      query: () => {
        return {
          url: `/channels`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getTriggers: builder.query({
      query: () => {
        return {
          url: `/triggers`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateUserLocale: builder.mutation({
      query: (params) => {
        return {
          url: `/user/me`,
          method: 'PATCH',
          body: { ...params },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getShipmentStatuses: builder.query({
      query: () => {
        return {
          url: '/shipment/statuses',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return response?.data.reduce((prev, next) => {
            const status = {
              ...next,
              id: next.slug,
              label: next.title,
              substatuses: next.sub_statuses
                ? [...next.sub_statuses].map((sub) => ({
                    ...sub,
                    id: sub.slug,
                    label: sub.title,
                  }))
                : undefined,
            };
            prev.push(status);
            return prev;
          }, []);
        } else {
          return [];
        }
      },
    }),
  }),
});

export const {
  useGetShipmentStatusesQuery,
  useAllCarriersQuery,
  useGetHealthQuery,
  useGetUserLanguagesQuery,
  useGetLanguageListQuery,
  useGetUserDetailsQuery,
  useGetOrganizationsQuery,
  useGetChatIdentifierQuery,
  useUpdateUserLocaleMutation,
  useGetJobStatusQuery,
  useGetStoreStatisticsQuery,
  useGetChannelsQuery,
  useGetTriggersQuery,
  useGetJobStatusByLinkQuery,
} = commonBaseAPIs;
