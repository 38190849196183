import React from 'react';
import { Form, FormLayout, TextField, Modal } from '@shopify/polaris';
import RushModal from 'components/custom/RushModal';
import RushBanner from 'components/custom/RushBanner';
export default function EmailUpdateModal(props) {
  return (
    <RushModal
      open={props.showEmailUpdateModal}
      onClose={props.handleEmailUpdateModal}
      title="Update email address and name"
      primaryAction={{ content: 'Save' }}
      secondaryActions={{ content: 'Cancel' }}
    >
      <Modal.Section>
        <RushBanner bannerKey={'email-use-banner'} title="" status="info">
          <p>Email and name details will be used as sender to reply to.</p>
        </RushBanner>
        <br />
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                autoComplete={'off'}
                label="Name"
                type="text"
                value=""
                id={`input-email-update-modal-name`}
              />
              <TextField
                autoComplete={'off'}
                label="Email"
                type="text"
                value=""
                id={`input-email-update-modal-email`}
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Modal.Section>
    </RushModal>
  );
}
