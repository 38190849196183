import React from 'react';
import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  BlockStack,
  SkeletonDisplayText,
  Box,
} from '@shopify/polaris';

function SkeletonMessageTimelinePage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card roundedAbove="sm">
            <BlockStack gap={'400'}>
              <SkeletonDisplayText size="small" />
              <Box>
                <SkeletonBodyText lines={5} />
              </Box>
            </BlockStack>
            <br />
            <BlockStack gap={'400'}>
              <SkeletonDisplayText size="small" />
              <Box>
                <SkeletonBodyText />
              </Box>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card roundedAbove="sm">
            <Box>
              <BlockStack gap={'400'}>
                <SkeletonDisplayText size="small" />
                <Box>
                  <SkeletonBodyText lines={4} />
                </Box>
              </BlockStack>
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
export default SkeletonMessageTimelinePage;
