import React from 'react';
import {
  SkeletonThumbnail,
  SkeletonBodyText,
  Divider,
  Box,
  InlineStack,
} from '@shopify/polaris';

const TableSkeleton = () => {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < 20; i++) {
      skeletons.push(
        <React.Fragment key={i}>
          <Divider />
          <Box padding="200">
            <div className="skeleton-tbl">
              <InlineStack align="space-between" blockAlign="center">
                <SkeletonThumbnail size="extraSmall" />
                <SkeletonBodyText lines={1} />
                <SkeletonBodyText lines={1} />
                <SkeletonBodyText lines={1} />
                <SkeletonBodyText lines={1} />
                <SkeletonBodyText lines={1} />
              </InlineStack>
            </div>
          </Box>
        </React.Fragment>
      );
    }
    return skeletons;
  };

  return <>{renderSkeletons()}</>;
};

export default TableSkeleton;
