function useGetEmbededHost() {
  const host =
    new URLSearchParams(location.search).get('host') ||
    window.__SHOPIFY_DEV_HOST;
  /**
   * if we will not put it in windows on every route change as host will not be found, it will goto login app
   */
  window.__SHOPIFY_DEV_HOST = host;

  return host;
}

export default useGetEmbededHost;
