import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import DefaultPage from './DefaultPage';

function mapStateToProps(state) {
  return {
    storeUUID: state.shop.storeUUID,
  };
}

const mapDispatchToProps = {
  setShipBobToaster: settingsActions.Creators.setShipBobToaster,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
