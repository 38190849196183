import { API_URL, STAGE } from 'config';
import useIsEmbededApp from './useIsEmbededApp';
import useAdminLink from './useAdminLink';
import { ENV_STAGING } from 'Constants';

function useIntegrationCallbackURL({
  storeUUID,
  integrationURL,
  redirectPath,
}) {
  const isEmbededApp = useIsEmbededApp();

  const adminLink = useAdminLink();
  const rush_app = STAGE === ENV_STAGING ? 'rush-stage' : 'rush-app';

  const embeddedURL = `${adminLink}/apps/${rush_app}${location.pathname}`;
  const standaloneURL = window.location?.href;
  let redirection = isEmbededApp ? embeddedURL : standaloneURL;
  if (redirectPath) {
    redirection = redirectPath;
  }

  return `${API_URL}${integrationURL}?store_uuid=${storeUUID}&redirection=${redirection}`;
}

export default useIntegrationCallbackURL;
