import React, { useEffect, useState, useCallback } from 'react';
import {
  Layout,
  Card,
  RadioButton,
  TextField,
  BlockStack,
  Text,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import RushBanner from 'components/custom/RushBanner';

export default function RealTimeStatusSection({
  orderPageConfigObj,
  setOrderPageConfigObj,
}) {
  const [t] = useTranslation();
  const [showWarning, setShowWarning] = useState(true);
  const dissmissBanner = useCallback(() => {
    setShowWarning(!showWarning);
  }, [showWarning]);

  const [sectionVisibilityData, setSectionVisibilityData] = useState(false);
  const [showEstimatedDelivery, setShowEstimatedDelivery] = useState(false);
  const [sectionOrder, setSectionOrder] = useState(1);

  useEffect(() => {
    setSectionVisibilityData(
      orderPageConfigObj.configuration.realtime_section.visible
    );
    setShowEstimatedDelivery(
      orderPageConfigObj.configuration.realtime_section.estimated_delivery_dates
    );
    setSectionOrder(orderPageConfigObj.configuration.realtime_section.order);
  }, [orderPageConfigObj]);

  const handleSelectSectionVisibilityChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        realtime_section: {
          ...orderPageConfigObj.configuration.realtime_section,
          visible: !sectionVisibilityData,
        },
      },
      isUpdated: true,
    });
    setSectionVisibilityData((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, sectionVisibilityData]);
  const handleShowEstimatedDeliveryChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        realtime_section: {
          ...orderPageConfigObj.configuration.realtime_section,
          estimated_delivery_dates: !showEstimatedDelivery,
        },
      },
      isUpdated: true,
    });
    setShowEstimatedDelivery((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, showEstimatedDelivery]);

  const handleChange = useCallback(
    (value) => {
      setOrderPageConfigObj({
        configuration: {
          ...orderPageConfigObj.configuration,
          realtime_section: {
            ...orderPageConfigObj.configuration.realtime_section,
            order: parseInt(value),
          },
        },
        isUpdated: true,
      });
      setSectionOrder(value);
    },
    [orderPageConfigObj, setOrderPageConfigObj]
  );

  return (
    <Layout.AnnotatedSection
      title={t('settings.thankyou_page.realtime.section.title')}
      description={t('settings.thankyou_page.realtime.section.description')}
    >
      <Card roundedAbove="sm">
        <Text variant="headingMd" as="h2">
          {t('settings.thankyou_page.realtime.card.title')}
        </Text>
        <BlockStack gap="300">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('settings.thankyou_page.realtime.card.description')}
          </Text>
          <img
            style={{ width: '100%', height: 'auto' }}
            src="https://assets.rush.app/shopifyapp/shopify-order-status-page-feature/realtime_section.png"
            alt=""
          />

          <BlockStack gap="100">
            <Text variant="bodyMd" as="span">
              {t('settings.thankyou_page.realtime.section_visibility.title')}
            </Text>
            <BlockStack>
              <RadioButton
                label={t('settings.thankyou_page.realtime.shown_option')}
                checked={sectionVisibilityData}
                name="realtime_section_visible"
                onChange={handleSelectSectionVisibilityChange}
                value={true}
              />
              <RadioButton
                label={t('settings.thankyou_page.realtime.hidden_option')}
                name="realtime_section_visible"
                checked={!sectionVisibilityData}
                onChange={handleSelectSectionVisibilityChange}
                value={false}
              />
            </BlockStack>
          </BlockStack>
          {showWarning &&
            orderPageConfigObj.configuration
              .estimated_delivery_dates_feature_warning_message && (
              <RushBanner
                bannerKey={'thankyou-page-banner'}
                title=""
                status="warning"
                onDismiss={dissmissBanner}
              >
                <Text variant="bodyMd" as="span">
                  <TranslateLink
                    text={
                      orderPageConfigObj.configuration
                        .estimated_delivery_dates_feature_warning_message
                    }
                  />
                </Text>
              </RushBanner>
            )}
          <BlockStack gap="100">
            <Text variant="bodyMd" as="span">
              {t('settings.thankyou_page.realtime.estimated_days.title')}
            </Text>
            <BlockStack>
              <RadioButton
                label={t('settings.thankyou_page.realtime.shown_option')}
                checked={showEstimatedDelivery}
                name="estimated_delivery_dates_visible"
                onChange={handleShowEstimatedDeliveryChange}
                value={true}
              />
              <RadioButton
                label={t('settings.thankyou_page.realtime.hidden_option')}
                name="estimated_delivery_dates_visible"
                checked={!showEstimatedDelivery}
                onChange={handleShowEstimatedDeliveryChange}
                value={false}
              />
            </BlockStack>
          </BlockStack>

          <div className="ContactCard-TextField">
            <TextField
              autoComplete={'off'}
              type="number"
              label={t('settings.thankyou_page.realtime.order.title')}
              value={`${sectionOrder !== 0 ? sectionOrder : 1}`}
              onChange={handleChange}
              min={1}
            />
          </div>
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  );
}
