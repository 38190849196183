import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  LOCAL_STORAGE,
  REDIRECT_ACTION_SHIPBOB,
  REDIRECT_ACTION_SHIPMENT_DETAIL,
} from 'Constants';
import { useTranslation } from 'react-i18next';
import { STORE_URLS } from 'config/urls';
import { ClientStorage } from 'ClientStorage';
import OrderSync from 'pages/OrderSync';

export default function LoadingPage({ storeUUID, setShipBobToaster }) {
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);
  const [syncOrderCase, setSyncOrderCase] = useState(false);
  const [seeTrackingNumber, setSeeTrackingNumber] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (!storeUUID) {
      navigate('/s?isRedirected=true');
      return;
    }

    switch (searchParams.get('action')) {
      case REDIRECT_ACTION_SHIPBOB:
        setShipBobToaster(t(searchParams.get('message_id')));
        setRedirect(STORE_URLS.SHIP_BOB_CONFIG);
        return;
      case REDIRECT_ACTION_SHIPMENT_DETAIL:
        setRedirect(
          `${STORE_URLS.SHIPMENTS}/${searchParams.get('shipmentId')}`
        );
        navigate({ search: '', replace: true });

        return;
      case 'shipments_view':
        setSeeTrackingNumber(true);
        return;
      case 'order_sync':
        setSyncOrderCase(true);
        return;
      default:
        break;
    }
    try {
      if (ClientStorage?.get(LOCAL_STORAGE.SAW_ONBOARDING) !== true) {
        setRedirect(STORE_URLS.ONBOARDING);
        return;
      }
    } catch {
      setRedirect(STORE_URLS.ONBOARDING);
      return;
    }

    setRedirect(STORE_URLS.DASHBOARD);
  }, [searchParams]);

  if (redirect && !syncOrderCase) {
    return navigate(`/s/${storeUUID}${redirect}`);
  }

  return (
    (syncOrderCase || seeTrackingNumber) && (
      <OrderSync
        externalId={searchParams.get('id')}
        isSyncCase={syncOrderCase}
      />
    )
  );
}
