import {
  Box,
  InlineStack,
  Icon,
  Text,
  BlockStack,
} from '@shopify/polaris';
import React from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { ExternalSmallIcon } from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
import { isEmpty } from 'lodash';

function OrderInformation({ order = {} }) {
  const [t] = useTranslation();
  const formatDate = (date) => moment(date).format('ll');

  return isEmpty(order) ? (
    ''
  ) : (
    <Box padding="400">
      <BlockStack gap="400">
        <Text variant="headingMd">
          {t('shipment_details.order_information')}
        </Text>
        <BlockStack gap="100">
          {!!order.created_at && (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.created_at')}</Text>
              <Text>{formatDate(order.created_at)}</Text>
            </InlineStack>
          )}
          {!!order.fulfilled_at_date && (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.Fulfilled_at')}</Text>
              <Text>{formatDate(order.fulfilled_at_date)}</Text>
            </InlineStack>
          )}
          {!!order.label && (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.number')}</Text>
              <InlineStack align="space-between">
                <ExternalLink
                  external={false}
                  target="_blank"
                  url={order.order_admin_link}
                >
                  {order.label}
                </ExternalLink>
                <Icon source={ExternalSmallIcon} color={'interactive'} />
              </InlineStack>
            </InlineStack>
          )}
          {!!order.order_customer_link && (
            <InlineStack align="space-between">
              <Text>{t('shipment_details.order_status_page')}</Text>
              <InlineStack>
                <ExternalLink
                  external={true}
                  target="_blank"
                  url={order.order_customer_link}
                >
                  Link
                </ExternalLink>
                <Icon source={ExternalSmallIcon} color={'interactive'} />
              </InlineStack>
            </InlineStack>
          )}
          <InlineStack align="space-between">
            <Text>{t('shipment_details.total_value')}</Text>
            <Text>{order.order_total_price}</Text>
          </InlineStack>
        </BlockStack>
      </BlockStack >
    </Box >
  );
}
export default OrderInformation;
