import React, { useMemo } from 'react';
import withActionsHOC from './withActionsHOC';
import { Button, Spinner } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useGetPagesConfigQuery } from 'pages/TrackingPageNew/newTrackingPageApis';

function PublishButton(props) {
  const [t] = useTranslation();

  const { publishPage, isPublishing } = props;

  const pagesConfigRes = useGetPagesConfigQuery();
  const {
    data: pagesConfig = {},
    isFetching: isConfigLoading,
    isSuccess: isConfigLoaded,
  } = pagesConfigRes;

  const showPublishButton = useMemo(() => {
    if (!isConfigLoading && isConfigLoaded) {
      return pagesConfig?.instance?.uuid !== props.uuid;
    } else {
      return false;
    }
  }, [isConfigLoading, isConfigLoaded, props.uuid]);

  return (
    <>
      {isConfigLoading ? <Spinner size="small" /> : ''}
      {showPublishButton ? (
        <Button loading={isPublishing} onClick={publishPage} primary>
          {t('tracking_page_new.publish')}
        </Button>
      ) : (
        ''
      )}
    </>
  );
}

export default withActionsHOC(PublishButton);
