import React, { useState } from 'react';
import {
  Card,
  Text,
  Box,
  InlineStack,
  Button,
  Icon,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { ExternalSmallIcon } from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
import DeleteKlaviyoConfigModal from './DeleteKlaviyoConformModal';
function KlaviyoAccount({ onRemove, isLoading }) {
  const [t] = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleRemoveClick = () => {
    setModalOpen(true);
  };

  const handleConfirmRemove = () => {
    onRemove();
    setModalOpen(false);
  };

  const handleCancelRemove = () => {
    setModalOpen(false);
  };

  return (
    <Card padding={0}>
      <Box padding={'500'} borderBlockEndWidth="100" borderColor="border-brand">
        <InlineStack align="space-between" blockAlign="center">
          <ExternalLink
            key={'klaviyo-account'}
            external={true}
            url={t('notifications.klaviyo_account_link')}
          >
            <InlineStack gap="100" blockAlign="center">
              <Text fontWeight="semibold">
                {t('common.integration_account', { integration: 'Klaviyo' })}
              </Text>
              <Box>
                <Icon source={ExternalSmallIcon} color="interactive" />
              </Box>
            </InlineStack>
          </ExternalLink>
          <Button
            plain
            destructive
            onClick={handleRemoveClick}
            loading={isLoading}
          >
            {t('common.remove')}
          </Button>
        </InlineStack>
      </Box>

      <DeleteKlaviyoConfigModal
        isOpen={isModalOpen}
        onClose={handleCancelRemove}
        onDelete={handleConfirmRemove}
      />
    </Card>
  );
}

export default KlaviyoAccount;
