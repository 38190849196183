import React from 'react';
import { SHIPMENT_ACTION_DELETE } from 'Constants';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import RushModal from 'components/custom/RushModal';
import { Modal } from '@shopify/polaris';
export default function ActionModal({
  onAction,
  onClose,
  modalAction,
  shipmentTrackNumber,
}) {
  const [t] = useTranslation();
  const text =
    modalAction === SHIPMENT_ACTION_DELETE
      ? t('shipment_detail.modal.delete.modal_text', {
          shipment_cypher: shipmentTrackNumber,
        })
      : t('shipment_detail.modal.mark_as_delivered.modal_text', {
          shipment_cypher: shipmentTrackNumber,
        });
  return (
    <RushModal
      open={true}
      onClose={onClose}
      title={
        modalAction === SHIPMENT_ACTION_DELETE
          ? t('shipment_detail.modal.header.delete.title')
          : t('shipment_detail.modal.header.mark_as_delivered.title')
      }
      primaryAction={{
        content:
          modalAction === SHIPMENT_ACTION_DELETE
            ? t('shipment_detail.actions.delete')
            : t('shipment_detail.actions.mark_as_delivered'),
        destructive: modalAction === SHIPMENT_ACTION_DELETE,
        onAction: onAction,
      }}
      secondaryActions={[
        {
          content: t('shipment_detail.modal.button.cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TranslateLink text={text} />
      </Modal.Section>
    </RushModal>
  );
}
