import React from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

import { FEATURE_KLAVIYO_PAGE } from 'Constants';
import CustomToggleSettings from 'components/CustomToggleSettings';

export default function KlaviyoPageCustomization({
  deactivateFeature,
  activateFeature,
  localKlaviyoObj,
  klaviyoConfig,
  setLocalKlaviyoObj,
  resetKlaviyo,
  resetKlaviyoConfig,
}) {
  const [t] = useTranslation();

  const { config = {} } = localKlaviyoObj;
  const { is_active = false } = config;

  const manageEnableDisable = () => {
    setLocalKlaviyoObj({
      config: {
        ...klaviyoConfig,
        is_active: !is_active,
      },
      isUpdated: false,
    });
    if (localKlaviyoObj && is_active) {
      deactivateFeature(FEATURE_KLAVIYO_PAGE);
      resetKlaviyoConfig();
    } else {
      activateFeature(FEATURE_KLAVIYO_PAGE);
      resetKlaviyo();
    }
  };

  const contentLocalizationActiveStatus = is_active
    ? t('settings.thankyou_page.customization.enable.button_on')
    : t('settings.thankyou_page.customization.enable.button_off');

  return (
    <Layout.AnnotatedSection
      title={t('settings.klaviyo.section.title')}
      description={t('settings.klaviyo.section.description')}
    >
      <CustomToggleSettings
        buttonLabel={contentLocalizationActiveStatus}
        onToggle={manageEnableDisable}
        isActive={is_active}
        content={t('settings.klaviyo.enable.section.title')}
      ></CustomToggleSettings>
    </Layout.AnnotatedSection>
  );
}
