import { ClientStorage } from 'ClientStorage';
import { VISITOR_URLS } from 'config/urls';
import { LOCAL_STORAGE } from 'Constants';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { shopActions } from 'redux/store/shopSlice';
import {
  useForgotPasswordMutation,
  useGetVisitorLanguagesQuery,
  useLoginMutation,
} from '../visitorAPIs';
import { visitorActions } from '../visitorSlice';

const VisitorHOC = (Component) => (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLang, setSelectedLang] = useState({});

  const {
    data: visitorLanguages = [],
    isLoading: isLangsLoading,
    isSuccess: isLangsLoaded,
  } = useGetVisitorLanguagesQuery();

  const [
    recoverPassword,
    { isLoading: isRecoverLoading, isSuccess: isRecoverSuccess },
  ] = useForgotPasswordMutation();

  const forgotPassword = (email) => {
    dispatch(visitorActions.setEmailToRecoverPassword(email));
    recoverPassword(email);
  };

  useEffect(() => {
    if (!isRecoverLoading && isRecoverSuccess) {
      navigate(`${VISITOR_URLS.RECOVER_SUCCESS}${location.search}`);
    }
  }, [isRecoverLoading, isRecoverSuccess]);

  const [
    login,
    { isLoading: isLoginLoading, isSuccess: isLoginSuccess, data: loginData },
  ] = useLoginMutation();

  const applyToken = (token, resetSearch) => {
    i18next.setDefaultNamespace('rush-fe-app');
    ClientStorage.set(LOCAL_STORAGE.ACCESS_TOKEN, token);
    dispatch(visitorActions.setAccessToken(token));
    dispatch(shopActions.setStoreUUID(null));
    if (resetSearch) {
      window.open(`${location.origin}/s`, '_self');
    } else {
      window.open(`${location.origin}/s${location.search}`, '_self');
    }
  };

  useEffect(() => {
    if (!isLoginLoading && isLoginSuccess) {
      applyToken(loginData.access_token);
    }
  }, [isLoginSuccess, loginData, isLoginLoading]);

  useEffect(() => {
    if (isLangsLoaded && !isLangsLoading) {
      let language = ClientStorage.get(LOCAL_STORAGE.LANGUAGE);

      if (!language) {
        language = 'en';
        ClientStorage.set(LOCAL_STORAGE.LANGUAGE, 'en');
      }

      const lang = visitorLanguages.find(
        ({ language_iso_639 }) => language_iso_639 === language
      );

      setSelectedLang(lang);
    }
  }, [isLangsLoading, isLangsLoaded]);

  const changeVisitorLanguage = (langUUID) => {
    const newLanguage =
      visitorLanguages.find(({ uuid }) => uuid === langUUID) || {};
    setSelectedLang(newLanguage);
    if (newLanguage?.language_iso_639) {
      i18next.changeLanguage(newLanguage?.language_iso_639);
      ClientStorage.set(LOCAL_STORAGE.LANGUAGE, newLanguage?.language_iso_639);
    }
  };

  return (
    <Component
      {...props}
      visitorLanguages={isLangsLoading ? null : visitorLanguages}
      isLoading={isLangsLoading || isLoginLoading || isRecoverLoading}
      selectedVisitorLanguageCode={selectedLang?.language_iso_639}
      selectedVisitorLanguage={selectedLang?.uuid}
      changeVisitorLanguage={changeVisitorLanguage}
      login={login}
      forgotPassword={forgotPassword}
      applyToken={applyToken}
    />
  );
};

export default VisitorHOC;
