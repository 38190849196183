import {
  SkeletonBodyText,
  Card,
  TextField,
  BlockStack,
  Box,
  InlineStack,
  Button,
} from '@shopify/polaris';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NewSourceCodePageTitle,
  trackingPageActions,
} from 'pages/TrackingPageNew/trackingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProxyConfigQuery } from 'pages/TrackingPageNew/newTrackingPageApis';
import { useNavigate } from 'react-router-dom';
import { STORE_URLS } from 'config/urls';
import '../styles.scss';
import RushModal from 'components/custom/RushModal';
function SourceCodeWizard({ onClose }) {
  const [showError, setShowError] = useState(false);
  const shop = useSelector((state) => state.shop);
  const title = useSelector(NewSourceCodePageTitle);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const configRes = useGetProxyConfigQuery();
  const { isLoading, data: { public_proxy_link = {} } = {} } = configRes;
  const handleAddPage = () => {
    if (title !== '') {
      navigate(`/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGE_SOURCE_CODE}`);
    } else {
      setShowError(true);
    }
  };

  const primaryAction = useMemo(() => {
    return {
      content: t('settings.os2_tracking_page.wizard.step3.add_page'),
      onAction: handleAddPage,
    };
  }, [handleAddPage]);

  const onTitleChange = (value) => {
    dispatch(trackingPageActions.setSourceCodePageTitle(value));
  };

  return (
    <RushModal
      title={t('source_code.wizard.title')}
      open={true}
      onClose={onClose}
      large
    >
      <div className="step3-container">
        <Card roundedAbove="sm">
          <>
            <Box padding="0">
              <BlockStack gap={'300'}>
                <TextField
                  autoComplete={'off'}
                  label={t(
                    'settings.os2_tracking_page.wizard.step3.name_box.title'
                  )}
                  value={title}
                  onChange={onTitleChange}
                  error={
                    showError &&
                    !title &&
                    t(
                      'settings.os2_tracking_page.wizard.step3.page_title_required'
                    )
                  }
                  autoFocus
                />
                {isLoading ? (
                  <>
                    <br />
                    <SkeletonBodyText lines={2}></SkeletonBodyText>
                  </>
                ) : (
                  <TextField
                    autoComplete={'off'}
                    label={t(
                      'settings.os2_tracking_page.wizard.step3.handle_box.title'
                    )}
                    prefix={
                      public_proxy_link.domain +
                      public_proxy_link.proxy +
                      public_proxy_link.extension
                    }
                    disabled={true}
                  />
                )}
              </BlockStack>
            </Box>
            <Box></Box>
          </>
          <br />
          <InlineStack align="end" gap={'200'}>
            <Button primary onClick={primaryAction.onAction}>
              {primaryAction.content}
            </Button>
          </InlineStack>
        </Card>
      </div>
    </RushModal>
  );
}

export default SourceCodeWizard;
