import React from 'react';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  BlockStack,
  Box,
} from '@shopify/polaris';

function SkeletonBlackListPage() {
  return (
    <SkeletonPage>
      <BlockStack gap="400">
        <Card roundedAbove="sm">
          <BlockStack gap="200">
            <Box>
              <SkeletonBodyText />
            </Box>
          </BlockStack>
          <br />
          <BlockStack gap="200">
            <Box>
              <SkeletonBodyText />
            </Box>
          </BlockStack>
        </Card>
        <Card roundedAbove="sm">
          <BlockStack gap="200">
            <Box>
              <SkeletonBodyText />
            </Box>
          </BlockStack>
          <br />
          <BlockStack gap="200">
            <Box>
              <SkeletonBodyText />
            </Box>
          </BlockStack>
        </Card>
        <Card roundedAbove="sm">
          <BlockStack gap="200">
            <Box>
              <SkeletonBodyText />
            </Box>
          </BlockStack>
          <br />
          <BlockStack gap="200">
            <Box>
              <SkeletonBodyText />
            </Box>
          </BlockStack>
        </Card>
      </BlockStack>
    </SkeletonPage>
  );
}
export default SkeletonBlackListPage;
