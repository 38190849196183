import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { privateKeyValidator, publicKeyValidator } from 'Utils/utils';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import SmallTopMessage from 'components/SmallTopMessage';
import KlaviyoDetails from './KlaviyoDetails/KlaviyoDetails';
import KlaviyoPageCustomization from './KlaviyoPageCustomization';
import KlaviyoActions from './KlaviyoActions';
import KlaviyoFlows from './KlaviyoFlows/KlaviyoFlows';
import KlaviyoFlowTable from './KlaviyoFlowTable/KlaviyoFlowTable';
import SaveBar from './SaveBar';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import SettingsToaster from '../SettingsToaster';
import { STORE_URLS } from 'config/urls';
import Subtitle from 'components/Subtitle';
import i18next from 'i18next';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';

import isNull from 'lodash/isNull';

export default function KlaviyoPage({
  showUnsavedModal,
  getKlaviyo,
  saveKlaviyo,
  loading = true,
  klaviyoConfig,
  klaviyoMeta,
  resetKlaviyo,
  klaviyoConfigSaved,
  getKlaviyoSummaryFlow,
  klaviyoFlowSummary,
  loadingFlow,
  loadingFlowError,
  loadingFeatureId,
  featureRequest,
  resetFeatureRequest, // Define resetFeatureRequest
  shop,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [localKlaviyoObj, setLocalKlaviyoObj] = useState({});
  const [publicApiKeyError, setPublicApiKeyError] = useState(null);
  const [privateApiKeyError, setPrivateApiKeyError] = useState(null);
  const [privateApiKey, setPrivateApiKey] = useState(null);
  const [publicApiKey, setPublicApiKey] = useState(null);
  const [selectedTrigger, setSelectedTrigger] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [refreshedAtDate, setRefreshedAtDate] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  const memoizedLocalKlaviyoObj = useMemo(() => {
    return {
      config: klaviyoConfig,
      meta: klaviyoMeta,
      isUpdated: false,
    };
  }, [klaviyoConfig, klaviyoMeta]);

  useEffect(() => {
    if (klaviyoConfigSaved) {
      getKlaviyo();
      privateApiKey && getKlaviyoSummaryFlow(privateApiKey);
      resetKlaviyo();
    }
  }, [klaviyoConfigSaved, resetKlaviyo, getKlaviyo, getKlaviyoSummaryFlow, privateApiKey]);

  useEffect(() => {
    resetKlaviyo();
    getKlaviyo();
  }, [getKlaviyo, resetKlaviyo, i18next.language]);

  useEffect(() => {
    if (featureRequest) {
      setIsUpdated(false);
      getKlaviyo();
      resetFeatureRequest();
      setPrivateApiKeyError(false);
      setPublicApiKeyError(false);
    }
  }, [featureRequest, getKlaviyo, resetFeatureRequest]);

  useEffect(() => {
    if (klaviyoFlowSummary) {
      const { refreshed_at = '' } = klaviyoFlowSummary;
      setRefreshedAtDate(refreshed_at);
    }
  }, [klaviyoFlowSummary]);

  useEffect(() => {
    if (!loadingFlowError && !loadingFlow && klaviyoFlowSummary === undefined && !isEmpty(privateApiKey)) {
      privateApiKey !== null && privateKeyValidator(trim(privateApiKey)) && getKlaviyoSummaryFlow(privateApiKey);
    }
  }, [loadingFlow, getKlaviyoSummaryFlow, loadingFlowError, klaviyoFlowSummary, privateApiKey, klaviyoConfigSaved]);

  useEffect(() => {
    if (klaviyoConfig?.hasOwnProperty('is_active') && !loading && !isUpdated) {
      setLocalKlaviyoObj(memoizedLocalKlaviyoObj);
    }
  }, [memoizedLocalKlaviyoObj, klaviyoConfig, loading, isUpdated]);

  const discardChanges = useCallback(() => {
    setIsUpdated(false);
    setPrivateApiKeyError(false);
    setPublicApiKeyError(false);

    setLocalKlaviyoObj(memoizedLocalKlaviyoObj);
  }, [memoizedLocalKlaviyoObj]);

  const redirectBackToPrevious = useCallback(() => {
    navigate(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
  }, [navigate, shop.storeUUID]);

  const callbackFunction = useCallback(() => {
    discardChanges();
    redirectBackToPrevious();
  }, [discardChanges, redirectBackToPrevious]);

  const redirectBack = useCallback(() => {
    resetKlaviyo();

    if (klaviyoConfigSaved) {
      showUnsavedModal(callbackFunction);
      return;
    }

    redirectBackToPrevious();
  }, [showUnsavedModal, resetKlaviyo, klaviyoConfigSaved, callbackFunction, redirectBackToPrevious]);

  useEffect(() => {
    if (!isEmpty(localKlaviyoObj.config) && localKlaviyoObj.config.triggers) {
      const { config } = localKlaviyoObj;
      const temp = config.triggers.map((trigger) => (trigger.is_active ? trigger.trigger_slug : null)).filter((item) => !isNull(item));
      setPublicApiKey(config.public_api_key);
      setPrivateApiKey(config.private_api_key);
      setSelectedLanguage(config.language);
      setSelectedTrigger(temp);
    }
  }, [klaviyoConfig, localKlaviyoObj]);

  const updateKlaviyoPayload = useCallback(() => {
    setPublicApiKeyError(null);
    setPrivateApiKeyError(null);
    setIsUpdated(true);
  }, []);

  const submit = useCallback(() => {
    if (!publicApiKey || isEmpty(trim(publicApiKey)) || !publicKeyValidator(trim(publicApiKey))) {
      setPublicApiKeyError(t('klaviyo.modal.public_api_key_error_text'));
      return;
    }
    if (!privateApiKey || isEmpty(trim(privateApiKey)) || !privateKeyValidator(trim(privateApiKey))) {
      setPrivateApiKeyError(t('klaviyo.modal.private_api_key_error_text'));
      return;
    }
    const tempTriggers = klaviyoConfig.triggers.map((trigger) => {
      if (selectedTrigger.includes(trigger.trigger_slug)) {
        return { ...trigger, is_active: true };
      } else {
        return { ...trigger, is_active: false };
      }
    });
    saveKlaviyo({
      public_api_key: trim(publicApiKey),
      private_api_key: trim(privateApiKey),
      triggers: tempTriggers,
      language: selectedLanguage,
    });
    setIsUpdated(false);
  }, [publicApiKey, privateApiKey, selectedTrigger, klaviyoConfig, selectedLanguage, saveKlaviyo, t]);

  if (!localKlaviyoObj.hasOwnProperty('config')) {
    return <LoadingLayoutPage />;
  }

  return (
    <>
      <SmallTopMessage />
      <RushPage
        backAction={{ onAction: redirectBack }}
        title={t('settings.klaviyo.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('settings.shipbob_pages.subtitle.read_more'),
                source: 'https://support.rush.app/notifications/klaviyo/integrate.html',
              },
            ]}
          />
        }
      >
        <Layout>
          <SaveBar
            isSaveBarEnabled={isUpdated}
            submitData={submit}
            discardAction={discardChanges}
          />
          {(klaviyoConfigSaved || loading || loadingFeatureId > 0) && <RushLoading />}

          <KlaviyoPageCustomization
            klaviyoConfig={klaviyoConfig}
            localKlaviyoObj={localKlaviyoObj}
            setLocalKlaviyoObj={setLocalKlaviyoObj}
            resetKlaviyo={resetKlaviyo}
          />
          {localKlaviyoObj.config?.is_active && (
            <>
              <Layout.AnnotatedSection
                title={t('settings.klaviyo.section.title')}
                description={t('settings.klaviyo.section.configure.description')}
              >
                <KlaviyoDetails
                  klaviyoConfig={klaviyoConfig}
                  klaviyoMeta={klaviyoMeta}
                  localKlaviyoObj={localKlaviyoObj}
                  setPublicApiKey={setPublicApiKey}
                  setSelectedLanguage={setSelectedLanguage}
                  setSelectedTrigger={setSelectedTrigger}
                  publicApiKeyError={publicApiKeyError}
                  publicApiKey={publicApiKey}
                  selectedLanguage={selectedLanguage}
                  selectedTrigger={selectedTrigger}
                  loading={loading}
                  klaviyoConfigSaved={klaviyoConfigSaved}
                  updateKlaviyoPayload={updateKlaviyoPayload}
                  privateApiKey={privateApiKey}
                  privateApiKeyError={privateApiKeyError}
                  setPrivateApiKey={setPrivateApiKey}
                  setPrivateApiKeyError={setPrivateApiKeyError}
                />
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={t('settings.klaviyo.actions.title')}
              >
                <KlaviyoActions
                  klaviyoConfig={klaviyoConfig}
                  klaviyoMeta={klaviyoMeta}
                  publicApiKey={publicApiKey}
                  setPublicApiKeyError={setPublicApiKeyError}
                  selectedLanguage={selectedLanguage}
                />
                <KlaviyoFlows
                  klaviyoConfig={klaviyoConfig}
                  klaviyoMeta={klaviyoMeta}
                />
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={t('settings.klaviyo.flow_summary.title')}
                description={t('settings.klaviyo.flow_summary.description')}
              >
                <KlaviyoFlowTable
                  klaviyoConfig={klaviyoConfig}
                  klaviyoMeta={klaviyoMeta}
                  klaviyoFlowSummary={klaviyoFlowSummary}
                  getKlaviyoSummaryFlow={getKlaviyoSummaryFlow}
                  loadingFlow={loadingFlow}
                  privateApiKey={privateApiKey}
                  refreshedAtDate={refreshedAtDate}
                />
              </Layout.AnnotatedSection>
            </>
          )}
        </Layout>
        <UnsavedChangesModal />
      </RushPage>
      <SettingsToaster />
    </>
  );
}
