import {
  Box,
  Card,
  Loading,
  Select,
  SkeletonBodyText,
  Text,
  TextField,
  BlockStack,
} from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import RushModal from 'components/custom/RushModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateCustomTPMutation,
  useGetCustomTPQuery,
  useUpdateCustomTPMutation,
} from '../newTrackingPageApis';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';

function CustomTPModal({ onClose }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const searchOptions = [
    {
      label: t('tracking_page_new.search_option_any_email_or_order_number'),
      value: 'any',
    },
    {
      label: t('tracking_page_new.search_option_order_number_and_email'),
      value: 'order_with_email',
    },
    {
      label: t('tracking_page_new.search_option_order_number'),
      value: 'order',
    },
    { label: t('tracking_page_new.search_option_email'), value: 'email' },
  ];

  const [values, setValues] = useState({
    title: '',
    link: '',
    search_mode: 'any',
  });

  const [showError, setShowError] = useState(false);

  const editPageUUID = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('custom_tp_uuid');
  }, [location]);

  useEffect(() => {
    if (!editPageUUID) {
      setValues({
        title: '',
        link: '',
        search_mode: 'any',
      });
    }
  }, [editPageUUID]);

  const {
    data: response = {},
    isFetching: isLoading,
    isSuccess,
    refetch,
  } = useGetCustomTPQuery(editPageUUID, {
    skip: !editPageUUID,
  });

  const [createPage, { isLoading: isPageCreating, isSuccess: isPageCreated }] =
    useCreateCustomTPMutation();
  const [updatePage, { isLoading: isPageUpdating, isSuccess: isPageUpdated }] =
    useUpdateCustomTPMutation();

  useEffect(() => {
    if (editPageUUID) {
      refetch();
    }
  }, [editPageUUID]);

  useEffect(() => {
    if (!isLoading && isSuccess && editPageUUID) {
      setValues({
        title: response.data.title,
        link: response.data.link?.replace('https://', ''),
        search_mode: response.data.search_mode,
      });
    }
  }, [isLoading, isSuccess, editPageUUID]);

  useEffect(() => {
    if (!isPageCreating && isPageCreated) {
      onClose();
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_created'),
          type: 'success',
        })
      );
    }
  }, [isPageCreated, isPageCreating]);

  useEffect(() => {
    if (!isPageUpdating && isPageUpdated) {
      onClose();

      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_updated'),
          type: 'success',
        })
      );
    }
  }, [isPageUpdated, isPageUpdating]);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleAdd = () => {
    if (values.title && values.link) {
      if (editPageUUID) {
        updatePage({
          uuid: editPageUUID,
          data: { ...values, link: `https://${values.link}` },
        });
      } else {
        createPage({ ...values, link: `https://${values.link}` });
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      {(isPageCreating || isPageUpdating || isLoading) && <Loading />}
      <RushModal
        title={t('tracking_page_new.card4_label')}
        open={true}
        onClose={onClose}
        primaryAction={{
          content: editPageUUID
            ? t('settings.os2_tracking_page.wizard.step3.update_page')
            : t('settings.os2_tracking_page.wizard.step3.add_page'),
          onAction: handleAdd,
          loading: isPageCreating || isPageUpdating,
        }}
      >
        <Card roundedAbove="sm" padding={200}>
          <Box padding={'500'} paddingBlockStart={'0'}>
            <BlockStack gap={'500'}>
              <Text as="span" color="subdued">
                <TranslateLink
                  text={t('tracking_page_new.customTP.description')}
                />
              </Text>
              {isLoading ? (
                <Box>
                  <SkeletonBodyText lines={3} />
                </Box>
              ) : (
                <>
                  <TextField
                    autoComplete={'off'}
                    label={t(
                      'settings.os2_tracking_page.wizard.step3.name_box.title'
                    )}
                    value={values.title}
                    onChange={(value) => handleChange('title', value)}
                    error={
                      showError &&
                      !values.title &&
                      t(
                        'settings.os2_tracking_page.wizard.step3.page_title_required'
                      )
                    }
                    autoFocus
                  />

                  <TextField
                    autoComplete={'off'}
                    label={t(
                      'settings.os2_tracking_page.wizard.step3.handle_box.title'
                    )}
                    value={values.link}
                    onChange={(value) =>
                      handleChange('link', value.replace('https://', ''))
                    }
                    prefix={'https://'}
                    error={
                      showError &&
                      !values.link &&
                      t(
                        'settings.os2_tracking_page.wizard.step3.page_handle_required'
                      )
                    }
                    helpText={t('tracking_page_new.customTP.where_to_redirect')}
                  />

                  <BlockStack gap={'300'}>
                    <Select
                      label={t('common.search_criteria')}
                      options={searchOptions}
                      value={values.search_mode}
                      onChange={(value) => handleChange('search_mode', value)}
                      helpText={
                        <TranslateLink
                          text={t('tracking_page_new.customTP.footer')}
                        />
                      }
                    />
                  </BlockStack>
                </>
              )}
            </BlockStack>
          </Box>
        </Card>
      </RushModal>
    </>
  );
}

export default CustomTPModal;
