import React, { useCallback, useState } from 'react';
import {
  Card,
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  FormLayout,
  Icon,
  Text,
  TextField,
  BlockStack,
  Box,
  InlineStack,
} from '@shopify/polaris';
import { DeleteIcon, ExternalSmallIcon } from '@shopify/polaris-icons';

import { useTranslation } from 'react-i18next';
import FAQModal from './FAQModal';

const FAQCard = (props) => {
  const { is_faq, faq_link, faq_type, faq_structure = [], onChange } = props;
  const [t] = useTranslation();

  const [fAQIndex, setFAQIndex] = useState(null);
  const [showFAQModal, setShowFAQModal] = useState(false);

  const title = t('tracking_page_new.faq.title');
  const description = t('tracking_page_new.faq.description');
  const enableCheckboxLabel = t('tracking_page_new.faq.enable_checkbox_label');
  const sourceTypeLabel = t('tracking_page_new.faq.source_type_label');
  const sourceTypeOptions = [
    { label: t('tracking_page_new.faq.link_option_label'), value: 'link' },
    {
      label: t('tracking_page_new.faq.content_option_label'),
      value: 'structure',
    },
  ];

  const handleToggleEnabled = (value) => {
    onChange('is_faq', value);
  };

  const handleSourceTypeChange = ([value]) => {
    onChange('faq_type', value);
  };

  const handleLinkToFAQPageChange = (value) => {
    onChange('faq_link', value);
  };

  const handleShowFAQModalChange = useCallback(() => {
    if (showFAQModal) {
      setFAQIndex(null);
    }
    setShowFAQModal(!showFAQModal);
  }, [showFAQModal]);

  const editFAQ = (index) => {
    setFAQIndex(index);
    handleShowFAQModalChange();
  };

  const deleteFAQ = (index) => {
    let tempFAQ =
      [
        ...(faq_structure || []).slice(0, index),
        ...(faq_structure || []).slice(index + 1),
      ] || [];

    onChange('faq_structure', tempFAQ);
  };

  const addFAQ = (item) => {
    let tempFAQ = [...(faq_structure || [])];
    if (fAQIndex !== null) {
      tempFAQ[fAQIndex] = item;
    } else {
      tempFAQ.push(item);
    }
    onChange('faq_structure', tempFAQ);
    handleShowFAQModalChange();
  };

  return (
    <>
      <Card roundedAbove="sm">
        <BlockStack gap="200">
          <Text as="h4" variant="headingMd">
            {title}
          </Text>
          <Text as="span" color="subdued">
            {description}
          </Text>
        </BlockStack>
        <Box paddingBlockStart={'5'}>
          <BlockStack gap={'400'}>
            <Checkbox
              checked={is_faq}
              label={enableCheckboxLabel}
              onChange={handleToggleEnabled}
            />

            {is_faq && (
              <BlockStack gap="200">
                <FormLayout>
                  <div className="small-gap-list">
                    <ChoiceList
                      title={sourceTypeLabel}
                      choices={sourceTypeOptions}
                      selected={[faq_type]}
                      onChange={handleSourceTypeChange}
                    />
                  </div>
                </FormLayout>

                {faq_type === 'link' && (
                  <Box>
                    <TextField
                      autoComplete={'off'}
                      value={faq_link}
                      label={t(
                        'settings.tracking_page.faq.card.option_link_to_another_page'
                      )}
                      onChange={handleLinkToFAQPageChange}
                      id={`input-faq-link`}
                    />
                  </Box>
                )}
                {faq_type === 'structure' && (
                  <BlockStack gap={'200'}>
                    <InlineStack gap={'500'} align="space-between">
                      <Text variant="headingSm" as="h2">
                        {t('settings.tracking_page.faq.card.content.title')}
                      </Text>
                      <Button variant="plain" onClick={handleShowFAQModalChange}>
                        {t(
                          'settings.tracking_page.faq.card.content.add_btn_label'
                        )}
                      </Button>
                    </InlineStack>
                    <Box
                      padding={'0'}
                      borderColor="border-brand"
                      borderWidth="1"
                      borderRadius="2"
                    >
                      {(faq_structure || []).length > 0 && (
                        <>
                          {faq_structure.map((faqItem, index) => (
                            <div
                              className="SearchReplace-List"
                              key={faqItem.question}
                            >
                              <div className="SearchReplace-List-Heading">
                                <Text variant="bodyMd" as="span">
                                  {faqItem.question}
                                </Text>
                              </div>
                              <di>
                                <ButtonGroup variant='segmented'>
                                  <Button
                                    size="slim"
                                    onClick={() => editFAQ(index)}
                                    icon={<Icon source={ExternalSmallIcon} />}
                                  />
                                  <Button
                                    size="slim"
                                    onClick={() => deleteFAQ(index)}
                                    icon={<Icon source={DeleteIcon} />}
                                  />
                                </ButtonGroup>
                              </di>
                            </div>
                          ))}
                        </>
                      )}
                      {(faq_structure || []).length === 0 && (
                        <Box padding={'500'}>
                          <div className="CustomEmptyState">
                            <Text variant="bodyMd" as="span" color="subdued">
                              {t(
                                'settings.tracking_page.faq.card.content.no_row_message'
                              )}
                            </Text>
                          </div>
                        </Box>
                      )}
                    </Box>
                  </BlockStack>
                )}
              </BlockStack>
            )}
          </BlockStack>
        </Box>
      </Card>
      {showFAQModal && (
        <FAQModal
          onClose={handleShowFAQModalChange}
          questionAns={faq_structure[fAQIndex]}
          onChange={addFAQ}
        />
      )}
    </>
  );
};

export default FAQCard;
