import React, { useCallback, useState } from 'react';
import { Card, Box, Divider } from '@shopify/polaris';
import moment from 'moment';
import {
  DATE_RANGE_VALUE_LAST_30_DAYS,
  DB_DATE_FORMAT_STRING,
} from 'Constants';

import RevenueStatistics from './RevenueStatistics';
import DeliveryTimes from './DeliveryTimes';
import ShipmentPerStatus from './ShipmentPerStatus';
import OrdersPerDestinations from './OrdersPerDestinations';
import CarrierUsage from './CarrierUsage';
import DatePickerButton from 'components/DatePicker/DatePickerButton';

export default function RightPanel() {
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(29, 'days')._d,
    end: moment()._d,
    selectedOption: DATE_RANGE_VALUE_LAST_30_DAYS,
  });

  const handleSelectedDate = useCallback((selectedOption, start, end) => {
    setSelectedDates({
      start: moment(start).format(DB_DATE_FORMAT_STRING),
      end: moment(end).format(DB_DATE_FORMAT_STRING),
      selectedOption,
    });
  }, []);

  return (
    <Card roundedAbove="sm" padding={0}>
      <Box padding={'500'}>
        <DatePickerButton
          onChange={handleSelectedDate}
          start={moment(selectedDates.start).format(DB_DATE_FORMAT_STRING)}
          end={moment(selectedDates.end).format(DB_DATE_FORMAT_STRING)}
          selectedOption={selectedDates.selectedOption}
          disableDates={{ end: moment().format(DB_DATE_FORMAT_STRING) }}
        />
      </Box>
      <RevenueStatistics selectedDates={selectedDates} />
      <Divider />
      <DeliveryTimes selectedDates={selectedDates} />
      <Divider />
      <ShipmentPerStatus selectedDates={selectedDates} />
      <Divider />
      <OrdersPerDestinations selectedDates={selectedDates} />
      <Divider />
      <CarrierUsage selectedDates={selectedDates} />
    </Card>
  );
}
