import { useEffect, useState } from 'react';
import { useGetKlaviyoConfigQuery, useUpdateKlaviyoConfigMutation } from 'pages/Settings/KlaviyoIntegrationPage/KlaviyoIntegrationAPI';

const useSyncKlaviyoStatus = (storeUUID) => {
  const [isActive, setIsActive] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);

  const { data = {}, isFetching, isSuccess } = useGetKlaviyoConfigQuery(undefined, {
    skip: !storeUUID,
  });
  const klaviyoConfig = data?.data || {};

  const [updateKlaviyo] = useUpdateKlaviyoConfigMutation();

  useEffect(() => {
    if (isSuccess && klaviyoConfig) {
      setIsActive(klaviyoConfig.is_active);
    }
  }, [isSuccess, klaviyoConfig]);

  const handleToggleActive = () => {
    setToggleActive(!toggleActive);
    const newStatus = !isActive;

    setIsActive(newStatus);
    updateKlaviyo({
      ...klaviyoConfig,
      is_active: newStatus,
    });
  };

  return { isActive, toggleActive: handleToggleActive, isFetching };
};

export default useSyncKlaviyoStatus;
