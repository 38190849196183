import React from 'react';
import {
  Text,
  Card,
  Select,
  SkeletonBodyText,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function SearchCard({ proxyConfig, onProxyUpdate, isLoading }) {
  const [t] = useTranslation();

  const searchTitle = t('tracking_page_new.search_title');
  const searchDescription = t('tracking_page_new.search_description');
  const searchOptions = [
    {
      label: t('tracking_page_new.search_option_any_email_or_order_number'),
      value: 'any',
    },
    {
      label: t('tracking_page_new.search_option_order_number_and_email'),
      value: 'order_with_email',
    },
    {
      label: t('tracking_page_new.search_option_order_number'),
      value: 'order',
    },
    { label: t('tracking_page_new.search_option_email'), value: 'email' },
  ];
  const searchFooter = t('tracking_page_new.search_footer');

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="500">
        <BlockStack gap="200">
          <Text as="h4" variant="headingMd">
            {searchTitle}
          </Text>
          <Text as="span" color="subdued">
            {searchDescription}
          </Text>
        </BlockStack>

        {isLoading ? (
          <Box>
            <SkeletonBodyText />
          </Box>
        ) : (
          <BlockStack gap={'300'}>
            <Select
              label=""
              options={searchOptions}
              value={proxyConfig.search_mode}
              onChange={(value) => onProxyUpdate('search_mode', value)}
            />

            <Text as="span" color="subdued">
              {searchFooter}
            </Text>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}

export default SearchCard;
