// Will move this file to some common place once we will be mostly using redux toolkit instead of redux
/**
 * baseAPIs and storeAPIs are separeted because we have to reset all storeAPIs on store switch
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import i18next from 'i18next';
import moment from 'moment';

const { API_URL } = require('config');

export const prepareHeaders = (headers) => {
  const token = ClientStorage.get(LOCAL_STORAGE.ACCESS_TOKEN);
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }
  const storageLang = ClientStorage.get(LOCAL_STORAGE.LANGUAGE);
  headers.set('accept-language', storageLang || i18next.language);
  headers.set('utc-hours-offset', moment().utcOffset());
  return headers;
};

const customizedBaseQuery = (args, api, extraOptions) => {
  return fetchBaseQuery({
    baseUrl: args.baseUrl ? args.baseUrl : `${API_URL}`,
    prepareHeaders: prepareHeaders,
  })(args, api, extraOptions);
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: customizedBaseQuery,
  tagTypes: ['settings'],
  endpoints: () => ({}),
});
