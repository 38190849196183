import { installRoutesMeta } from 'Utils/utils';

function useInstallRoute() {
  const searchParams = new URLSearchParams(location.search);
  const meta = installRoutesMeta();
  const currentSlug = searchParams.get('install');

  return meta.find(({ slug }) => slug === currentSlug)?.install_url;
}

export default useInstallRoute;
