import { storeApi } from '../../redux/store/storeApi';

export const orderSyncAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: (externalId) => {
        return {
          url: `/orders/external/${externalId}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    syncOrder: builder.mutation({
      query: (externalId) => {
        return {
          url: `/orders/external/${externalId}/sync`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const { useGetOrderQuery, useLazyGetOrderQuery, useSyncOrderMutation } =
  orderSyncAPIs;
