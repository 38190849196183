import { Toast } from '@shopify/polaris';
import React from 'react';
import { Toast as BridgeToast } from '@shopify/app-bridge-react';
import useIsEmbededApp from 'hooks/useIsEmbededApp';

function RushToast(props) {
  const isEmbededApp = useIsEmbededApp();

  return isEmbededApp ? <BridgeToast {...props} /> : <Toast {...props} />;
}

export default RushToast;
