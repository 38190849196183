import React, { useEffect, useMemo, useState } from 'react';
import {
  Layout,
  Card,
  Badge,
  Text,
  InlineStack,
  BlockStack,
  Button,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import './style.scss';
import SmallTopMessage from 'components/SmallTopMessage';
import SettingsLoadingPage from './SettingsLoadingPage';
import SlackModal from './SlackModal';
import * as CONSTANTS from 'Constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openHubSpotChat } from 'Utils/utils';
import { STORE_URLS, USER_URLS } from 'config/urls';
import Subtitle from 'components/Subtitle';
import { useDispatch } from 'react-redux';
import {
  useActivateFeatureMutation,
  useDeactivateFeatureMutation,
  useGetSettingsQuery,
} from './settingsApis';
import { settingsActions } from 'redux/settings';
import LoadingCard from './SettingsLoadingPage/LoadingCard';
import { baseActions } from 'redux/store/baseSlice';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';
import { billingActions } from 'pages/Billing/billingSlice';
import UpdatePlanModal from 'pages/Billing/components/UpdatePlanModal';
import CustomActionList from 'CustomActionList';
import RushLoading from 'components/custom/RushLoader';
import { notificationsSubMenu } from 'components/LeftNavigation/navUtils';
import RushPage from 'components/custom/RushPage';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import BridgeButton from 'components/custom/BridgeButton';
import RushBanner from 'components/custom/RushBanner';

export default function Settings() {
  const [t] = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const path = location.pathname.split('/');
  const page = path[path.length - 1];
  const dispatch = useDispatch();

  const { data: user = {} } = useGetUserDetailsQuery();

  const [currentFeatureId, setCurrentFeatureId] = useState('');
  const [currentModule, setCurrentModule] = useState('');
  const isEmbededApp = useIsEmbededApp();

  const queryParams = useMemo(() => {
    const p = { type: 'service' };

    if (page === 'notifications') {
      p.type = 'notification';
    }
    return p;
  }, [page]);

  const {
    data: { feature_group: featureList = [] } = {},
    isLoading: loadingSettings,
    isFetching: fetchingSettings,
    isSuccess: settingsLoaded,
  } = useGetSettingsQuery(queryParams);

  const [
    activateFeature,
    { isLoading: featureActivating, isSuccess: featureActivated },
  ] = useActivateFeatureMutation();

  useEffect(() => {
    if (!featureActivating && featureActivated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.feature.enable.toast'),
          type: 'success',
        })
      );
    }
  }, [featureActivating, featureActivated]);

  const [
    deactivateFeature,
    { isLoading: featureDeactivating, isSuccess: featureDctivated },
  ] = useDeactivateFeatureMutation();

  useEffect(() => {
    if (!featureDeactivating && featureDctivated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.feature.disable.toast'),
          type: 'success',
        })
      );
    }
  }, [featureDeactivating, featureDctivated]);

  useEffect(() => {
    if (!fetchingSettings && settingsLoaded) {
      setCurrentFeatureId('');
      setCurrentModule(page);
    }
  }, [fetchingSettings, settingsLoaded, page]);

  const getButton = (featureId, featureButtonItem) => {
    let buttonObject = { content: featureButtonItem.content };
    if (featureButtonItem.external) {
      buttonObject.external = true;
      buttonObject.url = featureButtonItem.url;
      buttonObject.target = '_blank';
    }
    buttonObject.disabled =
      featureButtonItem.style === CONSTANTS.SETTINGS_BUTTON_DISABLED;
    buttonObject.destructive =
      featureButtonItem.style === CONSTANTS.SETTINGS_BUTTON_DESTRUCTIVE;

    if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_TRACKING_PAGE_GOTO_CONFIGURATION
    ) {
      buttonObject.url = '/settings/tracking';
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_FORECASTED_DELIVERY_DATE_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.SMART_EDD}`;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_EMAIL_CHANNEL_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.EMAIL_NOTIFICATION}`;
    } else if (featureButtonItem.id === CONSTANTS.SETTINGS_THANKYOU_PAGE) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.THANK_YOU}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_SMS_CHANNEL_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.SMS_NOTIFICATION}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_UPSELLS_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.UPSELLS}`;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_MERCHANTS_NOTE_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.MERCHANT_NOTE}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_PAYPAL_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.PAYPAL_CONFIG}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_SHIPBOB_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.SHIP_BOB_CONFIG}`;
    } else if (featureButtonItem.id.indexOf('_activate') > 0) {
      buttonObject.onClick = () => {
        activateFeature(featureId);
        setCurrentFeatureId(featureId);
      };
    } else if (featureButtonItem.id.indexOf('_deactivate') > 0) {
      buttonObject.onClick = () => {
        deactivateFeature(featureId);
        setCurrentFeatureId(featureId);
      };
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_SLACK_OPEN_CONFIGURATION
    ) {
      buttonObject.onClick = () =>
        dispatch(settingsActions.Creators.handleShowSlackModal());
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_KLAVIYO_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.KLAVIYO}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_KLAVIYO2_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.KLAVIYO_INTEGRATION}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_OMNISEND_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.OMNISEND}`;
    } else if (featureButtonItem.id === CONSTANTS.SETTINGS_UPGRADE_TO_PLAN) {
      buttonObject.onClick = () => dispatch(billingActions.toggleUpdateModal());
    } else if (featureButtonItem.id === CONSTANTS.SETTINGS_CUSTOMER_CHAT_OPEN) {
      buttonObject.onClick = openHubSpotChat;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_PRODUCT_REVIEW_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.PRODUCT_REVIEWS}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_YOTPO_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.YOTPO_SMS}`;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_SHOPIFY_FULFILMENT_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.FULLFILMENT_EVENTS}`;
    }

    // Disable buttons if on notifications page and user email is not verified
    // buttonObject.disabled =
    //   buttonObject.disabled
    //  ||
    // (page === 'notifications' &&
    //   !user.email_verified &&
    //   !user.sudo &&
    //   !isEmbededApp);
    return buttonObject;
  };
  const getPrimaryButtons = (featureId, featureButtons) => {
    let primaryButtons = null;
    if (!featureButtons) {
      return primaryButtons;
    }
    featureButtons.map((button) => {
      if (button.style === CONSTANTS.SETTINGS_BUTTON_PRIMARY) {
        primaryButtons = getButton(featureId, button);
      }
      if (button.style === CONSTANTS.SETTINGS_BUTTON_DESTRUCTIVE) {
        primaryButtons = getButton(featureId, button);
      }

      return null;
    });
    return primaryButtons;
  };

  const getSecondaryButtons = (featureId, featureButtons) => {
    let secondaryButtons = [];
    if (!featureButtons) {
      return secondaryButtons;
    }
    featureButtons.map((button) => {
      let btn = null;
      if (
        button.style !== CONSTANTS.SETTINGS_BUTTON_PRIMARY &&
        button.style !== CONSTANTS.SETTINGS_BUTTON_DESTRUCTIVE
      ) {
        btn = getButton(featureId, button);
      }
      if (btn) {
        secondaryButtons.push(btn);
      }
      return null;
    });
    return secondaryButtons;
  };

  const renderSecondaryButton = (secondaryButtons) => {
    return isEmbededApp && secondaryButtons[0]?.external ? (
      <BridgeButton {...secondaryButtons[0]} external={false}>
        {secondaryButtons[0]?.content}
      </BridgeButton>
    ) : (
      <Button size="large" {...secondaryButtons[0]}>{secondaryButtons[0]?.content}</Button>
    );
  };

  const showVerifyBanner =
    page === 'notifications' &&
    !user.email_verified &&
    !user.sudo &&
    !isEmbededApp;

  return (
    <>
      {fetchingSettings || featureDeactivating || featureActivating ? (
        <RushLoading />
      ) : (
        ''
      )}
      {showVerifyBanner ? (
        <Box padding={{ sm: '0', md: '500' }}>
          <RushBanner
            bannerKey={'email-verification-banner'}
            title={t('settings_page.banner.title')}
            action={{
              content: t('settings_page.banner.action'),
              onAction: () => navigate(USER_URLS.DETAILS),
            }}
            tone="warning"
          >
            <Text variant="bodyMd" as="span">
              {t('settings_page.banner.description')}
            </Text>
          </RushBanner>
        </Box>
      ) : (
        <SmallTopMessage />
      )}

      <RushPage
        title={
          page === 'notifications'
            ? t('navigation.item.notifications')
            : t('navigation.item.addons')
        }
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('shipments.subtitle.get_started'),
                source:
                  page === 'notifications'
                    ? 'https://support.rush.app/about-notifications'
                    : 'https://support.rush.app/about-add-ons',
              },
            ]}
          />
        }
        secondaryActions={
          page === 'notifications' && isEmbededApp
            ? notificationsSubMenu(t, params.uuid)?.map((item) => ({
              ...item,
              content: item.label,
            }))
            : undefined
        }
      >
        {loadingSettings || (fetchingSettings && currentModule !== page) ? (
          <SettingsLoadingPage />
        ) : (
          <div className={showVerifyBanner ? 'disabled-feature-page' : ''}>
            <Layout>
              {!loadingSettings &&
                featureList &&
                featureList.map((feature, index) => (
                  <Layout.AnnotatedSection
                    title={feature.title}
                    description={feature.description}
                    key={index}
                  >
                    <BlockStack gap={'400'}>
                      {(feature.features || []).map((featureDetail) => {
                        if (
                          currentFeatureId === featureDetail.feature_id &&
                          (fetchingSettings ||
                            featureDeactivating ||
                            featureActivating)
                        ) {
                          return <LoadingCard key={featureDetail.feature_id} />;
                        }
                        const primaryButton = getPrimaryButtons(
                          featureDetail.feature_id,
                          featureDetail.buttons
                        );
                        const secondaryButtons = getSecondaryButtons(
                          featureDetail.feature_id,
                          featureDetail.buttons
                        );
                        return (
                          <Card
                            roundedAbove="sm"
                            key={featureDetail.feature_id}
                          >
                            <div className="SettingCard ">
                              <div className="SettingCard-Thumbnail">
                                <img
                                  src={featureDetail.icon_link}
                                  className="SettingCard-Thumbnail-Image"
                                  alt={featureDetail.title}
                                />
                              </div>
                              <div className="SettingCard-Heading">
                                <InlineStack>
                                  <Text variant="headingMd" as="h2">
                                    <TranslateLink text={featureDetail.title} />
                                  </Text>
                                </InlineStack>
                              </div>
                              {featureDetail.status_label &&
                                featureDetail.status_badge_prop && (
                                  <div className="SettingCard-Status">
                                    <Badge
                                      tone={
                                        featureDetail.status_badge_prop.status
                                      }
                                      progress={
                                        featureDetail.status_badge_prop.progress
                                      }
                                    >
                                      {featureDetail.status_label}
                                    </Badge>
                                  </div>
                                )}
                            </div>
                            <div>
                              <Text variant="bodyMd" as="span">
                                <TranslateLink
                                  text={featureDetail.description}
                                />
                              </Text>
                            </div>
                            <br />
                            <InlineStack align="end" gap={'200'}>
                              {secondaryButtons?.length === 1 ? (
                                renderSecondaryButton(secondaryButtons)
                              ) : (
                                <CustomActionList
                                  actions={[
                                    ...secondaryButtons.map((b) => ({
                                      ...b,
                                      onAction: b.onClick,
                                    })),
                                  ]}
                                />
                              )}
                              {primaryButton && (
                                <Button variant="primary" tone="success" {...primaryButton}>
                                  {primaryButton?.content}
                                </Button>
                              )}
                            </InlineStack>
                          </Card>
                        );
                      })}
                    </BlockStack>
                  </Layout.AnnotatedSection>
                ))}
            </Layout>
          </div>
        )}
      </RushPage>
      <SlackModal />
      <UpdatePlanModal />
    </>
  );
}
