import React, { useEffect, useState } from 'react';
import { Button, InlineStack, Spinner } from '@shopify/polaris';
import { useGetJobStatusByLinkQuery } from 'redux/store/commonBaseApis';
function ButtonWithJob({
  label,
  job_link,
  onClick,
  onJobDone,
  skipCall,
  skipLoader,
}) {
  const [isJobDone, setIsJobDone] = useState(false);
  const jobStatusResponse = useGetJobStatusByLinkQuery(job_link, {
    skip: isJobDone || !job_link || skipCall,
    pollingInterval: 5000,
  });
  const { data: jobData = {}, isSuccess, isLoading } = jobStatusResponse || {};

  useEffect(() => {
    if (job_link) {
      setIsJobDone(false);
    }
  }, [job_link]);
  useEffect(() => {
    if (!isLoading && isSuccess && jobData.status === 'finished') {
      setIsJobDone(true);
      onJobDone(jobData);
    }
  }, [isLoading, isSuccess, jobData]);
  return (
    <Button variant="primary" tone="success" onClick={() => onClick(isLoading)}>
      <InlineStack blockAlign="center" gap={'100'}>
        {!isJobDone && job_link && !skipLoader ? <Spinner size="small" /> : ''}
        {label}
      </InlineStack>
    </Button>
  );
}

export default ButtonWithJob;
