import { useEffect } from 'react';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import i18next from 'i18next';
import {
  useGetUserDetailsQuery,
  useGetUserLanguagesQuery,
} from 'redux/store/commonBaseApis';
import { useUpdateUserLocaleMutation } from 'redux/store/commonBaseApis';
import useIsEmbededApp from './useIsEmbededApp';
import moment from 'moment';

function useGetAndSetUser({ skip }) {
  const isEmbededApp = useIsEmbededApp();
  const { data: userLanguages = [], isLoading: isLangsLoading } =
    useGetUserLanguagesQuery(undefined, { skip });

  const {
    isLoading: isUserLoading,
    data: userInfo,
    isSuccess: isUserLoaded = false,
  } = useGetUserDetailsQuery(undefined, { skip });

  const [updateUserLocale] = useUpdateUserLocaleMutation();

  useEffect(() => {
    if (!isUserLoading && isUserLoaded) {
      let langToSelect;
      const storageLang = ClientStorage.get(LOCAL_STORAGE.LANGUAGE);
      if (storageLang) {
        langToSelect = storageLang;
        if (isEmbededApp) {
          const updatedDateDiff = moment().diff(
            moment(userInfo.created_at),
            'm'
          );
          if (updatedDateDiff <= 10) {
            updateUserLocale({ language: langToSelect });
          }
        }
      } else {
        const userLang = userInfo?.language;
        const userLangISO =
          userLanguages.find(({ localisation_codes }) =>
            localisation_codes.includes(userLang)
          )?.language_iso_639 || userLang;
        langToSelect = userLangISO;
      }
      ClientStorage.set(LOCAL_STORAGE.TRACK_ANALYTICS, !userInfo?.sudo);
      ClientStorage.set(LOCAL_STORAGE.LANGUAGE, langToSelect);
      i18next.changeLanguage(langToSelect);
    }
  }, [isUserLoaded, isUserLoading, userLanguages]);

  return { isLoading: isUserLoading || isLangsLoading, user: userInfo };
}

export default useGetAndSetUser;
