import React from 'react';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  Box,
  SkeletonDisplayText,
  BlockStack,
  InlineStack,
  SkeletonThumbnail,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function PerksSkeleton() {
  const [t] = useTranslation();

  return (
    <SkeletonPage title={t('perks.title')} subtitle={t('perks.description')}>
      <BlockStack gap="500">
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <BlockStack gap={'500'}>
              <InlineStack gap={'300'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <BlockStack gap={'600'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="20%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <BlockStack gap={'500'}>
              <InlineStack gap={'300'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <BlockStack gap={'600'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="30%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <BlockStack gap={'500'}>
              <InlineStack gap={'300'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <BlockStack gap={'600'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="20%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <BlockStack gap={'500'}>
              <InlineStack gap={'300'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={'2'} />
              </Box>

              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <BlockStack gap={'500'}>
              <InlineStack gap={'300'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={'1'} />
              </Box>

              <BlockStack gap={'600'}>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
                <Box width="10%">
                  <SkeletonBodyText lines={'1'} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm" padding={0}>
          <Box padding="500">
            <BlockStack gap={'500'}>
              <InlineStack gap={'300'} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={'2'} />
                </Box>
              </InlineStack>
              <Box width="10%">
                <SkeletonBodyText lines={'1'} />
              </Box>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
      </BlockStack>
    </SkeletonPage>
  );
}
export default PerksSkeleton;
