import React from 'react';
import { Spinner } from '@shopify/polaris';
import './style.scss';
export default function LoadingPage({ text }) {
  return (
    <div className="LoadingPage-Wrapper">
      <div className="LoadingPage-Spinner">
        <Spinner accessibilityLabel="Loading" size="large" color="teal" />
      </div>
      <div className="LoadingPage-LoadingMessage">
        {text || <span>Loading...</span>}
      </div>
    </div>
  );
}
