import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  Icon,
  Button,
  Card,
  Text,
  InlineStack,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import { CUSTOM_LANGUAGE_CREATE, CUSTOM_LANGUAGE_EDIT } from 'Constants';
import FlagDropDown from './FlagDropDown';
import PublicLanguagesDropDown from './PublicLanguagesDropDown';
import './style.scss';
import BaseLanguageDropdown from './BaseLanguageDropdown';
import AutofillModal from './AutofillModal';
import _ from 'lodash';
import i18next from 'i18next';
import { useGetUserLanguagesQuery } from 'redux/store/commonBaseApis';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';

export default function Language({
  addCustomLanguage,
  updateCustomLanguage,
  getCountriesData,
  getTrackingPageLanguages,
  languages,
  languageData,
  languageDataForUpdate,
  countriesList,
  showUnsavedModal,
  loading,
  publicLanguagesList,
  getLanguageDataForUpdate,
  shop,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { languageId, mode } = useParams();
  const { data: rushAppLanguages = [] } = useGetUserLanguagesQuery();
  const [baseLanguage, setBaseLanguage] = useState(null);
  const [name, setName] = useState('');
  const [textValue, setTextValue] = useState({});
  const [selectedCountryFlag, setSelectedCountryFlag] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [originLanguage, setOriginLanguage] = useState(null);

  const [isUpdated, setIsUpdated] = useState(false);
  const [extensionError, setExtensionError] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(
    () => setShowModal((showModal) => !showModal),
    []
  );

  const [showErrors, setShowErrors] = useState(false);

  // For New Language
  useEffect(() => {
    if (mode === CUSTOM_LANGUAGE_EDIT || !countriesList || !languages) {
      return;
    }

    // Base Language
    setBaseLanguage(
      publicLanguagesList.find(
        ({ language_iso_639 }) => language_iso_639 === i18next.language
      )
    );

    // Flag
    const userLanguage =
      rushAppLanguages.find(
        ({ language_iso_639 }) => language_iso_639 === i18next.language
      ) || {};

    const selectedCountry = countriesList.find(
      ({ svg_flag_url }) => svg_flag_url === userLanguage.svg_flag_url
    );
    setSelectedCountryFlag(selectedCountry);

    // Origin Language1
    setOriginLanguage(
      languages.find(
        ({ language_iso_639 }) => language_iso_639 === i18next.language
      )
    );
  }, [countriesList, languages]);

  useEffect(() => {
    getCountriesData();
    getTrackingPageLanguages();
  }, [getCountriesData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (mode !== CUSTOM_LANGUAGE_EDIT) {
      return;
    }

    getLanguageDataForUpdate(languageId);
  }, [getLanguageDataForUpdate]);

  // For Edit Language
  useEffect(() => {
    if (
      mode !== CUSTOM_LANGUAGE_EDIT ||
      !languageDataForUpdate ||
      !countriesList ||
      !languages
    ) {
      return;
    }

    setBaseLanguage(
      publicLanguagesList.find(
        ({ localisation_codes }) =>
          !_.isEmpty(
            localisation_codes.filter((value) =>
              languageDataForUpdate.localisation_codes.includes(value)
            )
          )
      )
    );
    setName(languageDataForUpdate.label_local);
    setSelectedCountryFlag(
      countriesList.find(
        ({ png_flag_url }) =>
          png_flag_url === languageDataForUpdate.png_flag_url
      )
    );

    setOriginLanguage(
      languages.find(
        ({ language_iso_639 }) => language_iso_639 === i18next.language
      )
    );
    setTextValue(languageDataForUpdate.translations);
  }, [languageDataForUpdate, countriesList, languages]);

  const handleIsUpdate = useCallback(() => {
    setIsUpdated(true);
  }, []);

  const callbackAction = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const validateFields = () => {
    if (name?.length <= 0) {
      return false;
    }

    if (
      Object.keys(textValue).length <
      Object.keys(
        languageData?.translations || languageDataForUpdate?.translations
      ).length
    ) {
      for (const key of Object.keys(languageData.translations)) {
        if (!textValue[key]) {
          const section = document.querySelector(
            `#${key.replaceAll('.', '-')}`
          );
          section.scrollIntoView({ behavior: 'smooth', block: 'center' });
          section.getElementsByTagName('textarea').item(0).focus();
          return false;
        }
      }
    }

    return true;
  };

  const handleSaveLanguage = useCallback(() => {
    setShowErrors(true);
    setButtonLoading(true);

    if (!validateFields()) {
      return;
    }

    const data = {
      product_slug: 'tracking-page',
      store_uuid: shop.storeUUID,
      language: {
        localisation_codes: baseLanguage.localisation_codes,
        country_iso3_codes: baseLanguage.country_iso3_codes,
        left_to_right: baseLanguage.left_to_right,
        png_flag_url: selectedCountryFlag.png_flag_url,
        svg_flag_url: selectedCountryFlag.svg_flag_url,
        label_local: name,
        label_english: name,
        language_iso_639: baseLanguage.language_iso_639,
      },
      translations: textValue,
    };
    addCustomLanguage({ data, callbackAction });
  }, [
    addCustomLanguage,
    callbackAction,
    name,
    selectedCountryFlag,
    textValue,
    baseLanguage,
  ]);

  const handleUpdateLanguage = useCallback(() => {
    setShowErrors(true);
    setButtonLoading(true);
    handleIsUpdate();

    if (!validateFields()) {
      return;
    }

    const data = {
      product_slug: 'tracking-page',
      store_uuid: shop.storeUUID,
      language: {
        svg_flag_url: selectedCountryFlag.svg_flag_url,
        png_flag_url: selectedCountryFlag.png_flag_url,
        localisation_codes: baseLanguage.localisation_codes,
        country_iso3_codes: baseLanguage.country_iso3_codes,
        left_to_right: baseLanguage.left_to_right,
        label_local: name,
        label_english: name,
      },
      translations: textValue,
    };
    const params = {
      data: data,
      language_Id: languageDataForUpdate.uuid,
      callbackAction,
    };
    updateCustomLanguage(params);
  }, [
    baseLanguage,
    languageDataForUpdate,
    handleIsUpdate,
    callbackAction,
    name,
    languageId,
    selectedCountryFlag,
    textValue,
    updateCustomLanguage,
  ]);

  const nameChangeHandler = useCallback(
    (name) => {
      handleIsUpdate();
      if (validateRegex(name)) {
        setExtensionError(
          t(
            'settings.tracking_page.localisation.custom_language.space_validation'
          )
        );
      } else if (name.length > 100) {
        setExtensionError(
          t(
            'settings.tracking_page.localisation.custom_language.character_limit'
          )
        );
      } else {
        setExtensionError(null);
      }
      setName(name);
    },
    [handleIsUpdate, t]
  );

  const handleChange = (newValue, id) => {
    handleIsUpdate();

    const newObject = { ...textValue };

    if (newValue === '') {
      delete newObject[id];
    } else {
      newObject[id] = newValue;
    }

    setTextValue(newObject);
  };

  const callbackFunction = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const redirectBack = useCallback(() => {
    if (isUpdated) {
      showUnsavedModal(callbackFunction);
    } else {
      callbackFunction();
    }
  }, [isUpdated, showUnsavedModal, callbackFunction]);

  const handleAutofill = () => {
    handleIsUpdate();
    setTextValue(languageData.translations);
    toggleModal();
  };

  const handleOk = () => {
    const newObject = {
      ...textValue,
    };

    Object.keys(languageData.translations).forEach((key) => {
      if (!newObject[key]) {
        newObject[key] = languageData.translations[key];
      }
    });
    handleIsUpdate();
    setTextValue(newObject);
    toggleModal();
  };

  const validateRegex = (value) => {
    const spaceFilter = new RegExp('^\\s+.*');
    return spaceFilter.test(value);
  };

  return (
    <RushPage
      fullWidth
      backAction={{ onAction: redirectBack }}
      title={
        mode === CUSTOM_LANGUAGE_EDIT
          ? t(
            'settings.tracking_page.localisation.custom_language.edit_title',
            { language: loading ? '' : languageDataForUpdate?.label_local }
          )
          : t('settings.tracking_page.localisation.custom_language.title')
      }
      primaryAction={
        mode === CUSTOM_LANGUAGE_CREATE ? (
          <Button
            onClick={handleSaveLanguage}
            disabled={loading}
            loading={buttonLoading && loading}
            primary
          >
            {t('settings.tracking_page.localisation.custom_language.save')}
          </Button>
        ) : (
          <Button
            onClick={handleUpdateLanguage}
            disabled={loading || !isUpdated}
            loading={buttonLoading && loading}
            primary
          >
            {t('settings.tracking_page.localisation.custom_language.update')}
          </Button>
        )
      }
    >
      {loading && <RushLoading />}
      <Card roundedAbove="sm">
        <Box padding={'500'} borderBlockEndWidth="100" borderColor="border-brand">
          <InlineStack gap={'500'}>
            <BaseLanguageDropdown
              handleIsUpdate={handleIsUpdate}
              publicLanguagesList={publicLanguagesList}
              baseLanguage={baseLanguage}
              setBaseLanguage={setBaseLanguage}
              loading={loading}
            />

            <div style={{ width: '300px' }} className={'language-label'}>
              <TextField
                placeholder={t(
                  `settings.tracking_page.localisation.custom_language.enter_language`
                )}
                value={loading ? '' : name}
                error={
                  extensionError ||
                  (showErrors && name === ''
                    ? t(
                      'settings.tracking_page.localisation.custom_language.required'
                    )
                    : '')
                }
                onChange={nameChangeHandler}
                autoComplete={'off'}
                label={t(
                  'settings.tracking_page.localisation.custom_language.label'
                )}
              />
            </div>

            <FlagDropDown
              handleIsUpdate={handleIsUpdate}
              countriesList={countriesList}
              selectedCountryFlag={selectedCountryFlag}
              setSelectedCountryFlag={setSelectedCountryFlag}
              loading={loading}
            />
          </InlineStack>
        </Box>

        <Box padding={'500'} borderBlockEndWidth="100" borderColor="border-brand">
          <InlineStack blockAlign={'end'}>
            <PublicLanguagesDropDown
              handleIsUpdate={handleIsUpdate}
              languages={languages}
              selectedLanguage={originLanguage}
              setSelectedLanguage={setOriginLanguage}
              loading={loading}
            />
            <Button primary onClick={toggleModal} loading={loading}>
              {t(
                'settings.tracking_page.localisation.custom_language.autofill'
              )}
            </Button>
          </InlineStack>
        </Box>

        {!loading && (languageData || languageDataForUpdate) && (
          <React.Fragment>
            <AutofillModal
              show={showModal}
              onClose={toggleModal}
              selectedLanguageName={name}
              selectedOriginLanguageName={originLanguage?.label_local}
              onAutofill={handleAutofill}
              onOk={handleOk}
            />

            <Box
              padding={'500'}
              borderBlockEndWidth="100"
              borderColor="border-brand"
            >
              <BlockStack gap="100">
                <Box>
                  <div style={{ display: 'inline-block', width: '52%' }}>
                    <Text variant="bodyMd" as="span">
                      {t(
                        'settings.tracking_page.localisation.custom_language.translations',
                        { language: originLanguage?.label_local }
                      )}
                    </Text>
                  </div>

                  <div style={{ display: 'inline-block' }}>
                    <Text variant="bodyMd" as="span">
                      {t(
                        'settings.tracking_page.localisation.custom_language.translations',
                        { language: name }
                      )}
                    </Text>
                  </div>
                </Box>

                {Object.keys(
                  languageDataForUpdate?.translations ||
                  languageData?.translations
                )
                  .sort(function (a, b) {
                    return ('' + a).localeCompare(b);
                  })
                  .map((value, index) => {
                    return (
                      <InlineStack
                        key={index}
                        align={'space-between'}
                        blockAlign={'baseline'}
                      >
                        <Box width="100%">
                          <TextField
                            disabled={true}
                            value={
                              languageData
                                ? languageData.translations[value]
                                : languageDataForUpdate.translations[value]
                            }
                            autoComplete="off"
                            label={''}
                            multiline={Math.ceil(
                              mode === CUSTOM_LANGUAGE_EDIT
                                ? languageDataForUpdate?.translations[value]
                                  ?.length / 50
                                : languageData?.translations[value]?.length / 50
                            )}
                          />
                        </Box>
                        <Box>
                          <Button
                            plain
                            onClick={() => {
                              setTextValue({
                                ...textValue,
                                [value]:
                                  mode === CUSTOM_LANGUAGE_EDIT
                                    ? languageDataForUpdate.translations[value]
                                    : languageData.translations[value],
                              });
                            }}
                          >
                            <Icon source={ChevronRightIcon} />
                          </Button>
                        </Box>
                        <Box fill>
                          <div id={`${value.replaceAll('.', '-')}`}>
                            <TextField
                              value={textValue ? textValue[value] : ''}
                              onChange={(e) => handleChange(e, value)}
                              autoComplete="off"
                              label={''}
                              error={
                                showErrors && !textValue[value]
                                  ? t(
                                    'settings.tracking_page.localisation.custom_language.required'
                                  )
                                  : null
                              }
                              multiline={Math.ceil(
                                mode === CUSTOM_LANGUAGE_EDIT
                                  ? languageDataForUpdate?.translations[value]
                                    ?.length / 50
                                  : languageData?.translations[value]?.length /
                                  50
                              )}
                            />
                          </div>
                        </Box>
                      </InlineStack>
                    );
                  })}
              </BlockStack>
            </Box>
          </React.Fragment>
        )}
      </Card>
      <UnsavedChangesModal />
    </RushPage>
  );
}
