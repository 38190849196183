import { Button, BlockStack } from '@shopify/polaris';
import CustomToggleSettings from 'components/CustomToggleSettings';
import Subtitle from 'components/Subtitle';
import React, { useState, useEffect, useMemo } from 'react'; // Update the import
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IntegrationEvents from '../../common/IntegrationEvents';
// import IntegrationQuietHours from '../../common/IntegrationQuietHours';
import YotpoAccount from './YotpoAccount';
import YotpoBanner from './YotpoBanner';
import RushPage from 'components/custom/RushPage';
import NotificationModal from './NotificationModal';
import IntegrationSkeleton from 'pages/Settings/common/IntegrationSkeleton';
import { StoreUUID } from 'redux/store/shopSlice';
import {
  useGetYotpoConfigQuery,
  useUpdateYotpoConfigMutation,
  useDisconnectYotpoMutation,
  useSendTestNotificationMutation,
} from '../YotpoSmsPageAPI';
import RushSaveBar from 'components/custom/RushSaveBar';
import { isEqual } from 'lodash';
import RushLoading from 'components/custom/RushLoader';
import useIntegrationCallbackURL from 'hooks/useIntegrationCallbackURL';

function YotpoSmsPage() {
  const [t] = useTranslation();
  const storeUUID = useSelector(StoreUUID);
  const [showNotificationModal, setShowNotificatonModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [quiet, setQuiet] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [selectedNotificationEvent, setSelectedNotificationEvent] = useState(
    {}
  );
  const integrationInstallLink = useIntegrationCallbackURL({
    storeUUID,
    integrationURL: '/auth/yotpo/install',
  });

  const {
    data = {},
    isFetching: isLoading,
    isSuccess,
  } = useGetYotpoConfigQuery(undefined, {
    skip: !storeUUID,
  });

  const yotpoConfig = data?.data || {};
  const meta = data?.meta || {};

  const [updateYotpo, { isLoading: isUpdating }] =
    useUpdateYotpoConfigMutation();

  const [deleteYotpo, { isLoading: isDeleting }] = useDisconnectYotpoMutation();
  const [sendTestNotification, { isLoading: isSendingTestNotification }] =
    useSendTestNotificationMutation();

  useEffect(() => {
    if (isSuccess && !isLoading && yotpoConfig) {
      setEvents(yotpoConfig.events);
      setQuiet(yotpoConfig.quiet);
      setIsActive(yotpoConfig.is_active);
    }
  }, [isLoading, isSuccess, yotpoConfig]);

  const toggleNotificationModal = () => {
    setShowNotificatonModal(!showNotificationModal);
  };

  const handleDelete = () => {
    deleteYotpo();
  };

  const handleConnect = () => {
    window.open(`${integrationInstallLink}`, '_blank');
  };

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const handleNotificationSend = ({ mode, language, value }) => {
    const modeParams = mode === 'phone' ? { phone: value } : { email: value };

    sendTestNotification({
      ...modeParams,
      locale: language,
      trigger: selectedNotificationEvent
        ? {
          uuid: selectedNotificationEvent.trigger_uuid,
          params: selectedNotificationEvent.params,
        }
        : undefined,
    });
  };

  const handleAddEvent = (newEvent) => {
    setEvents([newEvent, ...events]);
  };

  const handleDeleteEvent = (event_index) => {
    const evts = events.filter((_, index) => index !== event_index);
    setEvents(evts);
  };

  const isDirtyState = useMemo(() => {
    const toSave = {
      quiet,
      events,
      isActive,
    };
    const existing = {
      quiet: yotpoConfig.quiet,
      events: yotpoConfig.events,
      isActive: yotpoConfig.is_active,
    };

    return !isEqual(toSave, existing);
  }, [quiet, events, isActive, yotpoConfig]);

  const handleSave = () => {
    updateYotpo({
      ...yotpoConfig,
      is_active: isActive,
      events,
      quiet,
    });
  };

  const handleDiscard = () => {
    setEvents(yotpoConfig.events);
    setQuiet(yotpoConfig.quiet);
    setIsActive(yotpoConfig.is_active);
  };

  const handleEventNotification = (nevent) => {
    setSelectedNotificationEvent(nevent);
    toggleNotificationModal();
  };

  const handleToggleEventActivation = (evt) => {
    const updatedEvent = { ...evt, is_active: !evt.is_active };

    const updatedEvents = events.map((event) =>
      event.uuid === updatedEvent.uuid ? updatedEvent : event
    );

    setEvents(updatedEvents);
  };

  return (
    <>
      {isSendingTestNotification || isLoading || isDeleting ? (
        <RushLoading />
      ) : (
        ''
      )}
      <RushPage>
        <YotpoBanner />
      </RushPage>

      {isDirtyState && !isLoading && (
        <RushSaveBar
          message={t('carrier-maskings.unsaved-changes')}
          saveAction={{
            content: t('carrier-maskings.save'),
            onAction: handleSave,
            loading: isLoading || isUpdating,
          }}
          discardAction={
            isLoading || isUpdating
              ? undefined
              : {
                onAction: handleDiscard,
                content: t('common.discard'),
              }
          }
        />
      )}
      {isLoading ? (
        <IntegrationSkeleton title={t('notifications.yotpo_sms.title')} />
      ) : (
        <RushPage
          backAction={{ url: `/s/${storeUUID}/notifications` }}
          title={t('notifications.yotpo_sms.title')}
          primaryAction={
            yotpoConfig.is_active ? (
              <Button
                plain
                onClick={() => {
                  setSelectedNotificationEvent('');
                  toggleNotificationModal();
                }}
                loading={isSendingTestNotification}
              >
                {t('common.send_test_notification')}
              </Button>
            ) : undefined
          }
          subtitle={
            <Subtitle
              actions={[
                {
                  text: t('common.how_to_get_started'),
                  source: t('notifications.yotpo_sms.get_started_link'),
                },
              ]}
            />
          }
        >
          <BlockStack gap={'500'}>
            {!Object.keys(yotpoConfig)?.length && (
              <CustomToggleSettings
                content={t('notifications.yotpo_sms.email_account.title')}
                buttonLabel={t('reconnect.primary.action')}
                onToggle={handleConnect}
              />
            )}

            {Object.keys(yotpoConfig)?.length ? (
              <>
                <CustomToggleSettings
                  buttonLabel={
                    isActive
                      ? t('common.inactivate')
                      : t('settings_page.addon_card.button.label_activate')
                  }
                  content={t('notifications.yotpo_sms.email_account.title')}
                  isActive={isActive}
                  onToggle={toggleActive}
                />
                <div className={!isActive ? 'disabled-feature-page' : ''}>
                  <BlockStack gap={'500'}>
                    <YotpoAccount
                      onRemove={handleDelete}
                      isLoading={isDeleting}
                    />
                    <IntegrationEvents
                      description={t(
                        'notifications.yotpo_sms.add_event.description'
                      )}
                      triggers={meta?.triggers}
                      events={events}
                      onAddEvent={handleAddEvent}
                      onDeleteEvent={handleDeleteEvent}
                      onSendEvent={handleEventNotification}
                      onToggleEventActivation={handleToggleEventActivation}
                    />
                    {/* <IntegrationQuietHours 
                    description={t(
                      'notifications.yotpo_sms.add_quiet_hours.description'
                    )}
                    quiet={quiet}
                    onChange={(value) => {
                      setQuiet(value);
                    }}
                  /> */}
                  </BlockStack>
                </div>
              </>
            ) : (
              ''
            )}
          </BlockStack>
        </RushPage>
      )}

      <NotificationModal
        open={showNotificationModal}
        onClose={toggleNotificationModal}
        onSend={handleNotificationSend}
      />
    </>
  );
}

export default YotpoSmsPage;
