import React, { useMemo, useState } from 'react';
import {
  Button,
  DataTable,
  Modal,
  Spinner,
  Text,
  InlineStack,
  BlockStack,
} from '@shopify/polaris';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetEDDStatsQuery } from '../smartEDDApis';
import { exportCSV } from '../utils';
import { ExportIcon } from '@shopify/polaris-icons';
import RushModal from 'components/custom/RushModal';
function EDDTimesModal({ onClose }) {
  const [t] = useTranslation();
  const { data = [], isLoading } = useGetEDDStatsQuery();
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedColumnIndex, setSortedColumnIndex] = useState(1);

  const handleSort = (selectedColumnIndex) => {
    if (sortedColumnIndex === selectedColumnIndex) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDirection('asc');
      setSortedColumnIndex(selectedColumnIndex);
    }
  };

  const handleExportClick = () => {
    exportCSV(data, t);
  };

  function sortArrayByColumn(array, columnIndex, direction) {
    const isAscending = direction === 'asc';
    const sortedArray = array.sort((a, b) => {
      const valueA = a[columnIndex];
      const valueB = b[columnIndex];
      if (valueA < valueB) {
        return isAscending ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAscending ? 1 : -1;
      }
      return 0;
    });
    return sortedArray;
  }

  const rowMarkup = useMemo(() => {
    const formated = data.map(
      ({ country, orders, edd = '', avg_days, avg_bdays }) => [
        country,
        orders,
        avg_days,
        avg_bdays,
        moment(edd).format('MMM D'),
      ]
    );

    const sortedData = sortArrayByColumn(
      formated,
      sortedColumnIndex,
      sortDirection
    );
    return sortedData;
  }, [sortDirection, sortedColumnIndex, data]);

  return (
    <RushModal
      large
      open={true}
      onClose={onClose}
      title={t('smart-edd.order-dates.title')}
    >
      <Modal.Section>
        <InlineStack align="space-between" blockAlign="center">
          <Text variant="headingSm" as="h3" color="subdued">
            {t('smart-edd.order-dates.description')}
          </Text>

          <Button
            icon={ExportIcon}
            disabled={!data.length}
            onClick={handleExportClick}
          >
            {t('smart-edd.order-dates.export-button')}
          </Button>
        </InlineStack>
      </Modal.Section>
      {isLoading ? (
        <BlockStack blockAlign="center" align="center">
          <br />
          <br />
          <InlineStack align="center">
            <Spinner />
          </InlineStack>
          <br />
          <br />
        </BlockStack>
      ) : (
        <DataTable
          headings={[
            t('smart-edd.order-dates.country'),
            t('smart-edd.order-dates.orders'),
            t('smart-edd.order-dates.avg-days'),
            t('smart-edd.order-dates.avg-business-days'),
            t('smart-edd.order-dates.estimated-delivery-date'),
          ]}
          rows={rowMarkup}
          sortable={[true, true, true, true, true]}
          columnContentTypes={['text', 'text', 'text', 'text', 'text']}
          onSort={handleSort}
        ></DataTable>
      )}
      <br />
    </RushModal>
  );
}
export default EDDTimesModal;
