import React from 'react';
import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  Text,
} from '@shopify/polaris';
export const LoadingCard = ({ lines, showBtn, showAvatar }) => {
  return (
    <Card roundedAbove="sm">
      <div className="SettingCard ">
        <div className="SettingCard-Heading">
          <SkeletonBodyText lines={1} />
        </div>
        <div className="SettingCard-Status"></div>
        <div className="SettingCard-Status"></div>
      </div>
      <div>
        <br />
        <Text variant="bodyMd" as="span">
          <SkeletonBodyText lines={lines} />
        </Text>
        <br />
      </div>
      {showBtn ? (
        <div className="SettingCard ">
          <div className="SettingCard-Heading"></div>
          <div className="SettingCard-Status Skeleton">
            <SkeletonDisplayText size="medium" />
          </div>
        </div>
      ) : (
        ''
      )}

      {showAvatar ? (
        <div className="SettingCard ">
          <div className="SettingCard-Thumbnail">
            <SkeletonThumbnail size="small" />
          </div>
          <div className="SettingCard-Heading">
            <SkeletonBodyText lines={3} />
          </div>
          <div className="SettingCard-Status"></div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};
function SkeletonBilling() {
  return (
    <Layout>
      <Layout.Section>
        <LoadingCard lines={3} showBtn={true} />
      </Layout.Section>
      <Layout.Section>
        <LoadingCard lines={2} showBtn={true} />
      </Layout.Section>
      <Layout.Section>
        <LoadingCard lines={1} />
      </Layout.Section>
      <Layout.Section>
        <LoadingCard lines={1} showAvatar={true} />
      </Layout.Section>
      <Layout.Section>
        <LoadingCard lines={1} showAvatar={true} />
      </Layout.Section>
    </Layout>
  );
}

export default SkeletonBilling;
