import { useEffect, useState, useMemo } from 'react';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { useGetAccessTokenMutation } from 'pages/Visitor/visitorAPIs';
import { visitorActions } from 'pages/Visitor/visitorSlice';
import { useDispatch } from 'react-redux';
import { useGetJobStatusQuery } from 'redux/store/commonBaseApis';
import { shopActions } from 'redux/store/shopSlice';
import { useNavigate } from 'react-router-dom';
import { isEmbededAppExperience } from 'Utils/utils';
import { AUTH_URLS } from 'config/urls';

function useGetAndSetToken() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const paramsOTT = useMemo(() => params.get('ott'), [params]);
  const paramsJobId = useMemo(() => params.get('job_id'), [params]);
  const popoutCase = useMemo(() => params.get('popout_case'), [params]);
  const embeddedLocale = useMemo(() => params.get('locale'), [params]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [jobId, setJobId] = useState(paramsJobId);

  useEffect(() => {
    setJobId(paramsJobId);
  }, [paramsJobId]);

  const [
    getAccessToken,
    {
      data: tokenResponse,
      isLoading: accessTokenLoading,
      isSuccess: accessTokenLoaded,
    },
  ] = useGetAccessTokenMutation();

  const { data: jobData, isSuccess: jobStatusSuccess } = useGetJobStatusQuery(
    jobId,
    {
      skip: !jobId,
      pollingInterval: 3000,
    }
  );

  useEffect(() => {
    if (jobStatusSuccess && jobData?.process === 'finished') {
      setJobId(null);
      const storeToNavigate = jobData?.output?.store_uuid;
      dispatch(shopActions.setStoreUUID(storeToNavigate));
      ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, storeToNavigate);
      !accessTokenLoading && setIsLoading(false);

      window.__SHOPIFY_DEV_IS_VERIFIED_APP = true;
      const isTempEmbeddedExp = isEmbededAppExperience(storeToNavigate);
      if (!isTempEmbeddedExp) {
        navigate(`${AUTH_URLS.RUSH_SPLASH}${window.location.search}`, {
          replace: true,
        });
      } else {
        const redirectPath = ClientStorage.get('__REDIRECT_PATH');

        if (redirectPath) {
          ClientStorage.remove('__REDIRECT_PATH');

          navigate(redirectPath, { replace: true });
        } else {
          if (popoutCase) {
            params.delete('popout_case');
            navigate(`${location.pathname}?${params.toString()}`, {
              replace: true,
            });
          } else if (storeToNavigate) {
            navigate(`/s/${storeToNavigate}`, { replace: true });
          } else {
            navigate(`/s`);
          }
        }
      }
    }
  }, [jobStatusSuccess, jobData, accessTokenLoading]);

  useEffect(() => {
    if (!accessTokenLoading && accessTokenLoaded) {
      dispatch(visitorActions.setAccessToken(tokenResponse.access_token));
      ClientStorage.set(LOCAL_STORAGE.ACCESS_TOKEN, tokenResponse.access_token);
      if (embeddedLocale) {
        // to set same locale in standalone which was in embedded while comming through pop out case
        ClientStorage.set(LOCAL_STORAGE.LANGUAGE, embeddedLocale);
      }
      /**
       * !JobId means we don't had to wait for jobstatus to be finished,
       * means no job_id in params but ott was there
       *  */
      if (!jobId) {
        setIsLoading(false);
        removeAuthParams();
      }
    }
  }, [accessTokenLoading, accessTokenLoaded, embeddedLocale]);

  useEffect(() => {
    const tokenFromStorage = ClientStorage.get(LOCAL_STORAGE.ACCESS_TOKEN);
    if (paramsOTT) {
      // Clear language from localstorage if we are going to be entered as sudo
      ClientStorage.remove(LOCAL_STORAGE.LANGUAGE);
      getAccessToken(paramsOTT);
    } else if (tokenFromStorage && !accessTokenLoading) {
      dispatch(visitorActions.setAccessToken(tokenFromStorage));
      setIsLoading(false);
    } else if (!jobId && !accessTokenLoading) {
      /**
       * in case ott was not present and also token was not present in localstorage then
       * we are unable to load token but loading must be turn to false
       */

      setIsLoading(false);
    }
  }, [paramsOTT]);

  const removeAuthParams = () => {
    params.delete('job_id');
    params.delete('ott');
    params.delete('skip_tracking');
    params.delete('domain');
    params.delete('popout_case');
    params.delete('locale');
    navigate({
      search: params.toString(),
    });
  };

  return isLoading;
}

export default useGetAndSetToken;
