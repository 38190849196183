import orderBy from 'lodash/orderBy';
import { baseApi } from '../../redux/store/baseApi';

export const visitorAPIs = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisitorLanguages: builder.query({
      query: () => {
        return {
          url: `/products/rush-fe-app/languages`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return orderBy(response?.data, 'label_local');
        } else {
          return [];
        }
      },
    }),

    getAffiliatesCampaign: builder.query({
      query: (params) => {
        return {
          url: `/affiliates/campaign/${params}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    login: builder.mutation({
      query: (params) => {
        return {
          url: `/auth/login`,
          method: 'POST',
          body: params,
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    register: builder.mutation({
      query: (params) => {
        const get_params = {};
        params.get_params.forEach((value, key) => {
          get_params[key] = value;
        });

        const data = {
          ...params,
          get_params,
        };
        return {
          url: `/auth/register?${params.get_params.toString()}`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    resendVerificationCode: builder.mutation({
      query: (verificationToken) => {
        return {
          url: `/verifications/resend`,
          method: 'POST',
          body: { token: verificationToken },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    verifyCode: builder.mutation({
      query: (params) => {
        return {
          url: `/verifications/code`,
          method: 'POST',
          body: { token: params.token, verification_code: params.code },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    forgotPassword: builder.mutation({
      query: (email) => {
        return {
          url: `/password/forgot`,
          method: 'POST',
          body: { email },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    resetPassword: builder.mutation({
      query: (params) => {
        return {
          url: `/password/reset`,
          method: 'POST',
          body: {
            email: params.email,
            password: params.password,
            token: params.token,
          },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateEmail: builder.mutation({
      query: (params) => {
        return {
          url: `/verifications`,
          method: 'PATCH',
          body: {
            token: params.token,
            new_contact: {
              email: params.email,
            },
          },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getAccessToken: builder.mutation({
      query: (token) => {
        return {
          url: `/auth/login-token`,
          method: 'POST',
          body: { token },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: `/auth/logout`,
          method: 'DELETE',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetVisitorLanguagesQuery,
  useGetAccessTokenMutation,
  useGetAffiliatesCampaignQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useResendVerificationCodeMutation,
  useVerifyCodeMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdateEmailMutation,
} = visitorAPIs;
