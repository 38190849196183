import React from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

import { FEATURE_THANK_YOU_PAGE } from 'Constants';
import CustomToggleSettings from 'components/CustomToggleSettings';

export default function ThankyouPageCustomization({
  deactivateFeature,
  activateFeature,
  orderPageConfigObj,
  setOrderPageConfigObj,
}) {
  const [t] = useTranslation();

  const manageEnableDisable = () => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        is_active: !orderPageConfigObj.configuration.is_active,
      },
      isUpdated: false,
    });
    if (orderPageConfigObj && orderPageConfigObj.configuration.is_active) {
      deactivateFeature(FEATURE_THANK_YOU_PAGE);
    } else {
      activateFeature(FEATURE_THANK_YOU_PAGE);
    }
  };

  const contentLocalizationActiveStatus = orderPageConfigObj.configuration
    .is_active
    ? t('settings.thankyou_page.customization.enable.button_on')
    : t('settings.thankyou_page.customization.enable.button_off');

  return (
    <Layout.AnnotatedSection
      title={t('settings.thankyou_page.customization.title')}
      description={t('settings.thankyou_page.customization.description')}
    >
      <CustomToggleSettings
        buttonLabel={contentLocalizationActiveStatus}
        onToggle={manageEnableDisable}
        isActive={orderPageConfigObj.configuration.is_active}
        content={t('settings.thankyou_page.customization.enable.title')}
      ></CustomToggleSettings>
    </Layout.AnnotatedSection>
  );
}
