import React, { useState, useCallback } from 'react';
import { Button, Popover, ActionList } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function CustomActionList(props) {
  const [t] = useTranslation();
  const { actions = [], outline = false } = props;
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button onClick={toggleActive} disclosure outline={outline}>
      {t('common.more')}
    </Button>
  );

  return actions?.length ? (
    <div>
      <Popover
        active={active}
        activator={activator}
        autofocusTarget="first-node"
        onClose={toggleActive}
      >
        <ActionList actionRole="menuitem" items={actions} />
      </Popover>
    </div>
  ) : (
    ''
  );
}

export default CustomActionList;
