import React, { useEffect, useState } from 'react';
import {
  IndexTable,
  Filters,
  Avatar,
  EmptyState,
  Icon,
  Button,
  InlineError,
  Spinner,
  Text,
  BlockStack,
  InlineStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { XSmallIcon, ProductIcon } from '@shopify/polaris-icons';
import '../styles.scss';

function ProductList({
  products = [],
  onChange,
  error,
  onBrowse,
  queryString = '',
  keyString = 'external_platform_id',
  header,
  loading,
}) {
  const [t] = useTranslation();
  const [queryValue, setQueryValue] = useState(queryString);
  const handleRemove = (id) => {
    onChange(products.filter((p) => p[keyString] !== id));
  };

  useEffect(() => {
    queryValue && setQueryValue(queryString);
  }, [queryValue, queryString]);

  useEffect(() => {
    if (queryValue) {
      onBrowse(queryValue);
    }
  }, [queryValue]);

  const emptyStatemarkup = (
    <EmptyState
      heading={
        <Text variant="bodyMd" as="span" color="subdued">
          <Icon source={ProductIcon} color="subdued" />
          <div style={{ textAlign: 'center' }}>
            {t('pre-shipment.no-product-selected')}
          </div>
        </Text>
      }
    />
  );

  const rowMarkup = products.map((prod, index) => {
    const { image, title, name } = prod;
    return (
      <IndexTable.Row
        id={prod[keyString]}
        key={prod[keyString]}
        position={index}
      >
        <IndexTable.Cell>
          <InlineStack blockAlign="center" gap={'200'}>
            <Avatar shape="square" source={image} />

            <BlockStack gap={'100'}>
              <Text variant="bodyMd" as="span">
                {title || name}
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                {prod[keyString]}
              </Text>
            </BlockStack>
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell className="action-buttons">
          <Button
            plain
            icon={XSmallIcon}
            onClick={() => handleRemove(prod[keyString])}
          />
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <div className="product-list-container">
      <div
        style={{
          padding: '16px',
          display: 'flex',
          paddingBottom: 0,
        }}
      >
        <div style={{ flex: 1 }}>
          <BlockStack gap="100">
            {header ? (
              header
            ) : (
              <Text variant="headingMd" as="h3">
                {t('pre-shipment.selected-products')}
              </Text>
            )}
            <Filters
              queryValue={queryValue}
              filters={[]}
              queryPlaceholder={t('pre-shipment.find-products')}
              onQueryChange={setQueryValue}
              onQueryClear={() => setQueryValue('')}
              onClearAll={() => setQueryValue('')}
            >
              <div style={{ paddingLeft: '0.4rem' }}>
                <Button onClick={() => onBrowse('')}>
                  {t('common.browse')}
                </Button>
              </div>
            </Filters>
            {<InlineError message={error} fieldID="myFieldID" />}
          </BlockStack>
        </div>
      </div>
      <IndexTable
        emptyState={emptyStatemarkup}
        selectable={false}
        itemCount={products.length}
        headings={[
          { title: 'Name', hidden: true },
          { title: 'Action', hidden: true },
        ]}
      >
        {loading ? (
          <InlineStack align="center">
            <Spinner size="small" />{' '}
          </InlineStack>
        ) : (
          rowMarkup
        )}
      </IndexTable>
      <div style={{ paddingTop: '1rem', borderBottom: '1px solid #ddd' }} />
    </div>
  );
}
export default ProductList;
