import React, { useMemo } from 'react';
import {
  Badge,
  Text,
  Link,
  SkeletonDisplayText,
  SkeletonBodyText,
  Icon,
  Layout,
  BlockStack,
  Divider,
  Card,
  Box,
  InlineStack,
  Button,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import withActionsHOC from './common/withActionsHOC';
import { ExternalSmallIcon } from '@shopify/polaris-icons';
import CustomActionList from 'CustomActionList';

function DefaultPageCard(props) {
  const {
    pageInfo = {},
    isLoading,
    actions,
    customizePage,
    published_at,
    instance_url,
  } = props;
  const [t] = useTranslation();

  const defaultTrackingPageTitle = t(
    'tracking_page_new.default_tracking_page_title'
  );
  const shopifyOpenStoreLabel = t('tracking_page_new.shopify_open_store_label');
  const shopifyModernLabel = t('tracking_page_new.modern_type');
  const shopifySourceCodeLabel = t('tracking_page_new.source_code_type');
  const defaultTrackingPageDescription = t(
    'tracking_page_new.default_tracking_page_description'
  );
  const customizeLabel = t('tracking_page_new.customize_label');
  const publishedLabel = t('tracking_page_new.published');
  const shopifCustomCodeLabel = t('tracking_page_new.custom_type');

  const getBadgeLabel = () => {
    switch (pageInfo.type) {
      case 'os2':
        return shopifyOpenStoreLabel;
      case 'modern':
        return shopifyModernLabel;
      case 'source':
      case 'traditional':
        return shopifySourceCodeLabel;
      case 'api':
        return shopifCustomCodeLabel;
    }
  };

  const badgetype = useMemo(() => {
    if (pageInfo.type === 'os2') {
      return 'success';
    } else if (pageInfo.type === 'modern') {
      return 'info';
    } else if (pageInfo.type === 'api') {
      return 'warning';
    } else {
      return undefined;
    }
  }, [pageInfo.type]);

  const renderSkeleton = () => {
    return (
      <Card roundedAbove="sm">
        <BlockStack gap={'500'}>
          <SkeletonDisplayText size="small" />
          <Box>
            <SkeletonBodyText lines={4} />
          </Box>
        </BlockStack>
      </Card>
    );
  };

  const handleLinkClick = () => {
    window.open(instance_url);
  };

  return (
    <Layout.Section>
      <BlockStack gap={'200'}>
        <Divider />
        <Text variant="headingMd" as="span">
          {defaultTrackingPageTitle}
        </Text>
        <Text color={'subdued'}>{defaultTrackingPageDescription}</Text>
      </BlockStack>
      <br />
      {isLoading ? (
        renderSkeleton()
      ) : (
        <Card roundedAbove="sm">
          <BlockStack gap="500">
            <BlockStack gap="200">
              <InlineStack gap="200">
                <Text variant="headingMd" as="span">
                  {pageInfo.title}
                </Text>
                <Badge tone={badgetype}>{getBadgeLabel()}</Badge>
              </InlineStack>
            </BlockStack>
            <BlockStack gap="200">
              <Box>
                <InlineStack gap="200">
                  <Text tone="subdued">{publishedLabel}:</Text>
                  <Text tone="subdued">
                    {moment(published_at).format('LLL')}
                  </Text>
                </InlineStack>
              </Box>
              <Box>
                <InlineStack gap="0" align="start">
                  <Link onClick={handleLinkClick} target="_blank">
                    {instance_url}
                  </Link>
                  <Box>
                    <Icon source={ExternalSmallIcon} color="interactive" />
                  </Box>
                </InlineStack>
              </Box>
            </BlockStack>
            <InlineStack align="end" gap={'200'}>
              {actions?.length ? <CustomActionList actions={actions} /> : ''}

              <Button variant="primary" tone="success" onClick={customizePage}>
                {customizeLabel}
              </Button>
            </InlineStack>
          </BlockStack>
        </Card>
      )}
    </Layout.Section>
  );
}

export default withActionsHOC(DefaultPageCard);
