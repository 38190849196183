import i18n from 'i18next';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import {
  SHOPIFY_EMBEDDED_APP_EXPERIENCE,
  SHOPIFY_EMBEDDED_STORES,
} from 'config';
import { STORE_URLS } from 'config/urls';

const PRIVATE_KEY_REGEX = /^pk_[A-Z0-9]{34}$/i;
const PUBLIC_KEY_REGEX = /^[A-Z0-9]{6}$/i;

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const privateKeyValidator = (privateKey) => {
  return PRIVATE_KEY_REGEX.test(privateKey);
};

export const publicKeyValidator = (publicKey) => {
  return PUBLIC_KEY_REGEX.test(publicKey);
};

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day}. ${month} at ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
}

export function timeAgo(dateParam) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return i18n.t('settings.klaviyo.flow_summary.last_update.now');
  } else if (seconds < 60) {
    return `${seconds} ${i18n.t(
      'settings.klaviyo.flow_summary.last_update.seconds_ago'
    )}`;
  } else if (seconds < 90) {
    return i18n.t('settings.klaviyo.flow_summary.last_update.about_minute');
  } else if (minutes < 60) {
    return `${minutes} ${i18n.t(
      'settings.klaviyo.flow_summary.last_update.minutes'
    )}`;
  } else if (isToday) {
    return getFormattedDate(
      date,
      i18n.t('settings.klaviyo.flow_summary.last_update.today')
    ); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(
      date,
      i18n.t('settings.klaviyo.flow_summary.last_update.yesterday')
    ); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10. January at 10:20
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
}

export function addUtmParamsToUrl(url) {
  if (!url) {
    return url;
  }

  const params = new URLSearchParams(url.split('?')[1]);
  if (!params.has('utm_source')) {
    params.append('utm_source', 'app.rush.app');
  }
  if (!params.has('utm_medium')) {
    params.append('utm_medium', 'app.rush.app');
  }
  return `${url.split('?')[0]}?${params.toString()}`;
}

export function openHubSpotChat() {
  if (ClientStorage.get(LOCAL_STORAGE?.TRACK_ANALYTICS) !== true) {
    return;
  }

  const hsWidget = window.HubSpotConversations?.widget;
  if (!hsWidget) {
    return false;
  }

  const status = hsWidget.status();
  if (status.loaded) {
    hsWidget.open();
  } else {
    hsWidget.load({ widgetOpen: true });
  }
  return true;
}

export function isUUID(string) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(string);
}

export function isEmailValid(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
export function isPhoneValid(phone) {
  const phoneRegex = /^\+[1-9]\d{1,14}$/;
  return phoneRegex.test(phone);
}

export function isEmbededAppExperience(storeUUID) {
  return (
    SHOPIFY_EMBEDDED_APP_EXPERIENCE ||
    (storeUUID && SHOPIFY_EMBEDDED_STORES?.split(',').includes(storeUUID))
  );
}

export const installRoutesMeta = () => {
  return [
    {
      slug: 'yotpo',
      image:
        'https://assets.rush.app/shopifyapp/settings-icons/yotpo-sms-bump.svg',
      name: 'Yotpo',
      config_url: '/channels/yotpo',
      install_url: '/auth/yotpo/install',
      component_route: STORE_URLS.YOTPO_SMS,
    },
    {
      slug: 'klaviyo',
      image: 'https://assets.rush.app/shopifyapp/settings-icons/klaviyo.svg',
      name: 'Klaviyo',
      config_url: '/channels/klaviyo',
      install_url: '/auth/klaviyo/install',
      component_route: STORE_URLS.KLAVIYO_INTEGRATION,
    },
    {
      slug: 'shipbob',
      image: 'https://assets.rush.app/shopifyapp/settings-icons/shipbob.svg',
      name: 'ShipBob',
      config_url: '/features/shipbob',
      install_url: '/auth/shipbob/install',
      component_route: STORE_URLS.SHIP_BOB_CONFIG,
    },
  ];
};
