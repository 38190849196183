import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import DataObjectParser from 'dataobject-parser';
import { API_URL, DEFAULT_LANGUAGE } from 'config';
import { LOCAL_STORAGE } from './Constants';
import { ClientStorage } from 'ClientStorage';

function configureI18n() {
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      backend: {
        customHeaders: () => ({
          'accept-language': undefined,
        }),
        loadPath: `${API_URL}/products/{{ns}}/languages/single?language={{lng}}`,
        parse: function (response) {
          const data = JSON.parse(response).data.translations;
          const r = DataObjectParser.transpose(data);
          return r.data();
        },
      },
      ns: 'rush-fe-app',
      defaultNS: 'rush-fe-app',
      lng: ClientStorage.get(LOCAL_STORAGE.LANGUAGE) || DEFAULT_LANGUAGE,
      fallbackLng: false,
      load: 'currentOnly',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    });
}

export function useI18n({ skip }) {
  if (!i18n.isInitialized && !skip) {
    configureI18n();
  }
  return i18n;
}
