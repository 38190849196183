import {
  Card,
  InlineStack,
  BlockStack,
  Text,
  Button,
  Box,
  Tooltip,
  Icon,
  Grid,
} from '@shopify/polaris';
import React, { useState, useMemo } from 'react';
import { DeleteIcon, AlertTriangleIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import AddEditEventModal from './AddEditEventModal';
import { isPremiumEvent, triggerDescriptionMap } from './utils';
import useCurrentPlanTier from 'hooks/useCurrentPlanTier';
import './styles.scss';
function IntegrationEvents({
  description,
  events,
  triggers,
  onAddEvent,
  onSendEvent,
  onDeleteEvent,
  onToggleEventActivation,
}) {
  const [t] = useTranslation();
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const currentTier = useCurrentPlanTier();
  const hasYotpoFeature = ['advanced', 'enterprise'].includes(currentTier);

  const toggleAddEventModal = () => {
    setShowAddEventModal(!showAddEventModal);
  };

  const triggerDescription = useMemo(() => {
    return triggerDescriptionMap(triggers);
  }, [triggers]);

  const filteredTriggers = useMemo(() => {
    const usedTriggers = events.map((evt) => evt.trigger_uuid);
    return triggers.filter(
      ({ uuid, parameters }) =>
        !usedTriggers.includes(uuid) || parameters !== null
    );
  }, [events, triggers]);

  return (
    <>
      <Box paddingInlineStart={{ sm: '0', xs: '5' }}>
        <BlockStack gap={'200'}>
          <Text variant="headingMd" as="span">
            {t('notifications.events_title')}
          </Text>
          <Text color={'subdued'}>{description}</Text>
          <Box>
            <Button onClick={toggleAddEventModal}>
              {t('notifications.add_events')}
            </Button>
          </Box>
        </BlockStack>
      </Box>
      <Card padding={0} roundedAbove="sm">
        <Box padding={'500'}>
          {events.length === 0 ? (
            <InlineStack align="center" blockAlign="center">
              <Text color="subdued">
                {t('notifications.events.no_event_message')}
              </Text>
            </InlineStack>
          ) : (
            ''
          )}
          <Grid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
            {events.map((notificationEvent, index) => {
              const isPremium = isPremiumEvent(notificationEvent, triggers);
              return (
                <>
                  <Grid.Cell>
                    <Tooltip
                      content={
                        triggerDescription[notificationEvent.trigger_uuid]
                      }
                    >
                      <Box paddingBlockEnd="200" paddingInlineEnd={8}>
                        <Text as="div" breakWord>
                          <span className="event-title">
                            {notificationEvent.title}
                          </span>
                        </Text>
                      </Box>
                    </Tooltip>
                  </Grid.Cell>
                  <Grid.Cell>
                    <div className="custom-h-stack">
                      <InlineStack gap={'200'} wrap={false} align="end">
                        <InlineStack blockAlign="center" wrap={false}>
                          {isPremium &&
                            !hasYotpoFeature &&
                            !notificationEvent.is_active ? (
                            <Tooltip
                              content={t(
                                'notifications.events.disabled_tooltip'
                              )}
                            >
                              <Icon source={AlertTriangleIcon} color="warning" />
                            </Tooltip>
                          ) : (
                            <span style={{ visibility: 'hidden' }}>.</span>
                          )}
                          <Button
                            primary={!notificationEvent.is_active}
                            disabled={
                              isPremium &&
                              !hasYotpoFeature &&
                              !notificationEvent.is_active
                            }
                            onClick={() =>
                              onToggleEventActivation(notificationEvent)
                            }
                            wrap={false}
                          >
                            <Text variant="headingSm" breakWord truncate>
                              {notificationEvent.is_active
                                ? t(
                                  'settings_page.addon_card.button.label_deactivate'
                                )
                                : t(
                                  'settings_page.addon_card.button.label_activate'
                                )}
                            </Text>
                          </Button>
                        </InlineStack>
                        <InlineStack>
                          <Button
                            onClick={() => onSendEvent(notificationEvent)}
                            fullWidth
                          >
                            <Text variant="headingSm" breakWord truncate>
                              {t('notifications.events.send_test_notification')}
                            </Text>
                          </Button>
                        </InlineStack>

                        <Button
                          onClick={() => onDeleteEvent(index)}
                          icon={DeleteIcon}
                        />
                      </InlineStack>
                    </div>
                  </Grid.Cell>
                </>
              );
            })}
          </Grid>
        </Box>
      </Card>
      {showAddEventModal && (
        <AddEditEventModal
          onClose={toggleAddEventModal}
          triggers={filteredTriggers}
          onAdd={onAddEvent}
          events={events}
        />
      )}
    </>
  );
}

export default IntegrationEvents;
