import React, { useEffect, useMemo, useState } from 'react';
import { Layout, SkeletonBodyText, Card, Box } from '@shopify/polaris';
import { ViewIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import SmallTopMessage from 'components/SmallTopMessage';
import Subtitle from 'components/Subtitle';
import DefaultPageCard from './DefaultPageCard';
import LocalizeMessages from './LocalizeMessagesCard';
import OSSettingsCard from './OSSettingsCard';
import PagesLibraryCard from './PagesLibraryCard';
import AddPageWizard from './OS2WizardCard/AddPageWizard';
import ThemesStatusModal from './OS2WizardCard/ThemesStatusModal';
import CloneModal from './OS2WizardCard/CloneModal';
import CustomTPModal from './CustomTPModal';
import RemoveModal from './OS2WizardCard/RemoveModal';
import AddNewPageCard from './AddNewPageCard';
import { useDispatch } from 'react-redux';
import {
  useGetPagesConfigQuery,
  useUpdateOS2ConfigMutation,
  useGetOS2PagesQuery,
  useGetProxyPagesQuery,
  useGetCustomPagesQuery,
} from '../newTrackingPageApis';
import ModernWizard from './ModernWizard';
import { trackingPageActions } from '../trackingPageSlice';
import SourceCodeWizard from './SourceCodeWizard';
import Permalink from './Permalink';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';
import { useLocation } from 'react-router-dom';

function TrackingPage() {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showOS2Wizard, setShowOS2Wizard] = useState(false);
  const [showCustomTPModal, setShowCustomTPModal] = useState(false);
  const [showModernWizard, setShowModernWizard] = useState(false);
  const [showSourceCodeWizard, setShowSourceCodeWizard] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState('');
  const [themeStatus, setThemeStatus] = useState(false);
  const [themes, setThemes] = useState([]);
  const [toCloneRemoveId, setToCloneRemoveId] = useState('');
  const [toCloneRemoveIsPublished, setToCloneRemoveIsPublished] =
    useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [autoOSTransfer, setAutoOSTransfer] = useState(false);

  const location = useLocation();
  /**
   * Api calls
   */

  const pagesConfigRes = useGetPagesConfigQuery();
  const {
    data: pagesConfig = {},
    isLoading: isConfigLoading,
    isFetching: isConfigFetching,
    isSuccess: isConfigLoaded,
  } = pagesConfigRes;

  const os2PagesRes = useGetOS2PagesQuery();
  const { data: os2PagesList = [], isFetching: isOS2PagesLoading } =
    os2PagesRes;

  const proxyPagesRes = useGetProxyPagesQuery();
  const { data: proxyPagesList = [], isLoading: isProxyPagesLoading } =
    proxyPagesRes;

  const customPagesRes = useGetCustomPagesQuery();
  const { data: customPagesList = [], isLoading: isCustomPagesLoading } =
    customPagesRes;

  const pagesList = useMemo(() => {
    return [
      ...os2PagesList.map((p) => ({
        ...p,
        type: 'os2',
      })),
      ...customPagesList.map((p) => ({
        ...p,
        preview_link: p.link,
      })),
      ...proxyPagesList,
    ];
  }, [os2PagesList, proxyPagesList, customPagesList]);
  const defaultPageType = useMemo(() => {
    return pagesConfig?.instance?.type || 'os2';
  }, [pagesConfig]);

  const [updateOS2Config, updateOS2ConfigRes] = useUpdateOS2ConfigMutation();
  const { isLoading: isConfigUpdating } = updateOS2ConfigRes;

  const initState = () => {
    const preMigrateOS2 = Boolean(
      pagesConfig.os2_config?.migrate_tracking_page_automatically
    );
    setAutoOSTransfer(preMigrateOS2);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('custom_tp_uuid')) {
      setShowCustomTPModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (isConfigLoaded && !isConfigLoading) {
      initState();
    }
  }, [isConfigLoaded, !isConfigLoading]);

  const isDirtyState = useMemo(() => {
    const preMigrateOS2 = Boolean(
      pagesConfig.os2_config?.migrate_tracking_page_automatically
    );

    return preMigrateOS2 !== autoOSTransfer;
  }, [pagesConfig.os2_config, autoOSTransfer]);

  const handleDiscard = () => {
    initState();
  };

  const handleAutoMigrateChange = (value) => {
    setAutoOSTransfer(value);
  };

  const handleCloneClick = (id) => {
    toggleThemeStatus();
    setToCloneRemoveId(id);
    toggleCloneModal();
  };

  const handleRemoveClick = (id, isPublished) => {
    toggleThemeStatus();
    setToCloneRemoveId(id);
    setToCloneRemoveIsPublished(isPublished);
    toggleRemoveModal();
  };

  const toggleCloneModal = () => {
    setShowCloneModal((show) => !show);
  };

  const toggleRemoveModal = () => {
    setShowRemoveModal((show) => !show);
  };

  const toggleThemeStatus = () => {
    setThemeStatus(!themeStatus);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleOS2Wizard = () => {
    setShowOS2Wizard(!showOS2Wizard);
  };
  const toggleCustomTPModal = () => {
    if (showCustomTPModal) {
      history.pushState({}, document.title, window.location.pathname);
    }
    setShowCustomTPModal(!showCustomTPModal);
  };

  const toggleModernWizard = () => {
    setShowModernWizard(!showModernWizard);
  };

  const toggleSourceCodeWizard = () => {
    setShowSourceCodeWizard(!showSourceCodeWizard);
  };

  /**
   *
   * This Add handler is the one being called from theme status dialog
   */
  const handleAddClick = (id) => {
    toggleThemeStatus();
    setSelectedThemeId(id);
    toggleOS2Wizard();
  };

  const handleAddPage = (selectedPageType) => {
    toggleAddModal();
    switch (selectedPageType) {
      case 'os2':
        setSelectedThemeId('');
        toggleOS2Wizard();
        break;
      case 'modern':
        dispatch(trackingPageActions.setNewModernPageTitle(''));
        toggleModernWizard();
        break;
      case 'source':
        dispatch(trackingPageActions.setSourceCodePageTitle(''));
        toggleSourceCodeWizard();
        break;
      case 'api':
        toggleCustomTPModal();
    }
  };

  const handleSave = () => {
    const preMigrateOS2 = Boolean(
      pagesConfig.os2_config?.migrate_tracking_page_automatically
    );

    if (preMigrateOS2 !== autoOSTransfer) {
      updateOS2Config({ migrate_tracking_page_automatically: autoOSTransfer });
    }
  };

  const filteredTrackingPages = useMemo(() => {
    const defaultPageUUID = pagesConfig?.instance?.uuid;
    return isConfigLoading
      ? []
      : pagesList?.filter(({ uuid }) => uuid != defaultPageUUID);
  }, [isConfigLoading, pagesList, pagesConfig]);

  return (
    <>
      <SmallTopMessage />

      {isDirtyState && !isConfigFetching && (
        <RushSaveBar
          message={t('common.unsaved-changes')}
          saveAction={{
            content: t('common.save'),
            onAction: handleSave,
            loading: isConfigUpdating,
          }}
          discardAction={{
            onAction: handleDiscard,
            loading: isConfigUpdating,
            content: t('common.discard'),
          }}
        />
      )}
      <RushPage
        title={t('tracking_page_new.title')}
        subtitle={
          <Subtitle
            text={t('tracking_page_new.subtitle')}
            actions={[
              {
                text: t('tracking_page_new.plan_get_started'),
                source:
                  'https://support.rush.app/everything-about-tracking-page',
              },
            ]}
          />
        }
        secondaryActions={[
          {
            content: t('tracking_page_new.button_label'),
            icon: ViewIcon,
            onAction: () => {
              window.open(pagesConfig?.tracking_page?.instance_url);
            },
            target: '_blank',
          },
        ]}
      >
        <Layout>
          {/* ...Hiding for now...
            <Layout.Section>
            <CooeeCard />
          </Layout.Section> */}
          {
            <DefaultPageCard
              url={pagesConfig?.instance?.preview_link}
              isLoading={isConfigFetching}
              isDefaultPageCard={true}
              pageInfo={pagesConfig.instance}
              published_at={pagesConfig?.tracking_page?.activated_at}
              instance_url={pagesConfig?.tracking_page?.instance_url}
              {...pagesConfig.instance}
            />
          }
          <Layout.Section>
            <Permalink />
          </Layout.Section>

          <Layout.Section>
            <LocalizeMessages
              isSelected={
                pagesConfig.tracking_page?.is_shipment_messages_translated
              }
            />
          </Layout.Section>

          {defaultPageType === 'os2' ? (
            <Layout.Section>
              {isConfigLoading ? (
                <Card roundedAbove="sm">
                  <Box>
                    <SkeletonBodyText size="small" lines={5} />
                  </Box>
                </Card>
              ) : (
                <OSSettingsCard
                  onStatusClick={toggleThemeStatus}
                  isSelected={autoOSTransfer}
                  onChange={handleAutoMigrateChange}
                />
              )}
            </Layout.Section>
          ) : (
            ''
          )}
          <Layout.Section>
            <PagesLibraryCard
              trackingPages={filteredTrackingPages}
              isLoading={
                isOS2PagesLoading || isProxyPagesLoading || isCustomPagesLoading
              }
            />
          </Layout.Section>
          <AddNewPageCard onAdd={handleAddPage} />
        </Layout>
      </RushPage>

      {showOS2Wizard && (
        <AddPageWizard
          onClose={toggleOS2Wizard}
          selectedThemeId={selectedThemeId}
        />
      )}

      {showCustomTPModal && <CustomTPModal onClose={toggleCustomTPModal} />}

      {showModernWizard && <ModernWizard onClose={toggleModernWizard} />}
      {showSourceCodeWizard && (
        <SourceCodeWizard onClose={toggleSourceCodeWizard} />
      )}

      {themeStatus && (
        <ThemesStatusModal
          onClose={toggleThemeStatus}
          onAdd={handleAddClick}
          onClone={handleCloneClick}
          onRemove={handleRemoveClick}
          setThemes={setThemes}
          isConfigLoading={isConfigLoading}
        />
      )}

      {showCloneModal && (
        <CloneModal
          onClose={toggleCloneModal}
          selectedThemeId={toCloneRemoveId}
          themes={themes}
        />
      )}

      {showRemoveModal && (
        <RemoveModal
          onClose={toggleRemoveModal}
          selectedThemeId={toCloneRemoveId}
          isPublishedTheme={toCloneRemoveIsPublished}
          themes={themes}
        />
      )}
    </>
  );
}

export default TrackingPage;
