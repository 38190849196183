import React from 'react';

import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink/TranslateLink';
import RushBanner from 'components/custom/RushBanner';

function MigrationWarningCard() {
  const [t] = useTranslation();

  return (
    <RushBanner
      bannerKey={'tracking-page-migration-banner'}
      title={t('migrate_to_os_page_title')}
      status="info"
    >
      <TranslateLink text={t('migrate_to_os_page_description')} />
    </RushBanner>
  );
}
export default MigrationWarningCard;
