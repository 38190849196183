import React, { useEffect, useState } from 'react';
import useUnmountSignal from 'use-unmount-signal';
import {
  Card,
  ResourceList,
  ResourceItem,
  SkeletonBodyText,
  Spinner,
  Badge,
  Text,
  Box,
  BlockStack,
  InlineStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { storeApi } from 'redux/store/storeApi';
import { useDispatch } from 'react-redux';
import '../styles.scss';
import { useGetThemesQuery } from 'pages/TrackingPageNew/newTrackingPageApis';
import RushModal from 'components/custom/RushModal';
const StatusType = {
  Init: undefined,
  Loading: 'Loading',
  Installed: 'Installed',
  Not_Installed: 'Not-Installed',
};

function ThemesStatusModal({ onClose, onAdd, onClone, onRemove, setThemes }) {
  const unmountSignal = useUnmountSignal();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState({});
  const themesResponse = useGetThemesQuery();

  const {
    data: themes = [],
    isFetching: isThemesFetching,
    refetch: refetchThemes,
    isSuccess: isThemesLoaded,
  } = themesResponse || {};

  useEffect(() => {
    refetchThemes();
  }, []);

  useEffect(() => {
    isThemesLoaded && setThemes(themes);
  }, [setThemes, isThemesLoaded, themes]);

  useEffect(() => {
    if (isThemesLoaded) {
      themes.reduce((last, { id }) => {
        return last
          .then(() => {
            return dispatch(
              storeApi.endpoints.checkInstall.initiate(id, {
                forceRefetch: true,
              })
            );
            // : Promise.reject('rejected');
          })
          .then((response) => {
            const { data } = response;
            const installStatus = data?.installed
              ? StatusType.Installed
              : StatusType.Not_Installed;
            setStatus((status) => ({ ...status, [id]: installStatus }));
          })
          .then(() => {
            return new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });
          })
          .catch(() => { });
      }, Promise.resolve());
    }
  }, [isThemesLoaded, themes, unmountSignal]);

  const getItemActions = (item) => {
    const { id } = item;
    if (status[id] === StatusType.Installed) {
      let actions = [
        {
          content: t('settings.os2_tracking_page.wizard.Clone'),
          onAction: () => onClone(id),
        },
        {
          content: t('settings.os2_tracking_page.wizard.remove'),
          onAction: () => onRemove(id, item.is_published),
          destructive: true,
        },
      ];
      if (item.is_published) {
        actions.unshift({
          content: t('settings.os2_tracking_page.wizard.add_tracking_page'),
          onAction: () => onAdd(id),
          primary: true,
        });
      }
      return actions;
    } else if (status[id] === StatusType.Not_Installed && item.is_published) {
      return [
        {
          content: t('settings.os2_tracking_page.wizard.add_tracking_page'),
          onAction: () => onAdd(id),
          primary: true,
        },
      ];
    } else {
      return null;
    }
  };

  const getStatusString = (id) => {
    if (status[id] === StatusType.Installed) {
      return t('settings.os2_tracking_page.wizard.app_installed');
    } else if (status[id] === StatusType.Not_Installed) {
      return t('settings.os2_tracking_page.wizard.app_not_installed');
    } else {
      return '';
    }
  };
  const getLabel = (theme) => {
    return theme.is_published ? (
      <>
        {theme.name} <Badge tone="success">Published</Badge>
      </>
    ) : (
      theme.name
    );
  };

  return (
    <RushModal
      iFrameName="themes-status-container"
      title={t('settings.os2_tracking_page.wizard.status_modal_title')}
      open={true}
      onClose={onClose}
    >
      <Card roundedAbove="sm" padding={0}>
        <Box padding="500">
          <BlockStack gap={'200'}>
            <p>{t('settings.os2_tracking_page.wizard.status_card_title')}</p>
            {isThemesFetching ? (
              <Box>
                <SkeletonBodyText size="small" />
              </Box>
            ) : (
              <Card roundedAbove="sm" padding={0}>
                <ResourceList
                  items={themes}
                  renderItem={(item) => {
                    const { id } = item;

                    return (
                      <ResourceItem
                        id={id}
                        shortcutActions={getItemActions(item)}
                        persistActions
                      >
                        <InlineStack gap="3" align="space-between">
                          <Box>
                            <h3>
                              <Text
                                variant="bodyMd"
                                as="span"
                                fontWeight="semibold"
                              >
                                {getLabel(item)}
                              </Text>
                            </h3>
                            <Text variant="bodyMd" as="span" color="subdued">
                              {getStatusString(id)}
                            </Text>
                          </Box>
                          {status[id] === StatusType.Init && (
                            <Box>
                              <Spinner size="small"></Spinner>
                            </Box>
                          )}
                        </InlineStack>
                      </ResourceItem>
                    );
                  }}
                />
              </Card>
            )}
          </BlockStack>
        </Box>
      </Card>
    </RushModal>
  );
}
export default ThemesStatusModal;
