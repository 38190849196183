import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLazyClearResourceCacheQuery } from 'pages/PreShipmentTimeline/preShipmentTimelineApis';
import { Button } from '@shopify/polaris';
import { useLazyGetOrdersTagsQuery } from 'redux/store/commonStoreApis';
import { useGetJobStatusQuery } from 'redux/store/commonBaseApis';
import { baseActions } from 'redux/store/baseSlice';
function ClearCacheButton(props) {
  const [t] = useTranslation();
  const { name } = props;
  const dispatch = useDispatch();
  const [jobId, setJobId] = useState(null);
  const [getTags] = useLazyGetOrdersTagsQuery();
  const jobStatusResponse = useGetJobStatusQuery(jobId, {
    skip: !jobId,
    pollingInterval: 5000,
  });
  const { data: jobData, isSuccess: jobStatusSuccess } =
    jobStatusResponse || {};
  const [clearResourceCache, clearCacheResponse] =
    useLazyClearResourceCacheQuery();
  const {
    data: clearCacheData,
    isFetching: isStoresClearCacheing,
    isSuccess: isClearCacheSuccess,
  } = clearCacheResponse;

  const handleStoreClearCache = () => {
    if (!jobId) {
      /**
       if there is already a job in process don't run sync again
       *  */
      clearResourceCache(name);
    }
  };

  useEffect(() => {
    if (jobStatusSuccess && jobData?.process === 'finished') {
      if (name == 'tags') {
        getTags('');
      }
      setJobId(null);
      dispatch(
        baseActions.setToastMessage({
          message: t('common.cache-cleared'),
          type: 'success',
        })
      );
    }
  }, [jobStatusSuccess, jobData]);

  useEffect(() => {
    if (isClearCacheSuccess && clearCacheData) {
      dispatch(
        baseActions.setToastMessage({
          message: t('common.clearing-cache-in-progress'),
          type: 'success',
        })
      );
      setJobId(clearCacheData?.job_id);
    }
  }, [clearCacheData, isClearCacheSuccess, dispatch]);

  return (
    <Button
      plain
      onClick={handleStoreClearCache}
      loading={isStoresClearCacheing}
    >
      {t('common.clear-cache')}
    </Button>
  );
}

export default ClearCacheButton;
