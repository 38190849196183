import SmallTopMessage from 'components/SmallTopMessage';
import RushPage from 'components/custom/RushPage';
import { STORE_URLS } from 'config/urls';
import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SipmentsDetailSkelton from './SipmentsDetailSkelton';
import { useTranslation } from 'react-i18next';
import {
  Banner,
  Button,
  Card,
  Divider,
  InlineStack,
  Icon,
  Layout,
  Text,
  Tooltip,
  BlockStack,
} from '@shopify/polaris';
import { ViewIcon } from '@shopify/polaris-icons';

import Carriers from './Carriers';
import TrackedCheckpoints from './TrackedCheckpoints';
import Flags from './Flags';
import OrderInformation from './OrderInformation';
import ShippingAddress from './ShippingAddress';
import ContactInformation from './ContactInformation';
import { useGetShipmentDetailsNewQuery } from '../ShipmentDetailsApis';
import { useGetShipmentStatusesQuery } from 'redux/store/commonBaseApis';
import RenderBadge from './RenderBadge';
import Actions from './Actions';
import RushLoading from 'components/custom/RushLoader';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ShipmentsQueryString } from 'pages/Shipments3/shipmentSlice';
import { useGetShipments3Query } from 'pages/Shipments3/shipmentsApi';
import ShipmentInformation from './ShipmentInformation';
import DeliveryTimes from './DeliveryTimes';
import PredictedDelivery from './PredictedDelivery';
import { useGetFeaturesQuery } from 'redux/store/commonStoreApis';
import { NotificationIcon } from '@shopify/polaris-icons';
import { FEATURES_SLUG } from 'Constants';
import Timeline from './Timeline';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';

function ShipmentDetails() {
  const params = useParams();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [isDelayedLoading, setIsDelayedLoading] = useState(false);
  const [hiddenCarrierDetails, setHiddenCarrierDetails] = useState([]);
  const { data: shipmentStatuses = [] } = useGetShipmentStatusesQuery();
  const shipmentsQueryString = useSelector(ShipmentsQueryString);
  const shipmentsResponse = useGetShipments3Query(shipmentsQueryString, {
    skip: isEmpty(shipmentStatuses) || isEmpty(shipmentsQueryString),
  });

  useEffect(() => {
    const numbers = Array.from({ length: 5 }, (_, index) => index + 1);
    let hidden = [];
    numbers.forEach((number) => {
      const key = `${LOCAL_STORAGE.SHIPMENT_DETAILS_CARRIER_HIDDEN}_${number}`;
      if (ClientStorage.get(key)) {
        hidden.push(key);
      }
    });
    setHiddenCarrierDetails(hidden);
  }, []);

  const toggleHiddenCarrier = (key, value) => {
    ClientStorage.set(key, value);
    let hidden = [...hiddenCarrierDetails];
    if (hidden.includes(key)) {
      hidden = hidden.filter((k) => k !== key);
    } else {
      hidden.push(key);
    }
    setHiddenCarrierDetails(hidden);
  };

  const {
    data: { shipments: shipmentsList = [] } = {},
    isFetching: shipmentsLoading,
  } = shipmentsResponse;

  const {
    data: response = {},
    isLoading: isDetailLoading,
    isFetching: isDetailFetching,
    isSuccess: isDetailLoaded,
    error,
    refetch: refetchShipmentDetails,
  } = useGetShipmentDetailsNewQuery(params.shipmentId);

  const {
    data: features = [],
    isSuccess: isFeaturesLoaded,
    isLoading: isFeatursLoading,
  } = useGetFeaturesQuery();

  const isEDDAvailable = useMemo(() => {
    if (isFeaturesLoaded && !isFeatursLoading) {
      const feature = features.find(
        ({ slug }) => slug === FEATURES_SLUG.SMART_EDD
      );
      return feature?.is_available;
    } else {
      return true;
    }
  }, [isFeaturesLoaded, isFeatursLoading, features]);

  const {
    actions = [],
    destination_address = {},
    customer = {},
    forecast = {},
    order = {},
    shipments = [],
    statistics = {},
    tracking_page,
    detail = {},
    order_timeline = [],
  } = response;

  const refetchShipmentWithDelay = (delay = 2000) => {
    setIsDelayedLoading(true);
    setTimeout(() => {
      if (!isDetailLoading) {
        refetchShipmentDetails();
      }
      setIsDelayedLoading(false);
    }, delay);
  };

  const getCurrentShipmentIndex = () => {
    return shipmentsList.findIndex(({ uuid }) => uuid === params.shipmentId);
  };

  const hasPreviousShipment = () => {
    return (
      !shipmentsLoading &&
      !isDetailFetching &&
      shipmentsList?.length &&
      getCurrentShipmentIndex() > 0
    );
  };
  const hasNextShipment = () => {
    return (
      !shipmentsLoading &&
      !isDetailFetching &&
      shipmentsList?.length > 1 &&
      getCurrentShipmentIndex() < shipmentsList?.length - 1
    );
  };

  const getPreviousShipment = () => {
    try {
      const prevShipment = shipmentsList[getCurrentShipmentIndex() - 1];
      navigate(
        `/s/${params.uuid}${STORE_URLS.SHIPMENTS}/${prevShipment?.uuid}`
      );
    } catch {
      console.log('error in getPreviousShipment');
    }
  };
  const getNextShipment = () => {
    try {
      const nextShipment = shipmentsList[getCurrentShipmentIndex() + 1];
      navigate(
        `/s/${params.uuid}${STORE_URLS.SHIPMENTS}/${nextShipment?.uuid}`
      );
    } catch {
      console.log('error in getNextShipment');
    }
  };

  const shipmentTitle = useMemo(() => {
    if (shipments.length > 0) {
      return {
        label: shipments[0].tracking_number,
        link: shipments[0].tracking_link,
      };
    } else {
      return null;
    }
  }, [shipments]);

  return isDetailFetching || isDelayedLoading ? (
    <>
      <RushLoading />
      <SipmentsDetailSkelton />
    </>
  ) : (
    <>
      <SmallTopMessage />
      <RushPage
        title={shipmentTitle ? shipmentTitle.label : 'Shipment'}
        secondaryActions={
          <InlineStack gap="200">
            {detail.quota_status !== 'over_quota' ? (
              <Button
                outline
                icon={ViewIcon}
                onClick={() => {
                  window.open(tracking_page);
                }}
              >
                {t('tracking_page_new.button_label')}
              </Button>
            ) : (
              ''
            )}
            {isEmpty(actions) ? undefined : (
              <Actions
                isDetailLoaded={isDetailLoaded}
                actions={actions}
                isDetailLoading={isDetailLoading}
                refetchWithDelay={refetchShipmentWithDelay}
                refetchShipmentDetails={refetchShipmentDetails}
                isDetailFetching={isDetailFetching}
                trackingNumber={shipmentTitle?.label}
                detail={detail}
              />
            )}
          </InlineStack>
        }
        titleMetadata={
          shipments.length > 0 ? (
            <InlineStack gap="200">
              {detail.is_muted && (
                <Tooltip content={t('shipments.muted.tooltip')}>
                  <Icon source={NotificationIcon} tone="subdued" />
                </Tooltip>
              )}
              <RenderBadge
                status_uuid={shipments[0].status_uuid}
                statuses={shipmentStatuses}
              />
              <RenderBadge
                status_uuid={shipments[0].status_uuid}
                sub_status_uuid={shipments[0].sub_status_uuid}
                statuses={shipmentStatuses}
              />
            </InlineStack>
          ) : (
            ''
          )
        }
        pagination={
          shipmentsList?.length
            ? {
              hasPrevious: hasPreviousShipment(),
              onPrevious: getPreviousShipment,
              hasNext: hasNextShipment(),
              onNext: getNextShipment,
            }
            : undefined
        }
        backAction={{
          content: t('navigation.item.shipments'),
          url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
        }}
      >
        <Layout>
          {isEmpty(response) && error ? (
            <Layout.Section>
              <Banner tone="warning">
                {error?.data?.errors?.message ||
                  t('shipment_details.no_carrier_info')}
              </Banner>
            </Layout.Section>
          ) : detail.quota_status === 'over_quota' ? (
            <Layout.Section>
              <Banner
                tone="warning"
                title={t('shipment_details.over_quota.title')}
              >
                <Text>{t('shipment_details.over_quota.description')}</Text>
              </Banner>
            </Layout.Section>
          ) : (
            <>
              <Layout.Section>
                <BlockStack gap="400">
                  <Card roundedAbove="sm" padding={0}>
                    <BlockStack>
                      <Carriers
                        shipments={shipments}
                        statuses={shipmentStatuses}
                        refetchWithDelay={refetchShipmentWithDelay}
                        toggleHiddenCarrier={toggleHiddenCarrier}
                        hiddenCarrierDetails={hiddenCarrierDetails}
                      />
                      <Divider />
                      <TrackedCheckpoints
                        shipments={shipments}
                        statuses={shipmentStatuses}
                        hiddenCarrierDetails={hiddenCarrierDetails}
                      />
                    </BlockStack>
                  </Card>

                  <Timeline orderTimeline={order_timeline} />
                </BlockStack>
              </Layout.Section>
              <Layout.Section variant="oneThird">
                <BlockStack gap="200">
                  {/* <Status /> */}
                  <Flags statistics={statistics} />
                  <PredictedDelivery
                    forecast={forecast}
                    isEDDAvailable={isEDDAvailable}
                  />
                  <Card roundedAbove="sm" padding={0}>
                    <BlockStack>
                      <ShipmentInformation statistics={statistics} />
                      <Divider />
                      <DeliveryTimes statistics={statistics} />
                      <Divider />
                      <OrderInformation order={order} />
                      <Divider />
                      <ShippingAddress
                        destination_address={destination_address}
                      />
                      <Divider />
                      <ContactInformation customer={customer} />
                    </BlockStack>
                  </Card>
                  {/* <Subscribers /> */}
                  {/* <CustomKeyValues /> */}
                </BlockStack>
              </Layout.Section>
            </>
          )}
        </Layout>
      </RushPage>
    </>
  );
}

export default ShipmentDetails;
