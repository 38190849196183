import { storeApi } from 'redux/store/storeApi';

export const trackingPageApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getPagesConfig: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/config?with_config=1&with_pages=0&with_instance=1',
          method: 'GET',
        };
      },
      providesTags: ['New-Tracking', 'custom-pages'],
      transformResponse: (response = {}) => response?.data || response,
    }),

    getProxyConfig: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/proxy/config',
          method: 'GET',
        };
      },
      providesTags: ['New-Tracking'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    getOS2Code: builder.query({
      query: (pageUuid) => ({
        url: `/features/tracking-page/assets/os2/${pageUuid}`,
        method: 'GET',
      }),
    }),
    getCustomTP: builder.query({
      query: (pageUuid) => ({
        url: `/features/tracking-page/api/${pageUuid}`,
        method: 'GET',
      }),
    }),
    getProxyPageConfig: builder.query({
      query: (pageId) => {
        return {
          url: `/features/tracking-page/proxy/${pageId}`,
          method: 'GET',
        };
      },
      providesTags: ['New-Tracking'],
      transformResponse: (response = {}) => response?.data || response,
    }),

    getOS2Pages: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/os2',
          method: 'GET',
        };
      },
      providesTags: ['New-Tracking'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    getCustomPages: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/api',
          method: 'GET',
        };
      },
      providesTags: ['custom-pages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    getProxyPages: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/proxy',
          method: 'GET',
        };
      },
      providesTags: ['New-Tracking'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    getThemes: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/os2/themes',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    checkInstall: builder.query({
      query: (id) => {
        return {
          url: `/features/tracking-page/os2/themes/install-check/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    cloneTheme: builder.mutation({
      query: ({ fromID, toID }) => {
        return {
          url: `/features/tracking-page/os2/themes/clone/${fromID}/${toID}`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    syncTrackingPages: builder.mutation({
      query: () => {
        return {
          url: `/tracking-page/sync`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getPageMeta: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/os2/meta',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getSourcePageCode: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/template?theme=traditional&style=classic',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getProxyMeta: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/proxy/meta',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getPermaLink: builder.query({
      query: () => {
        return {
          url: '/features/tracking-page/permalink',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updatePermalink: builder.mutation({
      query: (code) => {
        return {
          url: `/features/tracking-page/permalink`,
          method: 'POST',
          body: { code },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    createTrackingPage: builder.mutation({
      query: (data) => {
        return {
          url: `/features/tracking-page/os2`,
          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['New-Tracking'],
    }),
    saveOS2Code: builder.mutation({
      query: ({ pageUuid, data }) => {
        return {
          url: `/features/tracking-page/assets/os2/${pageUuid}`,

          method: 'PUT',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    createCustomTP: builder.mutation({
      query: (data) => {
        return {
          url: `/features/tracking-page/api`,

          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['custom-pages'],
    }),
    updateCustomTP: builder.mutation({
      query: ({ uuid, data }) => {
        return {
          url: `/features/tracking-page/api/${uuid}`,

          method: 'PUT',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['custom-pages'],
    }),
    createModernTrackingPage: builder.mutation({
      query: (data) => {
        return {
          url: `/features/tracking-page/proxy/modern`,
          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['New-Tracking'],
    }),
    createSourceTrackingPage: builder.mutation({
      query: (data) => {
        return {
          url: `/features/tracking-page/proxy/source`,
          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['New-Tracking'],
    }),
    updateTrackingPage: builder.mutation({
      query: ({ uuid, data }) => {
        return {
          url: `/features/tracking-page/proxy/${uuid}`,
          method: 'PUT',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['New-Tracking'],
    }),

    updateOS2Config: builder.mutation({
      query: (data) => {
        return {
          url: `/features/tracking-page/os2/config`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['New-Tracking'],
    }),

    updateProxyConfig: builder.mutation({
      query: (data) => {
        return {
          url: `/features/tracking-page/proxy/config`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['New-Tracking'],
    }),

    publishPage: builder.mutation({
      query: ({ id, type }) => {
        return {
          url: `/features/tracking-page/publish`,
          method: 'POST',
          body: {
            page_uuid: id,
            page_type: type,
          },
        };
      },
      invalidatesTags: ['New-Tracking'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    duplicatePage: builder.mutation({
      query: ({ id, title }) => {
        return {
          url: `/features/tracking-page/${id}/duplicate`,
          method: 'POST',
          body: {
            title,
          },
        };
      },
      invalidatesTags: ['New-Tracking', 'custom-pages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    uninstallOS2Theme: builder.mutation({
      query: ({ id, with_pages = true }) => {
        return {
          url: `/features/tracking-page/os2/themes/uninstall/${id}?with_pages=${Number(
            with_pages
          )}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['New-Tracking'],
    }),
    removeCustomPage: builder.mutation({
      query: (id) => {
        return {
          url: `/features/tracking-page/api/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['custom-pages'],
    }),

    removeOS2Page: builder.mutation({
      query: ({ id, with_pages = true }) => {
        return {
          url: `/features/tracking-page/os2/${id}`,
          method: 'DELETE',
          body: { with_pages },
        };
      },
      invalidatesTags: ['New-Tracking'],
    }),
    removeProxyPage: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/features/tracking-page/proxy/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['New-Tracking'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetOS2CodeQuery,
  useGetCustomPagesQuery,
  useGetPagesConfigQuery,
  useGetCustomTPQuery,
  useLazyGetPagesConfigQuery,
  useGetProxyConfigQuery,
  useGetProxyPageConfigQuery,
  useGetOS2PagesQuery,
  useLazyGetOS2PagesQuery,
  useGetProxyPagesQuery,
  useGetPageMetaQuery,
  useGetSourcePageCodeQuery,
  useGetProxyMetaQuery,
  useGetThemesQuery,
  useLazycheckInstallQuery,
  useGetPermaLinkQuery,
  useUpdatePermalinkMutation,
  useInstallTemplateMutation,
  useCloneThemeMutation,
  useCreateTrackingPageMutation,
  useCreateModernTrackingPageMutation,
  useCreateSourceTrackingPageMutation,
  useUpdateTrackingPageMutation,
  useUpdateOS2ConfigMutation,
  useUpdateProxyConfigMutation,
  usePublishPageMutation,
  useRemoveOS2PageMutation,
  useUninstallOS2ThemeMutation,
  useRemoveProxyPageMutation,
  useDuplicatePageMutation,
  useSyncTrackingPagesMutation,
  useSaveOS2CodeMutation,
  useCreateCustomTPMutation,
  useUpdateCustomTPMutation,
  useRemoveCustomPageMutation,
} = trackingPageApi;
