import React, { useEffect, useCallback } from 'react';
import {
  Card,
  TextField,
  Text,
  Thumbnail,
  Button,
  DropZone,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink/TranslateLink';
import { useUploadLogoMutation } from 'pages/CarrierMasking/maskingApi';
import RushLoading from 'components/custom/RushLoader';

function BrandInfoCard(props) {
  const {
    go_to_shop_uri,
    store_details_link,
    store_information,
    store_logo_link,
    store_name,
    store_phone,
    onChange,
  } = props;
  const [t] = useTranslation();

  const [
    uploadLogo,
    { isSuccess: islogoUploaded, isLoading: islogoUploading, data: logoRes },
  ] = useUploadLogoMutation();

  useEffect(() => {
    if (islogoUploaded && !islogoUploading) {
      onChange('store_logo_link', logoRes?.url);
    }
  }, [islogoUploaded, islogoUploading]);

  const cardTitle = t('tracking_page_new.brand_info_card.card_title');
  const cardDescription = t(
    'tracking_page_new.brand_info_card.card_description'
  );

  const handleStoreNameChange = (newValue) => {
    onChange('store_name', newValue);
  };
  const handleStoreInformationChange = (newValue) => {
    onChange('store_information', newValue);
  };
  const handlePhoneIconUriChange = (newValue) => {
    onChange('store_phone', newValue);
  };
  const handleLinkIconUriChange = (newValue) => {
    onChange('store_details_link', newValue);
  };
  const handleGoToShopUriChange = (newValue) => {
    onChange('go_to_shop_uri', newValue);
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => {
      uploadLogo(acceptedFiles[0]);
    },
    [uploadLogo]
  );

  return (
    <>
      {islogoUploading ? <RushLoading /> : ''}
      <Card roundedAbove="sm" padding={0}>
        <BlockStack gap="500">
          <Box padding={'500'} paddingBlockEnd={'0'}>
            <BlockStack gap="200">
              <Text as="h4" variant="headingMd">
                {cardTitle}
              </Text>
              <Text as="span" color="subdued">
                {cardDescription}
              </Text>
            </BlockStack>
          </Box>

          <Box
            padding={'500'}
            paddingBlockStart={'0'}
            borderBlockEndWidth="100"
            borderColor="border-brand"
          >
            <BlockStack gap="500">
              <BlockStack gap="200">
                <TextField
                  autoComplete={'off'}
                  value={store_name}
                  label={t(
                    'settings.tracking_page.store_info.card.store_name_label'
                  )}
                  onChange={handleStoreNameChange}
                  placeholder={t(
                    'settings.tracking_page.store_info.card.store_name_default_input_text'
                  )}
                  id={`input-store-info-name`}
                />
                <Text variant="bodyMd" as="span" color="subdued">
                  <TranslateLink
                    text={t(
                      'settings.tracking_page.store_info.card.store_name_input_helper'
                    )}
                  />
                </Text>
              </BlockStack>
              <BlockStack gap={'100'}>
                <Text variant="bodyMd" as="span">
                  {t(
                    'settings.tracking_page.section_page_layout.style.logo_title'
                  )}
                </Text>
                <div>
                  {store_logo_link && (
                    <div className="PayLayout-DropZone-Div">
                      <div>
                        <Thumbnail source={store_logo_link} size="large" />
                        <div>
                          <Button
                            variant="plain"
                            tone="critical"
                            onClick={() => onChange('store_logo_link', '')}
                          >
                            {t(
                              'settings.tracking_page.section_page_layout.style.logo_remove'
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!store_logo_link && (
                    <DropZone
                      accept="image/*"
                      type="image"
                      allowMultiple={false}
                      onDrop={handleDropZoneDrop}
                    >
                      <DropZone.FileUpload
                        actionTitle={t(
                          'settings.tracking_page.section_page_layout.style.logo_action_title'
                        )}
                      />
                    </DropZone>
                  )}
                </div>
              </BlockStack>
            </BlockStack>
          </Box>
          <Box
            paddingBlockStart={'0'}
            padding={'500'}
            borderBlockEndWidth="100"
            borderColor="border-brand"
          >
            <TextField
              autoComplete={'off'}
              value={store_information}
              label={t(
                'settings.tracking_page.store_info.card.store_info_label'
              )}
              onChange={handleStoreInformationChange}
              placeholder={t(
                'settings.tracking_page.store_info.card.store_info_default_input_text'
              )}
              multiline={4}
              id={`input-store-info-description`}
            />
          </Box>
          <Box
            paddingBlockStart={'0'}
            padding={'500'}
            borderBlockEndWidth="100"
            borderColor="border-brand"
          >
            <BlockStack gap={'200'}>
              <TextField
                autoComplete={'off'}
                value={store_phone}
                label={t(
                  'settings.tracking_page.store_info.card.phone_icon_label'
                )}
                onChange={handlePhoneIconUriChange}
                placeholder={t(
                  'settings.tracking_page.store_info.card.phone_icon_default_input_text'
                )}
                id={`input-store-info-phone`}
              />
              <Text variant="bodyMd" as="span" color="subdued">
                <TranslateLink
                  text={t(
                    'settings.tracking_page.store_info.card.phone_icon_input_helper'
                  )}
                />
              </Text>
            </BlockStack>
          </Box>
          <Box
            paddingBlockStart={'0'}
            padding={'500'}
            borderBlockEndWidth="100"
            borderColor="border-brand"
          >
            <TextField
              autoComplete={'off'}
              value={store_details_link}
              label={t(
                'settings.tracking_page.store_info.card.chain_icon_label'
              )}
              onChange={handleLinkIconUriChange}
              placeholder={t(
                'settings.tracking_page.store_info.card.chain_icon_default_input_text'
              )}
              id={`input-store-info-link-icon`}
            />
          </Box>
          <Box paddingBlockStart={'0'} padding={'500'}>
            <BlockStack gap={'200'}>
              <TextField
                autoComplete={'off'}
                value={go_to_shop_uri}
                label={t(
                  'settings.tracking_page.store_info.card.go_to_store_label'
                )}
                onChange={handleGoToShopUriChange}
                placeholder={t(
                  'settings.tracking_page.store_info.card.go_to_store_default_input_text'
                )}
                id={`input-store-info-go-to-store`}
              />
              <Text variant="bodyMd" as="span" color="subdued">
                <TranslateLink
                  text={t(
                    'settings.tracking_page.store_info.card.go_to_store_input_herlper'
                  )}
                />
              </Text>
            </BlockStack>
          </Box>
        </BlockStack>
      </Card>
    </>
  );
}

export default BrandInfoCard;
