import { isEmpty } from 'lodash';
import moment from 'moment';

export const hasAnyCarrierInfo = (shipments = []) => {
  return shipments.find((shipment) => {
    return !isEmpty(shipment.checkpoints);
  });
};

export const formatDate = (dateTime) => {
  const currentDate = moment.utc(dateTime);

  const formattedDate = currentDate.calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'MMMM D',
  });
  return formattedDate;
};

export const findStatus = (statuses, id) => {
  return statuses?.find(({ uuid }) => uuid === id);
};

export const getFlagsOptions = (t) => {
  return [
    {
      label: t('shipment_details.is_domestic_parcel'),
      value: 'is_domestic',
    },
    {
      label: t('shipment_details.is_international_parcel'),
      value: 'is_international',
    },
    {
      label: t('shipment_details.is_returned_returned'),
      value: 'is_returned',
    },
    {
      label: t('shipment_details.is_expired_tracking'),
      value: 'is_expired',
      tooltip: t(`shipment_details.tooltip_description_expired_tracking`),
    },
    {
      label: t('shipment_details.is_stalled_parcel'),
      value: 'is_stalled',
      tooltip: t(`shipment_details.tooltip_description_stalled_parcel`),
    },
    {
      label: t('shipment_details.is_problematic_parcel'),
      value: 'is_problematic',
      tooltip: t(`shipment_details.tooltip_description_problematic_parcel`),
    },
  ];
};
