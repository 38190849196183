import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Layout,
  Card,
  Form,
  FormLayout,
  TextField,
  Select,
  Checkbox,
  Text,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import { STORE_URLS } from 'config/urls';
import SmallTopMessage from 'components/SmallTopMessage';
import { useParams } from 'react-router-dom';
import {
  useGetReviewsConfigQuery,
  useUpdateReviewsConfigMutation,
} from '../productReviewsApi';
import ReviewsSkeletonPage from './ReviewsSkeletonPage';
import CodeString from 'components/CodeString/CodeString';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import CustomToggleSettings from 'components/CustomToggleSettings';
import RushLoading from 'components/custom/RushLoader';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';

export default function ProductReviews() {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [currConfig, setCurrConfig] = useState({});
  const [formError, setFormError] = useState({
    pickApp: '',
    secondaryField: '',
  });
  const [updateConfig, updateResponse] = useUpdateReviewsConfigMutation();
  const {
    data: saveResponse = {},
    isLoading: isSaving,
    isSuccess: isSaved,
  } = updateResponse;
  const { data, isFetching, isLoading } = useGetReviewsConfigQuery(uuid);

  const configuration = data?.configuration || {};
  const meta = data?.meta || {};

  const initState = useMemo(() => {
    return Object.keys(configuration).length
      ? {
        product_review_apps_uuid: configuration.review_app_uuid,
        is_active: configuration.is_active,
        config: configuration.config || {},
        skip_on_shipment_issues:
          configuration.skip_on_shipment_issues || false,
        skip_on_shipment_delay: configuration.skip_on_shipment_delay || false,
      }
      : {};
  }, [configuration]);

  useEffect(() => {
    Object.keys(initState).length && setCurrConfig({ ...initState });
  }, [initState]);

  useEffect(() => {
    if (isSaved && saveResponse) {
      dispatch(
        baseActions.setToastMessage({
          message: saveResponse.message,
          type: 'success',
        })
      );
    }
  }, [isSaved, saveResponse]);

  const handleSelectionChange = useCallback(
    (val) => {
      setCurrConfig((prev) => {
        return {
          ...prev,
          config: {},
          product_review_apps_uuid: val,
        };
      });
      setFormError((prev) => ({
        ...prev,
        pickApp: '',
      }));
    },
    [meta]
  );

  const selectedItem = useMemo(() => {
    return (
      (meta.review_apps || []).find(
        (item) => item.uuid === currConfig.product_review_apps_uuid
      ) || null
    );
  }, [currConfig.product_review_apps_uuid, meta.review_apps]);

  const secondaryField = useMemo(() => {
    if (selectedItem) {
      return (selectedItem?.config || [])[0];
    }
    return null;
  }, [selectedItem]);

  const handleActiveChange = useCallback(() => {
    setCurrConfig((prev) => {
      return {
        ...prev,
        is_active: !prev.is_active,
      };
    });
  }, [secondaryField, configuration]);

  const isValidateData = () => {
    const validation_errors = {};
    if (currConfig.is_active) {
      if (secondaryField) {
        if (!currConfig.config[secondaryField.slug]) {
          validation_errors.secondaryField = t(
            'settings.tracking_page.localisation.custom_language.required'
          );
        }
      }
      if (!currConfig.product_review_apps_uuid) {
        validation_errors.pickApp = t(
          'settings.tracking_page.localisation.custom_language.required'
        );
      }
    }
    setFormError(validation_errors);
    return Object.values(validation_errors).every((err) => err === '');
  };

  const handleSave = () => {
    if (isValidateData()) {
      updateConfig(currConfig);
    }
  };

  const handleDiscard = () => {
    setCurrConfig(initState);
  };

  const knowledgeLinkMarkup = useMemo(() => {
    if (selectedItem) {
      if (selectedItem.name === 'Custom Link') {
        return (
          <CodeString
            text={t('settings.tracking_page.product_reviews.custom_link_help', {
              'product-slug': `*product-slug*`,
              'product-id': '*product-id*',
            })}
            showBraces
          ></CodeString>
        );
      } else if (selectedItem.link_to_knowledge_base) {
        return (
          <TranslateLink
            text={t('settings.tracking_page.product_reviews.read_more', {
              review_app: selectedItem.name,
              link: selectedItem.link_to_knowledge_base,
            })}
          ></TranslateLink>
        );
      } else {
        return null;
      }
    }
    return null;
  }, [selectedItem, t]);

  const handleSecondaryFieldChange = useCallback(
    (val) => {
      setCurrConfig((prev) => {
        return {
          ...prev,
          config: {
            [secondaryField.slug]: val,
          },
        };
      });
      setFormError((prev) => ({
        ...prev,
        secondaryField: '',
      }));
    },
    [secondaryField]
  );

  const toggleCheckBox = useCallback((key) => {
    setCurrConfig((prev) => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  }, []);

  const isDirtyState = useMemo(() => {
    return !isEqual(currConfig, initState);
  }, [currConfig, initState]);

  const saveBarMarkup =
    isDirtyState && !isFetching ? (
      <RushSaveBar
        alignContentFlush
        message={t('unsaved_changes_modal.title')}
        saveAction={{
          onAction: handleSave,
          loading: isSaving,
          disabled: isSaving,
          content: t('common.save'),
        }}
        discardAction={{
          onAction: handleDiscard,
          loading: isSaving,
          disabled: isSaving,
          content: t('common.discard'),
        }}
      />
    ) : (
      ''
    );

  const options = useMemo(() => {
    return orderBy(meta.review_apps || [], 'name', 'asc').map((item) => {
      return {
        label: item.name,
        value: item.uuid,
      };
    });
  }, [meta]);

  return isLoading ? (
    <ReviewsSkeletonPage />
  ) : (
    <>
      {isFetching && <RushLoading />}
      <SmallTopMessage />
      <RushPage
        backAction={{
          url: `/s/${uuid}${STORE_URLS.ADDONS}`,
        }}
        title={t('settings.tracking_page.product_reviews.title')}
        subtitle={
          <Subtitle
            text={t('settings.tracking_page.product_reviews.subtitle')}
            actions={[
              {
                text: t('billing.subscription_plans_modal.plan_get_started'),
                source:
                  'https://support.rush.app/grow-product-reviews-with-rush ',
              },
            ]}
          />
        }
      >
        {saveBarMarkup}
        <Layout.AnnotatedSection
          title={t('settings.tracking_page.product_reviews.title')}
          description={t('settings.tracking_page.product_reviews.description')}
        >
          <CustomToggleSettings
            buttonLabel={
              currConfig.is_active
                ? t('settings.tracking_page.product_reviews.enable.button_on')
                : t('settings.tracking_page.product_reviews.enable.button_off')
            }
            onToggle={handleActiveChange}
            isActive={!!currConfig.is_active}
            content={t('settings.tracking_page.product_reviews.enable.title')}
          ></CustomToggleSettings>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title={t('settings.tracking_page.product_reviews.section.title')}
        >
          <Card roundedAbove="sm" subdued={!currConfig.is_active}>
            <Form>
              <FormLayout>
                <Select
                  label={t(
                    'settings.tracking_page.product_reviews.card.language.label'
                  )}
                  options={options}
                  onChange={handleSelectionChange}
                  value={currConfig.product_review_apps_uuid}
                  disabled={!currConfig.is_active}
                  id={`select-review-app-language`}
                  placeholder={'Pick review app'}
                  error={currConfig.is_active && formError.pickApp}
                />
                {knowledgeLinkMarkup ? (
                  <Text variant="bodyMd" as="span" color="subdued">
                    {knowledgeLinkMarkup}
                  </Text>
                ) : (
                  ''
                )}
                {secondaryField && (
                  <TextField
                    value={
                      currConfig.config
                        ? currConfig.config[secondaryField.slug]
                        : ''
                    }
                    label={secondaryField.label}
                    placeholder={secondaryField.placeholder}
                    onChange={handleSecondaryFieldChange}
                    id={`input-product-reviews-uuid`}
                    error={
                      currConfig.is_active &&
                      secondaryField &&
                      formError.secondaryField
                    }
                    autoComplete={'off'}
                    disabled={!currConfig.is_active}
                  />
                )}
                <Checkbox
                  label={t(
                    'settings.tracking_page.product_reviews.card.checkbox1'
                  )}
                  checked={currConfig.skip_on_shipment_issues}
                  onChange={() => toggleCheckBox('skip_on_shipment_issues')}
                  disabled={!currConfig.is_active}
                />
                <Checkbox
                  label={t(
                    'settings.tracking_page.product_reviews.card.checkbox2'
                  )}
                  checked={currConfig.skip_on_shipment_delay}
                  onChange={() => toggleCheckBox('skip_on_shipment_delay')}
                  disabled={!currConfig.is_active}
                />
              </FormLayout>
            </Form>
          </Card>
        </Layout.AnnotatedSection>
      </RushPage>
    </>
  );
}
