import React from 'react';

import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  Text,
} from '@shopify/polaris';
import '../style.scss';

export default function LoadingCard() {
  return (
    <Card roundedAbove="sm">
      <div className="SettingCard ">
        <div className="SettingCard-Thumbnail">
          <SkeletonThumbnail size="small" />
        </div>
        <div className="SettingCard-Heading">
          <SkeletonBodyText lines={1} />
        </div>
        <div className="SettingCard-Status"></div>
      </div>
      <div>
        <br />
        <Text variant="bodyMd" as="span">
          <SkeletonBodyText />
        </Text>
        <br />
      </div>
      <div className="SettingCard ">
        <div className="SettingCard-Heading"></div>
        <div className="SettingCard-Status Skeleton">
          <SkeletonDisplayText size="medium" />
        </div>
      </div>
    </Card>
  );
}
