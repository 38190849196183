import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserPage } from '@rush-deliveries/storybooks-shopify';
import { useNavigate, useLocation } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import VisitorHOC from '../VisitorHOC';
import { useResetPasswordMutation } from 'pages/Visitor/visitorAPIs';

function RecoverPassword({
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  isLoading,
  applyToken,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get('token');
  let email = params.get('email');
  if (!email || !token) {
    navigate(VISITOR_URLS.LOGIN);
  } else {
    email = email.replaceAll(' ', '+');
  }

  const [password, setPassword] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);

  const [
    resetPassword,
    {
      isLoading: isResettingPassword,
      isSuccess: isPasswordReset,
      data: resetData = {},
    },
  ] = useResetPasswordMutation();

  useEffect(() => {
    if (!isResettingPassword && isPasswordReset) {
      applyToken(resetData.access_token, true);
    }
  }, [isResettingPassword, isPasswordReset, resetData]);

  const handlePasswordChange = useCallback((value) => {
    setShowPasswordError(false);
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setShowPasswordError(false);

    let hasErrors = false;

    if (password.length < 6) {
      setShowPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    resetPassword({ email, password, token });
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={isLoading || isResettingPassword}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('charge_password_recovery_screen.title')}
          subtitle={
            <TranslateLink
              text={t('charge_password_recovery_screen.sub_title', {
                email,
              })}
            />
          }
          inputFields={[
            {
              placeholder: t(
                'charge_password_recovery_screen.password_placeholder'
              ),
              error: showPasswordError
                ? t('errors.password_minimum_length')
                : null,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          button={t('charge_password_recovery_screen.continue_btn')}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
export default VisitorHOC(RecoverPassword);
