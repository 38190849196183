import { isEqual } from 'lodash';

export const transformParamsToSave = (params = {}) => {
  return Object.keys(params).reduce((prev, next) => {
    if (params[next]) {
      prev = [...prev, { key: next, value: Number(params[next]) }];
    }
    return prev;
  }, []);
};

export const isPremiumEvent = (evt, triggers) => {
  const eventTrigger = triggers.find(({ uuid }) => uuid === evt.trigger_uuid);
  return eventTrigger?.is_premium;
};

export const checkIfEventExists = (events, newEvent) => {
  return !!events.find((evet) => isEqual(evet, newEvent));
};

export const triggerDescriptionMap = (triggers = []) => {
  return triggers.reduce((prev, next = {}) => {
    prev[next.uuid] = next.description;
    return prev;
  }, {});
};
