import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@shopify/polaris';

import RushModal from 'components/custom/RushModal';
export default function TemplateChangeModal({
  showTemplateChangeModal,
  setSelectedTemplateObject,
  setShowTemplateChangeModal,
}) {
  const [t] = useTranslation();
  return (
    <RushModal
      open={showTemplateChangeModal}
      onClose={() => {
        setShowTemplateChangeModal(false);
      }}
      title={t('emails.preview_change_modal.title')}
      primaryAction={{
        content: t('emails.preview_change_modal.primary_button'),
        onAction: () => {
          setShowTemplateChangeModal(false);
          setSelectedTemplateObject(null);
        },
      }}
      secondaryActions={{
        content: t('emails.preview_change_modal.cancel_button'),
        onAction: () => {
          setShowTemplateChangeModal(false);
        },
      }}
    >
      <Modal.Section>{t('emails.preview_change_modal.message')}</Modal.Section>
    </RushModal>
  );
}
