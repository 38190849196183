import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
} from '@shopify/polaris';
import React from 'react';

function ReviewsSkeletonPage() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.AnnotatedSection>
          <Card roundedAbove="sm">
            <SkeletonBodyText />
          </Card>
          <br />
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </BlockStack>
          </Card>
          <br />
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  );
}

export default ReviewsSkeletonPage;
