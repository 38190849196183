import React from 'react';
import Banner from 'components/Banner';
import { useGetSmallTopMessagesQuery } from 'redux/store/commonStoreApis';
import { Box } from '@shopify/polaris';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import { useSelector } from 'react-redux';

export default function SmallTopMessage() {
  const accessToken = useSelector(AccessToken);

  const { data: smallTopMessage = [] } = useGetSmallTopMessagesQuery('', {
    pollingInterval: 3600000,
    skip: !accessToken,
  });

  return (
    <>
      {smallTopMessage?.length ? (
        <div>
          {smallTopMessage.map((msg, index) => {
            return (
              <Box
                key={index}
                padding={{ sm: '0', md: '5' }}
                paddingBlockEnd={'0'}
              >
                <Banner
                  bannerObject={msg}
                  bannerKey={`small-top-message${index}`}
                />
              </Box>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
