import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { USER_URLS } from 'config/urls';
import { useGetQuestionnaireQuery } from 'pages/Questionnaire/questionnaireApi';
import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useCheckQuestionaire({ skip }) {
  const navigate = useNavigate();

  const { data: questionnaire, status: questionnaireStatus } =
    useGetQuestionnaireQuery('', {
      skip: skip || !ClientStorage.has(LOCAL_STORAGE.LANGUAGE),
    });
  const isQuestionnaireLoaded = useMemo(() => {
    return (
      questionnaireStatus === 'fulfilled' || questionnaireStatus === 'rejected'
    );
  }, [questionnaireStatus]);

  useEffect(() => {
    if (isQuestionnaireLoaded) {
      if (!questionnaire || location.pathname === USER_URLS.QUESTIONNAIRE) {
        return;
      }

      navigate(`${USER_URLS.QUESTIONNAIRE}?redirect=${location.pathname}`);
    }
  }, [questionnaire, isQuestionnaireLoaded]);

  return {
    isLoading:
      skip || !ClientStorage.has(LOCAL_STORAGE.LANGUAGE)
        ? false
        : !isQuestionnaireLoaded,
  };
}

export default useCheckQuestionaire;
