// Will move this file to some common place once we will be mostly using redux toolkit instead of redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './baseApi';

const { API_URL } = require('config');

const customizedBaseQuery = (args, api, extraOptions) => {
  const storeUUID = api?.getState()?.shop?.storeUUID;
  return fetchBaseQuery({
    baseUrl: args.baseUrl ? args.baseUrl : `${API_URL}/stores/${storeUUID}`,
    prepareHeaders: prepareHeaders,
  })(args, api, extraOptions);
};

export const storeApi = createApi({
  reducerPath: 'storeApi',
  baseQuery: customizedBaseQuery,
  tagTypes: [
    'OS2Tracking',
    'carrierMasking',
    'productReviews',
    'shipmentSettings',
    'dashboardTasks',
    'preShipments',
    'smartEDD',
    'shipments',
    'shipment-details',
    'New-Tracking',
  ],
  endpoints: () => ({}),
});
