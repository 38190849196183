import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  BlockStack,
  TextField,
  Button,
  ChoiceList,
  InlineStack,
  Text,
  Select,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { isEmailValid } from 'Utils/utils';
import { isPhoneValid } from 'Utils/utils';
import { ClientStorage } from 'ClientStorage';
const STORAGE_KEYS = {
  lastSentEmail: 'rush-notification-last-sent-email',
  lastSentPhone: 'rush-notification-last-sent-phone',
  lastSelectedLang: 'rush-notification-last-selected-lang',
};
function NotificationModal({ open, onClose, onSend, selectedEvent }) {
  const [selectedMode, setSelectedMode] = useState('email');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [t] = useTranslation();

  useEffect(() => {
    const savedEmail = ClientStorage.get(STORAGE_KEYS.lastSentEmail);
    const savedPhone = ClientStorage.get(STORAGE_KEYS.lastSentPhone);
    const savedLang = ClientStorage.get(STORAGE_KEYS.lastSelectedLang);

    if (savedLang) {
      setSelectedLanguage(savedLang);
    }
    if (savedEmail) {
      setEmailValue(savedEmail);
      setSelectedMode('email');
    }

    if (savedPhone) {
      setPhoneValue(savedPhone);
      setSelectedMode('phone');
    }
    setEmailError('');
    setPhoneError('');
  }, [open]);

  const handleSend = () => {
    const value = selectedMode === 'phone' ? phoneValue : emailValue;
    const isEmailMode = selectedMode === 'email';

    if (isEmailMode) {
      if (!emailValue || !isEmailValid(emailValue)) {
        setEmailError(t('notifications.valid_email_required'));
        return;
      }
      setEmailError('');
    } else {
      if (!phoneValue || !isPhoneValid(phoneValue)) {
        setPhoneError(t('notifications.valid_phone_required'));
        return;
      }
      setPhoneError('');
    }

    ClientStorage.set(STORAGE_KEYS.lastSelectedLang, selectedLanguage);
    ClientStorage.set(
      STORAGE_KEYS.lastSentEmail,
      isEmailMode ? emailValue : ''
    );
    ClientStorage.set(
      STORAGE_KEYS.lastSentPhone,
      isEmailMode ? '' : phoneValue
    );

    onSend({
      mode: selectedMode,
      language: selectedLanguage,
      value,
      selectedEvent,
    });
    onClose();
  };

  const handleChange = useCallback(([value]) => {
    setSelectedMode(value);
  }, []);

  const handleLanguageChange = useCallback((value) => {
    setSelectedLanguage(value);
    setEmailError('');
    setPhoneError('');
  }, []);

  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
  ];

  return (
    <Modal open={open} onClose={onClose} save={onSend} titleHidden>
      <Modal.Section>
        <BlockStack gap="300">
          <BlockStack gap="200">
            <Text variant="headingMd" as="h2">
              {t('notifications.yotpo_sms.notification_modal.title')}
            </Text>
            <Text variant="bodyMd" as="span">
              {t('notifications.yotpo_sms.notification_modal.description')}
            </Text>
          </BlockStack>
          <ChoiceList
            choices={[
              { label: t('common.email'), value: 'email' },
              { label: t('common.phone'), value: 'phone' },
            ]}
            selected={selectedMode}
            onChange={handleChange}
          />

          <Select
            label={t(
              'notifications.yotpo_sms.notification_modal.choicelist_title'
            )}
            options={languageOptions}
            value={selectedLanguage}
            onChange={handleLanguageChange}
          />

          {selectedMode === 'email' ? (
            <TextField
              label={t('common.email')}
              type="email"
              value={emailValue}
              onChange={(value) => {
                setEmailValue(value);
                setEmailError('');
              }}
              autoComplete="off"
              error={emailError}
            />
          ) : (
            <TextField
              label={t('common.phone')}
              type="text"
              value={phoneValue}
              onChange={(value) => {
                setPhoneValue(value);
                setPhoneError('');
              }}
              autoComplete="off"
              error={phoneError}
            />
          )}

          <InlineStack align="end" gap="3">
            <Button onClick={onClose}>{t('common.cancel')}</Button>
            <Button primary onClick={handleSend}>
              {t('common.send')}
            </Button>
          </InlineStack>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
}

export default NotificationModal;
