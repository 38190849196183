import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  storeTitle: null,
  storeUUID: undefined,
  tempStoreDomain: '',
};

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setStoreTitle(state, action) {
      state.storeTitle = action.payload;
    },
    setStoreUUID(state, action) {
      state.storeUUID = action.payload;
    },
    setTempStoreDomain(state, action) {
      state.tempStoreDomain = action.payload;
    },
  },
});

// Actions
export const shopActions = shopSlice.actions;

// Selectors
export const StoreUUID = (state) => {
  if (state.shop.storeUUID === 'undefined') {
    return undefined;
  } else {
    return state.shop.storeUUID;
  }
};
export const StoreTitle = (state) => {
  if (state.shop.storeTitle === 'undefined') {
    return undefined;
  } else {
    return state.shop.storeTitle;
  }
};
export const TempStoreDomain = (state) => {
  return state.shop.tempStoreDomain;
};

// Reducer
const shop = shopSlice.reducer;
export default shop;
