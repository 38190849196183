import stringHelper from 'helpers/string';

export default {
  historyPush: function (navigate, location, to) {
    let queryString = '';
    if (location?.search) {
      const cookieParams = stringHelper.parseQueryString(location.search);
      if (cookieParams) {
        queryString = Object.keys(cookieParams)
          .map(
            (key) =>
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(cookieParams[key])
          )
          .join('&');
      }
    }

    let customURL = to;
    if (queryString) {
      customURL += (to.includes('?') ? '&' : '?') + queryString;
    }

    navigate(customURL);
  },
};
