import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isUUID } from 'Utils/utils';
import { shopActions } from 'redux/store/shopSlice';

function useStorageStoreId({ skip }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!skip) {
      const storeUUIDParam = location.pathname.split('/')[2];
      if (isUUID(storeUUIDParam)) {
        dispatch(shopActions.setStoreUUID(storeUUIDParam));
        ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, storeUUIDParam);
      } else {
        const storeUUID = ClientStorage.get(LOCAL_STORAGE.LAST_STORE_UUID);
        storeUUID &&
          storeUUID !== 'undefined' &&
          storeUUID !== 'null' &&
          dispatch(shopActions.setStoreUUID(storeUUID));
      }
    }
  }, [skip]);
  return '';
}

export default useStorageStoreId;
