import {
  BlockStack,
  Text,
  Box,
  Modal,
  Select,
  TextField,
} from '@shopify/polaris';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { checkIfEventExists, transformParamsToSave } from './utils';
import useCurrentPlanTier from 'hooks/useCurrentPlanTier';
import RushBanner from 'components/custom/RushBanner';
const AddEditEventModal = ({ events, triggers, onClose, onAdd }) => {
  const [t] = useTranslation();

  const [selectedTrigger, setSelectedTrigger] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [params, setParams] = useState({});
  const currentTier = useCurrentPlanTier();
  const hasYotpoFeature = ['advanced', 'enterprise'].includes(currentTier);

  useEffect(() => {
    if (triggers?.length) {
      setSelectedTrigger(triggers[0]?.uuid);
    }
  }, [triggers]);

  const getTitle = (trigger, params) => {
    let p = '';
    if (params.length && trigger.parameters?.length) {
      const triggerLabel = trigger.parameters[0]?.label;
      p = '- ' + params[0]?.value + ' ' + triggerLabel;
    }
    return `${trigger?.title} ${p}`;
  };

  const isPremium = useMemo(() => {
    return triggers.find((trigger) => trigger.uuid === selectedTrigger)
      ?.is_premium;
  }, [selectedTrigger, triggers]);

  const handleAddEvent = () => {
    const trigger = triggers.find(
      (trigger) => trigger.uuid === selectedTrigger
    );

    const transformedParams = transformParamsToSave(params);
    const newEvent = {
      title: getTitle(trigger, transformedParams),
      trigger_uuid: selectedTrigger,
      parameters: transformedParams,
      is_active: !isPremium || (isPremium && hasYotpoFeature),
    };

    const evetAlreadyCreated = checkIfEventExists(events, newEvent);
    const isAllZeros = Object.values(params)?.every((v) => !Number(v) || v < 0);
    if (
      trigger.parameters?.length > 0 &&
      (transformedParams?.length === 0 || isAllZeros)
    ) {
      setErrorMessage(
        t(`common.field-required`, { field_name: trigger.parameters[0]?.label })
      );
      return;
    } else if (evetAlreadyCreated) {
      setErrorMessage(t(`notifications.event_already_created_error`));
      return;
    }

    onAdd(newEvent);
    onClose();
  };

  const triggersList = triggers.map((trigger) => {
    return {
      label: trigger.title,
      value: trigger.uuid,
    };
  });

  const handleParamValueUpdate = (key, value) => {
    setErrorMessage('');
    setParams({ ...params, [key]: value });
  };

  const paramControlsRenderer = useMemo(() => {
    const selectedTriggerObj = triggers.find(
      (trigger) => trigger.uuid === selectedTrigger
    );
    return (selectedTriggerObj?.parameters || []).map((param) => {
      return (
        <TextField
          key={param.key}
          label={param.label}
          type="number"
          value={params[param.key]}
          onChange={(value) => {
            if (value < 0) {
              value = 0;
            } else if (value > 90) {
              value = 90;
            }
            handleParamValueUpdate(param.key, value);
          }}
          error={errorMessage}
          suffix={t('common.days')}
          autoComplete="off"
          min={0}
          max={90}
        />
      );
    });
  }, [triggers, selectedTrigger, params, errorMessage]);

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t('notifications.add_event_notification.title')}
      secondaryActions={[
        {
          content: t('common.cancel'),
          onAction: onClose,
        },
      ]}
      primaryAction={{
        content: t('common.add'),
        onAction: handleAddEvent,
      }}
    >
      <Box padding={'500'}>
        <BlockStack gap={'500'}>
          {isPremium && !hasYotpoFeature && (
            <RushBanner status="warning" bannerKey={'yotpo-isPremium'}>
              {
                triggers.find((trigger) => trigger.uuid === selectedTrigger)
                  ?.is_premium
              }
              <Text>
                {t(
                  'notifications.yotpo_sms.event_notificatioon_banner.description'
                )}
              </Text>
            </RushBanner>
          )}
          <BlockStack gap="200">
            <Select
              label={t('notifications.event')}
              options={triggersList}
              onChange={(value) => {
                setSelectedTrigger(value);
                setErrorMessage('');
              }}
              value={selectedTrigger}
            />
            {selectedTrigger && (
              <Text color="subdued">
                {
                  triggers.find((trigger) => trigger.uuid === selectedTrigger)
                    ?.description
                }
              </Text>
            )}
          </BlockStack>
          <Box width="50%">
            <BlockStack gap={'400'}>{paramControlsRenderer}</BlockStack>
          </Box>
        </BlockStack>
      </Box>
    </Modal>
  );
};

export default AddEditEventModal;
