import {
  Card,
  SkeletonBodyText,
  InlineStack,
  Text,
  Box,
  BlockStack,
} from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageItems from './PageItems';
import RefreshPagesButton from './common/RefreshPagesButton';

function PagesLibraryCard({ trackingPages = [], isLoading }) {
  const [t] = useTranslation();
  const libraryCardTitle = t('tracking_page_new.library_card_title');
  const libraryCardDescription = t(
    'tracking_page_new.library_card_description'
  );

  return (
    <Card roundedAbove="sm" padding={0}>
      <BlockStack gap={'500'}>
        <Box padding={'500'} width="100%" paddingBlockEnd={'0'}>
          <InlineStack align="space-between" blockAlign="start" gap={'500'}>
            <BlockStack gap="200">
              <Text variant="headingMd" as="span">
                {libraryCardTitle}
              </Text>
              <Text color="subdued">{libraryCardDescription}</Text>
            </BlockStack>
            <RefreshPagesButton />
          </InlineStack>
        </Box>
        {isLoading && (
          <Box padding={'500'}>
            <SkeletonBodyText size="medium" lines={6} />
          </Box>
        )}
        {!isLoading && trackingPages.length === 0 ? (
          <Box padding={'500'}>
            <BlockStack blockAlign="center" align="center">
              <Text variant="bodyMd" as="span" tone="subdued">
                {t('tracking_page_new.no_tracking_page_message')}
              </Text>
            </BlockStack>
          </Box>
        ) : (
          !isLoading && <PageItems pages={trackingPages}></PageItems>
        )}
      </BlockStack>
    </Card>
  );
}

export default PagesLibraryCard;
