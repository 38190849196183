import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  ChoiceList,
  Icon,
  InlineError,
  Layout,
  PageActions,
  SkeletonBodyText,
  InlineStack,
  Box,
  Text,
  BlockStack,
} from '@shopify/polaris';
import {
  useDeletePreShipmentTimelineMutation,
  useAddPreShipmentTimelineMutation,
  useGetPreShipmentTimelinesQuery,
  useUpdatePreShipmentTimelineMutation,
  useLazyGetPreviewLinkQuery,
} from 'pages/PreShipmentTimeline/preShipmentTimelineApis';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SearchIcon } from '@shopify/polaris-icons';
import MessagesCard from './MessagesCard';
import SkeletonMessageTimelinePage from './SkeletonMessageTimelinePage';
import SmallTopMessage from 'components/SmallTopMessage';
import ProductList from './ProductList';
import ProductsPicker from 'components/ProductsPicker/ProductsPicker';
import { useDispatch } from 'react-redux';
import '../styles.scss';
import { useGetOrdersTagsQuery } from 'redux/store/commonStoreApis';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';
import ClearCacheButton from 'components/ClearCacheButton';
import FeaturePageWrapper from 'components/FeaturePageWrapper';
import { FEATURES_SLUG } from 'Constants';
import { baseActions } from 'redux/store/baseSlice';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';

function MessageTimelinePage({ isFeatureAvailable }) {
  const [t] = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [getPreviewLink, previewAPIResponse] = useLazyGetPreviewLinkQuery();

  const {
    currentData: previewResponse = {},
    isFetching: previewLoading,
    isSuccess: isPreviewSuccess,
    isError: isPreviewError,
  } = previewAPIResponse;

  const {
    data: tagsPresponse = {},
    isSuccess: tagsLoaded,
    isFetching: tagsLoading,
  } = useGetOrdersTagsQuery('');

  const tagOptions = useMemo(() => {
    if (tagsLoaded && tagsPresponse) {
      return (tagsPresponse?.tags || []).map((tag) => ({
        value: tag,
        label: tag,
      }));
    } else {
      return [];
    }
  }, [tagsPresponse, tagsLoaded]);

  const [showProductsPicker, setShowProductsPicker] = useState(false);
  const [formErrors, setFormErrors] = useState({
    products: false,
    tags: false,
    messages: false,
  });
  const [productsFilterQuery, setProductsFilterQuery] = useState('');
  const [productType, setProductType] = useState(['all-products']);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [tagType, setTagType] = useState(['all-tags']);
  const [selectedTags, setSelectedTags] = useState([]);
  const [messages, setMessages] = useState([]);

  const duplicateCase = useMemo(() => {
    try {
      return location.pathname.endsWith('duplicate');
    } catch {
      return false;
    }
  }, [params]);

  const addCase = useMemo(() => {
    return params.timelineId === 'new';
  }, [params]);

  const preShipmentTimelineResponse = useGetPreShipmentTimelinesQuery();
  const {
    data: timelines,
    isLoading: isTimelinesLoading,
    isSuccess: isTimelinesLoaded,
    isFetching: isTimelinesFetching,
  } = preShipmentTimelineResponse || {};

  const [addPreShipmentTimeline, addResponse] =
    useAddPreShipmentTimelineMutation();
  const {
    isLoading: addingTimeline,
    isSuccess: timelineAdded,
    data: addResponseData,
  } = addResponse;

  const [updatePreShipmentTimeline, updateResponse] =
    useUpdatePreShipmentTimelineMutation();
  const { isLoading: updatingTimeline, isSuccess: timelineUpdated } =
    updateResponse;

  const [deletePreShipmentTimeline, deleteResponse] =
    useDeletePreShipmentTimelineMutation();
  const { isLoading: deletingTimeline, isSuccess: timelineDeleted } =
    deleteResponse;

  const originalTimeline = useMemo(() => {
    if (
      isTimelinesLoaded &&
      !isTimelinesLoading &&
      Array.isArray(timelines) &&
      timelines.length
    ) {
      return timelines.find((m) => m.uuid === params.timelineId);
    }
  }, [timelines, params.timelineId, isTimelinesLoaded, isTimelinesLoading]);

  const isDirtyState = useMemo(() => {
    if (addCase || duplicateCase) {
      return true;
    }
    if (!originalTimeline) {
      return false;
    }

    const prods = originalTimeline.filter_by_products || [];
    const tags = originalTimeline.filter_by_tags || [];

    const orgObject = {
      productType:
        !prods || !prods.length ? ['all-products'] : ['specific-products'],
      tagType: !tags || !tags.length ? ['all-tags'] : ['specific-tags'],
      products: prods,
      tags: tags,
      messages: originalTimeline.messages,
    };
    const currentObject = {
      productType: productType,
      tagType: tagType,
      products: selectedProducts,
      tags: selectedTags,
      messages: messages,
    };
    return !isEqual(orgObject, currentObject);
  }, [
    originalTimeline,
    selectedProducts,
    productType,
    tagType,
    selectedTags,
    messages,
    duplicateCase,
    addCase,
  ]);

  useEffect(() => {
    if (
      timelineAdded &&
      !isTimelinesFetching &&
      !isTimelinesLoading &&
      !addingTimeline
    ) {
      try {
        if (duplicateCase) {
          const routeLink =
            location.pathname.split(`/${params.timelineId}`)[0] +
            '/' +
            addResponseData.uuid;
          navigate(routeLink);
        } else {
          const routeLink =
            location.pathname.split(`/new`)[0] + '/' + addResponseData.uuid;
          navigate(routeLink);
        }
      } catch {
        navigate(-1);
      }
      addResponse.reset();
    }
  }, [
    timelineAdded,
    addResponseData,
    duplicateCase,
    isTimelinesFetching,
    isTimelinesLoading,
    addingTimeline,
  ]);

  const initState = useCallback((timeLine) => {
    const prods = timeLine.filter_by_products || [];
    const tags = timeLine.filter_by_tags || [];
    setMessages(timeLine.messages);
    setSelectedProducts(prods);
    setProductType(
      !prods || !prods.length ? ['all-products'] : ['specific-products']
    );
    setSelectedTags(tags);
    setTagType(!tags || !tags.length ? ['all-tags'] : ['specific-tags']);
  }, []);

  useEffect(() => {
    if (originalTimeline) {
      initState(originalTimeline);
    }
  }, [originalTimeline, initState]);

  const nextUUID = useMemo(() => {
    if (Array.isArray(timelines) && timelines.length) {
      const currMaskingIndex = timelines.findIndex(
        (m) => m.uuid === params.timelineId
      );
      if (currMaskingIndex + 1 < timelines.length) {
        return timelines[currMaskingIndex + 1].uuid;
      }
      return null;
    }
  }, [timelines, params]);

  const PrevUUID = useMemo(() => {
    if (Array.isArray(timelines) && timelines.length) {
      const currMaskingIndex = timelines.findIndex(
        (m) => m.uuid === params.timelineId
      );
      if (currMaskingIndex - 1 >= 0) {
        return timelines[currMaskingIndex - 1].uuid;
      }
      return null;
    }
  }, [timelines, params]);

  const gotoList = useCallback(() => {
    try {
      navigate(location.pathname.split(`/${params.timelineId}`)[0]);
    } catch {
      navigate.go(-1);
    }
  }, [history, params]);

  const TagsEmptyState = (
    <Text variant="bodyMd" as="span" color="subdued">
      <div style={{ padding: '2rem' }}>
        <Icon source={SearchIcon} color="subdued" />
        <div style={{ textAlign: 'center' }}>
          {t('common.could-not-find-any-results')}
        </div>
      </div>
    </Text>
  );

  const toggleProductsPicker = (query = '') => {
    setShowProductsPicker((show) => {
      if (show) {
        setProductsFilterQuery('');
      } else {
        setProductsFilterQuery(query);
      }
      return !show;
    });
  };

  const handlePickProducts = (products = []) => {
    const prods = products.map(({ id, title, ...p }) => ({
      image: p.image,
      external_platform_id: id,
      name: title,
    }));
    handleProductsChange(prods);
    toggleProductsPicker();
  };

  const validateData = () => {
    const errs = Object.keys(formErrors).reduce((prev, next) => {
      if (next === 'products') {
        prev[next] = productType.includes('specific-products')
          ? !selectedProducts.length
          : false;
      } else if (next === 'tags') {
        prev[next] = tagType.includes('specific-tags')
          ? !selectedTags.length
          : false;
      } else if (next === 'messages') {
        prev[next] = !messages.length;
      }

      return prev;
    }, {});
    setFormErrors({ ...errs });

    return Object.values(errs).every((e) => e === false);
  };
  const handleSave = () => {
    if (validateData()) {
      const toBeSaved = {
        filter_by_products:
          productType.includes('specific-products') && selectedProducts.length
            ? selectedProducts
            : null,
        filter_by_tags:
          tagType.includes('specific-tags') && selectedTags.length
            ? selectedTags
            : null,
        messages: messages.map(
          // eslint-disable-next-line no-unused-vars
          ({ uuid, _h, time_offset_hours, ...msg }) => msg
        ),
      };
      if (addCase || duplicateCase) {
        addPreShipmentTimeline({ ...toBeSaved });
      } else {
        updatePreShipmentTimeline({
          ...originalTimeline,
          ...toBeSaved,
        });
      }
    }
  };

  useEffect(() => {
    if (timelineAdded) {
      dispatch(
        baseActions.setToastMessage({
          message: t('common.added-successfully', { entity: 'Timeline' }),
          type: 'success',
        })
      );
    }
  }, [timelineAdded]);

  useEffect(() => {
    if (timelineUpdated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('common.updated-successfully', { entity: 'Timeline' }),
          type: 'success',
        })
      );
    }
  }, [timelineUpdated]);

  useEffect(() => {
    if (timelineDeleted) {
      dispatch(
        baseActions.setToastMessage({
          message: t('common.deleted-successfully', { entity: 'Timeline' }),
          type: 'success',
        })
      );
      navigate(location.pathname.split(`/${params.timelineId}`)[0]);
    }
  }, [timelineDeleted]);

  const handleDiscard = () => {
    initState(originalTimeline);
    setFormErrors({
      products: false,
      tags: false,
      messages: false,
    });
  };

  const handleDelete = () => {
    deletePreShipmentTimeline(originalTimeline.uuid);
  };

  const handleProductsTypeChange = (type) => {
    if (type.includes('all-products')) {
      setFormErrors({ ...formErrors, products: false });
    }
    setProductType(type);
  };
  const handleProductsChange = (products) => {
    setFormErrors({ ...formErrors, products: !products.length });
    setSelectedProducts(products);
  };

  const handleTagsTypeChange = (type) => {
    if (type.includes('all-tags')) {
      setFormErrors({ ...formErrors, tags: false });
    }
    setTagType(type);
  };
  const handleTagsChange = (tags) => {
    setFormErrors({ ...formErrors, tags: !tags.length });
    setSelectedTags(tags);
  };

  const handleMessagesChange = (msgs) => {
    setFormErrors({ ...formErrors, messages: !msgs.length });
    setMessages(msgs);
  };

  const handleOnBrowse = (query = '') => {
    toggleProductsPicker(query);
  };

  const handlePreview = () => {
    getPreviewLink(params.timelineId);
  };

  useEffect(() => {
    if (
      isPreviewSuccess &&
      previewResponse &&
      !isPreviewError &&
      !previewLoading
    ) {
      window.open(previewResponse.link, '_blank');
    }
  }, [isPreviewSuccess, previewResponse, isPreviewError, previewLoading]);

  return (
    <div className={!isFeatureAvailable ? 'disabled-feature-page' : ''}>
      <SmallTopMessage />
      {isDirtyState && !isTimelinesFetching && isFeatureAvailable ? (
        <RushSaveBar
          message={t('common.unsaved-changes')}
          saveAction={{
            content:
              addCase || duplicateCase ? t('common.create') : t('common.save'),
            onAction: handleSave,
            loading: addingTimeline || updatingTimeline,
          }}
          discardAction={
            addCase || duplicateCase
              ? {}
              : {
                content: t('common.discard'),
                onAction: handleDiscard,
                loading: addingTimeline || updatingTimeline,
              }
          }
        />
      ) : (
        ''
      )}
      {isTimelinesLoading ? (
        <SkeletonMessageTimelinePage />
      ) : (
        <div className="pre-shipment-timeline-page">
          <RushPage
            backAction={{
              onAction: gotoList,
            }}
            title={
              addCase || duplicateCase
                ? t('pre-shipment.add-new-timeline')
                : t('pre-shipment.page-title')
            }
            compactTitle
            secondaryActions={
              addCase || duplicateCase
                ? []
                : [
                  {
                    content: t('common.duplicate'),
                    accessibilityLabel: 'Secondary action label',
                    onAction: () => navigate(`duplicate`),
                  },
                  {
                    content: t('common.preview'),
                    onAction: handlePreview,
                    loading: previewLoading,
                  },
                ]
            }
            pagination={
              addCase || duplicateCase
                ? null
                : {
                  hasNext: !!nextUUID,
                  onNext: () => {
                    const newPath = window.location.pathname.replace(
                      params.timelineId,
                      nextUUID
                    );

                    navigate(newPath);
                  },
                  hasPrevious: !!PrevUUID,
                  onPrevious: () => {
                    const newPath = window.location.pathname.replace(
                      params.timelineId,
                      PrevUUID
                    );

                    navigate(newPath);
                  },
                }
            }
          >
            <BlockStack gap="100">
              <MessagesCard
                messages={messages}
                onChange={handleMessagesChange}
                error={
                  formErrors.messages ? (
                    <InlineError
                      message={t('pre-shipment.message-is-required')}
                    ></InlineError>
                  ) : (
                    ''
                  )
                }
              />
            </BlockStack>
            <br />
            <Card roundedAbove="sm" padding={0}>
              <Box padding="500" paddingBlockEnd={'0'}>
                <Text variant="headingMd" as="h2">
                  {t('pre-shipment.apply-message-timeline')}
                </Text>
              </Box>
              <Box
                padding={'500'}
                borderBlockEndWidth="100"
                borderColor="border-brand"
              >
                <BlockStack gap={'200'}>
                  <InlineStack align="space-between">
                    <Text variant="bodyMd">
                      <p>{t('common.products')}</p>
                    </Text>
                    <ClearCacheButton name="products" />
                  </InlineStack>
                  <div className="small-gap-list">
                    <ChoiceList
                      choices={[
                        {
                          label: t('pre-shipment.all-products'),
                          value: 'all-products',
                        },
                        {
                          label: t('pre-shipment.specific-products'),
                          value: 'specific-products',
                        },
                      ]}
                      selected={productType}
                      onChange={handleProductsTypeChange}
                    />
                  </div>
                </BlockStack>
              </Box>
              {productType.includes('specific-products') ? (
                <ProductList
                  queryString={productsFilterQuery}
                  onBrowse={handleOnBrowse}
                  products={selectedProducts}
                  onChange={handleProductsChange}
                  error={
                    formErrors.products
                      ? t('pre-shipment.product.required')
                      : ''
                  }
                />
              ) : (
                ''
              )}
              {tagsLoading ? (
                <Box
                  padding={'500'}
                  borderBlockEndWidth="100"
                  borderColor="border-brand"
                >
                  <SkeletonBodyText />
                </Box>
              ) : (
                <Box
                  padding={'500'}
                  borderBlockEndWidth="100"
                  borderColor="border-brand"
                >
                  <BlockStack gap={'200'}>
                    <InlineStack align="space-between">
                      <Text variant="bodyMd">
                        <p>{t('pre-shipment.order-tags')}</p>
                      </Text>
                      <ClearCacheButton name="tags" />
                    </InlineStack>
                    <div className="small-gap-list">
                      <ChoiceList
                        choices={[
                          {
                            label: t('pre-shipment.all-tags'),
                            value: 'all-tags',
                          },
                          {
                            label: t('pre-shipment.specific-tags'),
                            value: 'specific-tags',
                            renderChildren: (selected) =>
                              selected && tagsLoaded ? (
                                <AutoCompleteWithTags
                                  optionList={tagOptions}
                                  selected={selectedTags}
                                  onChange={handleTagsChange}
                                  emptyState={TagsEmptyState}
                                  placeholder={t('pre-shipment.find-tags')}
                                  error={formErrors.tags}
                                  errorMessage={t('pre-shipment.tag.required')}
                                />
                              ) : (
                                ''
                              ),
                          },
                        ]}
                        selected={tagType}
                        onChange={handleTagsTypeChange}
                      />
                    </div>
                  </BlockStack>
                </Box>
              )}
            </Card>
            {!(addCase || duplicateCase) && (
              <PageActions
                secondaryActions={[
                  {
                    content: t('common.delete'),
                    destructive: true,
                    onAction: handleDelete,
                    outline: true,
                    loading: deletingTimeline,
                  },
                ]}
              />
            )}
            <Layout.Section>
            </Layout.Section>
          </RushPage>
        </div>
      )}
      {showProductsPicker ? (
        <ProductsPicker
          onAdd={handlePickProducts}
          onCancel={toggleProductsPicker}
          selectedProducts={(selectedProducts || []).map(
            ({ external_platform_id, name, ...p }) => ({
              ...p,
              title: name,
              id: external_platform_id,
            })
          )}
          queryString={productsFilterQuery}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default FeaturePageWrapper(
  MessageTimelinePage,
  FEATURES_SLUG.PRE_SHIPMENT
);
