import React from 'react';
import { BlockStack } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { Modal } from '@shopify/polaris';

import RushModal from 'components/custom/RushModal';
export default function UnsavedChangesModal({
  unsavedModalOpen,
  showUnsavedModal,
  unsavedModalFns,
}) {
  const [t] = useTranslation();
  return (
    <RushModal
      open={unsavedModalOpen}
      onClose={showUnsavedModal}
      title={t('unsaved_changes_modal.title')}
      primaryAction={{
        content: t('unsaved_changes_modal.confirm_btn'),
        destructive: true,
        onAction: () => {
          unsavedModalFns();
          showUnsavedModal();
        },
      }}
      secondaryActions={[
        {
          content: t('unsaved_changes_modal.cancel_btn'),
          onAction: showUnsavedModal,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap={'200'}>
          <p>{t('unsaved_changes_modal.description')}</p>
        </BlockStack>
      </Modal.Section>
    </RushModal>
  );
}
