import { InlineStack, Box, IndexFiltersMode } from '@shopify/polaris';
import React, { useMemo } from 'react';
import CustomIndexTable from './CustomIndexTable';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';

function EditColumns({ data, columns = [], onColumnsChange }) {
  const changeVisibility = (column, selected) => {
    const updatedColumns = columns.map((c) => {
      if (c.title === column.title) {
        return {
          ...c,
          selected,
        };
      } else {
        return {
          ...c,
        };
      }
    });
    onColumnsChange(updatedColumns);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedColumns = Array.from(columns);
    const [removed] = updatedColumns.splice(result.source.index, 1);
    updatedColumns.splice(result.destination.index, 0, removed);
    onColumnsChange(updatedColumns);
  };

  const columnsMarkup = useMemo(() => {
    return columns.map((column, index) => (
      <Draggable
        key={column.title}
        draggableId={column.title}
        index={index}
        isDragDisabled={column.disabled}
      >
        {(provided) => (
          <div
            className={`edit-column ${column.selected ? '' : 'disabled'}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Box borderColor="border-brand" borderWidth="1" borderRadius="2">
              <CustomIndexTable
                tableProps={{
                  data: data,
                  columns: [column],
                  selectable: false,
                }}
                mode={IndexFiltersMode.EditingColumns}
                changeVisibility={changeVisibility}
              />
              {provided.placeholder}
            </Box>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    ));
  }, [data, columns]);

  return (
    <Box padding={'400'}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              <InlineStack gap={'200'} wrap={false}>
                {columnsMarkup}
              </InlineStack>
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
}

export default EditColumns;
