import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  FormLayout,
  TextField,
  Card,
  Spinner,
  Text,
  InlineStack,
  BlockStack,
  Modal,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetChargeLimitQuery,
  useUpdateChargeLimitMutation,
} from '../billingApis';
import './styles.scss';
import { billingActions } from '../billingSlice';
import RushModal from 'components/custom/RushModal';
export default function IncreaseLimitModal() {
  const [t] = useTranslation();
  const [newLimit, setNewLimitText] = useState(null);
  const dispatch = useDispatch();
  const { showChangeLimitModal } = useSelector((state) => state.billing);

  const { data: chargeLimit = {}, isLoading } = useGetChargeLimitQuery(
    undefined,
    {
      skip: !showChangeLimitModal,
    }
  );
  const [
    updateChargeLimit,
    {
      isLoading: isUpdating,
      error: { data: { errors: chargeLimitError } = {} } = {},
      data: chargeLimitResponse = {},
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateChargeLimitMutation();

  useEffect(() => {
    if (!isUpdating && isUpdateSuccess) {
      window.top.location.replace(chargeLimitResponse.charge_url);
    }
  }, [isUpdating, isUpdateSuccess, chargeLimitResponse]);

  const handleClose = () => {
    reset();
    dispatch(billingActions.toggleChargeLimitModal());
  };

  const handleNewLimitChange = useCallback((value) => {
    if (value > 10000) {
      return;
    }
    setNewLimitText(value);
  }, []);

  useEffect(() => {
    if (chargeLimit.charge_limit && showChangeLimitModal) {
      let forecastedUsage = chargeLimit?.charge_limit?.forecasted_usage;
      let chargeLimitValue = chargeLimit?.charge_limit?.charge_limit;

      const value =
        Math.ceil(Math.max(forecastedUsage * 1.5, chargeLimitValue) * 0.1) * 10;
      if (value > 0) {
        setNewLimitText(`${value}`);
      }
    }
  }, [chargeLimit, showChangeLimitModal, handleNewLimitChange]);

  return (
    <RushModal
      open={showChangeLimitModal}
      onClose={handleClose}
      title={t('billing.charge_limit_modal.title')}
      primaryAction={{
        content: t('billing.charge_limit_modal.apply_button_label'),
        onClick: () => updateChargeLimit(newLimit),
        disabled:
          isUpdating ||
          !newLimit ||
          Number(newLimit) <= 0 ||
          Number(newLimit) === chargeLimit?.charge_limit?.charge_limit,
        loading: isUpdating,
      }}
    >
      {isLoading ? (
        <Card roundedAbove="sm">
          <br />
          <br />
          <InlineStack blockAlign="center" align="center">
            <Spinner />
          </InlineStack>
          <br />
          <br />
        </Card>
      ) : (
        <Modal.Section>
          {chargeLimit.charge_limit && (
            <>
              <BlockStack gap={'500'}>
                <InlineStack>
                  <div className="ChangeLimit-Description">
                    {t('billing.charge_limit_modal.description')}
                  </div>
                </InlineStack>
                <InlineStack align="space-between">
                  <BlockStack gap="200">
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      {t('billing.charge_limit_modal.charge_limit_field')}
                    </Text>
                    <Text variant="bodyMd" as="span" color="subdued">
                      ${chargeLimit?.charge_limit?.charge_limit.toFixed(2)}
                    </Text>
                  </BlockStack>
                  <Form onSubmit={() => updateChargeLimit(newLimit)}>
                    <FormLayout>
                      <FormLayout.Group>
                        <TextField
                          autoComplete="off"
                          label={
                            <Text
                              variant="bodyMd"
                              as="span"
                              fontWeight="semibold"
                            >
                              {t('billing.charge_limit_modal.new_charge_limit')}
                            </Text>
                          }
                          type="number"
                          value={newLimit}
                          onChange={handleNewLimitChange}
                          autoFocus={true}
                          disabled={isUpdating}
                          error={chargeLimitError?.display?.description}
                          id="input-new-limit"
                          prefix={'$'}
                          min={1}
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Form>
                </InlineStack>
              </BlockStack>
            </>
          )}
        </Modal.Section>
      )}
    </RushModal>
  );
}
