import { InlineStack, Text, Button, Card } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CustomToggleSettings(props) {
  const {
    isActive,
    content,
    buttonLabel,
    onToggle,
    loading = false,
    disabled = false,
  } = props;
  const [t] = useTranslation();

  const toggleFeatureToggleLabel =
    buttonLabel ||
    (!isActive
      ? t('settings_page.addon_card.button.label_activate')
      : t('settings_page.addon_card.button.label_deactivate'));

  return (
    <Card roundedAbove="sm">
      <InlineStack align="space-between" blockAlign="center" wrap={false}>
        <Text variant="bodyMd" as="h6">
          {content}
        </Text>
        <Button
          onClick={onToggle}
          size="slim"
          variant={!isActive ? 'primary' : undefined}
          tone={!isActive ? 'success' : undefined}
          loading={loading}
          disabled={disabled}
        >
          {toggleFeatureToggleLabel}
        </Button>
      </InlineStack>
    </Card>
  );
}

export default CustomToggleSettings;
