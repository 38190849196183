import React, { useEffect, useState, useMemo } from 'react';
import {
  Card,
  IndexFilters,
  useSetIndexFiltersMode,
  Pagination,
  InlineStack,
  Box,
  IndexFiltersMode,
  SkeletonDisplayText,
} from '@shopify/polaris';
import CustomIndexTable from './CustomIndexTable';
import EditColumns from './EditColumns';
import { isEqual } from 'lodash';
import TableSkeleton from 'components/Skeletons/TableSkeleton';
import WithoutChecksTableSkeleton from 'components/Skeletons/WithoutChecksTableSkeleton';
import './styles.scss';

function FilterTable(props) {
  const {
    isLoading,
    filterProps = {},
    tableProps = {},
    tabProps = {},
    paginationProps = {},
  } = props;

  const { onSort, queryValue, appliedFilters, onClearAll } = filterProps;
  const {
    columns = [],
    onColumnsChange,
    allowColumnsEdit,
    data = [],
  } = tableProps;
  const { tabs, onTabChange, selectedTab } = tabProps;
  const { mode, setMode } = useSetIndexFiltersMode();

  const [editedColumns, setEditedColumns] = useState(columns);

  useEffect(() => {
    if (queryValue || (appliedFilters && appliedFilters.length > 0)) {
      setMode(IndexFiltersMode.Filtering);
    }
  }, [queryValue, appliedFilters, setMode]);

  useEffect(() => {
    setEditedColumns(columns);
  }, [columns]);

  const tabItems = useMemo(
    () =>
      tabs.map((item, index) => ({
        content: item,
        index,
        onAction: () => { },
        id: `${item}-${index}`,
        isLocked: index === 0,
        actions: allowColumnsEdit
          ? [
            {
              type: 'edit-columns',
              onAction: () => {
                setMode(IndexFiltersMode.EditingColumns);
              },
            },
          ]
          : [],
      })),
    [tabs, allowColumnsEdit, setMode]
  );

  const handleSort = (value) => {
    onSort(value);
  };

  const saveEditedColumns = () => {
    onColumnsChange(editedColumns);
    setMode(IndexFiltersMode.Default);
  };

  const renderSkeleton = useMemo(
    () =>
      tableProps.selectable ? (
        <TableSkeleton />
      ) : (
        <WithoutChecksTableSkeleton />
      ),
    [tableProps.selectable]
  );

  const renderTableSection = useMemo(() => {
    if (mode === IndexFiltersMode.EditingColumns) {
      return (
        <EditColumns
          data={data}
          columns={editedColumns}
          onColumnsChange={setEditedColumns}
        />
      );
    } else {
      return (
        <CustomIndexTable
          tableProps={{
            ...tableProps,
            columns: tableProps.columns.filter((c) => c.selected),
          }}
        />
      );
    }
  }, [mode, data, editedColumns, tableProps]);

  return (
    <div className="common-filter-table">
      <Card roundedAbove="sm" padding={0}>
        <IndexFilters
          {...filterProps}
          loading={isLoading}
          tabs={tabItems}
          selected={selectedTab}
          onSelect={onTabChange}
          canCreateNewView={false}
          mode={mode}
          setMode={setMode}
          cancelAction={{
            onAction: () => {
              if (mode === IndexFiltersMode.EditingColumns) {
                setEditedColumns(columns);
              } else if (mode === IndexFiltersMode.Filtering) {
                onClearAll();
              }
            },
            disabled: false,
            loading: false,
          }}
          primaryAction={
            mode === IndexFiltersMode.EditingColumns
              ? {
                onAction: saveEditedColumns,
                disabled: isEqual(columns, editedColumns),
                loading: false,
                type: 'save',
              }
              : undefined
          }
          onSort={handleSort}
        />
        <div id="filter-table">
          {isLoading ? renderSkeleton : renderTableSection}
        </div>
        {data.length === 0 ? (
          <br />
        ) : (
          <Box padding={'500'}>
            <InlineStack align="center">
              {isLoading ? (
                <Box width="150px">
                  <SkeletonDisplayText size="small" />
                </Box>
              ) : (
                <Pagination {...paginationProps} />
              )}
            </InlineStack>
          </Box>
        )}
      </Card>
    </div>
  );
}

export default FilterTable;
