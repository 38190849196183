import {
  Card,
  InlineStack,
  Text,
  Select,
  BlockStack,
  Button,
  Box,
  Divider,
} from '@shopify/polaris';
import React, { useMemo } from 'react';
import '../styles.scss';
import RushLogo from 'images/tryrush-icon.svg';
import ArrowRightMinor from 'images/ArrowRightMinor.svg';
import { useTranslation } from 'react-i18next';

function Step1(props) {
  const {
    icon,
    integrationName,
    storesList = [],
    onStoreChange,
    onCancel,
    onContinue,
    selectedStore,
    loading,
    alreadyConnected,
  } = props;
  const [t] = useTranslation();

  const storeName = useMemo(() => {
    return storesList.find(({ value }) => value === selectedStore)?.label;
  }, [storesList, selectedStore]);

  return (
    <Card padding={0}>
      <Box width="600px" height="400px" padding={{ sm: '0', md: '5' }}>
        <BlockStack gap={800} align="center" inlineAlign="center">
          <Text variant="heading3xl" as="h2" alignment="center">
            {t('install_modal.step1.title')}
          </Text>
          <InlineStack align="center" gap={600}>
            <img src={RushLogo} alt={'logo'} width={'40px'} height={'40px'} />
            <img src={ArrowRightMinor} />
            <img src={icon} alt={'logo'} width={'40px'} height={'40px'} />
          </InlineStack>
          <Box width="70%">
            <InlineStack align="center">
              <Text> {t('install_modal.step1.description')}</Text>
            </InlineStack>
          </Box>
          <Box width="70%">
            <Select
              options={storesList}
              onChange={onStoreChange}
              value={selectedStore}
              error={
                alreadyConnected
                  ? t('install_modal.store_already_connected', {
                    store_name: storeName,
                    integration_name: integrationName,
                  })
                  : ''
              }
            />
          </Box>
        </BlockStack>
      </Box>
      <Divider />
      <Box padding="400">
        <InlineStack gap="200" align="end">
          <Button size="medium" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            primary
            onClick={onContinue}
            loading={loading}
            disabled={alreadyConnected}
          >
            {t('common.continue')}
          </Button>
        </InlineStack>
      </Box>
    </Card>
  );
}

export default Step1;
