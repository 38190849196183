import { storeApi } from '../../redux/store/storeApi';

export const dashboardApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getShipmentsPerformance: builder.query({
      query: () => {
        return {
          url: `/reports/shipments-performance`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getReports: builder.mutation({
      query: (params) => {
        return {
          url: `/reports`,
          method: 'POST',
          body: { ...params },
        };
      },
    }),

    getDeliveryTimes: builder.query({
      query: ({ from, to }) => {
        return {
          url: `/reports/delivery-times?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getDestinations: builder.query({
      query: ({ from, to }) => {
        return {
          url: `/reports/shipment-destinations?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getShipmentsPerStatus: builder.query({
      query: ({ from, to }) => {
        return {
          url: `/reports/shipments-statuses?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getCarrierUsage: builder.query({
      query: ({ from, to }) => {
        return {
          url: `/reports/shipment-carriers?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetShipmentsPerformanceQuery,
  useGetReportsMutation,
  useGetDeliveryTimesQuery,
  useGetDestinationsQuery,
  useGetShipmentsPerStatusQuery,
  useGetCarrierUsageQuery,
} = dashboardApi;
