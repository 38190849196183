import { baseApi } from 'redux/store/baseApi';

export const fullfilmentEventsApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateFullfilmentNotification: builder.mutation({
      query: ({ type, storeId }) => {
        return {
          url: `/notifications/${storeId}/shopify/${type}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['settings'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const { useUpdateFullfilmentNotificationMutation } =
  fullfilmentEventsApis;
