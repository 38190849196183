import {
  Card,
  Checkbox,

  PageActions,
  BlockStack,
  TextField,
  Text,
  InlineStack,
} from '@shopify/polaris';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GeoLocationTargetCard from './GeoLocationTargetCard';
import TransitTimeCard from './TransitTimeCard';
import RushPage from 'components/custom/RushPage';

function TransitTimePage({
  times,
  onChange,
  onDelete,
  timeIndex,
  onBack,
  transitRangeError,
  showContryError,
  setShowContryError,
}) {
  const [t] = useTranslation();

  const [currTimeIndex, setCurrTimeIndex] = useState(timeIndex);

  useEffect(() => {
    setCurrTimeIndex(timeIndex);
  }, [timeIndex]);

  const currTime = useMemo(() => {
    return times[currTimeIndex];
  }, [times, currTimeIndex]);

  const handleChange = (key, value) => {
    let updated = { ...currTime, [key]: value };
    if (key === 'date_range') {
      updated = { ...updated, single_day: null };
    } else if (key === 'single_day') {
      updated = { ...updated, date_range: null };
    } else if (key === 'override_order_fulfillment_days') {
      updated = { ...updated, override_order_fulfillment_days_value: 2 };
    }
    if (key === 'geo_location_targeted') {
      setShowContryError(false);
    }
    onChange(updated, currTimeIndex);
  };

  const handleBack = () => {
    /**
     * if user tries to go back without selecting country set all locations
     */
    if (showContryError) {
      let updated = { ...currTime, geo_location_targeted: null };
      onChange(updated, currTimeIndex);
    }
    onBack();
  };

  return (
    <RushPage
      title={t('smart-edd.transit-time-page.title')}
      backAction={{ onAction: handleBack }}
    >
      <BlockStack gap="500">
        <GeoLocationTargetCard
          geo_location_targeted={currTime?.geo_location_targeted}
          onChange={handleChange}
          showError={showContryError}
        />
        <TransitTimeCard
          date_range={currTime?.date_range}
          single_day={currTime?.single_day}
          onChange={handleChange}
          error={transitRangeError}
        />
        <Card roundedAbove="sm">
          <Text variant="headingSm" as="h4">
            {t('smart-edd.transit-time-page.override-fulfillment')}
          </Text>
          <Checkbox
            label={t('smart-edd.transit-time-page.override')}
            checked={currTime && currTime['override_order_fulfillment_days']}
            onChange={(val) => {
              handleChange('override_order_fulfillment_days', val);
            }}
          />
          {currTime && currTime['override_order_fulfillment_days'] ? (
            <InlineStack gap={'200'}>
              <TextField
                autoComplete={'off'}
                min={0}
                max={90}
                prefix={t('smart-edd.transit-time-rules.business_days')}
                value={Number(currTime?.override_order_fulfillment_days_value)}
                onChange={(val) => {
                  if (val >= 0 && val <= 90) {
                    handleChange(
                      'override_order_fulfillment_days_value',
                      Number(val)
                    );
                  }
                }}
                type="number"
              />
            </InlineStack>
          ) : (
            ''
          )}
        </Card>
      </BlockStack>

      {timeIndex === -1 ? (
        ''
      ) : (
        <PageActions
          secondaryActions={[
            {
              content: t('common.delete'),
              destructive: true,
              onAction: () => {
                onDelete(currTimeIndex);
              },
              outline: true,
              loading: false,
            },
          ]}
        />
      )}
    </RushPage>
  );
}

export default TransitTimePage;
