import React, { useMemo } from 'react';
import {
  Box,
  Link,
  Badge,
  SkeletonBodyText,
  Text,
  BlockStack,
} from '@shopify/polaris';
import { DB_DATE_FORMAT_STRING } from 'Constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { STORE_URLS } from 'config/urls';
import { useGetShipmentsPerStatusQuery } from 'pages/Dashboard/DashboardApi';
import InfoTable from '../InfoTable';
import { getShipmentBadge } from 'Utils/BadgeUtils';
import { useParams } from 'react-router-dom';
export default function ShipmentPerStatus({ selectedDates = {} }) {
  const [t] = useTranslation();
  const params = useParams();

  const from = useMemo(() => {
    return moment(selectedDates.start).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.start]);
  const to = useMemo(() => {
    return moment(selectedDates.end).format(DB_DATE_FORMAT_STRING);
  }, [selectedDates.end]);

  const shipmentStatusesResponse = useGetShipmentsPerStatusQuery(
    { from, to },
    { skip: !(from || to) }
  );
  const {
    data: shipmentsPerStatus = [],
    isFetching,
    isSuccess,
  } = shipmentStatusesResponse || {};

  const shipmentsPerStatusData = useMemo(() => {
    return shipmentsPerStatus.map((dataItem) => {
      const shipmentBadge = getShipmentBadge(dataItem.slug);
      return [
        <Badge
          key={dataItem.slug}
          tone={shipmentBadge.tone}
          progress={shipmentBadge.progress}
        >
          {dataItem.title}
        </Badge>,
        <Link
          key={`${STORE_URLS.SHIPMENTS}?${dataItem.shipment_get_query}`}
          url={`/s/${params.uuid}${STORE_URLS.SHIPMENTS}?${dataItem.shipment_get_query}`}
        >
          {dataItem.total}
        </Link>,
        <Text
          key={`.${STORE_URLS.SHIPMENTS}?${dataItem.shipment_get_query}`}
          variant="bodyMd"
          as="span"
          color="subdued"
        >
          {' '}
          {dataItem.percentage}
        </Text>,
      ];
    });
  }, [shipmentsPerStatus]);

  return (
    <Box padding={'500'}>
      <BlockStack gap="300">
        <Text variant="headingSm" as="span">
          {t('dashboard.analytics_sheet.shipment_status_title')}
        </Text>
        {isFetching || !isSuccess ? (
          <BlockStack gap="0">
            <SkeletonBodyText />
          </BlockStack>
        ) : (
          <InfoTable data={shipmentsPerStatusData} />
        )}
      </BlockStack>
      {!isFetching && shipmentsPerStatus?.length === 0 && (
        <div className="CustomEmptyState">
          <Text variant="bodyMd" as="span" color="subdued">
            {t('dashboard.analytics_sheet.no_row_message')}
          </Text>
        </div>
      )}
    </Box>
  );
}
