import { Banner } from '@shopify/polaris';
import { LOCAL_STORAGE } from 'Constants';
import React, { useEffect, useState } from 'react';
import { ClientStorage } from 'ClientStorage';

function RushBanner(props) {
  const { children, bannerKey, ..._pageProps } = props;
  const [dismissedBanners, setDismissedBanners] = useState([]);
  const [storageLoaded, setStorageLoaded] = useState(false);

  useEffect(() => {
    let storedDismissedBanners = '[]';
    try {
      // Retrieve dismissed banners from local storage
      storedDismissedBanners = ClientStorage.get(
        LOCAL_STORAGE.RUSH_CLOSED_BANNERS
      );
      // eslint-disable-next-line no-empty
    } catch {}
    if (
      typeof storedDismissedBanners === 'string' &&
      storedDismissedBanners !== 'undefined'
    ) {
      setDismissedBanners(JSON.parse(storedDismissedBanners));
    }
    setStorageLoaded(true);
  }, []);

  const handleDismiss = () => {
    // Add the dismissed banner key to the local storage
    const updatedDismissedBanners = [...dismissedBanners, bannerKey];
    try {
      ClientStorage.set(
        LOCAL_STORAGE.RUSH_CLOSED_BANNERS,
        JSON.stringify(updatedDismissedBanners)
      );
      // eslint-disable-next-line no-empty
    } catch {}
    setDismissedBanners(updatedDismissedBanners);
  };

  // Check if the bannerKey is in the dismissedBanners array
  const isBannerDismissed = dismissedBanners.includes(bannerKey);

  return !isBannerDismissed && storageLoaded ? (
    <Banner {..._pageProps} onDismiss={handleDismiss}>
      {children}
    </Banner>
  ) : null;
}

export default RushBanner;
