import React from 'react';
import {
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useSyncOrderMutation,
} from '../orderSyncApi';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StoreUUID } from 'redux/store/shopSlice';
import { STORE_URLS } from 'config/urls';
import { useNavigate } from 'react-router-dom';
import LoadingPage from 'components/LoadingPage';

function OrderSync({ isSyncCase, externalId }) {
  const storeUUID = useSelector(StoreUUID);
  const navigate = useNavigate();
  const [
    getOrderInfo,
    {
      data: orderInfoLazy,
      isLoading: isOrderLoadingLazy,
      isSuccess: isOrderLoadedLazy,
    },
  ] = useLazyGetOrderQuery(externalId);

  const {
    data: orderInfo,
    isLoading: isOrderLoading,
    isSuccess: isOrderLoaded,
    isError: isOrderError,
  } = useGetOrderQuery(externalId, { skip: isSyncCase });

  const [syncOrder, { isLoading: isSyncing, isSuccess: isSynced }] =
    useSyncOrderMutation();

  useEffect(() => {
    if (!isOrderLoadingLazy && isOrderLoadedLazy) {
      navigate(
        `/s/${storeUUID}${STORE_URLS.SHIPMENTS}?search=${encodeURIComponent(
          orderInfoLazy?.label
        )}`
      );
    }
  }, [isOrderLoadingLazy, isOrderLoadedLazy]);

  useEffect(() => {
    if (!isOrderLoading && isOrderLoaded) {
      navigate(
        `/s/${storeUUID}${STORE_URLS.SHIPMENTS}?search=${encodeURIComponent(
          orderInfo?.label
        )}`
      );
    }
  }, [isOrderLoading, isOrderLoaded]);

  useEffect(() => {
    if (!isOrderLoading && isOrderError) {
      syncOrder(externalId);
    }
  }, [isOrderLoading, isOrderError, externalId]);

  useEffect(() => {
    if (!isSyncing && isSynced) {
      setTimeout(() => {
        getOrderInfo(externalId);
      }, 5000);
    }
  }, [isSyncing, isSynced]);

  useEffect(() => {
    if (isSyncCase) {
      syncOrder(externalId);
    }
  }, [isSyncCase]);

  return <LoadingPage />;
}

export default OrderSync;
