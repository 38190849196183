import {  Text,Modal } from '@shopify/polaris';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import withActionsHOC from './withActionsHOC';
import RushModal from 'components/custom/RushModal';
function PublishModal(props) {
  const { onClose, publishPage, isPublishing, isPublished } = props;
  const [t] = useTranslation();
  const publishModalTitle = t('tracking_page_new_publish_modal_title');
  const publishModalDescription = t(
    'tracking_page_new_publish_modal_description'
  );

  useEffect(() => {
    if (!isPublishing && isPublished) {
      onClose();
    }
  }, [isPublishing, isPublished]);

  return (
    <>
      <RushModal
        open={open}
        onClose={onClose}
        title={publishModalTitle}
        primaryAction={{
          content: t('tracking_page_new.publish'),
          onAction: publishPage,
          loading: isPublishing,
        }}
        secondaryActions={[
          {
            content: t('common.cancel'),
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <Text as="span" color="subdued">
            {publishModalDescription}
          </Text>
        </Modal.Section>
      </RushModal>
    </>
  );
}

export default withActionsHOC(PublishModal);
