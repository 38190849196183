import axios from 'axios';
import _ from 'lodash';
import i18next from 'i18next';
import { CANCEL } from 'redux-saga';
import moment from 'moment';
import { ClientStorage } from 'ClientStorage';

const CancelToken = axios.CancelToken;
export class API {
  static headers() {
    return {
      'Content-Type': 'application/json',
      utc_hours_offset: moment().utcOffset(),
    };
  }

  static request(options) {
    options.headers = _.merge(this.headers(), options.headers);
    if (!options.skipAcceptLang) {
      options.headers['Accept-Language'] =
        ClientStorage.get('rush_app_language') || i18next.language;
    }
    const source = CancelToken.source();
    options.cancelToken = source.token;

    const request = axios(options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.toString() === 'Cancel') {
          console.error('request cancelled');
          return;
        }

        if (
          !error.response ||
          (error.response && error.response.status >= 500)
        ) {
          console.error('error_toaster.server_fail_500');

          throw error.response;
        } else if (error.message === 'Network Error') {
          axios({
            mehtod: 'get',
            url: 'https://www.rush.app/',
          })
            .then(() => {
              console.error('error_toaster.unable_to_reach_server');

              throw error;
            })
            .catch(() => {
              console.error('error_toaster.check_internet_connection');

              throw error;
            });
        } else if (error.response.data.id === 'unauthenticated') {
          const url = `${window.location.origin}/auth${window.location.search}`;
          window.top.location.replace(url);
        } else {
          throw error.response;
        }
      });
    request[CANCEL] = () => source.cancel();
    return request;
  }
}
