import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  InlineStack,
  OptionList,
  Popover,
  Spinner,
  Box,
  TextField,
  BlockStack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useCloneThemeMutation } from 'pages/TrackingPageNew/newTrackingPageApis';
import '../styles.scss';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import RushModal from 'components/custom/RushModal';
function CloneModal({ onClose, selectedThemeId, themes }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [openCloneDD, setOpenCloneDD] = useState(false);
  const [cloneToThemeId, setCloneToThemeId] = useState('');
  const [cloneTemplate, cloneresponse] = useCloneThemeMutation();
  const { isSuccess: cloneSuccess, isLoading: clonning } = cloneresponse;

  const toggleOpenCloneDD = useCallback(
    () => setOpenCloneDD((openCloneDD) => !openCloneDD),
    []
  );

  useEffect(() => {
    if (cloneSuccess) {
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.os2_tracking_page.wizard.cloned_toast'),
          type: 'success',
        })
      );
      onClose();
    }
  }, [cloneSuccess]);

  const handleClone = () => {
    cloneTemplate({
      fromID: selectedThemeId,
      toID: cloneToThemeId,
    });
  };

  const getLabel = (theme) => {
    return theme.is_published ? `${theme.name} (Published)` : theme.name;
  };

  const renderDropdown = () => {
    let theme = themes.find(({ id }) => id === selectedThemeId);
    return (
      <TextField
        autoComplete={'off'}
        label={t('settings.os2_tracking_page.wizard.selected_theme')}
        disabled
        value={getLabel(theme)}
      />
    );
  };

  const cloneDDactivator = (
    <Button onClick={toggleOpenCloneDD} disclosure fullWidth textAlign="left">
      {cloneToThemeId
        ? getLabel(themes.find((t) => t.id === cloneToThemeId))
        : t('settings.os2_tracking_page.wizard.select_theme')}
    </Button>
  );

  const renderCloneDropdown = () => {
    let themeOptions = selectedThemeId
      ? themes.filter(({ id }) => id !== selectedThemeId)
      : themes;
    const options = themeOptions.map((theme) => ({
      label: getLabel(theme),
      value: theme.id,
    }));
    return (
      <BlockStack gap="100">
        <p>{t('settings.os2_tracking_page.wizard.destination_theme')}</p>
        <Popover
          fullWidth
          activator={cloneDDactivator}
          active={openCloneDD}
          onClose={toggleOpenCloneDD}
        >
          <OptionList
            allowMultiple={false}
            title={t('settings.os2_tracking_page.wizard.select_theme')}
            onChange={([selected]) => {
              toggleOpenCloneDD();
              setCloneToThemeId(selected);
            }}
            options={options}
            selected={[cloneToThemeId]}
          />
        </Popover>
      </BlockStack>
    );
  };

  return (
    <RushModal
      title={t('settings.os2_tracking_page.wizard.Clone')}
      open={true}
      onClose={onClose}
    >
      <div className="clonecard-container">
        <Card roundedAbove="sm">
          <Box padding="500">
            {clonning ? (
              <InlineStack align="center">
                <Spinner size="large" />
              </InlineStack>
            ) : (
              <InlineStack align="space-between">
                <Box>{renderDropdown()}</Box>
                <Box>{renderCloneDropdown()}</Box>
              </InlineStack>
            )}
          </Box>
          <Box></Box>
          <InlineStack align="end" gap={'200'}>
            {!clonning ? (
              <Button disabled={!cloneToThemeId} onClick={onClose}>
                {t('common.close')}
              </Button>
            ) : (
              ''
            )}
            {!clonning ? (
              <Button primary disabled={!cloneToThemeId} onClick={handleClone}>
                {t('settings.os2_tracking_page.wizard.Clone')}
              </Button>
            ) : (
              ''
            )}
          </InlineStack>
        </Card>
      </div>
    </RushModal>
  );
}
export default CloneModal;
