import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  Checkbox,
  Select,
  TextField,
  Link,
  Icon,
  ButtonGroup,
  Button,
  BlockStack,
  SkeletonPage,
  SkeletonBodyText,
  InlineError,
  Tooltip,
  RadioButton,
  Text,
  Box,
  InlineStack,
} from '@shopify/polaris';
import SmallTopMessage from 'components/SmallTopMessage';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import { TINY_MCE_KEY } from 'config';
import { ViewIcon, XIcon } from '@shopify/polaris-icons';
import { Editor } from '@tinymce/tinymce-react';
import EmailPreviewModal from '../EmailPreviewModal';
import EmailDeleteModal from '../EmailDeleteModal';
import _ from 'lodash';
import {
  EMAIL_RULE_SEND_AFTER_IMMEDIATE,
  EMAIL_RULE_SEND_AFTER_AFTER,
  EMAIL_RULE_STATUS_OFF,
  EMAIL_RULE_STATUS_ON,
  EMAIL_RULE_SUB_STATUS_ANY_TEXT,
  EMAIL_RULE_SUB_STATUS_ANY_VALUE,
} from 'Constants';
import TemplateChangeModal from '../TemplateChangeModal';
import EmailTestModal from '../EmailTestModal';
import CustomHistoryPush from 'helpers/redirect';
import './style.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { STORE_URLS } from 'config/urls';
import i18next from 'i18next';
import RushPage from 'components/custom/RushPage';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
import RushSaveBar from 'components/custom/RushSaveBar';
export default function EmailNotificationRule({
  getEmailTriggersMeta,
  emailTriggerMeta,
  emailToaster,
  resetEmailToaster,
  postEmailTrigger,
  getEmailTriggerById,
  emailTriggerDetail,
  emailTriggerLoading,
  putEmailTriggerById,
  emailTriggerDetailDeleted,
  resetEmailTriggerDetail,
  emailTriggerCreated,
  emailTriggerDetailSaved,
  loading,
  shop,
  ...props
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const topBarUnsavedMessage = t(
    'emails.trigger_page.toaster.top_bar_rule_unsaved_message'
  );
  const topBarSavedMessage = t(
    'emails.trigger_page.toaster.top_bar_rule_saved_message'
  );
  const [contextualBarMsg, setContextualBarMsg] = useState(topBarSavedMessage);

  // toast section
  const [emailTriggerById, setEmailTriggerById] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [isShowToastError, setIsShowToastError] = useState(false);
  const [showToastText, setShowToastText] = useState(null);

  const [showEmailDeleteModal, setShowEmailDeleteModal] = useState(false);
  const closeEmailDeleteModal = useCallback(() => {
    setShowEmailDeleteModal(false);
    setEmailTriggerById(null);
  }, []);

  const [showEmailTestModal, setShowEmailTestModal] = useState(false);
  const closeEmailTestModal = useCallback(() => {
    setShowEmailTestModal(false);
    setEmailTriggerById(null);
  }, []);

  const dismissShowToast = useCallback(() => {
    setShowToast((active) => !active);
    resetEmailToaster();
    setEmailTriggerById(null);
  }, [resetEmailToaster]);

  useEffect(() => {
    if (showToast) {
      dispatch(
        baseActions.setToastMessage({
          message: showToastText,
          type: isShowToastError ? 'error' : 'success',
          onDismiss: dismissShowToast,
        })
      );
    }
  }, [showToast]);

  const setValuesForToaster = (emailToaster) => {
    setShowToast(emailToaster.showToast);
    setIsShowToastError(emailToaster.isErrorToast);
    setShowToastText(emailToaster.toastText);
  };
  const setErrorValuesForToaster = (toastText, setError) => {
    setShowToast(true);
    setIsShowToastError(true);
    setShowToastText(toastText);
    if (_.isFunction(setError)) {
      setError(toastText);
    }
  };

  useEffect(() => {
    if (emailTriggerCreated) {
      setContextualBarMsg(t('emails.trigger_page.toaster.rule_saved'));
      setRuleId(emailTriggerDetail.id);
    }
  }, [emailTriggerCreated, t, emailTriggerDetail]);
  useEffect(() => {
    if (emailTriggerDetailSaved) {
      setContextualBarMsg(t('emails.trigger_page.toaster.rule_updated'));
    }
  }, [emailTriggerDetailSaved, t]);

  useEffect(() => {
    setValuesForToaster(emailToaster);
  }, [emailToaster]);

  // when sections
  const [ruleId, setRuleId] = useState(params.notificationId);
  const [whenTriggerValue, setWhenTriggerValue] = useState(undefined);
  const [whenTriggerObject, setWhenTriggerObject] = useState(null);
  const [whenTriggerDurationText, setWhenTriggerDurationText] = useState('');
  const [whenTriggerDurationError, setWhenTriggerDurationError] =
    useState(null);

  // status checkbox section
  const [selectedStatusCheckBox, setSelectedStatusCheckBox] = useState(null);

  const [selectedStatusObject, setSelectedStatusObject] = useState(null);
  const [selectedSubStatusCheckBox, setSelectedSubStatusCheckBox] = useState(
    []
  );
  const [statusCheckBoxesList, setStatusCheckBoxesList] = useState([]);

  // send email section
  const [sendEmailSelectedValue, setSendEmailSelectedValue] =
    useState(undefined);
  const [sendEmailDurationText, setSendEmailDurationText] = useState('');
  const [sendEmailDurationError, setSendEmailDurationError] = useState(null);

  // status
  const [ruleStatus, setRuleStatus] = useState(null);

  // template section
  const [selectedTemplateObject, setSelectedTemplateObject] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailSubjectError, setEmailSubjectError] = useState(null);
  const [emailBody, setEmailBody] = useState(null);
  const [emailBodyError, setEmailBodyError] = useState(null);
  const [emailPreviewObject, setEmailPreviewObject] = useState(null);
  const [showTemplateChangeModal, setShowTemplateChangeModal] = useState(null);

  // when section callback.
  const handleWhenTriggerValue = useCallback(
    (value) => {
      setWhenTriggerValue(value);
      setContextualBarMsg(topBarUnsavedMessage);
    },
    [topBarUnsavedMessage]
  );
  const handleWhenTriggerDurationTextChange = useCallback((value) => {
    setWhenTriggerDurationText(value);
    setWhenTriggerDurationError(null);
  }, []);
  const handleWhenTriggerDurationTextBlur = useCallback(() => {
    if (
      whenTriggerDurationText.search(
        /^((\d+m)+\s*)*((\d+w)+\s*)*((\d+d)+\s*)*((\d+h)+\s*)*((\d+m)+\s*)*$/i
      ) < 0
    ) {
      setWhenTriggerDurationError(
        t('emails.trigger_page.card.triggers.when_duration_error')
      );
    } else {
      setWhenTriggerDurationError(null);
    }
    setContextualBarMsg(topBarUnsavedMessage);
  }, [whenTriggerDurationText, t, topBarUnsavedMessage]);

  // status checkbox,
  const handleStatusCheckBoxes = useCallback(
    (selectedValue) => {
      setSelectedStatusCheckBox(selectedValue);
      setContextualBarMsg(topBarUnsavedMessage);
      setSelectedSubStatusCheckBox([EMAIL_RULE_SUB_STATUS_ANY_VALUE]);
    },
    [topBarUnsavedMessage]
  );

  const handleSubStatusCheckBoxes = (selectedValue) => {
    let tempSubStatus;
    if (
      selectedValue === EMAIL_RULE_SUB_STATUS_ANY_VALUE &&
      !selectedSubStatusCheckBox.includes(selectedValue)
    ) {
      setSelectedSubStatusCheckBox([EMAIL_RULE_SUB_STATUS_ANY_VALUE]);
    } else {
      if (selectedSubStatusCheckBox.includes(selectedValue)) {
        tempSubStatus = selectedSubStatusCheckBox.filter(
          (item) => item !== selectedValue
        );
      } else {
        tempSubStatus = [...selectedSubStatusCheckBox, selectedValue];
      }
      if (
        tempSubStatus.length > 1 &&
        tempSubStatus.includes(EMAIL_RULE_SUB_STATUS_ANY_VALUE)
      ) {
        tempSubStatus = tempSubStatus.filter(
          (item) => item !== EMAIL_RULE_SUB_STATUS_ANY_VALUE
        );
      } else if (tempSubStatus.length === 0) {
        tempSubStatus = [EMAIL_RULE_SUB_STATUS_ANY_VALUE];
      }
      setSelectedSubStatusCheckBox(tempSubStatus);
    }
    setContextualBarMsg(topBarUnsavedMessage);
  };

  // send email section.
  const handleSendEmailSelectedValueChange = useCallback(
    (value) => {
      setSendEmailSelectedValue(value);
      setContextualBarMsg(topBarUnsavedMessage);
    },
    [topBarUnsavedMessage]
  );
  const sendEmailOptions = [
    {
      label: t('emails.trigger_page.card.triggers.send_email_immediate'),
      value: EMAIL_RULE_SEND_AFTER_IMMEDIATE,
    },
    {
      label: t('emails.trigger_page.card.triggers.send_email_after'),
      value: EMAIL_RULE_SEND_AFTER_AFTER,
    },
  ];

  const handleSendEmailDurationTextChange = useCallback((value) => {
    setSendEmailDurationText(value);
    setSendEmailDurationError(null);
  }, []);
  const handleSendEmailDurationTextBlur = useCallback(() => {
    if (
      sendEmailDurationText.search(
        /^((\d+m)+\s*)*((\d+w)+\s*)*((\d+d)+\s*)*((\d+h)+\s*)*((\d+m)+\s*)*$/i
      ) < 0
    ) {
      setSendEmailDurationError(
        t('emails.trigger_page.card.triggers.send_email_duration_error')
      );
    } else {
      setSendEmailDurationError(null);
    }
    setContextualBarMsg(topBarUnsavedMessage);
  }, [sendEmailDurationText, t, topBarUnsavedMessage]);

  // email templates
  const handleSelectedTemplateObject = useCallback(
    (template) => {
      setSelectedTemplateObject(template);
      setEmailSubject(template.email_subject);
      setEmailBody(template.email_body);
      setContextualBarMsg(topBarUnsavedMessage);
    },
    [topBarUnsavedMessage]
  );

  const handleEmailSubjectChange = useCallback((value) => {
    setEmailSubject(value);
    setEmailSubjectError(null);
  }, []);
  const handleEmailSubjectBlur = useCallback(() => {
    if (!emailSubject) {
      setEmailSubjectError(
        t('emails.trigger_page.card.triggers.email_subject_error')
      );
    } else {
      setEmailSubjectError(null);
    }
    setContextualBarMsg(topBarUnsavedMessage);
  }, [emailSubject, t, topBarUnsavedMessage]);

  const handleEmailBodyChange = useCallback(
    (value) => {
      setEmailBody(value);
      setEmailBodyError(null);
      setContextualBarMsg(topBarUnsavedMessage);
    },
    [topBarUnsavedMessage]
  );

  const setEmailTriggerDetailValues = useCallback(() => {
    if (emailTriggerDetail) {
      setWhenTriggerValue(emailTriggerDetail.trigger);
      setEmailSubject(emailTriggerDetail.email_subject);
      setEmailBody(emailTriggerDetail.email_body);
      setWhenTriggerDurationText(emailTriggerDetail.filter_duration_label);
      setRuleStatus(
        emailTriggerDetail.is_active
          ? EMAIL_RULE_STATUS_ON
          : EMAIL_RULE_STATUS_OFF
      );
      setSelectedTemplateObject({
        email_subject: emailTriggerDetail.email_subject,
        email_body: emailTriggerDetail.email_body,
      });
      if (emailTriggerDetail.filter_status) {
        setSelectedStatusCheckBox(emailTriggerDetail.filter_status[0]);
      }
      if (emailTriggerDetail.filter_substatus) {
        setSelectedSubStatusCheckBox(emailTriggerDetail.filter_substatus);
      } else {
        setSelectedSubStatusCheckBox([EMAIL_RULE_SUB_STATUS_ANY_VALUE]);
      }
      if (emailTriggerDetail.sent_with_delay_label) {
        setSendEmailDurationText(emailTriggerDetail.sent_with_delay_label);
        setSendEmailSelectedValue(EMAIL_RULE_SEND_AFTER_AFTER);
      } else {
        setSendEmailSelectedValue(EMAIL_RULE_SEND_AFTER_IMMEDIATE);
      }
    }
  }, [emailTriggerDetail]);

  // all the effects goes here.
  useEffect(() => {
    getEmailTriggersMeta();
  }, [getEmailTriggersMeta, i18next.language]);

  useEffect(() => {
    if (emailTriggerMeta.statuses) {
      setStatusCheckBoxesList(_.sortBy(emailTriggerMeta.statuses, 'id'));
    }
    if (emailTriggerMeta.triggers && !ruleId) {
      setWhenTriggerValue(emailTriggerMeta.triggers[0].slug);
    }
  }, [emailTriggerMeta, ruleId]);

  useEffect(() => {
    if (!ruleId) {
      setRuleStatus(EMAIL_RULE_STATUS_OFF);
    }
  }, [ruleId]);

  useEffect(() => {
    const selectedStatus = statusCheckBoxesList
      .map((status) => {
        if (status.id === selectedStatusCheckBox) {
          return status;
        } else {
          return undefined;
        }
      })
      .filter((item) => !_.isUndefined(item));
    setSelectedStatusObject(selectedStatus[0]);
  }, [statusCheckBoxesList, selectedStatusCheckBox]);

  useEffect(() => {
    if (emailTriggerMeta.triggers) {
      const tempObject = emailTriggerMeta.triggers
        .map((trigger) => {
          if (trigger.slug === whenTriggerValue) {
            return trigger;
          } else {
            return undefined;
          }
        })
        .filter((item) => !_.isUndefined(item))[0];
      setWhenTriggerObject(tempObject);
    }
  }, [emailTriggerMeta, whenTriggerValue]);
  useEffect(() => {
    if (params.notificationId && !emailTriggerDetail && !emailTriggerLoading) {
      getEmailTriggerById(params.notificationId);
    }
  }, [props, getEmailTriggerById, emailTriggerDetail, emailTriggerLoading]);
  useEffect(() => {
    if (emailTriggerDetail) {
      setEmailTriggerDetailValues();
    }
  }, [emailTriggerDetail, setEmailTriggerDetailValues]);

  useEffect(() => {
    if (emailTriggerDetailDeleted) {
      resetEmailTriggerDetail();
      CustomHistoryPush.historyPush(
        navigate,
        location,
        `/settings/emailNotification/`
      );
    }
  }, [emailTriggerDetailDeleted, resetEmailTriggerDetail, history]);

  let templateCardActions = {};
  if (selectedTemplateObject) {
    templateCardActions = {
      content: t('emails.preview_change_modal.template_reset_button'),
      onAction: () => {
        setShowTemplateChangeModal(true);
      },
    };
  }
  const saveEmailRule = () => {
    if (_.isNull(selectedTemplateObject)) {
      setErrorValuesForToaster(t('emails.trigger_page.card.template_error'));
      return;
    }
    if (
      whenTriggerObject.support_timelimit &&
      _.isEmpty(whenTriggerDurationText)
    ) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.triggers.when_duration_error'),
        setWhenTriggerDurationError
      );
      return;
    }
    if (
      whenTriggerObject.support_shipment_statuses &&
      _.isNull(selectedStatusCheckBox)
    ) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.status_selection_error')
      );
      return;
    }
    if (
      whenTriggerObject.support_shipment_statuses &&
      !_.isNull(selectedStatusCheckBox) &&
      _.isEmpty(selectedSubStatusCheckBox)
    ) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.sub_status_selection_error')
      );
      return;
    }
    if (
      sendEmailSelectedValue === EMAIL_RULE_SEND_AFTER_AFTER &&
      _.isEmpty(sendEmailDurationText)
    ) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.triggers.send_email_duration_error'),
        setSendEmailDurationError
      );
      return;
    }
    if (!emailSubject) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.triggers.email_subject_error'),
        setEmailSubjectError
      );
      return;
    }
    if (_.isNull(emailBody)) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.triggers.email_body_error'),
        setEmailBodyError
      );
      return;
    }
    let emailObject = {
      is_active: ruleStatus !== EMAIL_RULE_STATUS_OFF,
      trigger_slug: whenTriggerObject.slug,
      email_subject: emailSubject,
      email_body: emailBody,
    };
    if (whenTriggerObject.support_shipment_statuses) {
      emailObject['filter_status'] = [selectedStatusCheckBox];
      const tempSubStatus = selectedSubStatusCheckBox.filter(
        (item) => item !== EMAIL_RULE_SUB_STATUS_ANY_VALUE
      );
      if (tempSubStatus.length > 0) {
        emailObject['filter_substatus'] = selectedSubStatusCheckBox;
      }
    }

    if (whenTriggerObject.support_timelimit) {
      emailObject['filter_duration_label'] = whenTriggerDurationText;
    }
    if (sendEmailSelectedValue === EMAIL_RULE_SEND_AFTER_AFTER) {
      emailObject['sent_with_delay_label'] = sendEmailDurationText;
    }
    if (ruleId) {
      putEmailTriggerById(ruleId, emailObject);
    } else {
      postEmailTrigger(emailObject);
    }
  };

  if (
    _.isEmpty(emailTriggerMeta) ||
    (params.notificationId && !emailTriggerDetail)
  ) {
    return (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <Card roundedAbove="sm">
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <Card roundedAbove="sm">
              <SkeletonBodyText />
            </Card>
          </Layout.Section>

          <Layout.Section>
            <Card roundedAbove="sm">
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <Card roundedAbove="sm">
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
        </Layout >
      </SkeletonPage >
    );
  }
  const GetSummary = () => {
    if (!whenTriggerValue) {
      return <div />;
    }
    let text;
    const triggerTranslationObject = emailTriggerMeta.triggers
      .map((trigger) => {
        if (trigger.slug === whenTriggerValue) {
          return trigger;
        } else {
          return undefined;
        }
      })
      .filter((item) => !_.isUndefined(item))[0];
    if (selectedStatusObject) {
      text = t('email.trigger_summary_with_statuses', {
        trigger_translation: triggerTranslationObject.label,
        shipment_statuses: selectedStatusObject.label,
      });
    } else {
      text = t('email.trigger_summary', {
        trigger_translation: triggerTranslationObject.label,
      });
    }
    if (
      sendEmailSelectedValue === EMAIL_RULE_SEND_AFTER_AFTER &&
      !_.isEmpty(sendEmailDurationText)
    ) {
      text =
        text +
        t('email.trigger_summary_ends_with_delay', {
          delay: sendEmailDurationText,
        });
    }
    return <TranslateLink text={text} />;
  };
  const sendRequestToDelete = () => {
    setEmailTriggerById(emailTriggerDetail.id);
    setShowEmailDeleteModal(true);
  };
  const sendRequestToTest = () => {
    if (!emailSubject) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.triggers.email_subject_error')
      );
      return;
    }
    if (!emailBody) {
      setErrorValuesForToaster(
        t('emails.trigger_page.card.triggers.email_body_error')
      );
      return;
    }
    setEmailTriggerById(ruleId);
    setShowEmailTestModal(true);
  };
  const discardChanges = () => {
    if (emailTriggerDetail) {
      setEmailTriggerDetailValues();
    } else {
      setWhenTriggerValue(emailTriggerMeta.triggers[0].slug);
      setWhenTriggerDurationText('');
      setSelectedStatusCheckBox(null);
      setSelectedSubStatusCheckBox([]);
      setSendEmailSelectedValue(EMAIL_RULE_SEND_AFTER_IMMEDIATE);
      setSendEmailDurationText('');
      setSelectedTemplateObject(null);
      setEmailSubject('');
      setEmailBody('');
      setRuleStatus(EMAIL_RULE_STATUS_OFF);
    }
    setContextualBarMsg(topBarSavedMessage);
  };
  let secondaryActions = [];
  if (emailTriggerDetail) {
    secondaryActions = [
      // {
      //   content: t('emails.trigger_page.header_actions.duplicate'),
      //   icon: () => { return <Icon source={DuplicateIcon} color="inkLighter" /> },
      // },
      {
        content: t('emails.trigger_page.header_actions.send_test'),
        icon: () => {
          return <Icon source={ViewIcon} color="inkLighter" />;
        },
        onAction: sendRequestToTest,
      },
      {
        content: t('emails.trigger_page.header_actions.delete'),
        icon: () => {
          return <Icon source={XIcon} color="inkLighter" />;
        },
        onAction: sendRequestToDelete,
      },
    ];
  }

  return (
    <>
      <SmallTopMessage />
      <RushPage
        backAction={{
          url: `/s/${shop.storeUUID}${STORE_URLS.EMAIL_NOTIFICATION}`,
        }}
        title={t('emails.trigger_page.title')}
        secondaryActions={secondaryActions}
      >
        <Layout>
          <Layout.Section>
            {contextualBarMsg === topBarUnsavedMessage &&
              selectedTemplateObject && (
                <RushSaveBar
                  alignContentFlush
                  message={contextualBarMsg}
                  saveAction={{
                    onAction: () => saveEmailRule(),
                    loading: loading,
                    disabled: loading,
                    content: t('common.save'),
                  }}
                  discardAction={{
                    onAction: () => discardChanges(),
                    loading: loading,
                    disabled: loading,
                    content: t('common.discard'),
                  }}
                />
              )}
            <Card roundedAbove="sm">
              <BlockStack gap="500">
                <Text variant="headingMd" as="h2">
                  {t('emails.trigger_page.card.triggers.title')}
                </Text>
                <TranslateLink
                  text={t('emails.trigger_page.card.triggers.description')}
                />
              </BlockStack>
              <div className="EmailNotification-Form">
                <div className="EmailNotification-FormColumn">
                  <div>
                    <Select
                      label={t(
                        'emails.trigger_page.card.triggers.select_when_label'
                      )}
                      options={emailTriggerMeta.triggers.map((trigger) => ({
                        value: trigger.slug,
                        label: trigger.label,
                      }))}
                      onChange={handleWhenTriggerValue}
                      value={whenTriggerValue}
                      id="select-email-notification-when"
                    />
                  </div>
                  <TranslateLink
                    text={t(
                      'emails.trigger_page.card.triggers.select_when_description'
                    )}
                  />
                </div>
                {whenTriggerObject && whenTriggerObject.support_timelimit && (
                  <div className="EmailNotification-FormColumn">
                    <TextField
                      type="text"
                      value={whenTriggerDurationText}
                      label={t(
                        'emails.trigger_page.card.triggers.when_duration_label'
                      )}
                      placeholder={t(
                        'emails.trigger_page.card.triggers.when_duration_placeholder'
                      )}
                      onChange={handleWhenTriggerDurationTextChange}
                      onBlur={handleWhenTriggerDurationTextBlur}
                      error={whenTriggerDurationError}
                      id="input-email-notification-when-duration-label"
                      autoComplete={'off'}
                    />
                    <TranslateLink
                      text={t(
                        'emails.trigger_page.card.triggers.when_duration_description'
                      )}
                    />
                  </div>
                )}
              </div>
              {whenTriggerObject &&
                whenTriggerObject.support_shipment_statuses && (
                  <div className="EmailNotification-Form Space">
                    <div className="EmailNotification-FormColumn">
                      <Text variant="bodyMd" as="span">
                        <TranslateLink
                          text={t('emails.trigger_page.card.status_label')}
                        />
                      </Text>
                      <div>
                        {statusCheckBoxesList.map((statusBox) => (
                          <div key={statusBox.id}>
                            <RadioButton
                              label={statusBox.label}
                              checked={selectedStatusCheckBox === statusBox.id}
                              onChange={(e) => {
                                handleStatusCheckBoxes(statusBox.id, e);
                              }}
                              id={`input-email-notification-status-radio-button-${statusBox.id}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    {selectedStatusObject && (
                      <div className="EmailNotification-FormColumn">
                        <Text variant="bodyMd" as="span">
                          <TranslateLink
                            text={t(
                              'emails.trigger_page.card.sub_status_label'
                            )}
                          />
                        </Text>
                        <div>
                          {selectedStatusObject &&
                            selectedStatusCheckBox !==
                            EMAIL_RULE_SUB_STATUS_ANY_VALUE && (
                              <div>
                                <Checkbox
                                  label={EMAIL_RULE_SUB_STATUS_ANY_TEXT}
                                  checked={selectedSubStatusCheckBox.includes(
                                    EMAIL_RULE_SUB_STATUS_ANY_VALUE
                                  )}
                                  onChange={(e) => {
                                    handleSubStatusCheckBoxes(
                                      EMAIL_RULE_SUB_STATUS_ANY_VALUE,
                                      e
                                    );
                                  }}
                                  id={`input-email-notification-sub-status-checkbox-button-${EMAIL_RULE_SUB_STATUS_ANY_VALUE}`}
                                />
                              </div>
                            )}
                          {selectedStatusObject.substatuses &&
                            selectedStatusObject.substatuses.map(
                              (subStatus) => (
                                <div key={subStatus.id}>
                                  <Checkbox
                                    label={subStatus.label}
                                    checked={selectedSubStatusCheckBox.includes(
                                      subStatus.id
                                    )}
                                    onChange={(e) => {
                                      handleSubStatusCheckBoxes(
                                        subStatus.id,
                                        e
                                      );
                                    }}
                                    id={`input-email-notification-sub-status-checkbox-button-${subStatus.id}`}
                                  />
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              <div className="EmailNotification-Form Space">
                <div className="EmailNotification-FormColumn">
                  <div>
                    <Select
                      label={t('emails.trigger_page.card.send_email_label')}
                      options={sendEmailOptions}
                      onChange={handleSendEmailSelectedValueChange}
                      value={sendEmailSelectedValue}
                      id={`select-email-notification-send-email`}
                    />
                  </div>
                </div>
                {sendEmailSelectedValue === EMAIL_RULE_SEND_AFTER_AFTER && (
                  <div className="EmailNotification-FormColumn">
                    <TextField
                      type="text"
                      value={sendEmailDurationText}
                      label={t(
                        'emails.trigger_page.card.triggers.send_email_duration_label'
                      )}
                      onChange={handleSendEmailDurationTextChange}
                      onBlur={handleSendEmailDurationTextBlur}
                      error={sendEmailDurationError}
                      id={`input-email-notification-send-email-duration`}
                      autoComplete={'off'}
                    />
                    <TranslateLink
                      text={t(
                        'emails.trigger_page.card.triggers.send_email_duration_description'
                      )}
                    />
                  </div>
                )}
              </div>
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <Card roundedAbove="sm" padding={0}>
              <Box padding={'500'} paddingBlockEnd={'0'}>
                <Text variant="headingMd" as="h2">
                  {t('emails.trigger_page.card.triggers_summary.title')}
                </Text>
              </Box>
              <Box
                padding={'500'}
                borderBlockEndWidth="100"
                borderColor="border-brand"
              >
                <BlockStack gap={'200'}>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    {t('emails.trigger_page.card.triggers_summary.trigger')}
                  </Text>
                  <GetSummary />
                </BlockStack>
              </Box>
              {emailTriggerMeta && emailTriggerMeta.trigger_active_statuses && (
                <Box
                  padding={'500'}
                  borderBlockEndWidth="100"
                  borderColor="border-brand"
                >
                  <BlockStack gap="200">
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      {t(
                        'emails.trigger_page.card.triggers_summary.status_title'
                      )}
                    </Text>
                    <ButtonGroup segmented fullWidth connectedTop>
                      {emailTriggerMeta.trigger_active_statuses.map(
                        (status) => (
                          <Button
                            key={status.slug}
                            primary={ruleStatus === status.slug}
                            onClick={() => {
                              setRuleStatus(status.slug);
                              setContextualBarMsg(topBarUnsavedMessage);
                            }}
                          >
                            {status.label}
                          </Button>
                        )
                      )}
                    </ButtonGroup>
                  </BlockStack>
                </Box>
              )}
            </Card>
          </Layout.Section>
        </Layout>
        <br />
        <Layout>
          <Layout.Section>
            <Card roundedAbove="sm" padding={0}>
              <Box padding={'500'} paddingBlockEnd={'0'}>
                <InlineStack gap={'500'} align="space-between">
                  <Text variant="headingMd" as="h2">
                    {selectedTemplateObject
                      ? t('emails.trigger_page.card.email_content.label')
                      : t('emails.trigger_page.card.pick_template.label')}
                  </Text>
                  <Button plain onClick={templateCardActions.onAction}>
                    {templateCardActions.content}
                  </Button>
                </InlineStack>
              </Box>
              <Box padding={'500'}>
                {!selectedTemplateObject && (
                  <div>
                    {emailTriggerMeta.templates.map((template) => (
                      <div className="EmailTemplate-Div" key={template.slug}>
                        <div className="EmailTemplate-Image">
                          <img src={template.image} alt={template.label} />
                        </div>
                        <div className="EmailTemplate-Label">
                          {template.label}
                        </div>
                        <div className="EmailTemplate-Buttons">
                          <ButtonGroup segmented fullWidth connectedTop>
                            <Button
                              onClick={() => {
                                setEmailPreviewObject(template);
                              }}
                              size="slim"
                            >
                              {t(
                                'emails.trigger_page.card.email_content.preview_button'
                              )}
                            </Button>
                            <Button
                              onClick={() => {
                                handleSelectedTemplateObject(template);
                              }}
                              size="slim"
                            >
                              {t(
                                'emails.trigger_page.card.email_content.select_button'
                              )}
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {selectedTemplateObject && (
                  <div>
                    <TextField
                      type="text"
                      label={t(
                        'emails.trigger_page.card.email_content.email_subject_label'
                      )}
                      value={emailSubject}
                      onChange={handleEmailSubjectChange}
                      onBlur={handleEmailSubjectBlur}
                      error={emailSubjectError}
                      id={`input-email-notification-subject`}
                      autoComplete={'off'}
                    />
                    <br />
                    <Text variant="bodyMd" as="span">
                      {t(
                        'emails.trigger_page.card.email_content.email_body_label'
                      )}
                    </Text>
                    <Editor
                      apiKey={TINY_MCE_KEY}
                      value={emailBody}
                      init={{
                        height: 500,
                        menubar: false,
                        branding: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | fullscreen  preview code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor link | removeformat ',
                      }}
                      onEditorChange={handleEmailBodyChange}
                    />
                    <br />
                    {emailBodyError && (
                      <InlineError
                        message={emailBodyError}
                        fieldID={'emailBodyError'}
                      />
                    )}
                  </div>
                )}
              </Box>
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text variant="headingMd" as="h2">
                  {t(
                    'emails.trigger_page.card.email_content.short_codes_title'
                  )}
                </Text>

                <Text variant="bodyMd" as="span">
                  <TranslateLink
                    text={t(
                      'emails.trigger_page.card.email_content.short_codes_heading'
                    )}
                  />
                </Text>
              </BlockStack>
              <br />
              {emailTriggerMeta.shortcodes.map((shortCode) => (
                <Tooltip
                  content={shortCode.label}
                  dismissOnMouseOut
                  preferredPosition="above"
                  key={shortCode.slug}
                >
                  <Link>{`${shortCode.slug}`}</Link>
                  <br />
                </Tooltip>
              ))}
            </Card>
          </Layout.Section>
        </Layout >
        <EmailPreviewModal
          emailPreviewObject={emailPreviewObject}
          setEmailPreviewObject={setEmailPreviewObject}
        />
        <TemplateChangeModal
          setSelectedTemplateObject={setSelectedTemplateObject}
          showTemplateChangeModal={showTemplateChangeModal}
          setShowTemplateChangeModal={setShowTemplateChangeModal}
        />
        <EmailTestModal
          closeEmailTestModal={closeEmailTestModal}
          showEmailTestModal={showEmailTestModal}
          emailTriggerById={emailTriggerById}
          emailSubject={emailSubject}
          emailBody={emailBody}
        />
        <EmailDeleteModal
          showEmailDeleteModal={showEmailDeleteModal}
          closeEmailDeleteModal={closeEmailDeleteModal}
          triggerId={emailTriggerById}
        />
      </RushPage >
    </>
  );
}
