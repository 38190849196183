import { combineReducers } from 'redux';
import { settingsReducer } from '../settings';
import { trackingReducer } from '../tracking';
import { storeApi } from './storeApi';
import { baseApi } from './baseApi';
import shipmentsReducer from 'pages/Shipments3/shipmentSlice';
import billingReducer from 'pages/Billing/billingSlice';
import base from './baseSlice';
import shop from './shopSlice';
import trackingPage from 'pages/TrackingPageNew/trackingPageSlice';
import visitor from 'pages/Visitor/visitorSlice';

export default combineReducers({
  settings: settingsReducer,
  shipments: shipmentsReducer,
  base: base,
  shop: shop,
  trackingPage: trackingPage,
  trackingDetail: trackingReducer,
  billing: billingReducer,
  visitor: visitor,
  [baseApi.reducerPath]: baseApi.reducer,
  [storeApi.reducerPath]: storeApi.reducer,
});
