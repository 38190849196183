import {
  Box,
  InlineStack,
  Icon,
  Text,
  BlockStack,
} from '@shopify/polaris';
import { ExternalSmallIcon } from '@shopify/polaris-icons';
import ExternalLink from 'components/ExternalLink';
import { isEmpty } from 'lodash';

import React from 'react';
import { useTranslation } from 'react-i18next';

function ContactInformation({ customer = {} }) {
  const [t] = useTranslation();

  return isEmpty(customer) ? (
    ''
  ) : (
    <Box padding="400">
      <BlockStack gap="400">
        <Text variant="headingMd">
          {t('shipment_details.contact_information')}
        </Text>
        <BlockStack>
          <InlineStack>
            {!!customer.name && (
              <ExternalLink
                external={false}
                target="_blank"
                url={customer.link}
              >
                {customer.name}
              </ExternalLink>
            )}
            <Box>
              <Icon source={ExternalSmallIcon} tone={'interactive'} />
            </Box>
          </InlineStack>
          {!!customer.email && <Text>{customer.email}</Text>}
        </BlockStack>
      </BlockStack>
    </Box>
  );
}

export default ContactInformation;
