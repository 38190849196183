import { Loading } from '@shopify/polaris';
import { Loading as BridgeLoading } from '@shopify/app-bridge-react';

import React from 'react';
import useIsEmbededApp from 'hooks/useIsEmbededApp';

function RushLoading(props) {
  const isEmbededApp = useIsEmbededApp();

  return isEmbededApp ? <BridgeLoading {...props} /> : <Loading {...props} />;
}

export default RushLoading;
