import React, { useEffect, useMemo, useState } from 'react';
import { useGetProxyConfigQuery } from 'pages/TrackingPageNew/newTrackingPageApis';
import isEqual from 'lodash/isEqual';
import { useUpdateProxyConfigMutation } from 'pages/TrackingPageNew/newTrackingPageApis';
import { useGetProxyMetaQuery } from 'pages/TrackingPageNew/newTrackingPageApis';

const withProxyConfig = (Component) => (props) => {
  const [configState, setConfigState] = useState({
    facebook_pixel: '',
    google_analytics: '',
    google_analytics4: '',
    google_conversion_id: '',
    google_tag_manager: '',
    is_tracking_analytics: false,
    localisation_default_language_uuid: '',
    localisation_languages_selected_type: '',
    localisation_languages_selected_uuids: '',
    localisation_use_multiple_languages: false,
    public_proxy_link: '',
    store_uuid: '',
    uuid: '',
    search_mode: 'any',
  });

  const [
    updateConfig,
    { isLoading: isProxyUpdating, isSuccess: isProxyUpdated },
  ] = useUpdateProxyConfigMutation();

  const { data: metaRes = {}, isLoading: isMetaLoading } =
    useGetProxyMetaQuery();

  const {
    isFetching: isConfigFetching,
    isSuccess: isConfigLoaded,
    data: proxyConfig = {},
  } = useGetProxyConfigQuery();

  useEffect(() => {
    if (!isConfigFetching && isConfigLoaded) {
      setConfigState(proxyConfig);
    }
  }, [isConfigFetching, isConfigLoaded]);

  const handleProxyUpdate = (key, value) => {
    setConfigState({
      ...configState,
      [key]: value,
    });
  };

  const isDirtyState = useMemo(() => {
    const currentObject = { ...configState };
    const orgObject = Object.keys(currentObject).reduce((prev, next) => {
      prev[next] = proxyConfig[next];
      return prev;
    }, {});
    return !isEqual(orgObject, currentObject);
  });

  const handleSave = () => {
    updateConfig(configState);
  };

  const discardChanges = () => {
    setConfigState(proxyConfig);
  };

  return (
    <>
      <Component
        {...props}
        isProxyLoading={isConfigFetching}
        isDirtyProxy={isDirtyState}
        proxyConfig={configState}
        onProxyUpdate={handleProxyUpdate}
        saveProxyConfig={handleSave}
        isProxyUpdating={isProxyUpdating}
        isProxyUpdated={isProxyUpdated}
        resetConfig={discardChanges}
        metaInfo={metaRes}
        isMetaLoading={isMetaLoading}
      />
    </>
  );
};

export default withProxyConfig;
