import { storeApi } from '../../redux/store/storeApi';

export const NotificationsActivityFeedApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (queryParams) => {
        return {
          url: `/notifications?${queryParams}`,
          method: 'GET',
        };
      },
    }),
    getNotificationDetails: builder.query({
      query: ({ uuid }) => {
        return {
          url: `/notifications/${uuid}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || undefined,
    }),
    setEDDConfig: builder.mutation({
      query: (data) => {
        return {
          url: `/features/edd/config`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      invalidatesTags: ['smartEDD', 'settings'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useGetNotificationDetailsQuery,
} = NotificationsActivityFeedApi;
