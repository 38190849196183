import React, { useState } from 'react';
import {
  AlertTriangleIcon,
  AlertCircleIcon,
  RefreshIcon,
  GiftCardIcon,
} from '@shopify/polaris-icons';
import TranslateLink from 'components/TranslateLink';

import { openHubSpotChat } from '../../Utils/utils';
import { SETTINGS_CUSTOMER_CHAT_OPEN } from '../../Constants';
import IncreaseLimitModal from 'pages/Billing/components/IncreaseLimitModal';
import UpdatePlanModal from 'pages/Billing/components/UpdatePlanModal';
import { useDispatch } from 'react-redux';
import { billingActions } from 'pages/Billing/billingSlice';
import RushBanner from 'components/custom/RushBanner';

export default function ShowBanner({ bannerObject = {} }) {
  const dispatch = useDispatch();

  const toggleUpdatePlanModal = () => {
    dispatch(billingActions.toggleUpdateModal());
  };

  const toggleChargeLimitModal = () => {
    dispatch(billingActions.toggleChargeLimitModal());
  };

  function getAction(bannerObject, key) {
    const actionObject = bannerObject[key];
    if (!actionObject) {
      return;
    }

    let action = { content: actionObject.content };

    if (actionObject.external) {
      action.external = true;
      action.url = actionObject.url;
      return action;
    }

    if (actionObject.id === 'update_usage_charges_cap_limit') {
      action.onAction = toggleChargeLimitModal;
    } else if (actionObject.id === 'show_subscription_plans') {
      action.onAction = toggleUpdatePlanModal;
    } else if (actionObject.id === SETTINGS_CUSTOMER_CHAT_OPEN) {
      action.onAction = openHubSpotChat;
    }

    return action;
  }

  const [isVisible, setIsVisible] = useState(true);
  let icon = null;
  const action = getAction(bannerObject, 'action');
  const secondaryAction = getAction(bannerObject, 'secondary_action');

  if (bannerObject.icon === 'AlertTriangleIcon') {
    icon = AlertTriangleIcon;
  } else if (bannerObject.icon === 'AlertCircleIcon') {
    icon = AlertCircleIcon;
  } else if (bannerObject.icon === 'RefreshIcon') {
    icon = RefreshIcon;
  } else if (bannerObject.icon === 'GiftCardIcon') {
    icon = GiftCardIcon;
  }

  if (!isVisible) {
    return <div />;
  }

  let onDismissCallback = false;
  if (bannerObject.close?.visible) {
    onDismissCallback = () => {
      setIsVisible(false);
    };
  }

  return (
    <React.Fragment>
      <RushBanner
        title={bannerObject.title}
        status={bannerObject.status}
        action={action}
        onDismiss={onDismissCallback}
        icon={icon}
        secondaryAction={secondaryAction}
        bannerKey={bannerObject.key || bannerObject.title}
      >
        <TranslateLink text={bannerObject.message} target={'_blank'} />
      </RushBanner>
      <IncreaseLimitModal />
      <UpdatePlanModal />
    </React.Fragment>
  );
}
