import React from 'react';
import {
  IndexTable,
  LegacyCard,
  Badge,
  Button,
  Text,
  InlineStack,
} from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowUpIcon, ArrowDownIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { useUpdateCarrierMaskingMutation } from '../maskingApi';
import './styles.scss';
import { getTargetCarrierLabel } from './utils';
import RushLoading from 'components/custom/RushLoader';

function MaskingDataTable({ maskings, tabMaskings, allCarriers, setMaskings }) {
  const [t] = useTranslation();
  const [updatemasking, updateMaskingResponse] =
    useUpdateCarrierMaskingMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading: isUpdatingMaskings } = updateMaskingResponse;

  const reorderArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length || new_index < 0) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };
  const handleMoveUp = (index) => {
    let updated = reorderArray([...maskings], index, index - 1);
    setMaskings(updated);
    syncUpdates(updated);
  };

  const handleMoveDown = (index) => {
    let updated = reorderArray([...maskings], index, index + 1);
    setMaskings(updated);
    syncUpdates(updated);
  };

  const syncUpdates = (updated) => {
    const differences = updated.reduce((prev, next, index) => {
      if (next.index !== index) {
        prev.push({ ...next, index });
      }
      return prev;
    }, []);
    if (differences.length) {
      differences.map(({ uuid, ...updatedMasking }) => {
        updatemasking({ id: uuid, data: updatedMasking });
      });
    }
  };

  const ClickAbleCell = (props) => {
    return (
      <div
        className="clickable-container-div"
        onClick={() => {
          navigate(location.pathname + '/' + props.uuid);
        }}
      >
        {props.children}
      </div>
    );
  };
  const overrideNameMarkup = (item) => {
    if (item.completely_hide_carrier || item.override_name_option === 1) {
      return (
        <em>
          <Text variant="bodyMd" as="span" tone="subdued">
            {t('carrier-maskings.hidden')}
          </Text>
        </em>
      );
    } else if (item.override_name_option === 0) {
      return (
        <em>
          <Text variant="bodyMd" as="span" tone="subdued">
            {t('carrier-maskings.keep-carrier-name')}
          </Text>
        </em>
      );
    } else {
      return (
        <Text variant="bodyMd" as="span">
          {item.override_name_value}
        </Text>
      );
    }
  };
  const rowMarkup = (tabMaskings || []).map((item, itemIndex) => {
    const {
      uuid,
      is_active,
      target_carriers_all,
      target_carriers_uuid,
      completely_hide_carrier,
      override_logo_value,
      index,
    } = item;
    return (
      <IndexTable.Row id={uuid} key={uuid} position={index}>
        <IndexTable.Cell className={completely_hide_carrier && 'subdued-cell'}>
          {override_logo_value ? (
            <ClickAbleCell uuid={uuid}>
              <img
                alt=""
                width="30px"
                height="30px"
                style={{
                  objectPosition: 'center',
                }}
                src={override_logo_value}
              />
            </ClickAbleCell>
          ) : null}
        </IndexTable.Cell>
        <IndexTable.Cell
          className={`carrier-name-container ${completely_hide_carrier && 'subdued-cell'
            }`}
        >
          <ClickAbleCell uuid={uuid}>{overrideNameMarkup(item)}</ClickAbleCell>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <ClickAbleCell uuid={uuid}>
            {is_active ? (
              <Badge tone="success">{t('carrier-maskings.active')}</Badge>
            ) : (
              <Badge>{t('carrier-maskings.inactive')}</Badge>
            )}
          </ClickAbleCell>
        </IndexTable.Cell>
        {target_carriers_all ? (
          <IndexTable.Cell>
            <ClickAbleCell uuid={uuid}>
              {t('carrier-maskings.tab-all')}{' '}
            </ClickAbleCell>
          </IndexTable.Cell>
        ) : (
          <IndexTable.Cell className="target-carriers">
            <ClickAbleCell uuid={uuid}>
              {(target_carriers_uuid || []).length
                ? getTargetCarrierLabel(target_carriers_uuid, allCarriers)
                : '-'}
            </ClickAbleCell>
          </IndexTable.Cell>
        )}
        <IndexTable.Cell className="navigate-buttons">
          <InlineStack blockAlign="end">
            <Button
              disabled={itemIndex === 0 || isUpdatingMaskings}
              icon={ArrowUpIcon}
              variant="plain"
              onClick={() => handleMoveUp(itemIndex)}
            ></Button>
            <Button
              disabled={
                itemIndex === tabMaskings.length - 1 || isUpdatingMaskings
              }
              icon={ArrowDownIcon}
              variant="plain"
              onClick={() => handleMoveDown(itemIndex)}
            ></Button>
          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <>
      <LegacyCard roundedAbove="sm" padding={0}>
        <IndexTable
          selectable={false}
          itemCount={tabMaskings.length}
          headings={[
            {
              title: t('carrier-maskings.avatar'),
              hidden: true,
              flush: true,
            },
            { title: t('carrier-maskings.override-name') },
            { title: t('carrier-maskings.status') },
            { title: t('carrier-maskings.target-carriers') },
            { title: 'actions', hidden: true, flush: true },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </LegacyCard>
      {isUpdatingMaskings && <RushLoading />}
    </>
  );
}
export default MaskingDataTable;
