import React, { useEffect, useMemo, useState } from 'react';
import {
  Layout,
  Text,
  InlineStack,
  Box,
  BlockStack,
} from '@shopify/polaris';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import SmallTopMessage from 'components/SmallTopMessage';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { useDispatch } from 'react-redux';
import TaskItemSkeleton from './TaskItemSkeleton';
import TaskItem from './TaskItem';
import {
  useGetDashboardTasksQuery,
  useDismissTaskMutation,
} from '../onBoardingApi';
import './style.scss';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';
import { baseActions } from 'redux/store/baseSlice';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';

function OnBoarding() {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { isLoading: isLoadingUser, data: user = {} } =
    useGetUserDetailsQuery();

  const dashboardResponse = useGetDashboardTasksQuery();
  const {
    data = {},
    isLoading: dashboardLoading,
    isFetching: dashboardFetching,
  } = dashboardResponse || {};

  const [dismissTaskApi, dismissTaskResponse = {}] = useDismissTaskMutation();
  const {
    data: dismissTaskData = {},
    isLoading: isDismissingTask,
    isSuccess: isTaskDismissd,
  } = dismissTaskResponse;

  useEffect(() => {
    if (isTaskDismissd && dismissTaskData) {
      dispatch(
        baseActions.setToastMessage({
          message: dismissTaskData.message,
          type: 'success',
        })
      );
    }
  }, [isTaskDismissd, dismissTaskData]);

  const [dashboardTasks, setdashboardTasks] = useState([]);

  useEffect(() => {
    if (data?.tasks) {
      const tasks = (data.tasks || []).filter((task) => !task?.is_dismissed);
      setdashboardTasks(tasks);
    }
  }, [data]);

  const handleDismissTask = (taskId) => {
    setdashboardTasks((tasks = []) => {
      return tasks.filter((t) => t?.task_id !== taskId);
    });
    dismissTaskApi(taskId);
  };

  useEffect(() => {
    try {
      ClientStorage.set(LOCAL_STORAGE.SAW_ONBOARDING, true);
      // eslint-disable-next-line no-empty
    } catch { }
  }, []);

  const pageHeading = useMemo(
    () =>
      t('onboarding.title', {
        user_first_name: user?.first_name,
      }),
    [user]
  );

  return (
    <>
      {dashboardFetching || isDismissingTask || isLoadingUser ? (
        <RushLoading />
      ) : (
        ''
      )}
      <SmallTopMessage />
      <RushPage
        title={pageHeading}
        subtitle={
          dashboardTasks && !isEmpty(dashboardTasks)
            ? t('onboarding.subtitle')
            : undefined
        }
        fullWidth={false}
        narrowWidth={false}
      >
        <Layout>
          {dashboardLoading ? (
            <Box width="100%">
              <BlockStack gap={'500'}>
                {Array(5)
                  .fill(0)
                  .map((i) => (
                    <TaskItemSkeleton key={i} />
                  ))}
              </BlockStack>
            </Box>
          ) : !isEmpty(dashboardTasks) ? (
            <>
              <Layout.Section>
                <InlineStack blockAlign="center">
                  <Box width="100%">
                    <Text variant="headingMd" as="h2">
                      {t('dashboard.tasks.title')}
                    </Text>
                  </Box>
                </InlineStack>
              </Layout.Section>
              <Layout.Section>
                {(dashboardTasks || []).map((task, index) => (
                  <TaskItem
                    taskItem={task}
                    key={index}
                    onDismissTask={handleDismissTask}
                  />
                ))}
              </Layout.Section>
            </>
          ) : (
            <Layout.Section>
              <InlineStack blockAlign={'center'}>
                <Text variant="bodyMd" as="span" color={'subdued'}>
                  <p style={{ fontSize: 18 }}>{t('onboarding.empty_label')}</p>
                </Text>
              </InlineStack>
            </Layout.Section>
          )}
          <Layout.Section />
        </Layout>
      </RushPage>
    </>
  );
}

export default OnBoarding;
