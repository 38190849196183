import React from 'react';
import { Icon, Text, BlockStack, InlineStack } from '@shopify/polaris';
import { ExternalSmallIcon, NoteIcon } from '@shopify/polaris-icons';
import ExternalLink from '../ExternalLink';

export default function Subtitle({ text, actions }) {
  return (
    <BlockStack gap={'200'}>
      <Text variant="bodyMd" as="span">
        {text}
      </Text>
      <InlineStack gap={'200'}>
        {actions.map((action) => (
          <ExternalLink url={action.source} key={action.text} external={true}>
            <InlineStack gap={'100'}>
              <Icon source={action.icon || NoteIcon} color={'interactive'} />
              <Text variant="bodyMd" as="span">
                {action.text}
              </Text>
              <Icon source={ExternalSmallIcon} color={'interactive'} />
            </InlineStack>
          </ExternalLink>
        ))}
      </InlineStack>
    </BlockStack>
  );
}
