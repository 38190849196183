import RushPage from 'components/custom/RushPage';
import React, { useMemo, useState, useEffect } from 'react';
import './styles.scss';
import Step1 from './InstallWizard/Step1';
import Step2 from './InstallWizard/Step2';
import Step3 from './InstallWizard/Step3';
import { useNavigate, useParams } from 'react-router-dom';
import { installRoutesMeta } from 'Utils/utils';
import { useGetOrganizationsQuery } from 'redux/store/commonBaseApis';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import { useSelector } from 'react-redux';
import { USER_URLS } from 'config/urls';
import useIntegrationCallbackURL from 'hooks/useIntegrationCallbackURL';
import { Spinner } from '@shopify/polaris';
import useCheckIntegration from 'hooks/useCheckIntegration';
import RushLoading from 'components/custom/RushLoader';
function InstallManager() {
  const navigate = useNavigate();
  const accessToken = useSelector(AccessToken);

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedStoreUUID, setSelectedStoreUUID] = useState();
  const [checkForConnection, setCheckForConnection] = useState(false);
  const [alreadyConnected, setAlreadyConnected] = useState(false);

  const { install_slug } = useParams();
  const meta = installRoutesMeta();
  const currentMeta = meta.find(({ slug }) => slug === install_slug) || {};
  const { image, name, install_url, config_url, component_route } = currentMeta;

  const { loading, success, error, data, reset } = useCheckIntegration({
    url: `/stores/${selectedStoreUUID}${config_url}`,
    skip: !selectedStoreUUID || !checkForConnection,
  });

  useEffect(() => {
    if (checkForConnection && !loading && (error || success)) {
      setCheckForConnection(false);
      if (error && !data) {
        setAlreadyConnected(false);
        setCurrentStep(2);
      } else if (success && data) {
        setAlreadyConnected(true);
      }
    }
  }, [loading, data, checkForConnection, error]);

  const path = `${location.origin}/s/${selectedStoreUUID}${component_route}`;
  console.log({ path });

  const integrationInstallLink = useIntegrationCallbackURL({
    storeUUID: selectedStoreUUID,
    integrationURL: install_url,
    redirectPath: path,
  });

  const {
    data: organizations = [],
    isLoading: organizationsLoading,
    isSuccess: organizationsLoaded,
  } = useGetOrganizationsQuery(undefined, { skip: !accessToken });

  const stores = useMemo(() => {
    if (!organizationsLoading && organizationsLoaded) {
      return organizations.reduce((prev, next) => {
        prev = [...prev, ...next.stores];
        return prev;
      }, []);
    } else {
      return [];
    }
  }, [organizationsLoading, organizationsLoaded]);

  const storeName = useMemo(() => {
    return stores.find(({ uuid }) => uuid === selectedStoreUUID)?.store_name;
  }, [stores, selectedStoreUUID]);

  useEffect(() => {
    if (stores.length > 1) {
      setSelectedStoreUUID(stores[0]?.uuid);
      setCurrentStep(1);
    } else if (stores.length == 1) {
      setSelectedStoreUUID(stores[0]?.uuid);
      setCurrentStep(2);
    } else if (stores.length == 0) {
      setCurrentStep(3);
    }
  }, [stores]);

  const handleStoreChange = (uuid) => {
    setSelectedStoreUUID(uuid);
    reset();
    setAlreadyConnected(false);
  };

  const handleCancel = () => {
    navigate('/s');
  };

  const handleContinue = () => {
    setCheckForConnection(true);
  };

  const handleConnect = () => {
    console.log({ integrationInstallLink });
    window.open(`${integrationInstallLink}`, '_self');
  };

  const handleAddStore = () => {
    navigate(USER_URLS.ADD_STORE);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            icon={image}
            storesList={stores?.map(({ store_name, uuid }) => ({
              label: store_name,
              value: uuid,
            }))}
            selectedStore={selectedStoreUUID}
            onStoreChange={handleStoreChange}
            onCancel={handleCancel}
            onContinue={handleContinue}
            alreadyConnected={alreadyConnected}
            loading={loading}
            integrationName={name}
          />
        );
      case 2:
        return (
          <Step2
            icon={image}
            name={name}
            storeName={storeName}
            onCancel={handleCancel}
            onConnect={handleConnect}
          />
        );
      case 3:
        return (
          <Step3
            icon={image}
            name={name}
            onCancel={handleCancel}
            onAddStore={handleAddStore}
          />
        );
    }
  };

  return (
    <RushPage>
      {loading && <RushLoading />}
      <div className="install-wrapper">
        {organizationsLoading ? <Spinner /> : renderStep()}
      </div>
    </RushPage>
  );
}

export default InstallManager;
