import { PERMISSION_URLS } from 'config/urls';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SkipPermissions } from 'redux/store/baseSlice';
import { useGetStoreStatisticsQuery } from 'redux/store/commonBaseApis';
import { shopActions, StoreUUID } from 'redux/store/shopSlice';

function useCheckPermission({ skip }) {
  const accessToken = useSelector(AccessToken);
  const skipPermissions = useSelector(SkipPermissions);
  const storeUUID = useSelector(StoreUUID);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isSuccess: gotPermissionsResponse,
    isFetching: isPermissionsFecthing,
    isLoading: isPermissionsLoading,
    data: permissionsData = {},
    refetch: refetchStatistics,
  } = useGetStoreStatisticsQuery(storeUUID, {
    skip: !storeUUID || !accessToken || skip,
  });

  useEffect(() => {
    if (gotPermissionsResponse && !isPermissionsFecthing) {
      dispatch(shopActions.setStoreTitle(permissionsData?.name));
      if (!skipPermissions) {
        if (permissionsData?.status === 'uninstalled') {
          navigate(PERMISSION_URLS.RECONNECT);
        } else if (permissionsData?.is_permissions_pending_approval) {
          navigate(PERMISSION_URLS.APPROVE);
        }
      }
    }
  }, [permissionsData, gotPermissionsResponse, skipPermissions, dispatch]);

  useEffect(() => {
    if (storeUUID && accessToken && !skip) {
      refetchStatistics();
    }
  }, [storeUUID, accessToken, skip]);

  return { isLoading: isPermissionsLoading };
}

export default useCheckPermission;
