import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MediaCard, VideoThumbnail } from '@shopify/polaris';
import VideoModal from 'components/VideoModal';
import OrderImportModal from 'components/OrderImportModal';
import { addUtmParamsToUrl } from 'Utils/utils';
import TranslateLink from 'components/TranslateLink';
import { STORE_URLS } from 'config/urls';
import { useCompleteTaskMutation } from 'pages/OnBoarding/onBoardingApi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { baseActions } from 'redux/store/baseSlice';

export default function TaskItem({ taskItem = {}, onDismissTask }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [completeTaskApi] = useCompleteTaskMutation();

  const [openOrderImportModal, setOpenOrderImportModal] = useState(false);

  const [videoSource, setVideoSource] = useState(false);
  const [videoTitle, setVideoTitle] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const showTaskCompleteToast = (message) => {
    dispatch(
      baseActions.setToastMessage({
        message: message || t('task.toaster.completed'),
        type: 'success',
      })
    );
  };

  const handleSyncClose = useCallback((ordersSynced, message) => {
    setOpenOrderImportModal((oIModal) => {
      if (ordersSynced === true && oIModal) {
        showTaskCompleteToast(message);
      }
      return !oIModal;
    });
  }, []);

  const handleModalChange = useCallback(() => {
    setShowVideoModal((show) => {
      if (show) {
        showTaskCompleteToast();
      }
      return !show;
    });
  }, [showTaskCompleteToast]);

  const completeTask = useCallback(
    (taskId, taskCard = {}) => {
      if (taskCard.is_video) {
        setVideoSource(taskCard.video_url);
        setVideoTitle(taskCard.title);
        setShowVideoModal(true);
      } else if (taskId === 'sync_more_than_default_days') {
        handleSyncClose();
      } else if (taskId === 'configure_tracking_page') {
        navigate(`/s/${params.uuid}${STORE_URLS.TRACKING_PAGES}`);
      }
      completeTaskApi(taskId);
    },
    [history, handleSyncClose]
  );

  return (
    <>
      <MediaCard
        size={taskItem.card?.size}
        title={taskItem.card?.title}
        primaryAction={
          taskItem.card?.primary_action
            ? {
                content: taskItem.card?.primary_action?.content?.text,
                onAction: () => {
                  completeTask(taskItem.task_id, taskItem.card);
                },
                external: taskItem.card.primary_action?.external,
                url: addUtmParamsToUrl(taskItem.card?.primary_action?.url),
                target: '_blank',
              }
            : undefined
        }
        secondaryAction={
          taskItem.card.secondary_action
            ? {
                content: taskItem.card?.secondary_action?.content?.text,
                external: taskItem.card?.secondary_action?.external,
                url: addUtmParamsToUrl(taskItem.card?.secondary_action?.url),
                target: '_blank',
              }
            : undefined
        }
        description={<TranslateLink text={taskItem.card?.description} />}
        popoverActions={taskItem.card?.popover_actions?.map((action) => ({
          content: action?.content,
          onAction: () => {
            onDismissTask(taskItem?.task_id);
          },
        }))}
        key={taskItem?.task_id}
        portrait={taskItem?.portrait_mode}
      >
        {taskItem.card?.is_video && (
          <VideoThumbnail
            videoLength={taskItem.card?.video_duration}
            thumbnailUrl={taskItem.card?.image_url}
            onClick={() => {
              completeTask(taskItem.task_id, taskItem.card);
            }}
          />
        )}
        {!taskItem.card?.is_video && (
          <img
            alt={taskItem.card?.title}
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            src={taskItem.card?.image_url}
          />
        )}
      </MediaCard>

      <VideoModal
        showVideoModal={showVideoModal}
        handleModalChange={handleModalChange}
        videoSrc={videoSource}
        videoTitle={videoTitle}
      />
      {openOrderImportModal && (
        <OrderImportModal
          openOrderImportModal={openOrderImportModal}
          onClose={handleSyncClose}
        />
      )}
    </>
  );
}
