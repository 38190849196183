import { Text, InlineCode } from "@shopify/polaris";
import React from "react";
import ReactMarkdown from "react-markdown";

// Rewrite `em`s (`*like so*`) to `i` with Code text style

function CodeString({ text, showBraces = false }) {
  return (
    <ReactMarkdown
      children={text}
      components={{
        em: ({ children }) => {
          if (showBraces) {
            return (
              <Text variant="bodyMd" as="span">
                <InlineCode>{`{ ${children} }`}</InlineCode>
              </Text>
            );
          } else {
            return (
              <Text variant="bodyMd" as="span">
                <InlineCode>{children}</InlineCode>
              </Text>
            );
          }
        },
      }}
    />
  );
}

export default CodeString;
