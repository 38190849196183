import React, { useEffect } from 'react';
import TranslateLink from 'components/TranslateLink';
import {
  Card,
  BlockStack,
  ChoiceList,
  FormLayout,
  TextField,
  Select,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

export default function OmnisendDetails({
  loading = true,
  omnisendMeta,
  localOmnisendObj,
  setSelectedLanguage,
  setSelectedTrigger,
  selectedLanguage,
  selectedTrigger,
  updateOmnisendPayload,
  setApiKey,
  apiKey,
  apiKeyError,
}) {
  const [t] = useTranslation();

  const handleApiKeyChange = (value) => {
    setApiKey(value);
    updateOmnisendPayload();
  };

  const handleSelectedLanguageChange = (value) => {
    setSelectedLanguage(value);
    updateOmnisendPayload();
  };

  const handleSelectedTriggerChange = (value) => {
    setSelectedTrigger(value);
    updateOmnisendPayload();
  };

  useEffect(() => {
    if (!isEmpty(localOmnisendObj.config) && localOmnisendObj.config.triggers) {
      const { config } = localOmnisendObj;
      const temp = config.triggers
        .map((trigger) => (trigger.is_active ? trigger.trigger_slug : null))
        .filter((item) => !isNull(item));
      setApiKey(config.api_key);
      setSelectedLanguage(config.language);
      setSelectedTrigger(temp);
    }
  }, [localOmnisendObj, setApiKey, setSelectedLanguage, setSelectedTrigger]);

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="100">
        <TranslateLink text={t('omnisend.modal.description')} />
        <BlockStack gap="100">
          <FormLayout>
            <TextField
              autoComplete={'off'}
              label={t('omnisend.modal.public_api_key_label')}
              onChange={handleApiKeyChange}
              value={apiKey}
              error={apiKeyError}
              placeholder={t('omnisend.modal.public_api_key_placeholder')}
              id={`input-omnisend-public-key`}
            />

            <Select
              label={t('omnisend.modal.language_label')}
              options={omnisendMeta.languages.map((lang) => ({
                label: lang.label,
                value: lang.slug,
              }))}
              onChange={handleSelectedLanguageChange}
              value={selectedLanguage}
              disabled={loading}
              id={`select-omnisend-selected-language`}
            />

            {omnisendMeta && omnisendMeta.triggers && (
              <div className="small-gap-list">
                <ChoiceList
                  allowMultiple
                  title={t('omnisend.modal.trigger_label')}
                  choices={omnisendMeta.triggers.map((trigger) => ({
                    label: trigger.label,
                    value: trigger.slug,
                  }))}
                  selected={selectedTrigger}
                  onChange={handleSelectedTriggerChange}
                  id={`select-omnisend-triggers`}
                />
              </div>
            )}
          </FormLayout>
        </BlockStack>
      </BlockStack>
    </Card>
  );
}
