import {
  Layout,
  Card,
  SkeletonBodyText,
  BlockStack,
  SkeletonDisplayText,
  Box,
  Divider,
  InlineStack,
} from '@shopify/polaris';
import RushPage from 'components/custom/RushPage';
import { STORE_URLS } from 'config/urls';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function SipmentsDetailSkelton() {
  const params = useParams();
  return (
    <RushPage
      title={<SkeletonDisplayText />}
      secondaryActions={
        <InlineStack gap="200">
          <Box width="100px">
            <SkeletonDisplayText />
          </Box>
          <Box width="100px">
            <SkeletonDisplayText />
          </Box>
        </InlineStack>
      }
      fullWidth={false}
      narrowWidth={false}
      backAction={{
        url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
      }}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <Card roundedAbove="sm" padding={0}>
              <Box padding="400">
                <BlockStack gap={'500'}>
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                </BlockStack>
              </Box>

              <Divider />

              <Box padding="400">
                <BlockStack gap={'500'}>
                  <Box>
                    <SkeletonBodyText lines={10} />
                  </Box>
                </BlockStack>
              </Box>
            </Card>

            {/* <Box padding="400">
              <BlockStack gap={'500'}>
                <Box>
                  <SkeletonBodyText lines={4} />
                </Box>
              </BlockStack>
            </Box> */}
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card roundedAbove="sm">
              <Box>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <Box>
                    <SkeletonBodyText lines={1} />
                  </Box>
                </BlockStack>
              </Box>
            </Card>
            <Card roundedAbove="sm">
              <Box>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                </BlockStack>
              </Box>
            </Card>
            <Card roundedAbove="sm">
              <Box>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <Box>
                    <SkeletonBodyText lines={2} />
                  </Box>
                </BlockStack>
              </Box>
            </Card>

            <Card roundedAbove="sm">
              <BlockStack gap={'400'}>
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={6} />
                </Box>
                <Divider />
                <Box>
                  <SkeletonBodyText lines={3} />
                </Box>
              </BlockStack>
            </Card>
            <BlockStack gap="400">
              <Card roundedAbove="sm">
                <Box>
                  <BlockStack gap="400">
                    <SkeletonDisplayText size="small" />
                    <Box>
                      <SkeletonBodyText lines={4} />
                    </Box>
                  </BlockStack>
                </Box>
              </Card>
              <Card roundedAbove="sm">
                <Box>
                  <BlockStack gap="400">
                    <SkeletonDisplayText size="small" />
                    <Box>
                      <SkeletonBodyText lines={2} />
                    </Box>
                  </BlockStack>
                </Box>
              </Card>
              <Divider />
            </BlockStack>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </RushPage >
  );
}
