import {
  Button,
  ButtonGroup,
  Card,
  Layout,
  BlockStack,
  Text,
  InlineStack,
  Box,
} from '@shopify/polaris';
import { DeleteIcon, ExternalSmallIcon } from '@shopify/polaris-icons';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../styles.scss';
import NonShippingModal from './NonShippingModal';
function DateRangesCard({ dateItems = [], onChange }) {
  const [t] = useTranslation();
  const [showNonShipping, setShowNonShipping] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const onAddExcludeRange = () => {
    setEditIndex(-1);
    setShowNonShipping(true);
  };

  const toggleNonInterval = () => {
    setShowNonShipping((v) => !v);
  };

  const onEditDate = (index) => {
    setEditIndex(index);
    toggleNonInterval();
  };

  const addRange = (item) => {
    onChange([...dateItems, item]);
  };
  const editRange = (item) => {
    let updated = [...dateItems];
    updated.splice(editIndex, 1, item);
    onChange(updated);
  };
  const deleteRange = (index) => {
    let updated = [...dateItems];
    updated.splice(index, 1);
    onChange(updated);
  };

  const handleSave = (values) => {
    if (editIndex === -1) {
      addRange(values);
    } else {
      editRange(values);
    }
    toggleNonInterval();
  };

  const dateItemsMarkup = useMemo(
    () =>
      dateItems.map((range, index) => {
        let variation = '';
        const now = moment();
        if (
          moment(range.to).isSame(now, 'day') &&
          moment(range.from).isSame(now, 'day')
        ) {
          variation = 'positive';
        } else if (now > moment(range.to)) {
          variation = 'subdued';
        } else if (now <= moment(range.to) && now >= moment(range.from)) {
          variation = 'positive';
        }
        return (
          <Box
            padding={'500'}
            borderBlockEndWidth="100"
            borderColor="border-brand"
            key={index}
          >
            <InlineStack align="space-between">
              <InlineStack gap={'100'}>
                <Box>
                  <Text variant="bodyMd" as="span" color={variation}>
                    {range.from
                      ? moment(range.from).format('MMM Do, YYYY') + ' - '
                      : ''}
                    {range.to ? moment(range.to).format('MMM Do, YYYY') : ''}
                    {range.to && range.note ? ' - ' : ''}
                    {range.note ? (
                      <Text variant="bodyMd" as="span" color="subdued">
                        {range.note}{' '}
                      </Text>
                    ) : (
                      ''
                    )}
                  </Text>
                </Box>
              </InlineStack>

              <div className="action-buttons">
                <ButtonGroup variant='segmented'>
                  <Button
                    size="slim"
                    accessibilityLabel={t('common.duplicate')}
                    onClick={() => onEditDate(index)}
                    icon={ExternalSmallIcon}
                  ></Button>{' '}
                  <Button
                    size="slim"
                    accessibilityLabel={t('common.delete')}
                    onClick={() => deleteRange(index)}
                    icon={DeleteIcon}
                  ></Button>
                </ButtonGroup>
              </div>
            </InlineStack>
          </Box>
        );
      }),
    [dateItems]
  );

  const emptyStatemarkup = (
    <Box padding={'500'}>
      <div className="empty_markup">
        <Text variant="bodyMd" as="span" color="subdued">
          {t('smart-edd.no-date-excluded')}
        </Text>
      </div>
    </Box>
  );

  return (
    <Layout.AnnotatedSection
      title={t('smart-edd.exclude-shipping-days.title')}
      description={t('smart-edd.exclude-shipping-days.description')}
    >
      <Card roundedAbove="sm">
        <InlineStack gap={'500'} align="space-between">
          <Text variant="headingMd" as="h2">
            {t('smart-edd.exclude-shipping-days.card.title')}
          </Text>
          <Button plain onClick={onAddExcludeRange}>
            {t('common.add')}
          </Button>
        </InlineStack>
        <br />
        <Box padding={'0'}>
          <BlockStack gap="300">
            <BlockStack gap="300">
              <Text variant="bodyMd" as="span" color="subdued">
                {t('smart-edd.preference.card.description')}
              </Text>
              <Card roundedAbove="sm" padding={0}>
                <div className="date-ranges-container">
                  {(dateItems || []).length
                    ? dateItemsMarkup
                    : emptyStatemarkup}
                </div>
              </Card>
            </BlockStack>
          </BlockStack>
        </Box>
      </Card>
      {showNonShipping ? (
        <NonShippingModal
          onClose={toggleNonInterval}
          onChange={handleSave}
          values={dateItems[editIndex]}
        />
      ) : (
        ''
      )}
    </Layout.AnnotatedSection>
  );
}

export default DateRangesCard;
