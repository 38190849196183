import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import RushSaveBar from 'components/custom/RushSaveBar';

export default function SaveBar({
  settingsLoading,
  discardAction,
  isSaveBarEnabled,
  submitData,
}) {
  const [t] = useTranslation();
  const saveKlaviyoPage = useCallback(() => {
    submitData();
  }, [submitData]);

  return (
    <>
      {isSaveBarEnabled && (
        <RushSaveBar
          alignContentFlush
          message={t('settings.thankyou.top_bar_rule_unsaved_message')}
          saveAction={{
            onAction: () => saveKlaviyoPage(),
            loading: settingsLoading,
            disabled: settingsLoading,
            content: t('common.save'),
          }}
          discardAction={{
            onAction: () => discardAction(),
            loading: settingsLoading,
            disabled: settingsLoading,
            content: t('common.discard'),
          }}
        />
      )}
    </>
  );
}
