import React from 'react';
import {
  MediaCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
  VideoThumbnail,
  Box,
} from '@shopify/polaris';

export default function TaskItemSkeleton() {
  return (
    <Box width="100%">
      <MediaCard
        size={'small'}
        description={
          <BlockStack gap={'100'}>
            <SkeletonBodyText lines={4} />
            <br />
            <SkeletonDisplayText size={'small'} />
          </BlockStack>
        }
      >
        <Box>
          <VideoThumbnail onClick={() => { }} thumbnailUrl={null} />
        </Box>
      </MediaCard>
    </Box>
  );
}
