import React from 'react';
import { Route } from 'react-router-dom';
import StoreRoute from 'components/StoreRoute';
import {
  OnBoarding as OnBoardingPage,
  Dashboard as DashboardPage,
  ShipmentSettings as ShipmentSettingsPage,
  Billing as BillingPage,
  Language as LanguagePage,
  Settings as SettingsPage,
  FullfilmentEvents as FullfilmentEventsPage,
  TrackingPageNew,
  TrackingPageModern,
  TrackingPageSourceCode,
  ThankyouPage as ThankyouPagePage,
  KlaviyoPage as KlaviyoPagePage,
  OmnisendPage,
  EmailNotification as EmailNotificationPage,
  EmailNotificationRule as EmailNotificationRulePage,
  SmartEDD,
  MerchantNote as MerchantNotePage,
  Upsells as UpsellsPage,
  ShipbobConfig as ShipbobConfigPage,
  // NotFound as NotFoundPage,
  DefaultPage,
  Blacklisting,
  PreShipmentTimeline,
  MessageTimelinePage,
  CarrierMasking,
  CarrierMaskingPage,
  Stores as StoresPage,
  Account as AccountPage,
  Questionnaire as QuestionnairePage,
  ProductReviews as ProductReviewsPage,
  AddStore as AddStorePage,
  Perks as PerksPage,
  ApprovePage,
  ReconnectPage,
  NotificationsActivityFeed,
  NotificationsActivityDetails,
  MembersAndPermissions,
  YotpoSmsPage,
  KlaviyoIntegrationPage,
} from 'pages';
import { STORE_URLS, USER_URLS, PERMISSION_URLS, AUTH_URLS } from 'config/urls';
import Shipments3 from 'pages/Shipments3/components/Shipments';
import Shipment3DetailPageNew from 'pages/ShipmentDetailsNew';

import RushSplash from 'pages/ShopifyAuth/RushSplash';
import InstallManager from 'pages/InstallManager/InstallManager';
import OS2EditCode from 'pages/TrackingPageNew/components/OS2EditCode';

const storeBaseUrl = '/s/:uuid';

const privateRoutes = (storeUUID, accessToken) => {
  return !accessToken
    ? []
    : [
        <Route
          key={USER_URLS.QUESTIONNAIRE}
          path={USER_URLS.QUESTIONNAIRE}
          element={<QuestionnairePage />}
          exact
        />,
        <Route
          key={PERMISSION_URLS.APPROVE}
          path={`${PERMISSION_URLS.APPROVE}`}
          element={<ApprovePage />}
        />,
        <Route
          key={`${PERMISSION_URLS.RECONNECT}`}
          path={`${PERMISSION_URLS.RECONNECT}`}
          element={<ReconnectPage />}
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.SMART_EDD}`}
          path={`${storeBaseUrl}${STORE_URLS.SMART_EDD}`}
          element={
            <StoreRoute>
              <SmartEDD />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}/:notificationId`}
          path={`${storeBaseUrl}${STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}/:notificationId`}
          element={
            <StoreRoute>
              <NotificationsActivityDetails />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}
          path={storeBaseUrl + STORE_URLS.NOTIFICATIONS_ACTIVITY_FEED}
          element={
            <StoreRoute>
              <NotificationsActivityFeed />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION_RULE}/:notificationId`}
          path={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION_RULE}/:notificationId`}
          element={
            <StoreRoute>
              <EmailNotificationRulePage />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION_RULE}`}
          path={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION_RULE}`}
          element={
            <StoreRoute>
              <EmailNotificationRulePage />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION}`}
          path={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION}`}
          element={
            <StoreRoute>
              <EmailNotificationPage />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.TRACKING_PAGES}/:languageId/:mode`}
          path={`${storeBaseUrl}${STORE_URLS.TRACKING_PAGES}/:languageId/:mode`}
          element={
            <StoreRoute>
              <LanguagePage />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl + STORE_URLS.TRACKING_PAGE_MODERN}/:pageId?`}
          path={`${storeBaseUrl + STORE_URLS.TRACKING_PAGE_MODERN}/:pageId?`}
          element={
            <StoreRoute>
              <TrackingPageModern />
            </StoreRoute>
          }
        />,
        <Route
          key={`${
            storeBaseUrl + STORE_URLS.TRACKING_PAGE_SOURCE_CODE
          }/:pageId?`}
          path={`${
            storeBaseUrl + STORE_URLS.TRACKING_PAGE_SOURCE_CODE
          }/:pageId?`}
          element={
            <StoreRoute>
              <TrackingPageSourceCode />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.TRACKING_PAGES}
          path={storeBaseUrl + STORE_URLS.TRACKING_PAGES}
          element={
            <StoreRoute>
              <TrackingPageNew />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.TRACKING_PAGES}
          path={`${storeBaseUrl + STORE_URLS.TRACKING_PAGES}/code/:pageUuid`}
          element={
            <StoreRoute>
              <OS2EditCode />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.PRODUCT_REVIEWS}
          path={storeBaseUrl + STORE_URLS.PRODUCT_REVIEWS}
          element={
            <StoreRoute>
              <ProductReviewsPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.THANK_YOU}
          path={storeBaseUrl + STORE_URLS.THANK_YOU}
          element={
            <StoreRoute>
              <ThankyouPagePage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.KLAVIYO}
          path={storeBaseUrl + STORE_URLS.KLAVIYO}
          element={
            <StoreRoute>
              <KlaviyoPagePage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.YOTPO_SMS}
          path={storeBaseUrl + STORE_URLS.YOTPO_SMS}
          element={
            <StoreRoute>
              <YotpoSmsPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.KLAVIYO_INTEGRATION}
          path={storeBaseUrl + STORE_URLS.KLAVIYO_INTEGRATION}
          element={
            <StoreRoute>
              <KlaviyoIntegrationPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.OMNISEND}
          path={storeBaseUrl + STORE_URLS.OMNISEND}
          element={
            <StoreRoute>
              <OmnisendPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.FULLFILMENT_EVENTS}
          path={storeBaseUrl + STORE_URLS.FULLFILMENT_EVENTS}
          element={
            <StoreRoute>
              <FullfilmentEventsPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.MERCHANT_NOTE}
          path={storeBaseUrl + STORE_URLS.MERCHANT_NOTE}
          element={
            <StoreRoute>
              <MerchantNotePage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.UPSELLS}
          path={storeBaseUrl + STORE_URLS.UPSELLS}
          element={
            <StoreRoute>
              <UpsellsPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.SHIP_BOB_CONFIG}
          path={storeBaseUrl + STORE_URLS.SHIP_BOB_CONFIG}
          element={
            <StoreRoute>
              <ShipbobConfigPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.DASHBOARD}
          path={storeBaseUrl + STORE_URLS.DASHBOARD}
          element={
            <StoreRoute>
              <DashboardPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.SHIPMENTS_CONFIGURATIONS}
          path={storeBaseUrl + STORE_URLS.SHIPMENTS_CONFIGURATIONS}
          element={
            <StoreRoute>
              <ShipmentSettingsPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.SHIPMENTS_BLACKLISTING}
          path={storeBaseUrl + STORE_URLS.SHIPMENTS_BLACKLISTING}
          element={
            <StoreRoute>
              <Blacklisting />
            </StoreRoute>
          }
        />,
        <Route
          key={`${
            storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
          }/:timelineId/duplicate`}
          path={`${
            storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
          }/:timelineId/duplicate`}
          element={
            <StoreRoute>
              <MessageTimelinePage />
            </StoreRoute>
          }
        />,
        <Route
          key={`${
            storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
          }/:timelineId`}
          path={`${
            storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
          }/:timelineId`}
          element={
            <StoreRoute>
              <MessageTimelinePage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE}
          path={storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE}
          element={
            <StoreRoute>
              <PreShipmentTimeline />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.CARRIER_MASKING}/:maskingId`}
          path={`${storeBaseUrl}${STORE_URLS.CARRIER_MASKING}/:maskingId`}
          element={
            <StoreRoute>
              <CarrierMaskingPage />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.CARRIER_MASKING}/:maskingId/duplicate`}
          path={`${storeBaseUrl}${STORE_URLS.CARRIER_MASKING}/:maskingId/duplicate`}
          element={
            <StoreRoute>
              <CarrierMaskingPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.CARRIER_MASKING}
          path={storeBaseUrl + STORE_URLS.CARRIER_MASKING}
          element={
            <StoreRoute>
              <CarrierMasking />
            </StoreRoute>
          }
        />,
        <Route
          key={`${storeBaseUrl}${STORE_URLS.SHIPMENTS}/:shipmentId`}
          path={`${storeBaseUrl}${STORE_URLS.SHIPMENTS}/:shipmentId`}
          element={
            <StoreRoute>
              <Shipment3DetailPageNew />
            </StoreRoute>
          }
        />,

        <Route
          key={storeBaseUrl + STORE_URLS.SHIPMENTS}
          path={storeBaseUrl + STORE_URLS.SHIPMENTS}
          element={
            <StoreRoute>
              <Shipments3 />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.BILLING}
          path={storeBaseUrl + STORE_URLS.BILLING}
          element={
            <StoreRoute>
              <BillingPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.NOTIFICATIONS}
          path={storeBaseUrl + STORE_URLS.NOTIFICATIONS}
          element={
            <StoreRoute>
              <SettingsPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.ADDONS}
          path={storeBaseUrl + STORE_URLS.ADDONS}
          element={
            <StoreRoute>
              <SettingsPage />
            </StoreRoute>
          }
        />,

        <Route
          key={storeBaseUrl + STORE_URLS.ONBOARDING}
          path={storeBaseUrl + STORE_URLS.ONBOARDING}
          element={
            <StoreRoute>
              <OnBoardingPage />
            </StoreRoute>
          }
        />,
        <Route
          key={storeBaseUrl + STORE_URLS.PERKS}
          path={storeBaseUrl + STORE_URLS.PERKS}
          element={
            <StoreRoute>
              <PerksPage />
            </StoreRoute>
          }
        />,
        <Route
          key={`/auth/:install_slug/install`}
          path={`/auth/:install_slug/install`}
          element={<InstallManager />}
        />,
        <Route
          key={USER_URLS.ADD_STORE}
          path={USER_URLS.ADD_STORE}
          element={<AddStorePage />}
          exact
        />,
        <Route
          key={USER_URLS.DETAILS}
          path={USER_URLS.DETAILS}
          element={<AccountPage />}
          exact
        />,
        <Route
          key={USER_URLS.MEMBERS_AND_PERMISSIONS}
          path={USER_URLS.MEMBERS_AND_PERMISSIONS}
          element={<MembersAndPermissions />}
          exact
        />,
        <Route
          key={AUTH_URLS.RUSH_SPLASH}
          path={AUTH_URLS.RUSH_SPLASH}
          element={<RushSplash />}
        />,
        <Route
          key={AUTH_URLS.SHOPIFY_EXTENSIONS}
          path={AUTH_URLS.SHOPIFY_EXTENSIONS}
          element={<DefaultPage />}
        />,

        <Route key={'/s/:uuid'} path={'/s/:uuid'} element={<DefaultPage />} />,
        <Route key={'/s'} path={'/s'} element={<StoresPage />} />,
      ];
};

export default privateRoutes;
