import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import {
  Login,
  Register,
  RegisterByInvite,
  Recover,
  EmailValidation,
  RecoverSuccess,
  RecoverPassword,
  ChangeEmail,
} from 'pages/Visitor';
import { DefaultPage } from 'pages';

function visitorRoutes(accessToken) {
  return [
    <Route
      key={VISITOR_URLS.LOGIN}
      path={VISITOR_URLS.LOGIN}
      element={<Login />}
    />,
    <Route
      key={VISITOR_URLS.REGISTER}
      path={VISITOR_URLS.REGISTER}
      element={<Register />}
    />,
    <Route
      key={VISITOR_URLS.REGISTER_BY_INVITE}
      path={VISITOR_URLS.REGISTER_BY_INVITE}
      element={<RegisterByInvite />}
    />,
    <Route
      key={VISITOR_URLS.RECOVER}
      path={VISITOR_URLS.RECOVER}
      element={<Recover />}
    />,
    <Route
      key={VISITOR_URLS.RECOVER_SUCCESS}
      path={VISITOR_URLS.RECOVER_SUCCESS}
      element={<RecoverSuccess />}
    />,
    <Route
      key={VISITOR_URLS.RECOVER_PASSWORD}
      path={VISITOR_URLS.RECOVER_PASSWORD}
      element={<RecoverPassword />}
    />,
    <Route
      key={VISITOR_URLS.EMAIL_VALIDATION}
      path={VISITOR_URLS.EMAIL_VALIDATION}
      element={<EmailValidation />}
    />,
    <Route
      key={VISITOR_URLS.CHANGE_EMAIL}
      path={VISITOR_URLS.CHANGE_EMAIL}
      element={<ChangeEmail />}
    />,

    <Route
      key={VISITOR_URLS.All}
      path="*"
      element={
        accessToken ? (
          <DefaultPage />
        ) : (
          <Navigate to={`${VISITOR_URLS.LOGIN}${location.search}`} />
        )
      }
    />,
  ];
}

export default visitorRoutes;
