import React from 'react';
import { Card, ChoiceList, Text } from '@shopify/polaris';
import CountriesAutoComplete from 'components/CountriesAutoComplete';
import { useTranslation } from 'react-i18next';

function GeoLocationTargetCard({ geo_location_targeted, onChange, showError }) {
  const [t] = useTranslation();

  return (
    <Card roundedAbove="sm">
      <Text variant="headingMd" as="h2">
        {t('smart-edd.transit-time-page.card1-title1')}
      </Text>
      <div className="small-gap-list">
        <ChoiceList
          choices={[
            {
              label: t('smart-edd.transit-time-page.all-locations'),
              value: 'all',
            },
            {
              label: t('smart-edd.transit-time-page.specific-locations'),
              value: 'specific',
              renderChildren: (selected) =>
                selected ? (
                  <CountriesAutoComplete
                    allowMultiple={true}
                    label={''}
                    placeholder={t('common.search-for-countries')}
                    onChange={(val) => {
                      onChange('geo_location_targeted', val);
                    }}
                    value={
                      Array.isArray(geo_location_targeted)
                        ? geo_location_targeted
                        : []
                    }
                    error={showError}
                    errorMessage={
                      showError
                        ? t('common.field-required', { field_name: 'Country' })
                        : ''
                    }
                  />
                ) : (
                  ''
                ),
            },
          ]}
          selected={!geo_location_targeted ? ['all'] : ['specific']}
          onChange={([val]) => {
            if (val === 'all') {
              onChange('geo_location_targeted', null);
            } else {
              onChange('geo_location_targeted', []);
            }
          }}
        />
      </div>
    </Card>
  );
}

export default GeoLocationTargetCard;
