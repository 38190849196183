import React from 'react';
import { useLocation, Route, Navigate } from 'react-router-dom';
import stringHelper from 'helpers/string';

export default function CustomRedirect({ to, from }) {
  const location = useLocation();

  let queryString = '';
  if (location?.search) {
    const cookieParams = stringHelper.parseQueryString(location.search);
    if (cookieParams) {
      queryString = Object.keys(cookieParams)
        .map((key) => key + '=' + cookieParams[key])
        .join('&');
    }
  }

  let customURL = `${to}?${queryString}`;
  if (to.indexOf('?') > 0) {
    customURL = `${to}&${queryString}`;
  }

  return <Route path={from} element={<Navigate to={customURL} replace />} />;
}
