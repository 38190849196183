import Subtitle from 'components/Subtitle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, InlineStack, BlockStack } from '@shopify/polaris';
import PageURLCard from '../common/PageURLCard';
import SearchCard from '../common/SearchCard';
import BrandInfoCard from './BrandInfoCard';
import HeaderLinks from './HeaderLinks';
import FaqSection from './FaqSection';
import CustomCSSCard from './CustomCSSCard';
import AnalyticsCard from '../common/AnalyticsCard';
import LocalizationCard from '../common/LocalizationCard';
import { ViewIcon } from '@shopify/polaris-icons';
import CustomLanguages from '../common/CustomLanguages';
import {
  NewModernPageStyle,
  NewModernPageTitle,
} from 'pages/TrackingPageNew/trackingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetProxyPageConfigQuery,
  useCreateModernTrackingPageMutation,
  useUpdateTrackingPageMutation,
} from 'pages/TrackingPageNew/newTrackingPageApis';
import withProxyConfig from '../common/withProxyConfig';
import isEqual from 'lodash/isEqual';
import { STORE_URLS } from 'config/urls';
import { baseActions } from 'redux/store/baseSlice';
import MapLocation from './MapLocation';
import MigrationWarningCard from '../MigrationWarningCard';
import PublishModal from '../common/PublishModal';
import PublishButton from '../common/PublishButton';
import ColorStyles from './ColorStyles';
import RushSaveBar from 'components/custom/RushSaveBar';
import RushPage from 'components/custom/RushPage';

function ModernPage(props) {
  const {
    proxyConfig,
    onProxyUpdate,
    isProxyLoading,
    isDirtyProxy,
    isProxyUpdating,
    isProxyUpdated,
    saveProxyConfig,
    resetConfig,
    metaInfo,
    isMetaLoading,
  } = props;

  const [t] = useTranslation();
  const pageTitle = t('tracking_page_new.modern_page_title');
  const pagelearn = t('tracking_page_new.modern_page_learn');
  const pagelearnlink = t('tracking_page_new.modern_page_learn_link');
  const pageDescription = t('tracking_page_new.modern_page_description');

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const params = useParams();
  const { pageId } = params;

  const selectedTitle = useSelector(NewModernPageTitle);
  const selectedStyle = useSelector(NewModernPageStyle);

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [pageState, setPageState] = useState({
    title: selectedTitle,
    page_layout: selectedStyle,
    is_custom_enabled: false,
    custom_css: undefined,
    is_faq: false,
    faq_type: 'link',
    faq_link: null,
    faq_structure: [],
    store_name: undefined,
    store_logo_link: undefined,
    store_information: undefined,
    store_phone: undefined,
    store_details_link: undefined,
    go_to_shop_uri: undefined,
    is_top_menu_links: false,
    top_menu_links: [],
  });

  const {
    // eslint-disable-next-line no-unused-vars
    data: { top_menu_links_tracked: _, ...pageConfig } = {}, // ignoring top_menu_links_tracked
    isFetching: isPageConfigFetching,
    isSuccess: isPageConfigLoaded,
  } = useGetProxyPageConfigQuery(pageId, {
    skip: !pageId,
  });

  useEffect(() => {
    if (isPageConfigLoaded && !isPageConfigFetching) {
      setPageState({ ...pageConfig });
    }
  }, [isPageConfigLoaded, isPageConfigFetching]);

  const [
    createPage,
    {
      isLoading: isCreatingPage,
      isSuccess: isPageCreated,
      data: { uuid: createdPageUUID } = {},
    },
  ] = useCreateModernTrackingPageMutation();

  useEffect(() => {
    if (!isCreatingPage && isPageCreated && createdPageUUID) {
      setShowPublishModal(true);
    }
  }, [isPageCreated, isCreatingPage, createdPageUUID]);

  const [updatePage, { isLoading: isPageUpdating, isSuccess: isPageUpdated }] =
    useUpdateTrackingPageMutation();

  useEffect(() => {
    if (!isCreatingPage && isPageCreated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_created'),
          type: 'success',
        })
      );
    }
  }, [isCreatingPage, isPageCreated]);

  useEffect(() => {
    if (
      (!isPageUpdating && isPageUpdated) ||
      (!isProxyUpdating && isProxyUpdated)
    ) {
      dispatch(
        baseActions.setToastMessage({
          message: t('tracking_page_new.page_updated'),
          type: 'success',
        })
      );
    }
  }, [isPageUpdating, isPageUpdated, isProxyUpdating, isProxyUpdated]);

  const isPageStateDirty = useMemo(() => {
    let isDirty = false;
    if (pageConfig) {
      const currentObject = { ...pageState };
      const orgObject = Object.keys(currentObject).reduce((prev, next) => {
        prev[next] = pageConfig[next];
        return prev;
      }, {});
      isDirty = !isEqual(orgObject, currentObject);
    }
    return isDirty;
  }, [pageConfig, pageState]);

  const isDirtyState = useMemo(() => {
    if (!pageId) {
      return true;
    } else {
      return isDirtyProxy || isPageStateDirty;
    }
  }, [pageId, isDirtyProxy, isPageStateDirty]);

  const handleSave = () => {
    if (pageId) {
      if (isPageStateDirty) {
        const { uuid, ...tobeSaved } = pageState;
        updatePage({ uuid, data: { ...tobeSaved } });
      }
    } else {
      createPage({ ...pageState, store_uuid: proxyConfig.store_uuid });
    }

    if (isDirtyProxy) {
      saveProxyConfig();
    }
  };

  const handleDiscard = () => {
    setPageState({ ...pageConfig });
    resetConfig();
  };

  const handleChange = useCallback(
    (key, value) => {
      setPageState({ ...pageState, [key]: value });
    },
    [pageState]
  );

  const handleModalClose = () => {
    setShowPublishModal(false);
    navigate(`${location.pathname}/${createdPageUUID}`);
  };

  const handlePreview = () => {
    window.open(pageConfig.preview_link);
  };

  return (
    <>
      {isDirtyState &&
        !isProxyLoading &&
        !isPageConfigFetching &&
        !showPublishModal && (
          <RushSaveBar
            message={t('common.unsaved-changes')}
            saveAction={{
              content: t('common.save'),
              onAction: handleSave,
              loading: isPageUpdating || isCreatingPage || isProxyUpdating,
            }}
            discardAction={
              pageId
                ? {
                  onAction: handleDiscard,
                  loading:
                    isPageUpdating || isCreatingPage || isProxyUpdating,
                  content: t('common.discard'),
                }
                : undefined
            }
          />
        )}

      <RushPage
        title={pageTitle}
        primaryAction={
          pageId ? (
            <InlineStack gap={'300'}>
              <Button outline icon={ViewIcon} onClick={handlePreview}>
                {t('common.preview')}
              </Button>
              <PublishButton
                uuid={pageId}
                type={'modern'}
                title={pageState.title}
              />
            </InlineStack>
          ) : undefined
        }
        backAction={{
          onAction: () =>
            navigate(`/s/${params.uuid}${STORE_URLS.TRACKING_PAGES}`),
        }}
        subtitle={
          <Subtitle
            text={pageDescription}
            actions={[
              {
                text: pagelearn,
                source: pagelearnlink,
              },
            ]}
          />
        }
      >
        <BlockStack gap="300">
          <MigrationWarningCard />
          {pageId ? (
            <PageURLCard
              onProxyUpdate={onProxyUpdate}
              proxyConfig={proxyConfig}
              isLoading={
                isProxyLoading || isMetaLoading || isPageConfigFetching
              }
              proxies={metaInfo?.page_link?.proxy || []}
            />
          ) : (
            ''
          )}
          <SearchCard
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
            isLoading={isProxyLoading || isMetaLoading || isPageConfigFetching}
          />
          {/* <NoteCard /> */}
          <BrandInfoCard
            go_to_shop_uri={pageState.go_to_shop_uri}
            store_details_link={pageState.store_details_link}
            store_information={pageState.store_information}
            store_logo_link={pageState.store_logo_link}
            store_name={pageState.store_name}
            store_phone={pageState.store_phone}
            onChange={handleChange}
          />
          {/* <ProductRecommendationsCard /> */}
          <AnalyticsCard
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
            isLoading={isProxyLoading || isMetaLoading || isPageConfigFetching}
          />
          <HeaderLinks
            top_menu_links={pageState.top_menu_links}
            is_top_menu_links={pageState.is_top_menu_links}
            onChange={handleChange}
            metaLinks={metaInfo?.top_menu_links?.icons || []}
          />
          <FaqSection
            is_faq={pageState.is_faq}
            faq_type={pageState.faq_type}
            faq_link={pageState.faq_link}
            faq_structure={pageState.faq_structure}
            onChange={handleChange}
          />
          {/* <FaqSection /> */}
          <LocalizationCard
            onProxyUpdate={onProxyUpdate}
            proxyConfig={proxyConfig}
            isLoading={isProxyLoading || isMetaLoading || isPageConfigFetching}
          />
          <CustomLanguages />
          <MapLocation
            selected={pageState.map_location_mode}
            onChange={handleChange}
          />
          <ColorStyles
            selected={pageState.page_layout}
            onChange={handleChange}
          />
          <CustomCSSCard value={pageState.custom_css} onChange={handleChange} />
        </BlockStack>
      </RushPage>
      {showPublishModal && (
        <PublishModal
          uuid={createdPageUUID}
          type={'modern'}
          onClose={handleModalClose}
          title={pageState.title}
        />
      )}
    </>
  );
}

export default withProxyConfig(ModernPage);
