import React, { useEffect, useMemo, useState } from 'react';
import TranslateLink from 'components/TranslateLink';
import {
  Card,
  Form,
  FormLayout,
  Select,
  TextField,
  Text,
  SkeletonBodyText,
  InlineStack,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import ExternalLink from '../../../../components/ExternalLink';
import { ENV_STAGING } from 'Constants';
import { STAGE } from 'config';
import { useParams } from 'react-router-dom';
import useAdminLink from 'hooks/useAdminLink';
import RushBanner from 'components/custom/RushBanner';

function PageURLCard({
  isLoading,
  proxyConfig = {},
  onProxyUpdate,
  proxies = [],
}) {
  const [t] = useTranslation();
  const params = useParams();
  const { pageId } = params;
  const adminLink = useAdminLink();

  const rush_app = STAGE === ENV_STAGING ? 'rush-stage' : 'rush-app';

  const cardTitle = t('tracking_page_new.tracking_page_url.card_title');
  const warningMessage = t(
    'tracking_page_new.tracking_page_url.warning_message',
    {
      proxy_link: `${adminLink}/settings/apps/app_installations/app/${rush_app}`,
    }
  );
  const selectLabel = t('tracking_page_new.tracking_page_url.select_label');
  const helperLabel = t('tracking_page_new.tracking_page_url.helper_label');

  const [link, setLink] = useState(null);
  const [extrension, setExtrension] = useState(null);
  const proxy_link = useMemo(
    () => proxyConfig.public_proxy_link,
    [proxyConfig]
  );

  const linkOptions = useMemo(() => {
    const sorted = [...proxies].sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    return sorted.map((item) => {
      return proxy_link.domain + item;
    });
  }, [proxies, proxy_link]);

  useEffect(() => {
    setLink(
      linkOptions.find((opt) => opt === proxy_link.domain + proxy_link.proxy)
    );
    setExtrension(proxy_link.extension);
  }, [proxy_link, linkOptions]);

  const handleExtensionChange = (value) => {
    onProxyUpdate('public_proxy_link', {
      ...proxy_link,
      extension: value,
    });
  };

  const handleLinkChange = (value) => {
    const selectedLink = proxies.find((p) => value.endsWith(p));
    onProxyUpdate('public_proxy_link', {
      ...proxy_link,
      proxy: selectedLink,
    });
  };

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="500">
        <BlockStack gap="200">
          <Text variant="headingMd" as="span">
            {cardTitle}
          </Text>
          <RushBanner status="info" bannerKey={'page-url-card'}>
            <TranslateLink text={warningMessage} />
          </RushBanner>
        </BlockStack>

        {isLoading ? (
          <Box>
            <SkeletonBodyText />
          </Box>
        ) : (
          <div>
            <Box paddingBlockEnd={'100'}>
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                {selectLabel}
              </Text>
            </Box>
            <div className="PageURL-Form">
              <Form>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      options={linkOptions}
                      id={`select-permlink-select-label`}
                      value={link}
                      onChange={handleLinkChange}
                    />
                    <TextField
                      autoComplete={'off'}
                      id={`input-extension`}
                      value={extrension}
                      onChange={handleExtensionChange}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>

              <br />

              <InlineStack gap="100">
                <Text url={''}>
                  {t('tracking_page_new.source_code_page_link', {})}
                </Text>
                <ExternalLink external url={link + extrension + `?v=${pageId}`}>
                  {link + extrension + `?v=${pageId}`}
                </ExternalLink>
              </InlineStack>
            </div>
            <div className="PageURL-Form">
              <Text variant="bodyMd" as="span" color="subdued">
                {helperLabel}
              </Text>
            </div>
          </div>
        )}
      </BlockStack>
    </Card>
  );
}

export default PageURLCard;
