import React from 'react';
import {
  Card,
  ChoiceList,
  Text,
  SkeletonBodyText,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import LanguageField from './LanguageField';
import { useGetLanguageListQuery } from 'redux/store/commonBaseApis';
import TranslateLink from 'components/TranslateLink/TranslateLink';

const LocalizationCard = ({ onProxyUpdate, proxyConfig, isLoading }) => {
  const { t } = useTranslation();

  const title = t('tracking_page_new.localization_card_title');
  const description = t('tracking_page_new.localization_options.description');
  const primarylanguage = t(
    'settings.tracking_page.localisation.card.primary_language'
  );

  const {
    localisation_default_language_uuid = '',
    localisation_languages_selected_type = '',
    localisation_languages_selected_uuids = '',
    localisation_use_multiple_languages = false,
  } = proxyConfig;

  const { data: langList = [] } = useGetLanguageListQuery();

  const handleLanguagePickerChange = (value) => {
    onProxyUpdate(
      'localisation_use_multiple_languages',
      value.includes('languagePicker')
    );
  };

  const handleShortlistChoiceChange = ([value]) => {
    onProxyUpdate('localisation_languages_selected_type', value);
  };

  const handlePrimaryLangChange = ([value]) => {
    onProxyUpdate('localisation_default_language_uuid', value);
  };

  const handleShotlistChange = (value) => {
    onProxyUpdate('localisation_languages_selected_uuids', value);
  };

  return (
    <Card roundedAbove="sm" padding={0}>
      <Box padding={'500'}>
        <BlockStack gap="200">
          <Text as="h4" variant="headingMd">
            {title}
          </Text>
          <Text as="span" color="subdued">
            <TranslateLink text={description} />
          </Text>
        </BlockStack>
      </Box>

      {isLoading ? (
        <Box padding="500">
          <SkeletonBodyText />
        </Box>
      ) : (
        <>
          <Box padding="500" paddingBlockStart={'0'}>
            <BlockStack gap="200">
              <Text variant="headingSm" as="h3">
                {primarylanguage}
              </Text>
              <LanguageField
                languages={[...langList]}
                allowMultiple={false}
                showSelection={false}
                setSelectedOptionsInStore={handlePrimaryLangChange}
                selectedLanguages={localisation_default_language_uuid}
              />
              <Text as="span" color="subdued">
                <TranslateLink
                  text={t(
                    'tracking_page_new.localization_options.auto_complete_description'
                  )}
                />
              </Text>
            </BlockStack>
          </Box>

          <Box
            padding="500"
            borderBlockEndWidth="100"
            borderBlockStartWidth="100"
            borderColor="border-brand"
          >
            <div className="small-gap-list">
              <ChoiceList
                title={
                  <Text variant="headingSm" as="h3">
                    {t(
                      'tracking_page_new.localization_options.choice_list_title'
                    )}
                  </Text>
                }
                choices={[
                  {
                    label: t(
                      'tracking_page_new.localization_options.single_language_tracking_page'
                    ),
                    value: 'singleLanguage',
                  },
                  {
                    label: t(
                      'tracking_page_new.localization_options.language_picker_on_tracking_page'
                    ),
                    value: 'languagePicker',
                  },
                ]}
                selected={
                  localisation_use_multiple_languages
                    ? 'languagePicker'
                    : 'singleLanguage'
                }
                onChange={handleLanguagePickerChange}
              />
            </div>
          </Box>
          {localisation_use_multiple_languages && (
            <Box padding="500">
              <div className="small-gap-list">
                <ChoiceList
                  title={
                    <Text variant="headingSm" as="h3">
                      {t(
                        'tracking_page_new.localization_options.language_picker_choice_list_title'
                      )}
                    </Text>
                  }
                  choices={[
                    {
                      label: t(
                        'tracking_page_new.localization_options.all_supported_languages'
                      ),
                      value: 'every',
                    },
                    {
                      label: t(
                        'tracking_page_new.localization_options.shortlist_languages'
                      ),
                      value: 'shortlist',
                    },
                  ]}
                  selected={
                    localisation_languages_selected_type === 'shortlist'
                      ? 'shortlist'
                      : 'every'
                  }
                  onChange={handleShortlistChoiceChange}
                />
              </div>
              {localisation_languages_selected_type === 'shortlist' && (
                <Box paddingBlockStart="2">
                  <LanguageField
                    languages={[...langList]}
                    allowMultiple={true}
                    showSelection={true}
                    setMultipleSelectedOptionsInStore={handleShotlistChange}
                    selectedLanguages={localisation_languages_selected_uuids}
                  />
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

export default LocalizationCard;
