import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddStorePage } from '@rush-deliveries/storybooks-shopify';
import ShopifyIcon from 'images/shopify-icon.svg';
import BigcommerceIcon from 'images/bigcommerce-icon.svg';
import RestAPIIcon from 'images/api-svgrepo-com.svg';
import TranslateLink from 'components/TranslateLink';
import { API_URL } from 'config';
import i18next from 'i18next';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';

export default function AddStore() {
  const [t] = useTranslation();

  const { data: user = {} } = useGetUserDetailsQuery();

  let translations;
  try {
    translations = require(`@shopify/polaris/locales/${i18next.language}.json`);
  } catch (e) {
    translations = require('@shopify/polaris/locales/en.json');
  }

  return (
    <>
      <AddStorePage
        translationFile={translations}
        title={t('add_store.title')}
        items={[
          {
            icon: ShopifyIcon,
            title: t('add_store.shopify.title'),
            description: t('add_store.shopify.description'),
            button: {
              text: t('add_store.connect'),

              onClick: async () => {
                window.open(
                  `${API_URL}/auth/shopify/connect?user_uuid=${user.id}`,
                  '_self'
                );
              },
              primary: true,
            },
          },
          {
            disabled: true,
            comingSoonText: t('add_store.coming_soon'),
            icon: RestAPIIcon,
            title: t('add_store.rest_api.title'),
            description: t('add_store.rest_api.description'),
            button: {
              text: t('add_store.connect'),
              onClick: () => {},
              primary: true,
              disabled: true,
            },
          },
          {
            disabled: true,
            comingSoonText: t('add_store.coming_soon'),
            icon: BigcommerceIcon,
            title: t('add_store.big_commerce.title'),
            description: t('add_store.big_commerce.description'),
            button: {
              text: t('add_store.connect'),
              onClick: () => {},
              primary: true,
              disabled: true,
            },
          },
        ]}
        footer={<TranslateLink text={t('add_store.footer')} />}
      />
    </>
  );
}
