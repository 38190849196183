import React from 'react';
import { Link } from '@shopify/polaris';
// import { useNavigate } from '@shopify/app-bridge-react';
import useIsEmbededApp from 'hooks/useIsEmbededApp';
import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';

const BridgeLink = ({ url, children, ...rest }) => {
  const app = useAppBridge();

  // const navigate = useNavigate();
  return (
    <Link
      {...rest}
      url={url}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        const redirect = Redirect.create(app);
        if (rest.pathType === 'app') {
          redirect.dispatch(Redirect.Action.APP, url);
        } else {
          redirect.dispatch(Redirect.Action.REMOTE, {
            url,
            newContext: rest.external === undefined || rest.external,
          });
        }
      }}
    >
      {children}
    </Link>
  );
};

export default function ExternalLink({ url, children, ...rest }) {
  const isEmbededApp = useIsEmbededApp();

  let link = url === null || !url ? '' : url;
  let newUrl = link;
  if (!rest.avoidUTM) {
    let hashtag = '';
    if (link?.includes('#')) {
      const tokens = link.split('#');
      link = tokens[0];
      hashtag = '#' + tokens[1];
    }

    const params = new URLSearchParams(link.split('?')[1]);
    if (!params.has('utm_source')) {
      params.append('utm_source', 'app.rush.app');
    }
    if (!params.has('utm_medium')) {
      params.append('utm_medium', 'app.rush.app');
    }

    newUrl = `${link.split('?')[0]}?${params.toString()}${hashtag}`;
  }

  return isEmbededApp ? (
    <BridgeLink url={newUrl} children={children} {...rest} />
  ) : (
    <Link url={newUrl} target={'_blank'} external {...rest}>
      {children}
    </Link>
  );
}
