import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetFeaturesQuery } from 'redux/store/commonStoreApis';
import { StoreUUID } from 'redux/store/shopSlice';

const FeaturePageWrapper = (Component, featureSlug) => (props) => {
  const storeUUID = useSelector(StoreUUID);

  const {
    data: features = [],
    isSuccess,
    isLoading,
  } = useGetFeaturesQuery(undefined, { skip: !storeUUID });
  const isAvailable = useMemo(() => {
    if (isSuccess && !isLoading) {
      const feature = features.find(({ slug }) => slug === featureSlug);
      return feature?.is_available;
    } else {
      return true;
    }
  }, [featureSlug, isSuccess, isLoading, features]);
  return <Component {...props} isFeatureAvailable={isAvailable} />;
};

export default FeaturePageWrapper;
