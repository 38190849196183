import React from 'react';
import { Card } from '@shopify/polaris';
import TabsSkeleton from './TabsSkeleton';
import WithoutChecksTableSkeleton from './WithoutChecksTableSkeleton';

function WithoutChecksTabsTableSkeleton() {
  return (
    <Card roundedAbove="sm" padding={0}>
      <TabsSkeleton />
      <WithoutChecksTableSkeleton />
    </Card>
  );
}

export default WithoutChecksTabsTableSkeleton;
