import React from 'react';
import { Text } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import RushBanner from 'components/custom/RushBanner';

function AddBanner() {
  const [t] = useTranslation();

  return (
    <RushBanner
      title={t('notifications.yotpo_sms.banner.title')}
      action={{
        content: t('notifications.yotpo_sms.banner_button.label'),
        url: t('notifications.yotpo_sms.banner_button.url'),
        external: true,
        target: '_blank',
      }}
      status="info"
      bannerKey={'yotpo-sms-and-email'}
    >
      <Text variant="bodyMd" as="span">
        {t('notifications.yotpo_sms.banner.description')}
      </Text>
    </RushBanner>
  );
}

export default AddBanner;
