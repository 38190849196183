import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import RushModal from 'components/custom/RushModal';
import { Modal } from '@shopify/polaris';
import _ from 'lodash';
export default function EmailDeleteModal({
  deleteEmailTriggerById,
  emailToaster,
  closeEmailDeleteModal,
  triggerId,
  ...props
}) {
  const [t] = useTranslation();
  const text = t('email.modal.trigger_delete.description');

  useEffect(() => {
    if (!_.isNull(emailToaster.toastText)) {
      closeEmailDeleteModal();
    }
  }, [emailToaster, closeEmailDeleteModal]);

  return (
    <RushModal
      open={props.showEmailDeleteModal}
      onClose={closeEmailDeleteModal}
      title={t('email.modal.trigger_delete.title')}
      primaryAction={{
        content: t('email.modal.trigger_delete.action_delete'),
        destructive: true,
        onAction: () => {
          deleteEmailTriggerById(triggerId);
          closeEmailDeleteModal();
        },
      }}
      secondaryActions={[
        {
          content: t('email.modal.trigger_delete.action_cancel'),
          onAction: closeEmailDeleteModal,
        },
      ]}
    >
      <Modal.Section>
        <TranslateLink text={text} />
      </Modal.Section>
    </RushModal>
  );
}
