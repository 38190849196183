import moment from 'moment';

export const transformParams = (urlParams) => {
  if (urlParams.has('searchByFields')) {
    urlParams.set(
      'searchByFields',
      urlParams
        .get('searchByFields')
        ?.toString()
        .replaceAll('&', ';')
        .replaceAll('=', ':') || ''
    );
  }
  if (urlParams.has('searchByRelation')) {
    urlParams.set(
      'searchByRelation',
      urlParams
        .get('searchByRelation')
        ?.toString()
        .replaceAll('&', ';')
        .replaceAll('=', ':') || ''
    );
  }

  if (urlParams.has('dateRange')) {
    urlParams.set(
      'dateRange',
      urlParams
        .get('dateRange')
        ?.toString()
        .replaceAll('&', ';')
        .replaceAll('=', ':') || ''
    );
  }
  return urlParams;
};

export const formatDate = (dateTime) => {
  const currentDate = moment.utc(dateTime);

  const formattedDate = currentDate.calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'MMMM D',
  });
  return formattedDate;
};

export const formatDateTime = (dateTime) => {
  const formattedDateTime = moment.utc(dateTime).calendar(null, {
    sameDay: '[Today at] hh:mm a',
    nextDay: '[Tomorrow at] hh:mm a',
    nextWeek: 'dddd [at] hh:mm a',
    sameElse: 'MMM D [at] hh:mm a',
  });
  return formattedDateTime;
};
