import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ChoiceList,
  Card,
  Checkbox,
  DropZone,
  Spinner,
  Button,
  Icon,
  Text,
  InlineCode,
  InlineStack,
  BlockStack,
  Box,
  Divider,
} from '@shopify/polaris';
import { AlertCircleIcon } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { useUploadLogoMutation } from '../../maskingApi';
import '../styles.scss';

function OverrideSetupCard({
  currCarrierRule,
  handleChoiceChange,
  formValidations,
}) {
  const [t] = useTranslation();
  const [uploadLogo, uploadLogoResponse] = useUploadLogoMutation();
  const {
    data: uploadData,
    isFetching: isLogoUploading,
    isSuccess: isLogoUploaded,
  } = uploadLogoResponse;
  const renderOverrideName = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) => handleChoiceChange('override_name_value', value)}
          value={currCarrierRule.override_name_value}
          autoComplete="off"
          error={
            !formValidations.override_name
              ? t('carrier-maskings.override-name-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange]
  );
  useEffect(() => {
    if (!isLogoUploading && isLogoUploaded && uploadData) {
      handleChoiceChange('override_logo_value', uploadData?.url);
    }
  }, [isLogoUploading, isLogoUploaded, uploadData]);

  const handleLogoRemove = (event) => {
    event.stopPropagation();
    handleChoiceChange('override_logo_value', null);
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => {
      uploadLogo(acceptedFiles[0]);
    },
    [uploadLogo]
  );

  const fileUpload = !currCarrierRule.override_logo_value && (
    <DropZone.FileUpload />
  );
  const uploadedFile = currCarrierRule.override_logo_value && (
    <div className="override-logo-container">
      <img
        width={'100px'}
        height={'100px'}
        src={currCarrierRule.override_logo_value}
      />
    </div>
  );

  const renderOverrideLogo = useCallback(
    (isSelected) =>
      isSelected && (
        <BlockStack gap="100">
          <BlockStack gap="200">
            <InlineStack gap="100">
              <DropZone
                accept="image/*"
                type="image"
                allowMultiple={false}
                onDrop={handleDropZoneDrop}
              >
                {isLogoUploading ? (
                  <Spinner />
                ) : (
                  <>
                    {uploadedFile}
                    {fileUpload}
                  </>
                )}
              </DropZone>
            </InlineStack>
            {currCarrierRule.override_logo_value && (
              <InlineStack blockAlign="center">
                <div className="remove-logo-buttom">
                  <Button onClick={handleLogoRemove} variant="plain" destructive>
                    Remove
                  </Button>
                </div>
              </InlineStack>
            )}
          </BlockStack>

          {!formValidations.override_logo && (
            <InlineStack gap={'200'}>
              <Box>
                <Icon size="small" source={AlertCircleIcon} color="critical" />
              </Box>
              <Text variant="bodyMd" as="span" color="critical">
                {t('carrier-maskings.override-logo-required')}
              </Text>
            </InlineStack>
          )}
        </BlockStack>
      ),
    [currCarrierRule, handleChoiceChange]
  );

  const renderOverrideWeb = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) =>
            handleChoiceChange('override_website_value', value)
          }
          value={currCarrierRule.override_website_value}
          autoComplete="off"
          placeholder="https://"
          helpText={
            <p>
              {t('carrier-maskings.use-shortcodes')}
              {':'}
              <br />
              <Text as="span">
                <InlineCode>
                  &#123;&#123;tracking_number&#125;&#125;{' '}
                </InlineCode>
              </Text>{' '}
              <Text as="span">
                <InlineCode>&#123;&#123;carrier_uuid&#125;&#125; </InlineCode>
              </Text>{' '}
              <Text as="span">
                <InlineCode>&#123;&#123;carrier_name&#125;&#125; </InlineCode>
              </Text>{' '}
            </p>
          }
          error={
            !formValidations.override_website
              ? t('carrier-maskings.override-website-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange]
  );
  const renderOverrideLink = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) =>
            handleChoiceChange('override_tracking_link_value', value)
          }
          value={currCarrierRule.override_tracking_link_value}
          autoComplete="off"
          placeholder="https://"
          helpText={
            <p>
              {t('carrier-maskings.use-shortcodes')}
              {':'}
              <br />
              <Text as="span">
                <InlineCode>
                  &#123;&#123;tracking_number&#125;&#125;{' '}
                </InlineCode>
              </Text>{' '}
              <Text as="span">
                <InlineCode>&#123;&#123;carrier_uuid&#125;&#125; </InlineCode>
              </Text>{' '}
              <Text as="span">
                <InlineCode>&#123;&#123;carrier_name&#125;&#125; </InlineCode>
              </Text>{' '}
            </p>
          }
          error={
            !formValidations.override_tracking_link
              ? t('carrier-maskings.override-tracking-link-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange]
  );
  const renderOverridePhone = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) =>
            handleChoiceChange('override_phone_value', value)
          }
          value={currCarrierRule.override_phone_value}
          autoComplete="off"
          placeholder="+"
          error={
            !formValidations.override_phone
              ? t('carrier-maskings.override-phone-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange, formValidations]
  );

  return (
    <Card roundedAbove="sm" padding={0}>
      <Box padding={'500'} paddingBlockEnd={'0'}>
        <Text variant="headingMd" as="h2">
          {t('carrier-maskings.override-setup')}
        </Text>
      </Box>
      <Box padding={'500'} paddingBlockEnd={'0'}>
        <BlockStack gap={'200'}>
          <Checkbox
            label={t('carrier-maskings.completely-hide-carrier')}
            onChange={(value) =>
              handleChoiceChange('completely_hide_carrier', value)
            }
            checked={currCarrierRule.completely_hide_carrier}
          ></Checkbox>
          <Text variant="bodyMd" as="span" color="subdued">
            {t('carrier-maskings.completely-hide-info')}
          </Text>
        </BlockStack>
      </Box>
      {!currCarrierRule.completely_hide_carrier ? (
        <>
          <Box padding={'500'}>
            <BlockStack gap={'200'}>
              <Text variant="headingSm" as="h2">
                {t('carrier-maskings.override-or-hide-properties')}
              </Text>
              <div className="small-gap-list">
                <ChoiceList
                  title={t('carrier-maskings.name')}
                  choices={[
                    { label: t('carrier-maskings.keep-it'), value: 0 },
                    { label: t('carrier-maskings.hide-it'), value: 1 },
                    {
                      label: t('carrier-maskings.override-it-with'),
                      value: 2,
                      renderChildren: renderOverrideName,
                    },
                  ]}
                  selected={[currCarrierRule.override_name_option]}
                  onChange={([value]) =>
                    handleChoiceChange('override_name_option', value)
                  }
                />
              </div>
            </BlockStack>
          </Box>
          <Divider />
          <Box
            padding={'500'}
            borderBlockEndWidth="0165"
            borderColor="border-brand"
          >
            <div className="small-gap-list">
              <ChoiceList
                title={t('carrier-maskings.logo')}
                choices={[
                  { label: t('carrier-maskings.keep-it'), value: 0 },
                  { label: t('carrier-maskings.hide-it'), value: 1 },
                  {
                    label: t('carrier-maskings.override-it-with'),
                    value: 2,
                    renderChildren: renderOverrideLogo,
                  },
                ]}
                selected={[currCarrierRule.override_logo_option]}
                onChange={([value]) =>
                  handleChoiceChange('override_logo_option', value)
                }
              />
            </div>
          </Box>
          <Box
            padding={'500'}
            borderBlockEndWidth="0165"
            borderColor="border-brand"
          >
            <div className="small-gap-list">
              <ChoiceList
                title={t('carrier-maskings.website')}
                choices={[
                  { label: t('carrier-maskings.keep-it'), value: 0 },
                  { label: t('carrier-maskings.hide-it'), value: 1 },
                  {
                    label: t('carrier-maskings.override-it-with'),
                    value: 2,
                    renderChildren: renderOverrideWeb,
                  },
                ]}
                selected={[currCarrierRule.override_website_option]}
                onChange={([value]) =>
                  handleChoiceChange('override_website_option', value)
                }
              />
            </div>
          </Box>
          <Box
            padding={'500'}
            borderBlockEndWidth="0165"
            borderColor="border-brand"
          >
            <div className="small-gap-list">
              <ChoiceList
                title={t('carrier-maskings.tracking-link')}
                choices={[
                  { label: t('carrier-maskings.keep-it'), value: 0 },
                  { label: t('carrier-maskings.hide-it'), value: 1 },
                  {
                    label: t('carrier-maskings.override-it-with'),
                    value: 2,
                    renderChildren: renderOverrideLink,
                  },
                ]}
                selected={[currCarrierRule.override_tracking_link_option]}
                onChange={([value]) =>
                  handleChoiceChange('override_tracking_link_option', value)
                }
              />
            </div>
          </Box>
          <Box
            padding={'500'}
            borderBlockEndWidth="0165"
            borderColor="border-brand"
          >
            <div className="small-gap-list">
              <ChoiceList
                title={t('carrier-maskings.phone')}
                choices={[
                  { label: t('carrier-maskings.keep-it'), value: 0 },
                  { label: t('carrier-maskings.hide-it'), value: 1 },
                  {
                    label: t('carrier-maskings.override-it-with'),
                    value: 2,
                    renderChildren: renderOverridePhone,
                  },
                ]}
                selected={[currCarrierRule.override_phone_option]}
                onChange={([value]) =>
                  handleChoiceChange('override_phone_option', value)
                }
              />
            </div>
          </Box>
        </>
      ) : (
        <br />
      )}
    </Card>
  );
}
OverrideSetupCard.propTypes = {
  currCarrierRule: PropTypes.object,
  handleChoiceChange: PropTypes.func,
};
export default OverrideSetupCard;
