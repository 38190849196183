import { Modal } from '@shopify/polaris';
import React from 'react';

function RushModal(props) {
  const { children, ..._pageProps } = props;

  return <Modal {..._pageProps}>{children}</Modal>;
}

export default RushModal;
