import React, { useState } from 'react';
import {  TextField ,Modal} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import RushModal from 'components/custom/RushModal';
const PageTitleModal = ({ onClose, onOk, isLoading }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!title) {
      setError(t('common.field-required', { field_name: 'Title' }));
      return;
    }
    onOk(title);
  };

  return (
    <div>
      <RushModal
        open={true}
        onClose={onClose}
        title={t('settings.os2_tracking_page.wizard.step3.name_box.title')}
        primaryAction={{
          loading: isLoading,
          content: t('common.duplicate'),
          onClick: handleSubmit,
        }}
      >
        <Modal.Section>
          <TextField
            autoComplete={'off'}
            label=""
            value={title}
            onChange={setTitle}
            error={!title && error}
            autoFocus
          />
        </Modal.Section>
      </RushModal>
    </div>
  );
};

export default PageTitleModal;
