const { installRoutesMeta } = require('Utils/utils');
const { VISITOR_URLS } = require('config/urls');
const { PERMISSION_URLS } = require('config/urls');
const { USER_URLS } = require('config/urls');

module.exports = {
  ENV_DEV: 'development',
  ENV_STAGING: 'staging',
  ENV_PRODUCTION: 'production',
  GEO_CITIES_PER_PAGE_RECORDS: 10,
  PER_PAGE_RECORDS: 20,
  SHIPMENTS_PER_PAGE_RECORDS: 50,
  SHOPIFY_API_VERSION: '2020-07',

  SHIPMENTS_SORT_OPTION_DATE_CREATED_DESC: '-created_at',

  DATE_RANGE_VALUE_TODAY: 'today',
  DATE_RANGE_VALUE_YESTERDAY: 'yesterday',
  DATE_RANGE_VALUE_LAST_7_DAYS: 'last_7_days',
  DATE_RANGE_VALUE_LAST_30_DAYS: 'last_30_days',
  DATE_RANGE_VALUE_LAST_60_DAYS: 'last_60_days',
  DATE_RANGE_VALUE_LAST_MONTH: 'last_month',
  DATE_RANGE_VALUE_LAST_90_DAYS: 'last_90_days',
  DATE_RANGE_VALUE_LAST_120_DAYS: 'last_120_days',
  DATE_RANGE_VALUE_THIS_YEAR: 'this_year',
  DATE_RANGE_VALUE_LAST_365_DAYS: 'last_365_days',
  DATE_RANGE_VALUE_PREVIOUS_MONTH: 'last_month',
  DATE_RANGE_VALUE_LAST_YEAR: 'last_year',
  DATE_RANGE_VALUE_CUSTOM: 'custom',

  DB_DATE_FORMAT_STRING: 'YYYY-MM-DD',
  TIME_FORMAT_STRING: 'YYYY-MM-DD HH:mm:ss',
  DATE_FORMAT_STRING: 'MM/DD/YYYY',
  SHOPIFY_PERMISSIONS:
    'read_products,write_orders,read_locations,read_locales,read_translations,read_customers,write_fulfillments,read_shipping,write_shipping,write_merchant_managed_fulfillment_orders',

  SHIPMENTS_SORT_OPTIONS: [
    { label: 'Newest created', value: '-created_at' },
    { label: 'Oldest created', value: 'created_at' },
    { label: 'Newest synced', value: 'last_synced_at' },
    { label: 'Oldest synced', value: '-last_synced_at' },
  ],

  IMPORT_DISPLAY_ORDER_NUMBER: 'display_order_number',
  IMPORT_TRACKING_NUMBER: 'tracking_number',
  IMPORT_CARRIER_SLUG: 'carrier_slug',
  IMPORT_AUTOMATIC_SLUG: 'automatic',
  IMPORT_MODAL_SHOW_RECORDS_COUNT: 3,

  SHIPMENT_STATUS_DELIVERED: 'delivered',
  SHIPMENT_ACTION_DELIVERED: 'MarkAsDelivered',
  SHIPMENT_ACTION_DELETE: 'Delete',
  TRACKING_PAGE_THEME_MODERN: 'modern',
  TRACKING_PAGE_THEME_TRADITIONAL: 'traditional',
  TRACKING_PAGE_THEME_DIY: 'diy',

  TRACKING_PAGE_ANIMATION: [
    { label: 'Animated point', value: true },
    { label: 'Already drown path', value: false },
  ],

  TRACKING_PAGE_SHIPMENT_DETAIL: [
    { label: 'Email Address', value: 'email_address' },
    { label: 'Phone number', value: 'phone_number' },
    { label: 'Order number + Email address', value: 'order_email_address' },
    { label: 'Order number + Phone number', value: 'order_phone_number' },
  ],
  TRACKING_PAGE_SEARCH_FROM_PHONE: 'check_phone',
  TRACKING_PAGE_SEARCH_FROM_EMAIL: 'check_email',
  TRACKING_PAGE_SEARCH_FROM_ORDER: 'check_order',

  CURRENT_MONTH: 'current',
  PREVIOUS_MONTH: 'previous',
  AUTH_STATUS_MISSION: 'missing',
  AUTH_STATUS_APPROVAL_REQUIRED: 'approval_required',
  AUTH_STATUS_GOOD: 'good',
  AUTH_CODE_ERROR: 'code is already used.',
  FEATURE_SUBSCRIPTION_PRICE: 1,
  FEATURE_SHIPMENTS: 2,
  FEATURE_TRACKING_PAGE: 4,
  FEATURE_FREE_TRACKINGS: 9,
  FEATURE_PAYPAL_NOTIFICATION: 10,
  FEATURE_EMAIL: 11,
  FEATURE_SHIPBOB: 26,
  FEATURE_THANK_YOU_PAGE: 30,
  FEATURE_KLAVIYO_PAGE: 12,
  FEATURE_OMNISEND_PAGE: 21,
  CHARGE_TYPE_SUBSCRIPTION: 'subscription',
  CHARGE_TYPE_CHARGE_LIMIT: 'cap',
  CHARGE_TYPE_PROMO: 'promo',
  CHARGE_TYPE_PROMO_CAP: 'promo_cap',
  BILLING_PLAN_REASON_START_A_PLAN: 1,
  BILLING_PLAN_REASON_UPGRADE_ID: 2,
  BILLING_PLAN_REASON_DOWNGRADE_ID: 3,
  BILLING_PLAN_REASON_PROMO_CODE_APPLIED: 4,
  BILLING_PLAN_REASON_CHARGE_LIMIT_ID: 8,
  BILLING_PLAN_REASON_RENEWAL: 9,
  BILLING_PLAN_REASON_FREE_ID: 10,
  FEATURE_FULLFILMENT_NOTIFICATION: 19,

  // small messages
  SMALL_TOP_MESSAGE_ONBOARDING_STORE_SYNCED: 'onboarding_store_synced',

  // settings page constants
  SETTINGS_BUTTON_DEFAULT: null,
  SETTINGS_BUTTON_PRIMARY: 'primary',
  SETTINGS_BUTTON_DESTRUCTIVE: 'destructive',
  SETTINGS_BUTTON_DISABLED: 'disabled',
  SETTINGS_EMAIL_CHANNEL_GOTO_CONFIGURATION: 'email_channel_goto_configuration',
  SETTINGS_EMAIL_CHANNEL_ACTION_ACTIVATE: 'email_channel_action_activate',
  SETTINGS_EMAIL_CHANNEL_ACTION_DEACTIVATE: 'email_channel_action_deactivate',
  SETTINGS_SMS_CHANNEL_GOTO_CONFIGURATION: 'sms_channel_goto_configuration',
  SETTINGS_SMS_CHANNEL_ACTION_ACTIVATE: 'sms_channel_action_activate',
  SETTINGS_SMS_CHANNEL_ACTION_DEACTIVATE: 'sms_channel_action_deactivate',
  SETTINGS_TRACKING_PAGE_GOTO_CONFIGURATION: 'tracking_page_goto_configuration',
  SETTINGS_THANKYOU_PAGE: 'shopify_order_status_page_open_configuration',
  SETTINGS_FORECASTED_DELIVERY_DATE_OPEN_CONFIGURATION:
    'smart_delivery_date_estimate_open_configuration',
  SETTINGS_FORECASTED_DELIVERY_DATE_ACTION_ACTIVATE:
    'forecasted_delivery_date_action_activate',
  SETTINGS_FORECASTED_DELIVERY_DATE_ACTION_DEACTIVATE:
    'forecasted_delivery_date_action_deactivate',
  SETTINGS_SHOPIFY_FULFILMENT_CONFIGURATION:
    'shopify_fulfilment_event_goto_configuration',
  SETTINGS_PAYPAL_OPEN_CONFIGURATION: 'paypal_open_configuration',
  SETTINGS_YOTPO_OPEN_CONFIGURATION: 'yotpo_smsbump_goto_configuration',
  SETTINGS_PAYPAL_ACTION_ACTIVATE: 'paypal_action_activate',
  SETTINGS_PAYPAL_ACTION_DEACTIVATE: 'paypal_action_deactivate',
  SETTINGS_PAYPAL_ACTION_DELETE: 'paypal_action_delete',
  SETTINGS_SHIPBOB_OPEN_CONFIGURATION: 'shipbob_open_configuration',
  SETTINGS_SHIPBOB_ACTION_ACTIVATE: 'shipbob_action_activate',
  SETTINGS_SHIPBOB_ACTION_DEACTIVATE: 'shipbob_action_deactivate',
  SETTINGS_KLAVIYO_OPEN_CONFIGURATION: 'klaviyo_open_configuration',
  SETTINGS_KLAVIYO2_OPEN_CONFIGURATION: 'klaviyo2_open_configuration',
  SETTINGS_KLAVIYO_ACTION_ACTIVATE: 'klaviyo_action_activate',
  SETTINGS_KLAVIYO_ACTION_DEACTIVATE: 'klaviyo_action_deactivate',
  SETTINGS_OMNISEND_OPEN_CONFIGURATION: 'omnisend_open_configuration',
  SETTINGS_OMNISEND_ACTION_ACTIVATE: 'omnisend_action_activate',
  SETTINGS_OMNISEND_ACTION_DEACTIVATE: 'omnisend_action_deactivate',
  SETTINGS_PRODUCT_REVIEW_OPEN_CONFIGURATION:
    'product_review_app_open_configuration',
  SETTINGS_SLACK_OPEN_CONFIGURATION: 'slack_open_configuration',
  SETTINGS_SLACK_ACTION_ACTIVATE: 'slack_action_activate',
  SETTINGS_SLACK_ACTION_DEACTIVATE: 'slack_action_deactivate',
  SETTINGS_ZAPIER_OPEN_CONFIGURATION: 'zapier_open_configuration',
  SETTINGS_ZAPIER_ACTION_ACTIVATE: 'zapier_action_activate',
  SETTINGS_ZAPIER_ACTION_DEACTIVATE: 'zapier_action_deactivate',
  SETTINGS_LOCALIZATION_GOTO_CONFIGURATION: 'localization_goto_configuration',
  SETTINGS_LOCALIZATION_ACTION_ACTIVATE: 'localization_action_activate',
  SETTINGS_LOCALIZATION_ACTION_DEACTIVATE: 'localization_action_deactivate',
  SETTINGS_MERCHANTS_NOTE_GOTO_CONFIGURATION:
    'merchants_note_goto_configuration',
  SETTINGS_MERCHANTS_NOTE_ACTION_ACTIVATE: 'merchants_note_action_activate',
  SETTINGS_MERCHANTS_NOTE_ACTION_DEACTIVATE: 'merchants_note_action_deactivate',
  SETTINGS_UPSELLS_GOTO_CONFIGURATION: 'upsells_goto_configuration',
  SETTINGS_UPSELLS_ACTION_ACTIVATE: 'upsells_action_activate',
  SETTINGS_UPSELLS_ACTION_DEACTIVATE: 'upsells_action_deactivate',
  SETTINGS_WEBHOOKS_GOTO_CONFIGURATION: 'webhooks_goto_configuration',
  SETTINGS_WEBHOOKS_ACTION_ACTIVATE: 'webhooks_action_activate',
  SETTINGS_WEBHOOKS_ACTION_DEACTIVATE: 'webhooks_action_deactivate',
  SETTINGS_SHOPIFY_FULFILMENT_EVENT_ACTION_ACTIVATE:
    'shopify_fulfilment_event_action_activate',
  SETTINGS_SHOPIFY_FULFILMENT_EVENT_ACTION_DEACTIVATE:
    'shopify_fulfilment_event_action_deactivate',
  SETTINGS_CUSTOMER_SUPPORT_LIVE_CHAT: 'live_customer_support_chat',
  SETTINGS_CUSTOMER_CHAT_OPEN: 'chat_open',
  SETTINGS_UPGRADE_TO_PLAN: 'upgrade_to_plan',

  TASK_ONBOARDING_VIDEO: 'is_onboarding_page_video_watched',
  TASK_ONBOARDING_FB_GROUP: 'is_onboarding_page_fb_group_joined',
  TASK_ONBOARDING_FORM: 'is_onboarding_page_form_completed',
  TASK_ONBOARDING_PAGE: 'is_onboarding_page_done',
  TASK_DASHBOARD_TRACKING_PAGE: 'is_customized_tracking_page_done',
  TASK_DASHBOARD_ADDON_PAGE: 'is_addon_page_configured_done',
  TASK_DASHBOARD_WELCOME_PLAN: 'is_welcome_plan_shown',
  TASK_ONBOARDING_STORE_SYNCED: 'is_onboarding_store_synced',

  ONBOARDING_TASKS: [
    'is_onboarding_page_video_watched',
    'is_onboarding_page_form_completed',
    'is_onboarding_page_fb_group_joined',
  ],

  QUOTA_TYPE_OVER_QUOTA: 5,
  UP_SELL_TYPE_COLLECTION: 'collection',
  FREE_TRACKINGS_FORMAT: 'XXX XXX XXX XXX',
  PRICE_TRACKINGS_FORMAT: 'XXX,XXX,XXX,XXX',

  PROMO_TYPE_PROMO: 'promo',
  PROMO_TYPE_SUBSCRIPTION: 'subscription_plan',

  EMAIL_RULE_SEND_AFTER_IMMEDIATE: 'immediate',
  EMAIL_RULE_SEND_AFTER_AFTER: 'after',

  EMAIL_RULE_SUB_STATUS_ANY_TEXT: 'Any',
  EMAIL_RULE_SUB_STATUS_ANY_VALUE: 'any',

  EMAIL_RULE_STATUS_ON: 'on',
  EMAIL_RULE_STATUS_OFF: 'off',

  BILLING_INTERVAL_30_DAYS: '30_days',
  BILLING_INTERVAL_ANNUAL: 'annual',

  SUBSCRIPTION_PLAN_GROUP_MONTHLY: 'monthly',
  SUBSCRIPTION_PLAN_GROUP_ANNUAL: 'annual',
  SUBSCRIPTION_PLAN_GROUP_SPECIAL: 'specials',

  SUBSCRIPTION_PLAN_HIDDEN_FEATURES: [1, 2, 9],

  DASHBOARD_ANALYTICS_TYPE_SHIPMENT_QUOTA_USAGE: 'SHIPMENT_QUOTA_USAGE',
  DASHBOARD_ANALYTICS_TYPE_REVENUE_STATISTICS: 'REVENUE_STATISTICS',
  DASHBOARD_ANALYTICS_TYPE_DELIVERY_TIMES: 'DELIVERY_TIMES',
  DASHBOARD_ANALYTICS_TYPE_SHIPMENT_PER_STATUS: 'SHIPMENT_PER_STATUS',
  DASHBOARD_ANALYTICS_TYPE_ORDERS_PER_DESTINATION: 'ORDERS_PER_DESTINATION',

  DASHBOARD_ANALYTICS_KIND_SHIPMENT_USAGE: 'shipments_usage',

  STATUS_SLUG_PENDING: 'pending',
  STATUS_SLUG_DELIVERED: 'delivered',

  TRACKING_PAGE_ACTIONS: {
    INIT: 'init',
    UPDATE: 'update',
    RESET: 'reset',
    VALIDATION: 'validation',
    LOCALIZATION_UPDATE: 'localization_update',
    CARRIER_UPDATE: 'carrier_update',
    TRACKING_INS_UPDATE: 'tracking_instrumentation',
    STORE_INFO_UPDATE: 'store_information',
    BLACKLISTING_UPDATE: 'blacklist',
  },

  TRACKING_PRODUCT_REVIEW_OKENDO: 'okendo.io',

  CUSTOM_LANGUAGE_CREATE: 'create',
  CUSTOM_LANGUAGE_EDIT: 'edit',

  REDIRECT_ACTION_SHIPBOB: 'installed_integration_shipbob',
  REDIRECT_ACTION_BILLING: 'billing_change',
  REDIRECT_ACTION_SHIPMENT_DETAIL: 'shipment_detail',

  LEFT_NAVIGATION_EXCLUDED_URLS: [
    '/s',
    '/s/',
    USER_URLS.QUESTIONNAIRE,
    USER_URLS.ADD_STORE,
    PERMISSION_URLS.APPROVE,
    PERMISSION_URLS.RECONNECT,
    ...Object.values(VISITOR_URLS),
    ...(installRoutesMeta() || []).map(({ install_url }) => install_url),
  ],

  TOP_BAR_EXCLUDED_URLS: [
    USER_URLS.QUESTIONNAIRE,
    PERMISSION_URLS.APPROVE,
    PERMISSION_URLS.RECONNECT,
    ...Object.values(VISITOR_URLS),
  ],

  LOCAL_STORAGE: {
    TRACK_ANALYTICS: 'trackAnalytics',
    LAST_STORE_UUID: 'lastStoreUuid',
    ACCESS_TOKEN: 'access_token',
    SHIPMENT_COLUMNS: 'shipmentColumns',
    LANGUAGE: 'rush_app_language',
    SAW_ONBOARDING: 'saw_onboarding',
    CHANGELOG_EXPLORED: 'rush_changelog_explored_for_07-18-2023',
    SHIPMENTS_SORTBY: 'rush_shipments_sortby',
    SHOW_MANAGER_CARD: 'rush_show_manager_card',
    SHOW_FOUNDER_CARD: 'rush_show_founder_card',
    RUSH_CLOSED_BANNERS: 'rush-closed-banners',
    RUSH_SESSION_TOKEN: 'rush_session_token',
    RUSH_SHOP: 'rush_shop',
    SHIPMENT_DETAILS_CARRIER_HIDDEN: 'shipment_details_carrier_hidden',
  },
  CHANGELOG_RELEASE_DATE: '07-18-2023', // MM-DD-YYYY
  FEATURES_SLUG: {
    SMART_EDD: 'smart_delivery_estimate',
    PRE_SHIPMENT: 'pre_shipment',
    SHIPBOB: 'shipbob',
  },
};
