import {
  Modal,
  Box,
  BlockStack,
  Text,
  Select,
  SkeletonBodyText,
} from '@shopify/polaris';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useReprocessShopifyFulfillmentsQuery,
  useSetOnDemandPreferencesMutation,
} from '../ShipmentSettingsApi';
import TranslateLink from 'components/TranslateLink/TranslateLink';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetStoreStatisticsQuery,
  useGetUserDetailsQuery,
} from 'redux/store/commonBaseApis';
import { baseActions } from 'redux/store/baseSlice';
import RushBanner from 'components/custom/RushBanner';
import { LOCAL_STORAGE } from 'Constants';
import { ClientStorage } from 'ClientStorage';

function ReprocessModal(props) {
  const { onClose, onJobStart, isJobRunning } = props;
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [selectedFrom, setSelectedFrom] = useState('');
  const [selectedModify, setSelectedModify] = useState('');
  const storeUUID = useSelector((state) => state.shop?.storeUUID);

  const { data: statisticsData = {} } = useGetStoreStatisticsQuery(storeUUID, {
    skip: !storeUUID,
  });

  const { data: user = {} } = useGetUserDetailsQuery();

  const {
    data = {},
    isLoading,
    isSuccess,
    refetch,
  } = useReprocessShopifyFulfillmentsQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);
  const [
    setOnDemandPreferences,
    {
      data: reProcessResponse = {},
      isLoading: isProcessing,
      isSuccess: isProcessed,
    },
  ] = useSetOnDemandPreferencesMutation();
  const showToast = useCallback((message) => {
    dispatch(
      baseActions.setToastMessage({
        message: message,
        type: 'success',
      })
    );
  }, []);
  useEffect(() => {
    if (!isProcessing && isProcessed) {
      onJobStart(reProcessResponse?.job_status_link);
      showToast(t('shipment_settings_new.fulfillmen.process_running_toast'));
    }
  }, [isProcessing, isProcessed, reProcessResponse]);

  const handleSelectFrom = useCallback((value) => setSelectedFrom(value), []);

  const options = useMemo(() => {
    return (data.range || []).map((d) => {
      return {
        label: d.label,
        value: d.slug,
      };
    });
  }, [data]);

  const items = useMemo(() => {
    return (data.process || []).map((d) => {
      return {
        label: d.label,
        value: d.slug,
      };
    });
  }, [data]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setSelectedFrom((data?.range || [{}])[0]?.slug);
      if (Array.isArray(data?.process) && data?.process.length > 1) {
        setSelectedModify((data?.process || [{}])[1]?.slug);
      }
      if (data?.job_status_link !== null) {
        onJobStart(data?.job_status_link);
      }
    }
  }, [data, isLoading, isSuccess]);

  const handleSelectModify = useCallback(
    (value) => setSelectedModify(value),
    []
  );

  const handleReprocess = () => {
    try {
      const storedDismissedBanners = ClientStorage.get(
        LOCAL_STORAGE.RUSH_CLOSED_BANNERS
      );
      const closedBanners = storedDismissedBanners
        ? JSON.parse(storedDismissedBanners)?.filter(
          (b) => b !== 'reprocess-modal-banner'
        )
        : [];
      closedBanners &&
        ClientStorage.set(
          LOCAL_STORAGE.RUSH_CLOSED_BANNERS,
          JSON.stringify(closedBanners)
        );
      // eslint-disable-next-line no-empty
    } catch { }

    setOnDemandPreferences({
      range: selectedFrom,
      process: selectedModify,
      user_uuid: user.id,
    });
  };

  return (
    <div style={{ height: '500px' }}>
      <Modal
        open={true}
        onClose={onClose}
        title={t('shipment_settings_new.fulfillment.reprocess_modal_title')}
        primaryAction={{
          content: t(
            'shipment_settings_new.fulfillment.reprocess_modal_button'
          ),
          onAction: handleReprocess,
          disabled: isProcessing || isJobRunning,
          loading: isProcessing || isJobRunning,
        }}
      >
        <Modal.Section>
          <Box padding="0">
            <BlockStack gap={'400'}>
              <BlockStack gap={'200'}>
                {isProcessing || isJobRunning ? (
                  <RushBanner
                    bannerKey={'reprocess-modal-banner'}
                    title={t(
                      'shipment_settings_new.fulfillment.reprocess_modal_banner_title'
                    )}
                    tone="info"
                  >
                    <p>
                      {t(
                        'shipment_settings_new.fulfillment.reprocess_modal_banner_description'
                      )}
                    </p>
                  </RushBanner>
                ) : (
                  ''
                )}
                <TranslateLink
                  text={t(
                    'shipment_settings_new.fulfillment.reprocess_modal_description',
                    { email: `**${statisticsData.customers_email}**` }
                  )}
                />
                <BlockStack gap={'400'}>
                  <BlockStack gap={'200'}>
                    <Text as="h6" variant="headingSm">
                      {t(
                        'shipment_settings_new.fulfillment.reprocess_modal_orders_from_title'
                      )}
                    </Text>

                    {isLoading ? (
                      <Box>
                        <SkeletonBodyText lines={1} />
                      </Box>
                    ) : (
                      <Select
                        options={options}
                        onChange={handleSelectFrom}
                        value={selectedFrom}
                        disabled={isProcessing || isJobRunning}
                      ></Select>
                    )}
                  </BlockStack>

                  <BlockStack gap={'300'}>
                    <Text as="h6" variant="headingSm">
                      {t(
                        'shipment_settings_new.fulfillment.reprocess_modal_modify_title'
                      )}
                    </Text>
                  </BlockStack>
                </BlockStack>
                {isLoading ? (
                  <Box>
                    <SkeletonBodyText lines={1} />
                  </Box>
                ) : (
                  <Select
                    options={items}
                    onChange={handleSelectModify}
                    value={selectedModify}
                    disabled={isProcessing || isJobRunning}
                  />
                )}
              </BlockStack>
            </BlockStack>
          </Box>
        </Modal.Section>
      </Modal>
    </div>
  );
}
export default ReprocessModal;
