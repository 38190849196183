import { orderBy } from 'lodash';
import { storeApi } from '../../redux/store/storeApi';

export const shipmentsAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getShipment3Carriers: builder.query({
      query: () => {
        return {
          url: '/carriers',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return {
            carriers: orderBy(response?.data?.carriers || [], [
              (carrier) => carrier.label?.toLowerCase(),
            ]),
          };
        } else {
          return response;
        }
      },
      providesTags: ['shipments-carriers'],
    }),
    getShipments3: builder.query({
      query: (queryParams) => {
        return {
          url: `/shipments3?${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    getShipmentDetails: builder.query({
      query: (shipmentId) => {
        return {
          url: `/shipments3/${shipmentId}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipment-details'],
    }),
    exportShipments3: builder.query({
      query: (urlParams) => {
        return {
          url: `/shipments3/export?${urlParams}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        return response?.data?.url || '';
      },
    }),
    getOrderSyncOptions: builder.query({
      query: () => {
        return {
          url: `/orders/sync-options`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getStoreDestinations: builder.query({
      query: () => {
        return {
          url: `/countries`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getStoreFirstMileCarriers: builder.query({
      query: () => {fl
        return {
          url: `/first-mile-carriers`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getStoreLastMileCarriers: builder.query({
      query: () => {
        return {
          url: `/last-mile-carriers`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    syncOrders: builder.mutation({
      query: (requestBody) => {
        const urlParams = new URLSearchParams({ ...requestBody }).toString();
        return {
          url: `/orders/sync?${urlParams}`,
          method: 'POST',
        };
      },
      // invalidatesTags: ['shipments'],
      invalidatesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    markAsDelivered3: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment/mark-as-delivered`,
          method: 'PUT',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      // invalidatesTags: ['shipments', 'top-messages'],
      invalidatesTags: ['top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    muteNotifications: builder.mutation({
      query: (data) => {
        return {
          url: `/shipment/update`,
          method: 'PATCH',
          body: data,
        };
      },
      // invalidatesTags: ['shipments', 'top-messages'],
      invalidatesTags: ['top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateCarrier3: builder.mutation({
      query: (data) => {
        return {
          url: `/shipment/reset`,
          method: 'PUT',
          body: { ...data },
        };
      },
      // invalidatesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateTrackingDetails3: builder.mutation({
      query: ({ shipmentId, ...data }) => {
        return {
          url: `/shipments/${shipmentId}/tracking-details`,
          method: 'PUT',
          body: { ...data },
        };
      },
      invalidatesTags: ['shipment-details', 'top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    reTrackShipments3: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment/retrack`,
          method: 'PUT',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    reProcessShipments3: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment/charge`,
          method: 'PUT',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    deleteShipments3: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipment`,
          method: 'DELETE',
          body: { shipment_uuid: shipmentUUIds },
        };
      },
      // invalidatesTags: ['shipments', 'top-messages'],
      invalidatesTags: ['top-messages'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetStoreFirstMileCarriersQuery,
  useGetStoreLastMileCarriersQuery,
  useGetStoreDestinationsQuery,
  useGetShipments3Query,
  useGetShipment3CarriersQuery,
  useLazyExportShipments3Query,
  useMarkAsDelivered3Mutation,
  useUpdateCarrier3Mutation,
  useReTrackShipments3Mutation,
  useReProcessShipments3Mutation,
  useDeleteShipments3Mutation,
  useUpdateTrackingDetails3Mutation,
  useGetShipmentDetailsQuery,
  useGetOrderSyncOptionsQuery,
  useSyncOrdersMutation,
  useMuteNotificationsMutation,
} = shipmentsAPIs;
