import React, { useMemo, useState } from 'react';
import { Provider } from '@shopify/app-bridge-react';
import { Layout } from '@shopify/polaris';
import { SHOPIFY_API_KEY } from 'config';
import useGetEmbededHost from 'hooks/useGetEmbededHost';
import RushPage from 'components/custom/RushPage';
import { useLocation, useNavigate } from 'react-router-dom';
import RushBanner from 'components/custom/RushBanner';

export function AppBridgeProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const host = useGetEmbededHost();

  const history = useMemo(
    () => ({
      replace: (path) => {
        navigate(path, { replace: true });
      },
    }),
    [navigate]
  );

  const routerConfig = useMemo(
    () => ({ history, location }),
    [history, location]
  );
  const [appBridgeConfig] = useState(() => {
    return {
      host,
      apiKey: SHOPIFY_API_KEY,
      forceRedirect: true,
    };
  });

  if (!SHOPIFY_API_KEY || !appBridgeConfig.host) {
    const bannerProps = !SHOPIFY_API_KEY
      ? {
          title: 'Missing Shopify API Key',
          children: (
            <>
              Your app is running without the SHOPIFY_API_KEY environment
              variable. Please ensure that it is set when running or building
              your React app.
            </>
          ),
        }
      : {
          title: 'Missing host query argument',
          children: (
            <>
              Your app can only load if the URL has a <b>host</b> argument.
              Please ensure that it is set, or access your app using the
              Partners Dashboard <b>Test your app</b> feature
            </>
          ),
        };

    return (
      <RushPage narrowWidth>
        <Layout>
          <Layout.Section>
            <div style={{ marginTop: '100px' }}>
              <RushBanner
                {...bannerProps}
                status="critical"
                bannerKey={'bridge-banner'}
              />
            </div>
          </Layout.Section>
        </Layout>
      </RushPage>
    );
  }

  return (
    <Provider config={appBridgeConfig} router={routerConfig}>
      {children}
    </Provider>
  );
}
