import React, { useMemo } from 'react';
import { DataTable } from '@shopify/polaris';
import './styles.scss';

function InfoTable(props) {
  const { data = [] } = props;

  const columnsNum = useMemo(() => {
    if (data.length) {
      return Object.keys(data[0]).length;
    }
    return 0;
  }, [data]);

  return (
    <div className="dashboard-info-table">
      <DataTable
        columnContentTypes={Array.from({ length: columnsNum }, (_, i) =>
          i === 0 ? 'text' : 'numeric'
        )}
        headings={[]}
        rows={data}
        increasedTableDensity={true}
        hoverable={false}
      />
    </div>
  );
}

export default InfoTable;
