import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CalloutCard } from '@shopify/polaris';
import ApproveImg from '../../images/reconnect.svg';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import LoadingPage from 'components/LoadingPage';
import { STORE_URLS } from 'config/urls';
import { useLazyConnectStoreQuery } from 'pages/Permissions/permissionsApis';
import { baseActions } from 'redux/store/baseSlice';
import RushPage from 'components/custom/RushPage';

function Reconnect() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [connectStore, { data = {}, isLoading, isSuccess }] =
    useLazyConnectStoreQuery();

  const shop = useSelector((state) => state.shop);
  console.log("🚀 ~ file: Reconnect.js:23 ~ Reconnect ~ shop:", shop)
  const storename = shop?.shopTitle || 'Store';
  const handleSkip = () => {
    dispatch(baseActions.setSkipPermissions(true));
    try {
      navigate(`/s/${shop.storeUUID}${STORE_URLS.DASHBOARD}`);
      // eslint-disable-next-line no-empty
    } catch {}
  };
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1200);
  });

  const handleReconnect = () => {
    connectStore({ uuid: shop.storeUUID, action: 'connect' });
  };

  useEffect(() => {
    if (isSuccess) {
      window.open(data.redirect_uri, '_self');
    }
  }, [data, isSuccess]);

  return (
    <>
      {!visible ? <LoadingPage /> : ''}
      <RushPage fullWidth>
        <div className={`permissions-card ${!visible ? 'hidden' : ''}`}>
          <CalloutCard
            title={t('reconnect.title', { store_name: storename })}
            illustration={ApproveImg}
            primaryAction={{
              content: t('reconnect.primary.action'),
              onAction: handleReconnect,
              loading: isLoading,
            }}
            secondaryAction={{
              content: t('approve.secondary.action'),
              onAction: handleSkip,
            }}
          >
            <p>{t('reconnect.description')}</p>
          </CalloutCard>
        </div>
      </RushPage>
    </>
  );
}

export default Reconnect;
