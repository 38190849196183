import moment from 'moment';

export const formatToCSV = (dataArr, t) => {
  const data = dataArr.reduce((prev, next) => {
    const n = { ...next, edd: moment(next.edd).format('MMM D') };
    prev.push(Object.values(n));
    return prev;
  }, []);

  var csvContent = `${t('smart-edd.order-dates.country')};${t(
    'smart-edd.order-dates.orders'
  )};${t('smart-edd.order-dates.avg-days')};${t(
    'smart-edd.order-dates.avg-business-days'
  )};${t('smart-edd.order-dates.estimated-delivery-date')}\n`;
  data.forEach(function (infoArray, index) {
    const dataString = infoArray.join(';');
    csvContent += index < data.length ? dataString + '\n' : dataString;
  });
  return csvContent;
};

export const exportCSV = (data, t) => {
  var a = document.createElement('a');
  const mimeType = 'application/octet-stream';
  const fileName = 'edd-stats.csv';
  const content = formatToCSV(data, t);
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href =
      'data:application/octet-stream,' + encodeURIComponent(content);
  }
};

export const getVisibilityOpts = (data) => {
  let opts = [];
  if (data.show_on_app_block) {
    opts.push('show_on_app_block');
  }
  if (data.show_on_notification) {
    opts.push('show_on_notification');
  }
  if (data.show_on_tracking_page) {
    opts.push('show_on_tracking_page');
  }
  return opts;
};
