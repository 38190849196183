import React, { useEffect, useState } from 'react';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { useGetAccessTokenMutation } from 'pages/Visitor/visitorAPIs';
import { visitorActions } from 'pages/Visitor/visitorSlice';
import { useDispatch } from 'react-redux';
import { useGetJobStatusQuery } from 'redux/store/commonBaseApis';
import { shopActions } from 'redux/store/shopSlice';
import { useNavigate } from 'react-router-dom';
import { useAppQuery } from 'hooks/useAppQuery';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { SHOPIFY_LOGIN_URL } from 'config';
import { AUTH_URLS } from 'config/urls';

import { isEmbededAppExperience } from 'Utils/utils';
const ottURL = `${SHOPIFY_LOGIN_URL}api/continue-with-rush`;
function RushAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jobId, setJobId] = useState('');
  const [isJobLoading, setIsJobLoading] = useState(true);

  const params = new URLSearchParams(window.location.href);
  const shop = params.get('shop');
  const sessionToken = ClientStorage.get(LOCAL_STORAGE.RUSH_SESSION_TOKEN);

  const {
    data: ottResponse,
    isLoading: isOttLoading,
    isSuccess: isOttLoaded,
  } = useAppQuery({
    url: `${ottURL}/?shop=${shop}&session_token=${sessionToken}`,
    reactQueryOptions: {
      refetchOnReconnect: false,
    },
  });

  const [
    getAccessToken,
    {
      data: tokenResponse,
      isLoading: isTokenLoading,
      isSuccess: isTokenLoaded,
    },
  ] = useGetAccessTokenMutation();

  const {
    data: jobData = {},
    isSuccess: jobStatusSuccess,
    isLoading: jobStatusLoading,
  } = useGetJobStatusQuery(jobId, {
    skip: !jobId,
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (
      !jobStatusLoading &&
      !isTokenLoading &&
      jobStatusSuccess &&
      jobData?.process === 'finished' &&
      isTokenLoaded
    ) {
      // set token once job is also finished
      ClientStorage.set(LOCAL_STORAGE.ACCESS_TOKEN, tokenResponse.access_token);
      ClientStorage.set(LOCAL_STORAGE.RUSH_SHOP, shop);
      dispatch(visitorActions.setAccessToken(tokenResponse.access_token));

      setJobId(null);
      const storeToNavigate =
        jobData?.output?.store_uuid && jobData?.output?.store_uuid !== null
          ? jobData?.output?.store_uuid
          : undefined;

      dispatch(shopActions.setStoreUUID(storeToNavigate));
      ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, storeToNavigate);

      !isTokenLoading && setIsJobLoading(false);
      window.__SHOPIFY_DEV_IS_VERIFIED_APP = true;
      const isTempEmbeddedExp = isEmbededAppExperience(storeToNavigate);
      if (!isTempEmbeddedExp) {
        navigate(`${AUTH_URLS.RUSH_SPLASH}${window.location.search}`, {
          replace: true,
        });
      } else {
        const redirectPath = ClientStorage.get('__REDIRECT_PATH');

        if (redirectPath) {
          ClientStorage.set('__REDIRECT_PATH', '');
          if (redirectPath === AUTH_URLS.SHOPIFY_EXTENSIONS) {
            navigate(`${redirectPath}${window.location.search}`);
          } else {
            navigate(`${redirectPath}`, {
              replace: true,
            });
          }
        } else {
          if (storeToNavigate) {
            navigate(`/s/${storeToNavigate}`);
          } else {
            navigate(`/s`);
          }
        }
      }
    }
  }, [
    jobStatusSuccess,
    jobData,
    jobStatusLoading,
    isTokenLoading,
    isTokenLoaded,
  ]);

  useEffect(() => {
    if (!isOttLoading && isOttLoaded && ottResponse?.query) {
      dispatch(shopActions.setTempStoreDomain(ottResponse?.query?.domain));
      getAccessToken(ottResponse.query?.ott);
      setTimeout(() => {
        setJobId(ottResponse.query?.job_id);
      }, 500);
    }
  }, [isOttLoading, isOttLoaded]);

  return isOttLoading || isJobLoading || isTokenLoading ? <LoadingPage /> : '';
}

export default RushAuth;
