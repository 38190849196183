import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@shopify/polaris';
import { UserPage } from '@rush-deliveries/storybooks-shopify';
import { isEmailValid } from 'Utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import { API_URL } from 'config';
import VisitorHOC from '../VisitorHOC';
import { useRegisterMutation } from 'pages/Visitor/visitorAPIs';
import { visitorActions } from 'pages/Visitor/visitorSlice';
import { useDispatch } from 'react-redux';

function Register({
  visitorLanguages,
  changeVisitorLanguage,
  selectedVisitorLanguage,
  isLoading,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [firstname, setFirstname] = useState(params.get('firstname') || '');
  const [lastname, setLastname] = useState(params.get('lastname') || '');
  const [email, setEmail] = useState(params.get('email') || '');
  const [password, setPassword] = useState('');

  const [showFirstnameError, setShowFirstnameError] = useState(false);
  const [showLastnameError, setShowLastnameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [compaignObj, setConpaignObj] = useState(undefined);

  const [
    register,
    {
      isLoading: isRegisterLoading,
      isSuccess: isRegisterSuccess,
      data: registerData = {},
    },
  ] = useRegisterMutation();

  useEffect(() => {
    const loadUTM = async () => {
      try {
        const response = await fetch(
          'https://assets.rush.app/configs/login.json'
        );
        const res = await response.json();
        const c = (res?.data || []).find(
          ({ utm_campaign }) => utm_campaign === utmCampaign
        );
        if (c) {
          setConpaignObj(c);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const utmCampaign = params.get('utm_campaign');
    if (utmCampaign && !compaignObj) {
      setConpaignObj({});
      loadUTM();
    }
  }, [params, compaignObj]);

  const handleFirstnameChange = useCallback((value) => {
    setShowFirstnameError(false);
    setFirstname(value);
  }, []);

  const handleLastnameChange = useCallback((value) => {
    setShowLastnameError(false);
    setLastname(value);
  }, []);

  const handleEmailChange = useCallback((value) => {
    setShowEmailError(false);
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setShowPasswordError(false);
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setShowFirstnameError(false);
    setShowLastnameError(false);
    setShowEmailError(false);
    setShowPasswordError(false);

    let hasErrors = false;

    if (firstname.length === 0) {
      setShowFirstnameError(true);
      hasErrors = true;
    }

    if (lastname.length === 0) {
      setShowLastnameError(true);
      hasErrors = true;
    }

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (password.length < 6) {
      setShowPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    register({
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      get_params: params,
      http_referrer: document.referrer || undefined,
    });
  };

  const handleGoogleClick = () => {
    let url = `${API_URL}/social/google/redirect?${params.toString()}`;
    if (document.referrer) {
      url += `&http_referrer=${document.referrer}`;
    }
    window.open(url, '_self');
  };
  const handleFacebookClick = () => {
    const url = `${API_URL}/social/facebook/redirect?${params.toString()}`;
    window.open(url, '_self');
  };
  const handleLinkedIdClick = () => {
    const url = `${API_URL}/social/linkedin/redirect?${params.toString()}`;
    window.open(url, '_self');
  };

  const handleSignInClick = () => {
    navigate(`${VISITOR_URLS.LOGIN}${location.search}`);
  };

  useEffect(() => {
    if (!isRegisterLoading && isRegisterSuccess) {
      const email = registerData?.new_contact?.email;
      dispatch(visitorActions.setRegisterPassword(password));
      params.set('token', registerData.token);
      params.set('email', email);
      navigate(`${VISITOR_URLS.EMAIL_VALIDATION}?${params.toString()}`);
    }
  }, [isRegisterLoading, isRegisterSuccess, registerData]);

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={isLoading || isRegisterLoading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={compaignObj?.title || t('registration_screen.title')}
          subtitle={compaignObj?.subtitle || t('registration_screen.sub_title')}
          brandButtons={{
            googleLabel: t('registration_screen.sign_up_with_google'),
            onGoogleClick: handleGoogleClick,
            onFacebookClick: handleFacebookClick,
            onLinkedInClick: handleLinkedIdClick,
          }}
          inputFields={[
            {
              placeholder: t('registration_screen.firstname_placeholder'),
              error: showFirstnameError
                ? t('registration_screen.firstname_required')
                : null,
              value: firstname,
              onChange: handleFirstnameChange,
              type: 'text',
            },
            {
              placeholder: t('registration_screen.lastname_placeholder'),
              error: showLastnameError
                ? t('registration_screen.lastname_required')
                : null,
              value: lastname,
              onChange: handleLastnameChange,
              type: 'text',
            },
            {
              placeholder: t('registration_screen.email_placeholder'),
              error: showEmailError
                ? t('change_email_screen.modal.invalid_email')
                : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
            {
              placeholder: t('registration_screen.password_placeholder'),
              error: showPasswordError
                ? t('errors.password_minimum_length')
                : null,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          button={
            compaignObj?.cta_button || t('registration_screen.register_btn')
          }
          footer1={
            <TranslateLink text={t('registration_screen.footer_message1')} />
          }
          footer2={
            <Text variant="bodyMd" as="span">
              {t('registration_screen.footer_message2')}{' '}
              <Link onClick={handleSignInClick}>
                {t('registration_screen.footer_action_message_link')}
              </Link>
            </Text>
          }
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}
export default VisitorHOC(Register);
