import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  Badge,
  Button,
  Icon,
  ButtonGroup,
  SkeletonBodyText,
  SkeletonDisplayText,
  Form,
  FormLayout,
  TextField,
  Box,
  Text,
  BlockStack,
  InlineStack,
} from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import EmailDeleteModal from './EmailDeleteModal';
import EmailUpdateModal from './EmailUpdateModal';
import VerifyDomainModal from './VerifyDomainModal';
import SmallTopMessage from 'components/SmallTopMessage';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import EmailTestModal from './EmailTestModal';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import './style.scss';
import CustomHistoryPush from 'helpers/redirect';
import { DeleteIcon, ExternalSmallIcon } from '@shopify/polaris-icons';
import { STORE_URLS } from 'config/urls';
import Subtitle from 'components/Subtitle';
import RushPage from 'components/custom/RushPage';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';

export default function EmailNotification({
  getEmailConfig,
  emailConfig,
  emailTriggers,
  activateEmailTrigger,
  deactivateEmailTrigger,
  emailToaster,
  resetEmailTriggerDetail,
  resetEmailToaster,
  updateEmailSender,
  loading,
  shop,
  emailError,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showEmailUpdateModal, setShowEmailUpdateModal] = useState(false);
  const [showEmailDeleteModal, setShowEmailDeleteModal] = useState(false);
  const [emailTriggerById, setEmailTriggerById] = useState(null);
  const [bccEmail, setBccEmail] = useState('');
  const [bccEmailError, setBccEmailError] = useState(null);
  const handleBccEmailChange = useCallback((value) => {
    setBccEmail(value);
  }, []);
  const handleBccEmailBlur = useCallback(() => {
    let isError = false;
    if (bccEmail) {
      const emails = bccEmail.split(',');
      const invalidEmails = emails
        .map((email) => {
          return email.trim().search(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i) < 0;
        })
        .filter((item) => item === true);
      if (invalidEmails.length > 0) {
        isError = true;
      }
    }
    if (!isError) {
      setBccEmailError(null);
      updateEmailSender({ bcc: bccEmail });
    } else {
      setBccEmailError(t('email.page.bcc_email.text_field_error'));
    }
  }, [bccEmail, updateEmailSender, t]);

  const closeEmailDeleteModal = useCallback(() => {
    setShowEmailDeleteModal(false);
    setEmailTriggerById(null);
  }, []);
  const handleEmailUpdateModal = useCallback(
    () => setShowEmailUpdateModal(!showEmailUpdateModal),
    [showEmailUpdateModal]
  );
  const [showVerifyDomainModal, setShowVerifyDomainModal] = useState(false);
  const handleVerifyDomainModal = useCallback(
    () => setShowVerifyDomainModal(!showVerifyDomainModal),
    [showVerifyDomainModal]
  );

  const [showToast, setShowToast] = useState(false);
  const [isShowToastError, setIsShowToastError] = useState(false);
  const [showToastText, setShowToastText] = useState(null);

  const [showEmailTestModal, setShowEmailTestModal] = useState(false);
  const closeEmailTestModal = useCallback(() => {
    setShowEmailTestModal(false);
    setEmailTriggerById(null);
  }, []);

  const dismissShowToast = useCallback(() => {
    setShowToast((active) => !active);
    resetEmailToaster();
  }, [resetEmailToaster]);

  useEffect(() => {
    if (showToast) {
      dispatch(
        baseActions.setToastMessage({
          message: showToastText,
          type: isShowToastError ? 'error' : 'success',
          onDismiss: dismissShowToast,
        })
      );
    }
  }, [showToast]);

  useEffect(() => {
    setShowToast(emailToaster.showToast);
    setIsShowToastError(emailToaster.isErrorToast);
    setShowToastText(emailToaster.toastText);
  }, [emailToaster]);

  useEffect(() => {
    getEmailConfig();
  }, [getEmailConfig]);

  useEffect(() => {
    if (emailConfig.bcc) {
      setBccEmail(emailConfig.bcc);
    }
  }, [emailConfig]);

  if (emailError) {
    navigate(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
    return;
  }

  if (_.isEmpty(emailConfig)) {
    return <LoadingLayoutPage />;
  }

  const activateEmailTriggerById = (triggerId) => {
    setEmailTriggerById(triggerId);
    activateEmailTrigger(triggerId);
  };

  const deactivateEmailTriggerById = (triggerId) => {
    setEmailTriggerById(triggerId);
    deactivateEmailTrigger(triggerId);
  };

  const sendRequestToTest = (triggerId) => {
    setEmailTriggerById(triggerId);
    resetEmailTriggerDetail();
    setShowEmailTestModal(true);
  };
  const sendRequestToDelete = (triggerId) => {
    setEmailTriggerById(triggerId);
    setShowEmailDeleteModal(true);
  };
  const redirectToDetail = (triggerId) => {
    resetEmailTriggerDetail();
    if (triggerId) {
      CustomHistoryPush.historyPush(
        navigate,
        location,
        `/s/${shop.storeUUID}${STORE_URLS.EMAIL_NOTIFICATION_RULE}/${triggerId}`
      );
    } else {
      CustomHistoryPush.historyPush(
        navigate,
        location,
        `/s/${shop.storeUUID}${STORE_URLS.EMAIL_NOTIFICATION_RULE}`
      );
    }
  };

  return (
    <>
      <SmallTopMessage />
      <RushPage
        backAction={{
          url: `/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`,
        }}
        title={t('email.page.title')}
        subtitle={
          <Subtitle
            text={t('email.page.description')}
            actions={[
              {
                text: t('settings.shipbob_pages.subtitle.read_more'),
                source:
                  'https://support.rush.app/activate-rush-smart-email-notifications',
              },
            ]}
          />
        }
      >
        <Layout>
          <Layout.AnnotatedSection
            title={t('email.page.section.send_email.title')}
            description={t('email.page.section.send_email.description')}
          >
            <Card roundedAbove="sm">
              <Text variant="headingMd" as="h2">
                {t('email.page.card.sent_from.title')}
              </Text>
              <div className="">
                <BlockStack gap="400">
                  <Box>
                    <div>
                      <Text variant="bodyMd" as="span">
                        {emailConfig.sender_name}{' '}
                        {`<${emailConfig.sender_email}>`}
                      </Text>
                    </div>
                    {emailConfig.dkim_props && (
                      <div className="Badge">
                        <Badge
                          progress={emailConfig.dkim_props.progress}
                          status={emailConfig.dkim_props.status}
                        >
                          {emailConfig.dkim_label}
                        </Badge>
                      </div>
                    )}
                  </Box>
                  <Box>
                    <Form>
                      <FormLayout>
                        <TextField
                          label={t('email.page.bcc_email.text_field_label')}
                          value={bccEmail}
                          onChange={handleBccEmailChange}
                          helpText={t(
                            'email.page.bcc_email.text_field_help_text'
                          )}
                          onBlur={handleBccEmailBlur}
                          error={bccEmailError}
                          disabled={loading}
                          autoComplete={'off'}
                        />
                      </FormLayout>
                    </Form>
                  </Box>
                </BlockStack>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('email.page.section.notification_events.title')}
            description={t(
              'email.page.section.notification_events.description'
            )}
          >
            <Card roundedAbove="sm" padding={0}>
              <Box padding={'500'}>
                <InlineStack gap={'500'} align="space-between">
                  <Text variant="headingMd" as="h2">
                    {t('email.page.card.notification_events.title')}
                  </Text>
                  <Button plain onClick={() => redirectToDetail()}>
                    {t('email.page.card.notification_events.action')}
                  </Button>
                </InlineStack>
              </Box>

              <div className="EmailList">
                {emailTriggers && (
                  <>
                    <div className="EmailList-Item ">
                      <Text variant="bodyMd" as="span">
                        {t('email.page.card.notification_events.trigger')}
                      </Text>
                    </div>
                    {emailTriggers.map((trigger) => (
                      <React.Fragment key={trigger.id}>
                        {trigger.id === emailTriggerById ? (
                          <div className="EmailList-Item">
                            <div className="TextLoading">
                              <SkeletonBodyText />
                            </div>
                            <div className="ButtonLoading">
                              <SkeletonDisplayText />
                            </div>
                            <div className="ButtonLoading">
                              <SkeletonDisplayText size="small" />
                            </div>
                          </div>
                        ) : (
                          <div className="EmailList-Item">
                            <div className="TextLabel">
                              <TranslateLink text={trigger.summary} />
                              <div>
                                <Badge
                                  progress={trigger.active_props.progress}
                                  tone={trigger.active_props.status}
                                >
                                  {trigger.is_active
                                    ? t('email.trigger_active_status.actived')
                                    : t(
                                      'email.trigger_active_status.deactivated'
                                    )}
                                </Badge>
                              </div>
                            </div>
                            <div className="TestButton">
                              <Button
                                disabled={!_.isNull(emailTriggerById)}
                                variant="plain"
                                onClick={() => sendRequestToTest(trigger.id)}
                              >
                                {t(
                                  'email.page.card.notification_events.test_notification'
                                )}
                              </Button>
                            </div>
                            <div className="Buttons">
                              <ButtonGroup variant='segmented'>
                                {trigger.is_active ? (
                                  <Button
                                    disabled={!_.isNull(emailTriggerById)}
                                    size="slim"
                                    onClick={() => {
                                      deactivateEmailTriggerById(trigger.id);
                                    }}
                                  >
                                    {t(
                                      'email.page.card.notification_events.trigger_disable'
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={!_.isNull(emailTriggerById)}
                                    size="slim"
                                    onClick={() => {
                                      activateEmailTriggerById(trigger.id);
                                    }}
                                  >
                                    {t(
                                      'email.page.card.notification_events.trigger_enable'
                                    )}
                                  </Button>
                                )}
                                <Button
                                  disabled={!_.isNull(emailTriggerById)}
                                  size="slim"
                                  icon={<Icon source={ExternalSmallIcon} />}
                                  onClick={() => {
                                    redirectToDetail(trigger.id);
                                  }}
                                />
                                <Button
                                  disabled={!_.isNull(emailTriggerById)}
                                  size="slim"
                                  icon={<Icon source={DeleteIcon} />}
                                  onClick={() => {
                                    sendRequestToDelete(trigger.id);
                                  }}
                                />
                              </ButtonGroup>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
                {(!emailTriggers || emailTriggers.length === 0) && (
                  <Box>
                    <div className="CustomEmptyState">
                      <Text variant="bodyMd" as="span" color="subdued">
                        {t(
                          'email.page.card.notification_events.no_trigger_message'
                        )}
                      </Text>
                    </div>
                  </Box>
                )}
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </RushPage>
      <EmailDeleteModal
        showEmailDeleteModal={showEmailDeleteModal}
        closeEmailDeleteModal={closeEmailDeleteModal}
        triggerId={emailTriggerById}
      />
      <EmailUpdateModal
        showEmailUpdateModal={showEmailUpdateModal}
        handleEmailUpdateModal={handleEmailUpdateModal}
      />
      <VerifyDomainModal
        showVerifyDomainModal={showVerifyDomainModal}
        handleVerifyDomainModal={handleVerifyDomainModal}
      />
      <EmailTestModal
        closeEmailTestModal={closeEmailTestModal}
        showEmailTestModal={showEmailTestModal}
        emailTriggerById={emailTriggerById}
      />
    </>
  );
}
