import React, { useState, useMemo, useEffect } from 'react';
import {
  Text,
  Button,
  Card,
  TextField,
  SkeletonBodyText,
  BlockStack,
  Box,
  InlineStack,
} from '@shopify/polaris';
import { Trans, useTranslation } from 'react-i18next';

import { ClipboardIcon } from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
import {
  useGetPermaLinkQuery,
  useUpdatePermalinkMutation,
} from '../newTrackingPageApis';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';
import ExternalLink from 'components/ExternalLink';
import RushLoading from 'components/custom/RushLoader';

function Permalink() {
  const dispatch = useDispatch();

  const [t] = useTranslation();
  const [value, setValue] = useState('');

  const permalinkTitle = t('tracking_page_new.permalink_title');
  const permalinkDescription = t('tracking_page_new.permalink_description');
  const permalinkPlaceholder = t('tracking_page_new.permalink_prefix');
  const permalinkUpdateButton = t('tracking_page_new.permalink_update_button');

  const { data: planResponse = {}, isLoading: isPlanLoading } =
    useGetCurrentBillingPlanQuery();

  const currentPlan = useMemo(() => {
    return planResponse?.billing_plan?.subscription_plan || {};
  }, [planResponse]);

  const isFreeOrBasicPlan = useMemo(() => {
    return [1217, 1218, 3].includes(currentPlan.id);
  }, [currentPlan]);

  const { data = {}, isLoading, isSuccess, refetch } = useGetPermaLinkQuery();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (Array.isArray(data) && data.length) {
        setValue(data[0].code);
      }
    }
  }, [isLoading, isSuccess]);

  const [updatePermalink, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdatePermalinkMutation();

  useEffect(() => {
    if (!isUpdating && isUpdated) {
      refetch();
      dispatch(
        baseActions.setToastMessage({
          message: t('common.updated-successfully', { entity: 'Code' }),
          type: 'success',
        })
      );
    }
  }, [isUpdating, isUpdated]);

  const handleUpdatePermalink = () => {
    if (!isFreeOrBasicPlan) {
      updatePermalink(value);
    }
  };

  const copyLink = () => {
    const prefix =
      Array.isArray(data) && data.length
        ? data[0].trac_url
        : permalinkPlaceholder;

    navigator.clipboard.writeText(prefix + value);
    dispatch(
      baseActions.setToastMessage({
        message: 'Copied to clipboard',
        type: 'success',
      })
    );
  };

  const permalinkFooter = useMemo(() => {
    const nonCustomLink =
      Array.isArray(data) && data.length
        ? data.find((d) => d.is_custom === false)
        : {};
    const link =
      nonCustomLink.trac_url + nonCustomLink.code || permalinkPlaceholder;
    return (
      <Trans
        i18nKey="tracking_page_new.permalink_footer"
        values={{
          permalink: link,
        }}
        components={[<ExternalLink key={link} external={true} url={link} />]}
      ></Trans>
    );
  }, [data]);

  const freePlanFooter = t('tracking_page_new.permalink_footer_free_plan');

  return (
    <>
      {isLoading || isUpdating ? <RushLoading /> : ''}
      <Card roundedAbove="sm">
        <BlockStack gap="500">
          <BlockStack gap="200">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {permalinkTitle}
            </Text>

            <Text color={'subdued'}>{permalinkDescription}</Text>
          </BlockStack>
          {isLoading ? (
            <Box>
              <SkeletonBodyText />
            </Box>
          ) : (
            <BlockStack gap="200">
              <Box padding="0" paddingBlockStart={'0'}>
                <InlineStack gap="200" blockAlign="center">
                  <TextField
                    autoComplete={'off'}
                    prefix={
                      Array.isArray(data) && data.length
                        ? data[0].trac_url
                        : permalinkPlaceholder
                    }
                    value={value}
                    onChange={setValue}
                    disabled={isFreeOrBasicPlan}
                    suffix={
                      <div style={{ marginTop: '6px' }}>
                        <Button
                          plain
                          icon={ClipboardIcon}
                          onClick={copyLink}
                        />
                      </div>
                    }
                  />
                  <Button
                    primary
                    onClick={handleUpdatePermalink}
                    disabled={isPlanLoading || isLoading || isFreeOrBasicPlan} // disable for free plan
                  >
                    {permalinkUpdateButton}
                  </Button>
                </InlineStack>
              </Box>

              {isPlanLoading ? (
                ''
              ) : (
                <Box padding="0">
                  <Text color={'subdued'}>
                    {!isFreeOrBasicPlan ? permalinkFooter : freePlanFooter}
                  </Text>
                </Box>
              )}
            </BlockStack>
          )}
        </BlockStack>
      </Card>
    </>
  );
}

export default Permalink;
