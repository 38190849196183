import { storeApi } from './storeApi';
import orderBy from 'lodash/orderBy';

const commonStoreAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrdersTags: builder.query({
      query: (queryString) => {
        return {
          url: `/orders/tags?search=${queryString}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getProducts: builder.query({
      query: (queryString) => {
        return {
          url: `/products?search=${queryString}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getProductsByIds: builder.query({
      query: (ids) => {
        return {
          url: `/products?external_ids=${ids}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getCurrentBillingPlan: builder.query({
      query: () => {
        return {
          url: `/billing/plans/current`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getCountries: builder.query({
      query: () => {
        return {
          url: '/geo/countries',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return orderBy(response?.data, 'label');
        } else {
          return [];
        }
      },
    }),
    getSmallTopMessages: builder.query({
      query: () => {
        return {
          url: '/small-top-messages',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['top-messages'],
    }),
    getFeatures: builder.query({
      query: () => {
        return {
          url: `/features/features`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    uploadLogo: builder.mutation({
      query: (file) => {
        const logoFile = new FormData();
        logoFile.append('file', file);
        logoFile.append('path', 'logo');
        return {
          url: `/uploads`,
          method: 'POST',
          body: logoFile,
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetCurrentBillingPlanQuery,
  useGetOrdersTagsQuery,
  useLazyGetOrdersTagsQuery,
  useLazyGetProductsQuery,
  useGetCountriesQuery,
  useLazyGetProductsByIdsQuery,
  useGetSmallTopMessagesQuery,
  useGetFeaturesQuery,
  useUploadLogoMutation,
} = commonStoreAPIs;
