import { useSelector } from 'react-redux';
import { useGetStoreStatisticsQuery } from 'redux/store/commonBaseApis';

function useAdminLink() {
  const { storeUUID } = useSelector((state) => state.shop);
  const { data = {} } = useGetStoreStatisticsQuery(storeUUID, {
    skip: !storeUUID,
  });

  return data.admin_link;
}

export default useAdminLink;
