import { storeApi } from '../../redux/store/storeApi';

export const shipmentSettingsApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getRules: builder.query({
      query: () => {
        return {
          url: `/tracking-number-rules`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipmentSettings'],
    }),
    applyToPendingShipments: builder.mutation({
      query: (config) => {
        return {
          url: `/tracking-number-rules/apply-to-pending-shipments`,
          method: 'POST',
          body: { ...config },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateRules: builder.mutation({
      query: (rulesData) => {
        return {
          url: `/tracking-number-rules`,
          method: 'PUT',
          body: { rules: [...rulesData] },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['shipmentSettings'],
    }),

    getShipmentSettings: builder.query({
      query: () => {
        return {
          url: `/shipment-settings`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipmentSettings'],
    }),
    reprocessShopifyFulfillments: builder.query({
      query: () => {
        return {
          url: `/notifications/shopify-fulfillment/reprocess`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getFulfilmentPreferences: builder.query({
      query: () => {
        return {
          url: `/channels/shopify-fulfilment-preferences`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipmentSettings'],
    }),
    setOnDemandPreferences: builder.mutation({
      query: (data) => {
        return {
          url: `/notifications/shopify-fulfillment/reprocess`,
          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateFulfilmentPreferences: builder.mutation({
      query: (value) => {
        return {
          url: `/channels/shopify-fulfilment-preferences/${value}`,
          method: 'PATCH',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['shipmentSettings'],
    }),
    updateShipmentSettings: builder.mutation({
      query: (config) => {
        return {
          url: `/shipment-settings`,
          method: 'PUT',
          body: { ...config },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['shipmentSettings'],
    }),
    pickTrigger: builder.mutation({
      query: (config) => {
        return {
          url: `/channels/shopify-fulfilment-preferences/triggers/enable`,
          method: 'PATCH',
          body: { ...config },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['shipmentSettings'],
    }),
  }),
});

export const {
  useGetRulesQuery,
  useUpdateRulesMutation,
  useApplyToPendingShipmentsMutation,
  useUpdateShipmentSettingsMutation,
  useGetShipmentSettingsQuery,
  useGetFulfilmentPreferencesQuery,
  useUpdateFulfilmentPreferencesMutation,
  usePickTriggerMutation,
  useReprocessShopifyFulfillmentsQuery,
  useSetOnDemandPreferencesMutation,
} = shipmentSettingsApi;
