import React from 'react';
import { Card, ChoiceList, Text, BlockStack } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function ColorStyles({ selected, onChange }) {
  const [t] = useTranslation();

  const title = t('tracking_page_new.modern.color_styles.title');
  const dark = t('settings.tracking_page.section_page_layout.map.dark_label');
  const light = t('settings.tracking_page.section_page_layout.map.light_label');
  return (
    <Card roundedAbove="sm">
      <BlockStack gap={'500'}>
        <Text variant="headingMd" as="h2">
          {title}{' '}
        </Text>
        <div className="small-gap-list">
          <ChoiceList
            title=""
            choices={[
              {
                label: dark,
                value: 'dark',
              },
              {
                label: light,
                value: 'light',
              },
            ]}
            selected={selected === null ? 'dark' : [selected]}
            onChange={([value]) => {
              onChange('page_layout', value);
            }}
          />
        </div>
      </BlockStack>
    </Card>
  );
}

export default ColorStyles;
