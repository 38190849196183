import React, { useEffect } from 'react';
import {
  Layout,
  Icon,
  ResourceList,
  ResourceItem,
  Text,
  Card,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { GOOGLE_TAG_ID, FULL_STORY_ORG } from 'config';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import SmallTopMessage from 'components/SmallTopMessage';
import { ClientStorage } from 'ClientStorage';
import _ from 'lodash';
import { STORE_URLS } from 'config/urls';
import { LOCAL_STORAGE } from 'Constants';
import { useDispatch, useSelector } from 'react-redux';
import RightPanel from './RightPanel';
import { useGetShipmentsPerformanceQuery } from '../DashboardApi';
import './style.scss';
import SkeletonDashboardPage from './SkeletonDashboardPage';
import { baseActions } from 'redux/store/baseSlice';
import RushLoading from 'components/custom/RushLoader';
import RushPage from 'components/custom/RushPage';

export default function Dashboard() {
  const [t] = useTranslation();

  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const base = useSelector((state) => state.base);

  const needActionsResponse = useGetShipmentsPerformanceQuery();
  const { data = {}, isFetching: shipmentsFetching } =
    needActionsResponse || {};
  const shipmentNeedAction = data.shipment_actions || [];

  useEffect(() => {
    if (ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) !== true) {
      return;
    }
    if (!base.gtmAppVisit) {
      if (GOOGLE_TAG_ID && GOOGLE_TAG_ID !== 'null') {
        sendDataToGTM({
          event: 'appVisit',
        });
      }
      if (FULL_STORY_ORG && FULL_STORY_ORG !== 'null' && window.FS) {
        window.FS.event('appVisit');
      }
      dispatch(baseActions.markGtmAppVisitTrue());
    }
  }, [base.gtmAppVisit]);

  return (
    <>
      {shipmentsFetching ? (
        <SkeletonDashboardPage />
      ) : (
        <>
          <SmallTopMessage />
          <RushPage titleHidden fullWidth={false} narrowWidth={false}>
            <Layout>
              <Layout.Section variant="oneHalf">
                <Layout>
                  <Layout.Section>
                    <Text variant="headingMd" as="h2">
                      {t('dashboard.need_action.card.title')}
                    </Text>
                  </Layout.Section>
                  <Layout.Section>
                    <Card roundedAbove="sm" padding={0}>
                      <div className="dashboard-resource-list">
                        <ResourceList
                          resourceName={{
                            singular: 'shipment',
                            plural: 'shipments',
                          }}
                          items={shipmentNeedAction}
                          renderItem={(item, index) => {
                            let url = item.shipment_get_query
                              ? `/s/${shop.storeUUID}${STORE_URLS.SHIPMENTS}?${item.shipment_get_query}`
                              : null;
                            if (_.isNull(url)) {
                              url = item.link ? item.link : null;
                            }
                            return (
                              <ResourceItem
                                id={index}
                                url={url}
                                accessibilityLabel={item.message}
                                external={!!item.link}
                              >
                                <div className="Dashboard-Shipments">
                                  <div className="Dashboard-Shipments-IconHolder">
                                    <div className="Image">
                                      <span>
                                        <img
                                          src={item.icon}
                                          className="NeedAction-Icon"
                                          alt={item.message}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="Dashboard-Shipments-Heading">
                                    <TranslateLink text={item.message} />
                                  </div>
                                  {url && (
                                    <div className="Dashboard-Shipments-Next">
                                      <Icon
                                        source={ChevronRightIcon}
                                        color="skyDark"
                                      />
                                    </div>
                                  )}
                                </div>
                              </ResourceItem>
                            );
                          }}
                        />
                      </div>
                    </Card>
                  </Layout.Section>
                </Layout>
              </Layout.Section>
              <Layout.Section variant="oneHalf">
                <Layout>
                  <Layout.Section>
                    <Text variant="headingMd" as="h2">
                      {t('dashboard.analytics_sheet.title')}
                    </Text>
                  </Layout.Section>
                  <Layout.Section>
                    <RightPanel />
                  </Layout.Section>
                </Layout>
              </Layout.Section>
            </Layout>
          </RushPage>
        </>
      )}
      {shipmentsFetching ? <RushLoading /> : ''}
    </>
  );
}
