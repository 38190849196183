import {
  Button,
  ButtonGroup,
  Card,
  Icon,
  Link,
  RangeSlider,
  Spinner,
  Box,
  Tooltip,
  Text,
  InlineStack,
  BlockStack,
} from '@shopify/polaris';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPlansQuery, useSubscripPlanMutation } from '../billingApis';
import { billingActions } from '../billingSlice';
import { featuresList, quotaPrice, transformToNumStr } from './utils';
import { useGetStoreStatisticsQuery } from 'redux/store/commonBaseApis';
import {
  XCircleIcon,
  MinusIcon,
  CheckSmallIcon,
} from '@shopify/polaris-icons';
import './styles.scss';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';
import RushLoading from 'components/custom/RushLoader';
import RushModal from 'components/custom/RushModal';
import useIsEmbededApp from 'hooks/useIsEmbededApp';

function UpdatePlanModal() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const storeUUID = useSelector((state) => state.shop?.storeUUID);
  const { showUpdateModal } = useSelector((state) => state.billing);
  const isEmbeddedApp = useIsEmbededApp();
  const [selectedPeriod, setSelectedPeriod] = useState('annual');
  const [rangeValue, setRangeValue] = useState(1);

  const { data: currentPlan = {}, refetch } = useGetCurrentBillingPlanQuery(
    undefined,
    {
      skip: !showUpdateModal,
    }
  );

  const [
    subscripPlan,
    { isLoading: isSubscripingPlan, isSuccess, data: subscriptionResponse },
  ] = useSubscripPlanMutation();

  useEffect(() => {
    if (!isSubscripingPlan && isSuccess) {
      if (subscriptionResponse.charge_url) {
        window.top.location.replace(subscriptionResponse.charge_url);
      } else if (!isEmbeddedApp) {
        window.location.reload();
      } else {
        refetch();
      }
    }
  }, [isSubscripingPlan, isSuccess, subscriptionResponse]);

  const {
    data: plansInfo = {},
    isLoading: isPlansLoading,
    isSuccess: isPlansLoaded,
  } = useGetPlansQuery(undefined, {
    skip: !showUpdateModal,
  });

  const {
    data: statisticsData = {},
    isSuccess: isStatisticsLoaded,
    isLoading: isStatisticsLoading,
    isError,
  } = useGetStoreStatisticsQuery(storeUUID, {
    skip: !storeUUID || !showUpdateModal,
  });

  const isMonthlyPlan = useMemo(() => {
    return currentPlan?.billing_plan?.subscription_plan
      ?.is_charge_limit_supported;
  }, [currentPlan, showUpdateModal]);

  const appliedPeriod = useMemo(() => {
    return isMonthlyPlan ? '30_days' : 'annual';
  }, [isMonthlyPlan]);

  useEffect(() => {
    if (isMonthlyPlan) {
      setSelectedPeriod('30_days');
    } else {
      setSelectedPeriod('annual');
    }
  }, [isMonthlyPlan]);

  const planGroups = useMemo(() => {
    if (isPlansLoaded && plansInfo && showUpdateModal) {
      return groupBy(plansInfo?.subscription_plans, 'billing_interval');
    }
  }, [isPlansLoaded, plansInfo, showUpdateModal]);

  const currentPlanGroup = useMemo(() => {
    if (planGroups && showUpdateModal) {
      return planGroups[selectedPeriod];
    } else {
      return [];
    }
  }, [planGroups, selectedPeriod, showUpdateModal]);

  const quotaPriceGroupRanges = useMemo(() => {
    return showUpdateModal
      ? (currentPlanGroup || []).reduce(
        (prev, next) => {
          const quotaFeature = next?.plan_features?.find(
            ({ feature_id }) => feature_id === 2
          );
          const priceFeature = next?.plan_features?.find(
            ({ feature_id }) => feature_id === 1
          );
          const updateFrequency = next?.plan_features?.find(
            ({ feature_id }) => feature_id === 47
          );
          prev[priceFeature.price] = {
            price: priceFeature.price,
            quota: quotaFeature.quota,
            updateFrequency: updateFrequency?.plan_value,
            group: next?.group_slug,
            name: next?.name,
            planId: next?.id,
          };
          return prev;
        },
        selectedPeriod === '30_days'
          ? {
            [0]: {
              price: 0,
              quota: 50,
              updateFrequency: 6,
              group: 'basic',
              name: 'Free',
              planId: plansInfo?.free_subscription_plan_id,
            },
          }
          : {}
      )
      : {};
  }, [currentPlanGroup, selectedPeriod, showUpdateModal, plansInfo]);

  const appliedPlanInfo = useMemo(() => {
    const appliedQuota = currentPlan.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === 2
    )?.quota;
    const appliedPrice = currentPlan.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === 1
    )?.price;
    return {
      ...currentPlan.billing_plan?.subscription_plan,
      quota: appliedQuota,
      price: appliedPrice,
      trial_expired_at: currentPlan.billing_plan?.trial_expired_at,
      remaining_days_for_trial: currentPlan.remaining_days_for_trial,
      is_in_trial: currentPlan.billing_plan?.is_in_trial,
      tier: currentPlan.billing_plan?.tier,
    };
  }, [currentPlan, showUpdateModal]);

  const suggestedIndex = useMemo(() => {
    if (
      !isStatisticsLoading &&
      isStatisticsLoaded &&
      statisticsData?.statistics &&
      showUpdateModal
    ) {
      try {
        const ms = selectedPeriod === 'annual' ? 12 : 1;
        const currentUsage =
          statisticsData.statistics?.platform_orders_per_month || 1;
        const nextShipments = currentUsage * 1.1 * ms;
        const rangeValues = Object.values(quotaPriceGroupRanges);

        const suggestedIndex = rangeValues.findIndex(
          ({ quota }) => quota > nextShipments
        );

        /**
         * adding this check as we don't want to say user for donwgrading his plan based on his current usage
         */
        const suggestedPrice = rangeValues[suggestedIndex]?.price;
        if (appliedPlanInfo.price >= suggestedPrice) {
          return -1;
        }

        return suggestedIndex;
      } catch (e) {
        return -1;
      }
    } else {
      return -1;
    }
  }, [
    isStatisticsLoading,
    isStatisticsLoaded,
    statisticsData,
    selectedPeriod,
    quotaPriceGroupRanges,
    showUpdateModal,
  ]);

  /**
   * this check is for those clients which are on special discount hense plan will not be found in plans list
   */
  const isAppliedPlanInPlans = useMemo(() => {
    const currentPlanIndex = Object.keys(quotaPriceGroupRanges).findIndex(
      (price) => Number(price) === Number(appliedPlanInfo.price)
    );
    return currentPlanIndex > -1;
  }, [appliedPlanInfo, quotaPriceGroupRanges]);

  const appliedPlanIndex = useMemo(() => {
    const currentPlanIndex = Object.keys(quotaPriceGroupRanges).findIndex(
      (price) => Number(price) === Number(appliedPlanInfo.price)
    );
    return currentPlanIndex > -1 ? currentPlanIndex : 0;
  }, [appliedPlanInfo, quotaPriceGroupRanges]);

  useEffect(() => {
    if (currentPlan.billing_plan && showUpdateModal) {
      if (appliedPlanInfo?.tier === 'free' || !isAppliedPlanInPlans) {
        setRangeValue(suggestedIndex);
      } else {
        setRangeValue(appliedPlanIndex);
      }
    }
  }, [
    appliedPlanInfo,
    showUpdateModal,
    appliedPlanIndex,
    suggestedIndex,
    isAppliedPlanInPlans,
  ]);

  const quotaRanges = useMemo(() => {
    return Object.values(quotaPriceGroupRanges || {}).map(({ quota }) => quota);
  }, [quotaPriceGroupRanges]);

  const handleClose = useCallback(() => {
    dispatch(billingActions.toggleUpdateModal(false));
  }, []);

  const handleRangeSliderChange = useCallback(
    (value) => setRangeValue(value),
    []
  );

  const startButtonLabel = () => {
    const { remaining_days_for_trial, is_in_trial, tier } = appliedPlanInfo;
    if (rangeValue <= 0 && selectedPeriod === '30_days') {
      return t('update_plan_modal.start_free');
    } else if (
      appliedPlanIndex === rangeValue &&
      selectedPeriod === appliedPeriod
    ) {
      return t('update_plan_modal.current_plan');
    } else if (is_in_trial) {
      return t('update_plan_modal.continue_trial', {
        remaining_days_for_trial,
      });
    } else if (tier === 'free') {
      return remaining_days_for_trial
        ? t('update_plan_modal.start_trial', { remaining_days_for_trial })
        : t('update_plan_modal.subscribe');
    } else {
      const selectedPlan = Object.values(quotaPriceGroupRanges)?.find(
        ({ quota }) => quota === quotaRanges[rangeValue]
      );
      const planLabel = selectedPlan?.name;
      const planPrice = selectedPlan?.price;
      const selectedDivider = selectedPeriod === '30_days' ? 1 : 12;
      const appliedDivider = appliedPeriod === '30_days' ? 1 : 12;

      return appliedPlanInfo.price / appliedDivider <
        planPrice / selectedDivider
        ? t('update_plan_modal.upgrade_to_plan', { planLabel })
        : t('update_plan_modal.downgrade_to_plan', { planLabel });
    }
  };

  const setSuggestedQuota = () => {
    setRangeValue(suggestedIndex);
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [isError]);

  return (
    <>
      {isSubscripingPlan ? <RushLoading /> : ''}

      <RushModal open={showUpdateModal} onClose={handleClose} size="large" titleHidden>
        {isPlansLoading ? (
          <Card roundedAbove="sm">
            <br />
            <br />
            <InlineStack blockAlign="center" align="center">
              <Spinner />
            </InlineStack>
            <br />
            <br />
          </Card>
        ) : (
          <Card roundedAbove="sm" padding={0}>
            <Box width="95%" padding={'500'}>
              <InlineStack align="space-between">
                <Box>
                  <InlineStack blockAlign="center">
                    <Text variant="headingMd" as="h2">
                      {t('billing.update_plan_title', {
                        period_label:
                          selectedPeriod === '30_days'
                            ? t('billing_intervals.monthly')
                            : t('billing_intervals.yearly'),
                      })}
                      ?
                    </Text>
                    <div className="m-close-button">
                      <Button
                        variant="plain"
                        icon={XCircleIcon}
                        onClick={handleClose}
                      />
                    </div>
                  </InlineStack>
                </Box>
                <Box>
                  <ButtonGroup variant='segmented'>
                    <Button
                      variant={selectedPeriod === '30_days' ? 'primary' : undefined}
                      tone={selectedPeriod === '30_days' ? 'success' : undefined}
                      size="large"
                      onClick={() => {
                        setSelectedPeriod('30_days');
                        setRangeValue(isMonthlyPlan ? appliedPlanIndex : 0);
                      }}
                    >
                      {plansInfo?.groups?.monthly?.caption}
                    </Button>
                    <Button
                      variant={selectedPeriod === 'annual' ? 'primary' : undefined}
                      tone={selectedPeriod === 'annual' ? 'success' : undefined}
                      size="large"
                      onClick={() => {
                        setSelectedPeriod('annual');
                        setRangeValue(!isMonthlyPlan ? appliedPlanIndex : 0);
                      }}
                    >
                      {plansInfo?.groups?.annual?.caption}
                    </Button>
                  </ButtonGroup>
                </Box>
              </InlineStack>

              <Box paddingBlockStart="300">
                <RangeSlider
                  label=""
                  min={0}
                  max={quotaRanges.length - 1}
                  value={rangeValue}
                  onChange={handleRangeSliderChange}
                  prefix={<p>{transformToNumStr(quotaRanges[0])}</p>}
                  suffix={
                    <p>
                      {transformToNumStr(quotaRanges[quotaRanges.length - 1])}
                    </p>
                  }
                />
                <BlockStack gap={'500'}>
                  <Text variant="bodyMd" as="span" tone="subdued">
                    {suggestedIndex !== -1 && (
                      <Trans
                        i18nKey="billing.quota_message"
                        values={{
                          quota: transformToNumStr(quotaRanges[suggestedIndex]),
                        }}
                        components={[<Link onClick={setSuggestedQuota} />]}
                      ></Trans>
                    )}
                  </Text>
                  {selectedPeriod === '30_days' ? (
                    <Text variant="bodyMd" as="span">
                      <Trans
                        i18nKey="billing.payment_stats_extra"
                        values={{
                          quota: transformToNumStr(quotaRanges[rangeValue]),
                          price: quotaPrice(
                            quotaPriceGroupRanges,
                            quotaRanges[rangeValue]
                          ),
                          extra: '$0.07',
                          period: t('billing_intervals.monthly'),
                        }}
                        components={[<strong />]}
                      ></Trans>
                    </Text>
                  ) : (
                    <Text variant="bodyMd" as="span">
                      <Trans
                        i18nKey="billing.payment_stats"
                        values={{
                          quota: transformToNumStr(quotaRanges[rangeValue]),
                          price: quotaPrice(
                            quotaPriceGroupRanges,
                            quotaRanges[rangeValue]
                          ),
                          period: t('billing_intervals.yearly'),
                        }}
                        components={[<strong />]}
                      ></Trans>
                    </Text>
                  )}
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      tone="success"
                      onClick={() => {
                        const rValue = rangeValue === -1 ? 0 : rangeValue;
                        subscripPlan(
                          Object.values(quotaPriceGroupRanges)?.find(
                            ({ quota }) => quota === quotaRanges[rValue]
                          )?.planId
                        );
                      }}
                      loading={isSubscripingPlan}
                      disabled={
                        ((appliedPlanIndex === 0 && rangeValue === -1) ||
                          appliedPlanIndex === rangeValue) &&
                        selectedPeriod === appliedPeriod &&
                        isAppliedPlanInPlans
                      }
                    >
                      {startButtonLabel()}
                    </Button>
                    <Button
                      onClick={() => {
                        window.open(
                          ' https://web.rush.app/contact-sales?utm_source=app.rush.app&utm_medium=app.rush.app',
                          '_blank'
                        );
                      }}
                    >
                      {t('billing.talk_to_sales')}
                    </Button>
                  </ButtonGroup>
                </BlockStack>
              </Box>
            </Box>
            <Box
              padding={'500'}
              borderBlockStartWidth="100"
              borderColor="border-brand"
            >
              <InlineStack align="space-between">
                <Box>
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    {t('billing.included_in_plan', {
                      plan: Object.values(quotaPriceGroupRanges)?.find(
                        ({ quota }) => quota === quotaRanges[rangeValue]
                      )?.name,
                    })}
                  </Text>
                </Box>

                <Box>
                  <Button
                    varaint="plain"
                    onClick={() => {
                      window.open(
                        'https://web.rush.app/pricing?shipments=2000&period=monthly&utm_source=app.rush.app&utm_medium=app.rush.app',
                        '_blank'
                      );
                    }}
                  >
                    {t('billing.full_comparison')}
                  </Button>
                </Box>
              </InlineStack>
              <br />
              <div className="features-container">
                {featuresList(
                  Object.values(quotaPriceGroupRanges)?.find(
                    ({ quota }) => quota === quotaRanges[rangeValue]
                  )
                )?.map(({ title, tooltip, enabled }) => {
                  return (
                    <Tooltip content={tooltip} key={title}>
                      <InlineStack gap="100" align="start">
                        <Box>
                          <Icon
                            source={enabled ? CheckSmallIcon : MinusIcon}
                            tone={enabled ? 'success' : 'subdued'}
                          />
                        </Box>
                        <Text
                          variant="bodyMd"
                          as="span"
                          tone={enabled ? 'base' : 'subdued'}
                        >
                          <div className="usage">{title}</div>
                        </Text>
                      </InlineStack>
                    </Tooltip>
                  );
                })}
              </div>
            </Box>
          </Card>
        )}
      </RushModal>
    </>
  );
}

export default UpdatePlanModal;
