import React from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

import { FEATURE_OMNISEND_PAGE } from 'Constants';
import CustomToggleSettings from 'components/CustomToggleSettings';

export default function OmnisendPageCustomization({
  deactivateFeature,
  activateFeature,
  localOmnisendObj,
  omnisendConfig,
  setLocalOmnisendObj,
  resetOmnisend,
  resetOmnisendConfig,
}) {
  const [t] = useTranslation();

  const { config = {} } = localOmnisendObj;
  const { is_active = false } = config;

  const manageEnableDisable = () => {
    setLocalOmnisendObj({
      config: {
        ...omnisendConfig,
        is_active: !is_active,
      },
      isUpdated: false,
    });
    if (localOmnisendObj && is_active) {
      deactivateFeature(FEATURE_OMNISEND_PAGE);
      resetOmnisendConfig();
    } else {
      activateFeature(FEATURE_OMNISEND_PAGE);
      resetOmnisend();
    }
  };

  const contentLocalizationActiveStatus = is_active
    ? t('settings.thankyou_page.customization.enable.button_on')
    : t('settings.thankyou_page.customization.enable.button_off');

  return (
    <Layout.AnnotatedSection
      title={t('settings.omnisend.section.title')}
      description={t('settings.omnisend.section.description')}
    >
      <CustomToggleSettings
        buttonLabel={contentLocalizationActiveStatus}
        onToggle={manageEnableDisable}
        isActive={is_active}
        content={t('settings.omnisend.enable.section.title')}
      ></CustomToggleSettings>
    </Layout.AnnotatedSection>
  );
}
