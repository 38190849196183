import React from 'react';
import ReactMarkdown from 'react-markdown';
import ExternalLink from '../ExternalLink';

export default function TranslateLink(props) {
  return (
    <ReactMarkdown
      children={props.text}
      components={{
        a: ({ ...renderProps }) => {
          return (
            <ExternalLink
              external={true}
              {...props}
              url={renderProps.href}
              onClick={(e) => props.onClick && props.onClick(e, renderProps)}
            >
              {renderProps.children?.length ? renderProps.children[0] : ''}
            </ExternalLink>
          );
        },
      }}
    />
  );
}
