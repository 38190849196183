import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useIsEmbededApp from './useIsEmbededApp';
import { AccessToken } from 'pages/Visitor/visitorSlice';
import { AUTH_URLS } from 'config/urls';
import { ClientStorage } from 'ClientStorage';
const authPaths = [
  AUTH_URLS.SHOPIFY_AUTH,
  AUTH_URLS.RUSH_AUTH,
  AUTH_URLS.RUSH_APPROVAL,
  AUTH_URLS.RUSH_EXIT_IFRAME,
];
function useEmbededAppRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = useSelector(AccessToken);
  const isEmbededApp = useIsEmbededApp();

  /**
   * if in embedded case user redirects to any of the routes directly then save redirect path, authorize and then navigate to that path again
   */
  useEffect(() => {
    if (
      isEmbededApp &&
      !accessToken &&
      !authPaths.includes(location.pathname)
    ) {
      location.pathname !== '/' &&
        ClientStorage.set('__REDIRECT_PATH', location.pathname);

      navigate(
        { pathname: AUTH_URLS.SHOPIFY_AUTH, search: location.search },
        {
          replace: true,
        }
      );
    }
  }, [accessToken, isEmbededApp, location]);

  return '';
}

export default useEmbededAppRedirect;
